<div class="video">
  <app-videoplayer
    class="shadow-xl"
    [autoplay]="false"
    [resetOnEnd]="true"
    [video]="currentVideo"
    data-testid="help-video-player"
  ></app-videoplayer>
</div>
<div class="menu">
  <mat-button-toggle-group name="videos" aria-label="Videos">
    <mat-button-toggle     (click)="selectVideo(item.key)"
                           *ngFor="let item of videos | keyvalue"
                           [class.active]="currentVideo === item.key"
                           class="menu-item"
                           data-testid="help-video">{{ item.value }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>
