import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { inject, Injectable, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { AdministrationComponent } from './administration/administration.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { FeedbackOverviewComponent } from './feedback-overview/feedback-overview.component';
import { InsurancesImportComponent } from './insurances-import/insurances-import.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { AdminManagementRolesComponent } from './admin-management-roles/admin-management-roles.component';
import { WhitelistAccessDialogComponent } from './whitelist-access-dialog/whitelist-access-dialog.component';
import { WhitelistedAccessesOverviewComponent } from './whitelisted-accesses-overview/whitelisted-accesses-overview.component';
import { WhitelistedAccessesComponent } from './whitelisted-accesses/whitelisted-accesses.component';
import {
  MatLegacyPaginatorIntl,
  MatLegacyPaginatorModule as MatPaginatorModule
} from '@angular/material/legacy-paginator';
import { RansomClauseInitializationComponent } from './ransom-clause-initialization/ransom-clause-initialization.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AdminManagementUserComponent } from './admin-management-user/admin-management-user.component';
import { QuickInvitationComponent } from './quick-invitation/quick-invitation.component';
import { CompanySubscriptionsComponent } from '../subscriptions/company-subscriptions/company-subscriptions.component';
import { AdminManagementCompanyComponent } from './admin-management-company/admin-management-company.component';
import { TranslocoService } from '@ngneat/transloco';
import { first, tap } from 'rxjs/operators';
import { PhishingMailsManagementComponent } from './phishing-mails-management/phishing-mails-management.component';

@Injectable()
class GermanPaginatorIntl extends MatLegacyPaginatorIntl {
  #transloco = inject(TranslocoService);

  constructor() {
    super();

    this.#transloco
      .selectTranslateObject('paginator')
      .pipe(
        first(),
        tap(paginator => {
          this.firstPageLabel = paginator.firstPageLabel;
          this.lastPageLabel = paginator.lastPageLabel;
          this.nextPageLabel = paginator.nextPageLabel;
          this.previousPageLabel = paginator.previousPageLabel;
          this.itemsPerPageLabel = paginator.itemsPerPageLabel;
          this.getRangeLabel = this.#getGermanRangeLabel;
        })
      )
      .subscribe();
  }

  #getGermanRangeLabel(page: number, pageSize: number, length: number) {
    const of = this.#transloco.translate('paginator.of');

    if (length == 0 || pageSize == 0) {
      return `0 ${of} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  }
}

@NgModule({
  declarations: [
    WhitelistedAccessesComponent,
    AdministrationComponent,
    WhitelistedAccessesOverviewComponent,
    WhitelistAccessDialogComponent,
    InsurancesImportComponent,
    CompanyManagementComponent,
    AdminManagementRolesComponent,
    FeedbackOverviewComponent,
    FeedbackDetailsComponent,
    NewsletterComponent,
    RansomClauseInitializationComponent,
    AdminManagementComponent,
    AdminManagementUserComponent
  ],
  imports: [
    CommonModule,
    LayoutsModule,
    MatTabsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    ColibriModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MaterialFileInputModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatTooltipModule,
    TranslateModule,
    MatPaginatorModule,
    QuickInvitationComponent,
    CompanySubscriptionsComponent,
    AdminManagementCompanyComponent,
    PhishingMailsManagementComponent
  ],
  exports: [
    WhitelistedAccessesComponent,
    AdministrationComponent,
    WhitelistedAccessesOverviewComponent,
    WhitelistAccessDialogComponent,
    InsurancesImportComponent,
    CompanyManagementComponent,
    AdminManagementRolesComponent,
    FeedbackOverviewComponent,
    FeedbackDetailsComponent,
    NewsletterComponent
  ],
  providers: [
    {
      provide: MatLegacyPaginatorIntl,
      useClass: GermanPaginatorIntl
    }
  ]
})
export class AdministrationModule {}
