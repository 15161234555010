import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerLatestResourceClicksStatisticComponent } from '../partner-latest-resource-clicks-statistic/partner-latest-resource-clicks-statistic.component';
import { PartnerResourceClicksStatisticComponent } from '../partner-resource-clicks-statistic/partner-resource-clicks-statistic.component';
import { PartnerStatisticsKurseBewertungComponent } from '../partner-statistics-kurse-bewertung/partner-statistics-kurse-bewertung.component';
import { PartnerWebinarStatisticComponent } from '../partner-webinar-statistic/partner-webinar-statistic.component';
import { PartnerToolkitUserUsageStatisticComponent } from '../partner-toolkit-user-usage-statistic/partner-toolkit-user-usage-statistic.component';
import { PartnerToolkitBusinessSectorUsageStatisticComponent } from '../partner-toolkit-business-sector-usage-statistic/partner-toolkit-business-sector-usage-statistic.component';
import { PartnerWebinarStatisticCircleProgressComponent } from '../partner-webinar-statistic-circle-progress/partner-webinar-statistic-circle-progress.component';

@Component({
  selector: 'app-partner-statistics',
  standalone: true,
  imports: [
    CommonModule,
    PartnerLatestResourceClicksStatisticComponent,
    PartnerResourceClicksStatisticComponent,
    PartnerStatisticsKurseBewertungComponent,
    PartnerWebinarStatisticComponent,
    PartnerToolkitUserUsageStatisticComponent,
    PartnerToolkitBusinessSectorUsageStatisticComponent,
    PartnerWebinarStatisticCircleProgressComponent
  ],
  templateUrl: './partner-statistics.component.html',
  styleUrl: './partner-statistics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerStatisticsComponent {}
