<app-back-layout [backUrl]="urlBuilder.goToUserEdit()" title="E-Mail-Adresse ändern">
  <wv-card *ngIf="email && token" class="content">
    <p>{{ 'userProfile.edit.changeEmailDescription' | transloco: {email: email} | renderHTML }}</p>
    <form (ngSubmit)="onSubmit(updateEmailForm, $event)" [formGroup]="updateEmailForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ 'form.label.password' | transloco }}</mat-label>
        <input [type]="passwordHide ? 'password' : 'text'" formControlName="password" matInput />
        <button
          (click)="passwordHide = !passwordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide"
          [tabIndex]="-1"
          mat-icon-button
          matSuffix
          type="button"
        >
          <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="updateEmailForm.get('password').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
      </mat-form-field>
      <button [disabled]="updateEmailForm.invalid" appearance="primary" wvButton>
        {{ 'userProfile.edit.changeEmailSubmitButton' | transloco }}
      </button>
    </form>
  </wv-card>
</app-back-layout>
