import {AbstractControl, ValidationErrors} from "@angular/forms";

/**
 * Custom Validators for this app
 */
export class CustomValidators {
  //EMAIL_REGEXP inspired by default E-Mail Validator https://github.com/angular/angular/blob/main/packages/forms/src/validators.ts. Just added äöüÄÖÜß to the regex.
  private static EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-ZäöüÄÖÜß0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-ZäöüÄÖÜß0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-ZäöüÄÖÜß0-9](?:[a-zA-ZäöüÄÖÜß0-9-]{0,61}[a-zA-ZäöüÄÖÜß0-9])?(?:\.[a-zA-ZäöüÄÖÜß0-9](?:[a-zA-ZäöüÄÖÜß0-9-]{0,61}[a-zA-ZäöüÄÖÜß0-9])?)*$/;
  private static USERNAME_REGEXP = /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\-._@+äöüÄÖÜß]+$/;
  private static QUICKINVITE_REGEXP = /^([a-zA-Z0-9äöüÄÖÜß._\-+]+@[a-zA-Z0-9äöüÄÖÜß._\-+]+\.[a-zA-Z]+\,.+\,.+\,.+\,(true|false)\n*)+$/;

  /**
   * Check for valid german e-mail adresses with umlauts
   * Returns 'email' validation error if e-mail address is invalid
   * @param control
   */
  static email(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional controls
    }

    return CustomValidators.EMAIL_REGEXP.test(control.value)
        ? null
        : {'email': true};
  }

  /**
   * Check for valid ASP.NET Identity username
   * Returns 'username' validation error if username is invalid
   * @param control
   */
  static username(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional controls
    }

    return CustomValidators.USERNAME_REGEXP.test(control.value)
        ? null
        : {'username': true};
  }

  static quickInviteCsv(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.QUICKINVITE_REGEXP.test(control.value)
        ? null
        : {'quickInviteCsv': true}
  }
}

function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
      ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}
