import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";

@Pipe({
  name: 'dateDistanceToNow'
})
export class DateDistanceToNowPipe implements PipeTransform {
  transform(date: string): string {
    return moment(date, null, "de").toNow(true);
  }
}
