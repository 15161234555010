import { TextValidator } from '@ac/colibri';
import { TaskCompletion, TaskComponent, TaskQuestion, TemporaryEmailTask } from '@ac/exam';
import { Component, effect, ElementRef, signal, viewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiValidationService } from '../api-validation.service';
import { TranslocoService } from '@ngneat/transloco';
import { LoadingDialogService } from '@app/layouts/loading-dialog/loading-dialog.service';

@Component({
  selector: 'ac-two-questions',
  templateUrl: './temporary-email.component.html',
  styleUrls: ['./temporary-email.component.scss']
})
export class TemporaryEmailComponent implements TaskComponent<TemporaryEmailTask> {
  configuration: TemporaryEmailTask;
  formQuestionOne: UntypedFormGroup | undefined;
  formQuestionTwo: UntypedFormGroup | undefined;
  formsSubmitted = signal({
    one: false,
    two: false
  });
  submittedEmail: string;
  answerTwoForm = viewChild<ElementRef>('answerTwoForm');
  private complete$$ = new Subject<TaskCompletion>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiValidationService: ApiValidationService,
    private transloco: TranslocoService,
    private loadingDialogService: LoadingDialogService
  ) {
    effect(() => {
      if (this.formsSubmitted().one && this.answerTwoForm() && !this.loadingDialogService.isOpen()) {
        this.answerTwoForm().nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }); //Scroll to second answer form when first form is submitted and dialog confirmed
  }

  complete = () => this.complete$$.asObservable();

  prepare(configuration: TemporaryEmailTask): void {
    this.configuration = configuration;

    this.formQuestionOne = this.buildFormForQuestion(this.configuration.questionOne);
    this.formQuestionTwo = this.buildFormForQuestion(this.configuration.questionTwo);
  }

  submitAnswerOne() {
    if (this.configuration.questionOne.inputField.validationEndpoint) {
      this.submittedEmail = this.formQuestionOne.value.answer;

      this.apiValidationService
        .validate(
          this.configuration.questionOne.inputField.validationEndpoint,
          JSON.stringify(this.formQuestionOne.value.answer)
        )
        .pipe(take(1))
        .pipe(
          catchError(error => {
            this.formQuestionOne = this.buildFormForQuestion(this.configuration.questionOne);
            this.formsSubmitted.update(formsSubmitted => ({ ...formsSubmitted, one: false }));
            return throwError(error);
          })
        )
        .subscribe(response => {
          this.configuration.solution = response.toString();
          this.formsSubmitted.update(formsSubmitted => ({ ...formsSubmitted, one: true }));
        });
    } else {
      this.formsSubmitted.update(formsSubmitted => ({ ...formsSubmitted, one: true }));
    }
  }

  submitAnswerTwo() {
    this.formsSubmitted.update(formsSubmitted => ({ ...formsSubmitted, two: true }));

    this.transloco
      .selectTranslate('exam.temporaryEmail.wrongCode', {
        code: this.configuration.solution
      })
      .subscribe(translation => {
        if (this.formQuestionTwo.value.answer === this.configuration.solution) {
          this.complete$$.next({ isCorrect: true });
        } else {
          this.complete$$.next({
            isCorrect: false,
            summary: {
              wrong: translation
            }
          });
        }
      });
  }

  private buildFormForQuestion(taskQuestion: TaskQuestion): UntypedFormGroup {
    const conditionalValidators = taskQuestion.inputField.rejectValuePattern
      ? [TextValidator.reject(taskQuestion.inputField.rejectValuePattern)]
      : [];

    return this.formBuilder.group(
      { answer: ['', [Validators.required, ...conditionalValidators]] },
      { updateOn: 'change' }
    );
  }
}
