import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingDialogState } from '../../../../../apps/app/src/app/layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../../../../../apps/app/src/app/layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../../../../../apps/app/src/app/layouts/loading-dialog/loading-dialog.service';

@Injectable({ providedIn: 'root' })
export class ApiValidationService {
  constructor(
    private http: HttpClient,
    private loadingDialog: LoadingDialogService,
    private translocoService: TranslocoService
  ) {}

  validate(url: string, body): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http.post<string>(url, body).pipe(
      tap({
        next: () =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: this.translocoService.translate('exam.temporaryEmail.submittedDialogText')
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        throw message;
      })
    );
  }
}
