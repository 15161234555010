import { ChangeDetectionStrategy, Component, effect, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DefaultChartOptionsService } from '../../../../phishing-mails/default-chart-options.service';

@Component({
  selector: 'app-partner-fehlenden-ressourcen',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-statistics-fehlenden-ressourcen.component.html',
  styleUrl: './partner-statistics-fehlenden-ressourcen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerStatisticsFehlendenRessourcenComponent {
  chart = viewChild(BaseChartDirective);
  chartType: ChartType = 'treemap';
  #defaultChartOptions = inject(DefaultChartOptionsService);

  chartData = {
    datasets: [
      {
        tree: [
          { name: ['Vorlage:','Auftragsverarbeitungsvertrag (AVV)','nach DSGVO'], value: 330 },
          { name: ['Checkliste:','Was tun im Falle eines Cyber-Angriffs?'], value: 24 },
          { name: ['Vorlage:','Datenschutzhinweise für Webseiten nach DSGVO'], value: 130 },
          { name: ['Vorlage:','Verfahrensverzeichnis gemäß DSGVO'], value: 412 },
          { name: ['Meldepflichten bei Datenschutzverletzungen:','Wann, wie und wo melden?'], value: 25 },
          { name: ['Vorlage:','Vertraulichkeitsvereinbarung (NDA)','für IT-Dienstleister und Mitarbeiter'], value: 250 },
          { name: ['Vorlage:','Datenschutz-Folgenabschätzung (DFA)'], value: 190 }
        ],
        treeLeafKey: 'name',
        key: 'value',
        borderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor,
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[0],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[0],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor,
        labels: {
          align: 'center',
          display: true,
          formatter(ctx) {
            if (ctx.type !== 'data') {
              return;
            }
            return [...ctx.raw._data.name, '', ctx.raw.v];
          },
          color: 'white',
          font: {
            size: 16
          },
          position: 'center',
          overflow: 'fit'
        }
      }
    ]
  };
  chartOptions: ChartOptions<'treemap'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title(items) {
            const dataItem = items[0].raw;
            const obj = (dataItem as any)._data;
            return obj.name;
          }
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          }
        },
        pan: {
          enabled: true,
        },
        limits: {
          y: {min: 'original', max: 'original'},
          x: {min: 'original', max: 'original'},
        },
      }
    },
    transitions: {
      zoom: {
        animation: {
          duration: 0
        }
      }
    }
  };

  constructor() {
    effect(() => {
      if (this.chart()) {
        this.chart().update();
      }
    });
  }
}
