import { FeedbackDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { FeedbackApiService } from '../../feedback/feedback-api.service';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent implements OnInit {
  feedback$: Observable<FeedbackDto>;

  constructor(
    private feedbackApi: FeedbackApiService,
    private route: ActivatedRoute,
    public urlBuilder: UrlBuilder
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => (this.feedback$ = this.feedbackApi.getFeedback(params.feedbackId)));
  }

  createMailToLink(email: string, feedbackId: string): string {
    return `mailto:${email}?subject=[Cyber Portal] Feedback #${feedbackId}`;
  }
}
