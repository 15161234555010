import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, forkJoin, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from '@env';
import { BrowserInfoService } from '../browser-info.service';
import { IdentityService } from '../identity/identity.service';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from '../url-builder.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private internalServerErrors = [500, 501, 502, 503, 504, 0];
  private permissionErrors = [401, 403];
  private resourceErrors = [404];

  constructor(
    private router: Router,
    private urls: UrlBuilder,
    private loadingDialog: LoadingDialogService,
    private datePipe: DatePipe,
    private identityService: IdentityService,
    private browserInfo: BrowserInfoService,
    private transloco: TranslocoService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('password-policy')) {
      next.handle(req);
    }
    if (!req.url.includes(environment.apiUrl)) {
      next.handle(req);
    }

    return next.handle(req).pipe(
      //timeout(environment.requestTimeoutMs),
      catchError((error: HttpErrorResponse | TimeoutError) => {
        if (error instanceof TimeoutError) {
          this.showTimeoutDialog(this.goToCyberPortal);
          return EMPTY;
        }

        //INTERNAL SERVER ERROR
        if (this.internalServerErrors.includes(error.status)) {
          this.showErrorDialog(this.goToCyberPortal);
          return EMPTY;
        }

        //NO PERMISSIONS
        if (this.permissionErrors.includes(error.status)) {
          if (this.identityService.isTokenExpired()) {
            this.showTokenExpiredDialog(this.logout);
            return EMPTY;
          }
          this.showNoPermissionDialog(this.goToLogin);
          return EMPTY;
        }

        //PAGE NOT FOUND
        if (this.resourceErrors.includes(error.status)) {
          this.showPageNotFoundDialog(this.goToCyberPortal);
          return EMPTY;
        }

        return throwError(error);
      })
    );
  }

  private goToLogin = () => this.router.navigateByUrl(this.urls.goToLogin());

  private goToCyberPortal = () => this.router.navigateByUrl(this.urls.goToHome());

  private logout = () => this.router.navigateByUrl(this.urls.goToLogout());

  private showErrorDialog(navigationTargetAfterClosing: () => Promise<boolean>): void {
    forkJoin({
      title: this.transloco.selectTranslate('error.failure.title').pipe(take(1)),
      description: this.transloco
        .selectTranslate('error.failure.description', {
          actionInfo: this.getActionInfo(),
          browserInfo: this.createBrowserInfoText()
        })
        .pipe(take(1))
    }).subscribe(translations => {
      this.loadingDialog.open(LoadingDialogComponent, {
        data: LoadingDialogState.Error,
        title: translations.title,
        content: translations.description,
        afterClose: navigationTargetAfterClosing
      });
    });
  }

  //todo: move out of interceptor
  private getActionInfo(): string {
    const date = this.datePipe.transform(new Date(), 'dd.MM.yyyy');
    const time = this.datePipe.transform(new Date(), 'HH:mm:ss');

    return 'Automatisch erfasste Informationen: ' + `%0ADatum: ${date}` + `%0AUhrzeit: ${time}`;
  }

  private createBrowserInfoText() {
    const browserName = this.browserInfo.getBrowserInfo().browserName;
    const fullVersion = this.browserInfo.getBrowserInfo().browserVersion;

    return 'Vewendeter Browser:' + `%0AName: ${browserName}` + `%0AVersion: ${fullVersion}`;
  }

  private showNoPermissionDialog(navigationTargetAfterClosing: () => Promise<boolean>) {
    forkJoin({
      title: this.transloco.selectTranslate('error.noPermission.title').pipe(take(1)),
      description: this.transloco.selectTranslate('error.noPermission.description').pipe(take(1))
    }).subscribe(translations => {
      this.loadingDialog.open(LoadingDialogComponent, {
        data: LoadingDialogState.Error,
        title: translations.title,
        content: translations.description,
        afterClose: navigationTargetAfterClosing
      });
    });
  }

  private showPageNotFoundDialog(navigationTargetAfterClosing: () => Promise<boolean>) {
    forkJoin({
      title: this.transloco.selectTranslate('error.pageNotFound.title').pipe(take(1)),
      description: this.transloco.selectTranslate('error.pageNotFound.description').pipe(take(1))
    }).subscribe(translations => {
      this.loadingDialog.open(LoadingDialogComponent, {
        data: LoadingDialogState.Error,
        title: translations.title,
        content: translations.description,
        afterClose: navigationTargetAfterClosing
      });
    });
  }

  private showTimeoutDialog(navigationTargetAfterClosing: () => Promise<boolean>) {
    forkJoin({
      title: this.transloco.selectTranslate('error.timeout.title').pipe(take(1)),
      description: this.transloco.selectTranslate('error.timeout.description').pipe(take(1))
    }).subscribe(translations => {
      this.loadingDialog.open(LoadingDialogComponent, {
        data: LoadingDialogState.Error,
        title: translations.title,
        content: translations.description,
        afterClose: navigationTargetAfterClosing
      });
    });
  }

  private showTokenExpiredDialog(navigationTargetAfterClosing: () => Promise<boolean>) {
    forkJoin({
      title: this.transloco.selectTranslate('error.tokenExpired.title').pipe(take(1)),
      description: this.transloco.selectTranslate('error.tokenExpired.description').pipe(take(1))
    }).subscribe(translations => {
      this.loadingDialog.open(LoadingDialogComponent, {
        data: LoadingDialogState.Error,
        title: translations.title,
        content: translations.description,
        afterClose: navigationTargetAfterClosing
      });
    });
  }
}
