import { CoursePlayerLessonDto } from '@ac/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-player-playlist-lesson',
  templateUrl: './course-player-playlist-lesson.component.html',
  styleUrls: ['./course-player-playlist-lesson.component.scss']
})
export class CoursePlayerPlaylistLessonComponent {
  @Input() lesson: CoursePlayerLessonDto;
}
