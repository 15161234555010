import { Component, computed, effect, inject, signal } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';
import { PhishingMailsCompanyStatisticService } from '../phishing-mails-company-statistic.service';
import { DateRange, Preset } from '@ac/colibri';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { Router } from '@angular/router';
import { isDate } from '../../utils/is-date';
import { environment } from '@env';
import { injectParams } from 'ngxtension/inject-params';
import { UrlBuilder } from '../../url-builder.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-phishing-mails-company-statistic',
  templateUrl: './phishing-mails-company-statistic.component.html',
  styleUrls: ['./phishing-mails-company-statistic.component.scss']
})
export class PhishingMailsCompanyStatisticComponent {
  range = signal<DateRange | null>(null);
  queryRange = injectQueryParams(params => ({ from: params.from, to: params.to }));
  protected readonly environment = environment;
  #params = injectParams();
  #urlBuilder = inject(UrlBuilder);
  #location = inject(Location);
  #phishingMailsStatisticService = inject(PhishingMailsCompanyStatisticService);
  #identityService = inject(IdentityService);
  #companyId = computed(() => this.#params().companyId || this.#identityService.getCompanyId());
  phishingMailOptions = this.#phishingMailsStatisticService.getStatisticOptions(this.#companyId()).result;
  min = computed(() => (this.phishingMailOptions().data ? new Date(this.phishingMailOptions().data.min) : null));
  max = computed(() => (this.phishingMailOptions().data ? new Date(this.phishingMailOptions().data.max) : null));
  presets = computed<Preset[]>(() =>
    this.phishingMailOptions().data
      ? this.phishingMailOptions().data.presets.map(p => ({
          label: p.label,
          range: { from: new Date(p.range.from), to: new Date(p.range.to) }
        }))
      : []
  );
  #phishingMailsCompanyStatisticQuery = this.#phishingMailsStatisticService.getStatistics(this.#companyId());
  phishingMailsCompanyStatistic = this.#phishingMailsCompanyStatisticQuery.result;

  dateRangeDisabled = computed(
    () => this.phishingMailOptions().data && !this.phishingMailOptions().data.allowConfiguration
  );

  protected isPackageUpgradeRecommended = computed(
    () => this.phishingMailOptions().data && !this.phishingMailOptions().data.allowConfiguration
  );

  constructor() {
    effect(() => this.#handleMissingUserIdUrlParam(), { allowSignalWrites: true });
    effect(() => this.#initializeStatisticDateRange(), { allowSignalWrites: true });
    effect(() => this.#updateStatisticDateRangeFromQueryParams(), { allowSignalWrites: true });
    effect(() => this.#updateStatisticDateRangeOnInputChange(), { allowSignalWrites: true });
  }

  #initializeStatisticDateRange() {
    if (!this.range() && this.phishingMailOptions().data) {
      this.range.set(this.phishingMailOptions().data.defaultRange);
    }
  }

  #updateStatisticDateRangeOnInputChange() {
    if (this.range()) {
      const queryOptions = this.#phishingMailsStatisticService.getStatisticsQueryOptions(
        this.#companyId(),
        this.range()
      );
      this.#phishingMailsCompanyStatisticQuery.updateOptions(queryOptions);
      this.#location.replaceState(
        this.#urlBuilder.goToPhishingMailsCompany(this.#companyId()),
        `from=${this.range().from.toISOString()}&to=${this.range().to.toISOString()}`
      );
    }
  }

  #updateStatisticDateRangeFromQueryParams() {
    const from = isDate(this.queryRange().from) ? new Date(this.queryRange().from) : null;
    const to = isDate(this.queryRange().from) ? new Date(this.queryRange().to) : null;

    if (from && to && from >= this.min() && to <= this.max()) {
      this.range.set({ from, to });
    }
  }

  #handleMissingUserIdUrlParam() {
    this.#location.replaceState(this.#urlBuilder.goToPhishingMailsCompany(this.#companyId()));
  }
}
