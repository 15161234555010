import { AccountantRegistrationDetailsDto, PasswordPolicyDto } from '@ac/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '../../../environments/environment';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-accountant-registration',
  templateUrl: './accountant-registration.component.html',
  styleUrls: ['./accountant-registration.component.scss']
})
export class AccountantRegistrationComponent implements OnInit {
  environment = environment;

  @Output() accountantDetails = new EventEmitter<AccountantRegistrationDetailsDto>();
  @Output() back = new EventEmitter<void>();
  passwordPolicy$: Observable<PasswordPolicyDto>;
  video: VideoDto = {
    captions: [
      {
        language: 'DE',
        label: 'Deutsch',
        source: environment.resources.helpVideoAccountantRegistrationSubtitles
      }
    ],
    streamUrl: environment.resources.helpVideoAccountantRegistration,
    downloadUrl: null
  };

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.passwordPolicy$ = this.identityService.getPasswordPolicy();
  }

  emitAccountantDetails(dto: AccountantRegistrationDetailsDto): void {
    this.accountantDetails.emit(dto);
  }

  goBack() {
    this.back.emit();
  }
}
