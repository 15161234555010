import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from '../url-builder.service';
import { EMPTY, Observable } from 'rxjs';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { catchError } from 'rxjs/operators';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ToolkitApiService {
  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService, private urlBuilder: UrlBuilder) {}

  checkPassword(passwordHashPrefix: string): Observable<{ [hashSuffix: string]: number }> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .post<{ [hashSuffix: string]: number }>(
        environment.apiUrl + environment.apiRoutes.toolkitPasswordChecks,
        JSON.stringify(passwordHashPrefix)
      )
      .pipe(
        catchError(message => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: message.error
          });
          return EMPTY;
        })
      );
  }
}
