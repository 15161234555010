<table *ngIf="dataSource" [dataSource]="dataSource" mat-table matSort>
  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.companyName' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.name }}</td>
    <td *matFooterCellDef mat-footer-cell>{{ getCompanyCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="accountantName">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.accountantName' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>
      <a [href]="'mailto:' + company.accountantEmail">{{ company.accountantName }}</a>
    </td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="employeeCount">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.employeeCount' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.employeeCount }}</td>
    <td *matFooterCellDef mat-footer-cell>{{ getTotalEmployeeCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="officeEmployeeCount">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.officeEmployeeCount' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.officeEmployeeCount }}</td>
    <td *matFooterCellDef mat-footer-cell>{{ getTotalOfficeEmployeeCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="businessSector">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.businessSector' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.businessSector }}</td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="numberOfUsers">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.numberOfUsers' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.numberOfUsers }}</td>
    <td *matFooterCellDef mat-footer-cell>{{ getTotalUserCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="numberOfPendingRegistrations">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>
      {{ 'tableColumn.numberOfPendingRegistrations' | transloco }}
    </th>
    <td *matCellDef="let company" mat-cell>{{ company.numberOfPendingRegistrations }}</td>
    <td *matFooterCellDef mat-footer-cell>{{ getTotalPendingRegistrationsCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.createdAt' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.createdAt | date: 'dd.MM.yyyy' }}</td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  <tr *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
