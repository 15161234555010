<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Vogel & Partner - Integrationen'"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="w-full"
    color="accent"
  >
    <mat-tab
      [label]="'Online-Kalender'"
    >
      <ng-template matTabContent>
        <img ngSrc="./assets/images/calendly.PNG" alt="Calendly" height="590" width="829" />
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Integration 2'"
    >
      <ng-template matTabContent>
        Bald verfügbar!
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Integration 3'"
    >
      <ng-template matTabContent>
        Bald verfügbar!
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
