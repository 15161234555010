<wv-card [title]="'Hinzufügen'">
  <form [formGroup]="createForm" class="grid grid-cols-3 gap-4">
    <mat-form-field appearance="outline">
      <mat-label>CompanyId</mat-label>
      <input formControlName="companyId" matInput />
      <mat-error *ngIf="createForm.get('companyId').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>PackageValue</mat-label>
      <input formControlName="packageValue" matInput type="number" />
      <mat-error *ngIf="createForm.get('packageValue').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('packageValue').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('packageValue').errors?.max">
        {{ 'form.error.max' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>From</mat-label>
      <input formControlName="from" matInput placeholder="yyyy-MM-dd">
      <mat-error *ngIf="createForm.get('from').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Duration in Months</mat-label>
      <input formControlName="durationInMonths" matInput type="number" />
      <mat-error *ngIf="createForm.get('durationInMonths').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('durationInMonths').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>LicenseCosts</mat-label>
      <input formControlName="licenseCosts" matInput type="number" />
      <mat-error *ngIf="createForm.get('licenseCosts').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Minimum Quantity</mat-label>
      <input formControlName="minimumQuantity" matInput type="number" />
      <mat-error *ngIf="createForm.get('minimumQuantity').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('minimumQuantity').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <button wvButton [disabled]="createForm.invalid" (click)="subscribe()">Abonnieren</button>
  </form>
</wv-card>

<wv-card [title]="'Aktualisieren'">
  <form [formGroup]="updateForm" class="grid grid-cols-3 gap-4">
    <mat-form-field appearance="outline">
      <mat-label>SubscriptionId</mat-label>
      <input formControlName="subscriptionId" matInput />
      <mat-error *ngIf="updateForm.get('subscriptionId').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>PackageValue</mat-label>
      <input formControlName="packageValue" matInput type="number" />
      <mat-error *ngIf="updateForm.get('packageValue').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('packageValue').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('packageValue').errors?.max">
        {{ 'form.error.max' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>From</mat-label>
      <input formControlName="from" matInput placeholder="yyyy-MM-dd">
      <mat-error *ngIf="updateForm.get('from').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Duration in Months</mat-label>
      <input formControlName="durationInMonths" matInput type="number" />
      <mat-error *ngIf="updateForm.get('durationInMonths').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('durationInMonths').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>LicenseCosts</mat-label>
      <input formControlName="licenseCosts" matInput type="number" />
      <mat-error *ngIf="updateForm.get('licenseCosts').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Minimum Quantity</mat-label>
      <input formControlName="minimumQuantity" matInput type="number" />
      <mat-error *ngIf="updateForm.get('minimumQuantity').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="createForm.get('minimumQuantity').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <button wvButton [disabled]="updateForm.invalid" (click)="update()">Aktualisieren</button>
  </form>
</wv-card>

<wv-card [title]="'Simulationen pausieren / fortsetzen'">
  <form [formGroup]="simulationForm" class="grid gap-4">
    <mat-form-field appearance="outline">
      <mat-label>SubscriptionId</mat-label>
      <input formControlName="subscriptionId" matInput />
      <mat-error *ngIf="updateForm.get('subscriptionId').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <div class="flex gap-4">
      <button wvButton [disabled]="simulationForm.invalid" (click)="pauseSimulations()" class="col-start-1">Pausieren</button>
      <button wvButton [disabled]="simulationForm.invalid" (click)="continueSimulations()">Fortsetzen</button>
    </div>
  </form>
</wv-card>

<wv-card>
  @if (subscriptions().isLoading) {
    Loading
  }
  @if (subscriptions().data; as subscriptions) {
    @if (subscriptions.length === 0) {
      Keine Abonnements vorhanden
    } @else {
      <ul>
        @for (subscription of subscriptions; track subscription.id) {
          <li>
            <pre>{{ subscription | json }}</pre>
          </li>
        }
      </ul>
    }
  }
  @if (subscriptions().isError) {
    <p>Error</p>
  }
</wv-card>
