import { Component, OnInit, signal } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';
import { UrlBuilder } from '../../url-builder.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-phishing-mails',
  templateUrl: './phishing-mails.component.html',
  styleUrls: ['./phishing-mails.component.scss']
})
export class PhishingMailsComponent implements OnInit {
  protected activePath = signal(this.location.path());

  constructor(
    public identityService: IdentityService,
    public urlBuilder: UrlBuilder,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.location.onUrlChange(() => this.activePath.set(this.location.path()));
  }
}
