import { ComponentFactoryResolver, Inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ExamSummaryComponent } from './exam-summary.component';
import { TASK_COMPONENT_REGISTRY } from './exam-task-registry-token';
import { completeTask, pendingTask } from './factories/task-state';
import { ExaminationConfiguration, ExaminationTask, TaskComponent, TaskResult } from './models';
import { ensureExistence } from './value-guards/ensure-existence';

@Injectable()
export class ExamWizard {
  private activeTaskIndex = 0;
  private taskResults: TaskResult[] = [];

  private examComplete$$ = new Subject<number>();
  private currentTask$$ = new Subject<TaskResult>();

  private host: ViewContainerRef | undefined;
  private configuration: ExaminationConfiguration | undefined;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(TASK_COMPONENT_REGISTRY)
    private taskComponentRegistry: {
      type: string;
      component: Type<TaskComponent<unknown>>;
    }[],
    private translocoService: TranslocoService
  ) {}

  examComplete = () => this.examComplete$$.asObservable();
  currentTask = () => this.currentTask$$.asObservable();

  useHost(host: ViewContainerRef): ExamWizard {
    this.host = host;
    return this;
  }

  start(configuration: ExaminationConfiguration) {
    ensureExistence(this.host, '[ExamWizard] "host" was not defined. Please use method `useHost` setting the host.');

    this.configuration = configuration;
    this.activeTaskIndex = 0;

    this.activateTask(this.configuration.tasks[this.activeTaskIndex]);
  }

  proceedWithNextTask() {
    this.activeTaskIndex++;

    if (this.activeTaskIndex >= this.configuration.tasks.length) {
      const correctAnswers = this.taskResults.filter(result => result.isCorrect).length;

      this.examComplete$$.next(correctAnswers);
      this.examComplete$$.complete();
      this.activateExamSummary();
    } else {
      this.activateTask(this.configuration.tasks[this.activeTaskIndex]);
    }
  }

  private activateTask(examinationTask: { type: string; task: ExaminationTask }) {
    this.currentTask$$.next(pendingTask());
    this.host.clear();

    const taskComponentType = this.resolveTaskComponentType(examinationTask.type);
    const taskComponentFactory = this.componentFactoryResolver.resolveComponentFactory(taskComponentType);
    const taskComponentRef = this.host.createComponent(taskComponentFactory);

    const defaultSummary = {
      correct: this.translocoService.translate('exam.defaultSummary.correct'),
      wrong: this.translocoService.translate('exam.defaultSummary.wrong')
    };

    taskComponentRef.instance.prepare(examinationTask.task);
    taskComponentRef.instance
      .complete()
      .pipe(
        first(),
        map(task =>
          completeTask(task.isCorrect, { ...defaultSummary, ...examinationTask.task.summary, ...task.summary })
        ),
        tap(completeTask => this.taskResults.push(completeTask)),
        tap(completeTask => this.currentTask$$.next(completeTask))
      )
      .subscribe();

    taskComponentRef.changeDetectorRef.detectChanges();
  }

  private resolveTaskComponentType(type: string): Type<TaskComponent<unknown>> {
    const taskComponent = this.taskComponentRegistry.find(entry => entry.type === type);

    if (!taskComponent) {
      throw new Error(`Cannot find Task Component for given type: ${type}`);
    }

    return taskComponent.component;
  }

  private activateExamSummary() {
    this.currentTask$$.next(pendingTask());
    this.host.clear();

    const summaryComponentFactory = this.componentFactoryResolver.resolveComponentFactory(ExamSummaryComponent);
    const summaryComponentRef = this.host.createComponent(summaryComponentFactory);

    summaryComponentRef.instance.results = this.taskResults;
    summaryComponentRef.changeDetectorRef.detectChanges();
  }
}
