import { UpdateEmailRequestDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from "../custom.validator";

@Component({
  selector: 'app-user-edit-email-form',
  templateUrl: './user-edit-email-form.component.html',
  styleUrls: ['./user-edit-email-form.component.scss']
})
export class UserEditEmailFormComponent implements OnInit {
  @Input() email: string;
  @Output() submitForm = new EventEmitter<UpdateEmailRequestDto>();
  userEditEmailForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.userEditEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email, CustomValidators.username]]
    });

    this.userEditEmailForm.get('email').setValue(this.email);
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UpdateEmailRequestDto = {
      email: userData.get('email').value
    };

    this.submitForm.emit(dto);
  }
}
