import {ChangeDetectionStrategy, Component, effect, inject, viewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {DefaultChartOptionsService} from "../../phishing-mails/default-chart-options.service";

@Component({
  selector: 'app-partner-webinar-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-webinar-statistic.component.html',
  styleUrl: './partner-webinar-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerWebinarStatisticComponent {
  chart = viewChild(BaseChartDirective);
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          text: "Teilnehmer",
          display: true
        },
      },
      y: {
        title: {
          text: "Webinar",
          display: true
        },
        offset: true

      }
    },
  };

  constructor() {
    const resourceClicks = [
      { webinar: "Checkliste zur Vorbereitung auf Cyber-Angriffe", participants: 13 },
      { webinar: "Erstellung eines Auftragsverarbeitungsvertrags (AVV)", participants: 20 },
      { webinar: "DSGVO-Compliance – Praktische Umsetzung in Unternehmen", participants: 25 },
      { webinar: "Datenschutzerklärungen rechtssicher gestalten", participants: 30 },
      { webinar: "Phishing-Angriffe erkennen und abwehren", participants: 4 },
      { webinar: "Notfallplan für IT-Sicherheitsvorfälle erstellen", participants: 50 },
      { webinar: "Sichere Datenlöschung gemäß DSGVO", participants: 100 },
      { webinar: "Informationssicherheitskonzept (ISMS) entwickeln und umsetzen", participants: 31 }
    ].sort((a, b) => b.participants - a.participants);

    const backgroundColor = this.#defaultChartOptions.getDefaultColors().backgroundColor[0];
    const hoverBackgroundColor = this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[0];
    const hoverBorderColor = this.#defaultChartOptions.getDefaultColors().hoverBorderColor;

    this.chartData.datasets =  [{
      data: resourceClicks.map((resourceClick) => resourceClick.participants),
      backgroundColor,
      hoverBackgroundColor,
      hoverBorderColor
    }];
    this.chartData.labels = resourceClicks.map((resourceClick) => resourceClick.webinar);

    effect(() => {
      if (this.chart()) {
        this.chart().update();
      }
    })
  }
}
