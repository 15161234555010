import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CreateCompanySubscriptionDto,
  SubscriptionsService,
  UpdateCompanySubscriptionDto
} from './subscriptions.service';
import { ColibriModule } from '@ac/colibri';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LayoutsModule } from '../../layouts/layouts.module';

@Component({
  selector: 'app-company-subscriptions',
  standalone: true,
  imports: [
    CommonModule,
    ColibriModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoPipe,
    MatDatepickerModule,
    LayoutsModule
  ],
  templateUrl: './company-subscriptions.component.html',
  styles: `:host {
    @apply grid gap-8
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySubscriptionsComponent {
  #subscriptionsService = inject(SubscriptionsService);
  protected subscriptions = this.#subscriptionsService.getSubscriptions().result;
  #formBuilder = inject(FormBuilder);
  protected createForm = this.#formBuilder.group({
    companyId: ['', [Validators.required]],
    packageValue: [0, [Validators.required, Validators.min(1), Validators.max(6)]],
    from: ['', [Validators.required]],
    durationInMonths: [1, [Validators.required, Validators.min(1)]],
    licenseCosts: [0, [Validators.required]],
    minimumQuantity: [0, [Validators.required, Validators.min(1)]]
  });

  protected updateForm = this.#formBuilder.group({
    subscriptionId: ['', [Validators.required]],
    packageValue: [0, [Validators.required, Validators.min(1), Validators.max(6)]],
    from: ['', [Validators.required]],
    durationInMonths: [1, [Validators.required, Validators.min(1)]],
    licenseCosts: [0, [Validators.required]],
    minimumQuantity: [0, [Validators.required, Validators.min(1)]]
  });

  protected simulationForm = this.#formBuilder.group({
    subscriptionId: ['', [Validators.required]]
  });

  subscribe() {
    if (this.createForm.invalid) return;

    const dto = this.createForm.getRawValue() as CreateCompanySubscriptionDto;

    this.#subscriptionsService.createSubscription().mutate(dto);
  }

  update() {
    if (this.updateForm.invalid) return;

    const subscriptionId = this.updateForm.get('subscriptionId').value;
    const dto = this.updateForm.getRawValue() as UpdateCompanySubscriptionDto;

    this.#subscriptionsService.updateSubscription().mutate({ id: subscriptionId, dto: dto });
  }

  pauseSimulations() {
    if (this.simulationForm.invalid) return;

    const subscriptionId = this.simulationForm.get('subscriptionId').value;

    this.#subscriptionsService.pausePhishing().mutate({ id: subscriptionId });
  }

  continueSimulations() {
    if (this.simulationForm.invalid) return;

    const subscriptionId = this.simulationForm.get('subscriptionId').value;

    this.#subscriptionsService.continuePhishing().mutate({ id: subscriptionId });
  }
}
