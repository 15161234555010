import { TaskResult } from '../models';

export function pendingTask(): TaskResult {
  return {
    isComplete: false,
    isCorrect: false,
    summary: ''
  };
}

export function completeTask(isCorrect: boolean, summary: { correct: string; wrong: string }): TaskResult {
  return {
    isComplete: true,
    isCorrect,
    summary: isCorrect ? summary.correct : summary.wrong
  };
}
