<h3 class="!font-bold" mat-dialog-title>Wie hat Ihnen der Kurs gefallen?</h3>
<mat-dialog-content>
  <div class="grid gap-4">
    <div class="*:text-4xl *:!h-9 *:!w-9">
      @for (star of stars(); track star; let index = $index) {
        <mat-icon
          [color]="'accent'"
          (click)="rating.set(index + 1)"
          [class.icon-filled]="index + 1 <= rating()"
        >star
        </mat-icon
        >
      }
    </div>

    <mat-form-field class="w-full">
      <mat-label>Kommentar</mat-label>
      <textarea [(ngModel)]="comment" matInput placeholder="Gut / Schlecht fand ich..."></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="submit()" mat-dialog-close wvButton>Bewertung abgeben</button>
</mat-dialog-actions>
