<wv-card
  data-testid="delete-profile-card"
  [title]="'userProfile.selfDelete.subtitle' | transloco"
  [overline]="'userProfile.selfDelete.title' | transloco"
>
  <p>{{ 'userProfile.selfDelete.description' | transloco | renderHTML }}</p>
  <button
    (click)="selfDeleteUser()"
    appearance="secondary"
    wvButton
  >
    {{ 'userProfile.selfDelete.submitButton' | transloco }}
  </button>
</wv-card>
