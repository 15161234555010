<table
  data-testid="user-overview-table"
  *ngIf="dataSource"
  [dataSource]="dataSource"
  mat-table
  matSort
>
  <ng-container matColumnDef="firstName">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="firstName"
    >
      {{ 'tableColumn.firstName' | transloco }}
    </th>
    <td
      *matCellDef="let user"
      mat-cell
    >
      {{ user.firstName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="lastName">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="lastName"
    >
      {{ 'tableColumn.lastName' | transloco }}
    </th>
    <td
      *matCellDef="let user"
      mat-cell
    >
      {{ user.lastName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="email"
    >
      {{ 'tableColumn.email' | transloco }}
    </th>
    <td
      data-testid="user-email"
      *matCellDef="let user"
      mat-cell
    >
      {{ user.email }}
    </td>
  </ng-container>
  <ng-container matColumnDef="department">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="department"
    >
      {{ 'tableColumn.department' | transloco }}
    </th>
    <td
      *matCellDef="let user"
      mat-cell
    >
      <mat-select
        (valueChange)="changeDepartment(user, $event)"
        [multiple]="false"
        [value]="user.department"
        [disabled]="user.isRegistrationPending"
      >
        <mat-option
          *ngFor="let department of departments"
          [disabled]="departmentIsUpdating"
          [value]="department"
        >
          {{ department }}
        </mat-option>

        <mat-progress-bar
          *ngIf="departmentIsUpdating"
          class="progress-bar"
          mode="indeterminate"
        ></mat-progress-bar>
      </mat-select>
    </td>
  </ng-container>
  <ng-container matColumnDef="assignedRoles">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="assignedRoles"
    >
      {{ 'tableColumn.roles' | transloco }}
    </th>
    <td
      *matCellDef="let user"
      mat-cell
    >
      <mat-select
        (valueChange)="changeRole(user, $event)"
        [multiple]="true"
        [value]="user.assignedRoles"
      >
        <mat-option
          *ngFor="let role of managedRoles"
          [disabled]="roleIsUpdating || role === 'Benutzer'"
          [value]="role"
        >
          {{ role }}
        </mat-option>

        <mat-progress-bar
          *ngIf="roleIsUpdating"
          class="progress-bar"
          mode="indeterminate"
        ></mat-progress-bar>
      </mat-select>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header="isRegistrationPending"
    >
      {{ 'tableColumn.status' | transloco }}
    </th>
    <td
      data-testid="user-status"
      *matCellDef="let user"
      mat-cell
    >
      {{ user.isRegistrationPending ? ('registration.pending' | transloco) : ('registration.completed' | transloco) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th
      *matHeaderCellDef
      mat-header-cell
    ></th>
    <td
      *matCellDef="let user"
      mat-cell
    >
      <div class="table-actions">
        <button
          (click)="resendInvitation(user)"
          *ngIf="user.isRegistrationPending"
          [matTooltip]="'tooltip.resendInvitation' | transloco"
          color="accent"
          mat-icon-button
        >
          <mat-icon svgIcon="email-sync"></mat-icon>
        </button>
        <button
          data-testid="button-delete-pending-invitation"
          (click)="deleteInvitation(user)"
          *ngIf="user.isRegistrationPending"
          [matTooltip]="'tooltip.delete' | transloco"
          color="accent"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          (click)="deleteUser(user)"
          *ngIf="!user.isRegistrationPending"
          [matTooltip]="'tooltip.delete' | transloco"
          color="accent"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr
    *matHeaderRowDef="displayedColumns"
    mat-header-row
  ></tr>
  <tr
    data-testid="table-entry"
    *matRowDef="let row; columns: displayedColumns"
    mat-row
  ></tr>
</table>
