import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IdentityService } from '../../identity/identity.service';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-cyber-portal-overview',
  templateUrl: './cyber-portal-overview.component.html',
  styleUrls: ['./cyber-portal-overview.component.scss']
})
export class CyberPortalOverviewComponent {
  environment = environment;

  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}
}
