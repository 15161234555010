import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { FaqComponent } from './faq/faq.component';
import { HelpDocumentsComponent } from './help-documents/help-documents.component';
import { HelpVideosComponent } from './help-videos/help-videos.component';
import { HelpComponent } from './help/help.component';
import { InfoVideosComponent } from './info-videos/info-videos.component';
import { IntroVideosComponent } from './intro-videos/intro-videos.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";

@NgModule({
  declarations: [
    HelpComponent,
    HelpVideosComponent,
    FaqComponent,
    HelpDocumentsComponent,
    InfoVideosComponent,
    IntroVideosComponent
  ],
    imports: [
        CommonModule,
        ColibriModule,
        BrowserModule,
        LayoutsModule,
        MatTabsModule,
        MatExpansionModule,
        TranslateModule,
        MatButtonToggleModule
    ],
  exports: [
    HelpComponent,
    HelpVideosComponent,
    FaqComponent,
    HelpDocumentsComponent,
    InfoVideosComponent,
    IntroVideosComponent
  ]
})
export class HelpModule {}
