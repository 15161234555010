import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CompanyDto, UpdateCompanyDto} from "../company.service";
import {NonNullableFormBuilder, Validators} from "@angular/forms";
import {CustomValidator, SelectOptionsService} from "@ac/colibri";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-company-edit-information-form',
  templateUrl: './company-edit-information-form.component.html',
  styleUrl: './company-edit-information-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyEditInformationFormComponent implements OnInit {
  @Input({required: true}) company: CompanyDto;
  @Output() submitForm = new EventEmitter<UpdateCompanyDto>();
  filteredOptions: Observable<string[]>;
  #formBuilder = inject(NonNullableFormBuilder);
  #optionsService = inject(SelectOptionsService);
  protected form = this.#formBuilder.group({
    name: ['', [Validators.required, Validators.pattern(`^^[0-9a-zA-ZäöüÄÖÜß]+([0-9a-zA-ZäöüÄÖÜß\\'\\,\\.\\-\\&\\! ])*$`)]],
    businessSector: ['', [Validators.required, CustomValidator.requireMatch(this.#optionsService.businessSectors())]],
    employeeCount: [1, [Validators.required, Validators.min(1)]],
    officeEmployeeCount: [1, [Validators.required, Validators.min(1)]],
  });

  ngOnInit(): void {
    this.form.patchValue({
      name: this.company.name,
      businessSector: this.company.businessSector,
      employeeCount: this.company.employeeCount,
      officeEmployeeCount: this.company.officeEmployeeCount
    });

    this.filteredOptions = this.form.get('businessSector').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.#optionsService.businessSectors().filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  save() {
    if(this.form.invalid)
      return;

    const dto = this.form.value as UpdateCompanyDto;

    this.submitForm.emit(dto);
  }
}
