import { ChangeDetectionStrategy, Component, computed, inject, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhishingMailsService } from '../../../phishing-mails/phishing-mails.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { TranslocoPipe } from '@ngneat/transloco';
import { ColibriModule } from '@ac/colibri';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { filter } from 'rxjs/operators';
import { LayoutsModule } from '../../../layouts/layouts.module';

@Component({
  selector: 'app-phishing-mails-management',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSortModule,
    TranslocoPipe,
    ColibriModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    LayoutsModule
  ],
  templateUrl: './phishing-mails-management.component.html',
  styleUrl: './phishing-mails-management.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhishingMailsManagementComponent {
  #phishingMailsService = inject(PhishingMailsService);
  phishingMailTemplates = this.#phishingMailsService.getTemplates().result;
  sort = viewChild(MatSort);
  dataSource = computed(() => {
    const source = new MatTableDataSource(this.phishingMailTemplates().data);
    source.sort = this.sort();
    return source;
  });
  displayedColumns: string[] = [
    'sendGridTemplateId',
    'title',
    'senderName',
    'senderEmail',
    'levelOfDifficulty',
    'created',
    'link'
  ];
  #formBuilder = inject(FormBuilder);
  protected sendForm = this.#formBuilder.group({
    sendGridTemplateId: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]]
  });
  protected templateFilter = signal('');
  protected filteredTemplates = computed(() => {
    return this.phishingMailTemplates()
      .data.filter(
        template =>
          template.sendGridTemplateId.toUpperCase().includes(this.templateFilter().toUpperCase()) ||
          template.title.toUpperCase().includes(this.templateFilter().toUpperCase())
      )
      .sort((a, b) => (a.sendGridTemplateId > b.sendGridTemplateId ? 1 : -1));
  });

  send() {
    if (this.sendForm.invalid) return;

    const sendGridTemplateId = this.sendForm.get('sendGridTemplateId').value;
    const email = this.sendForm.get('email').value;

    const template = this.phishingMailTemplates().data.find(
      template => template.sendGridTemplateId === sendGridTemplateId
    );

    if (!template) return;

    const props = { templateId: template.id, dto: { email: email } };

    this.#phishingMailsService.sendMail().mutate(props);
  }

  filterTemplates(search: string) {
    this.templateFilter.set(search);
  }
}
