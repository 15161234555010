import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {environment} from "@env";
import {TranslocoPipe} from "@ngneat/transloco";

@Component({
  selector: 'app-package-info-hint',
  standalone: true,
  imports: [CommonModule, TranslocoPipe],
  templateUrl: './package-info-hint.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageInfoHintComponent {
  protected readonly environment = environment;
}
