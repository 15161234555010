import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
  name: 'dateDistance'
})
export class DateDistancePipe implements PipeTransform {
  transform(date: string, date2: string): string {
    return moment(date).to(date2, true);
  }
}
