import { Component } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-phishing-mails-landing-page',
  templateUrl: './phishing-mails-landing-page.component.html',
  styleUrls: ['./phishing-mails-landing-page.component.scss']
})
export class PhishingMailsLandingPageComponent {
  constructor(public urlBuilder: UrlBuilder) {}
}
