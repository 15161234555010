<ng-container *transloco="let t">
  <awareness-center-wv-hero-banner [scrollTarget]="scrollTarget"></awareness-center-wv-hero-banner>
  <div #scrollTarget class="container">
    <p>{{ 'app.disclaimer' | transloco | renderHTML }}</p>
    <p>{{ t('ransom-clause.intro.description') }}</p>
    <div class="logos">
      <img [src]="wvLogoPath"/>
      <img [src]="coItLogoPath"/>
    </div>
    <wv-card [title]="'Informationen erfassen'">
      <form [formGroup]="form" class="relative">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'form.label.insuranceNumber' | transloco }}</mat-label>
          <input formControlName="insuranceNumber" matInput/>
          <mat-error *ngIf="form.get('insuranceNumber').errors?.required">
            {{ 'form.error.required' | transloco }}
          </mat-error>
          <mat-error *ngIf="form.get('insuranceNumber').errors?.pattern">
            {{ 'form.error.invalidInsuranceNumber' | transloco | renderHTML }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
          <input formControlName="policyHolderEmail" matInput type="email"/>
          <mat-error *ngIf="form.get('policyHolderEmail').errors?.required">
            {{ 'form.error.required' | transloco }}
          </mat-error>
          <mat-error *ngIf="form.get('policyHolderEmail').errors?.email">
            {{ 'form.error.invalidEmail' | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'form.label.companyName' | transloco }}</mat-label>
          <input formControlName="companyName" matInput/>
          <mat-error *ngIf="form.get('companyName').errors?.required">{{ 'form.error.required' | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-checkbox class="full-width" color="primary" formControlName="privacy" required>
          {{ 'ransom-clause.intro.privacy-hint' | transloco: {link: environment.urls.privacy} | renderHTML }}
        </mat-checkbox>
        @if (acceptedRecaptcha()) {
          <re-captcha class="recaptcha" formControlName="recaptcha"></re-captcha>
        } @else {
          <ac-recaptcha-consent (accepted)="acceptedRecaptcha.set(true)"></ac-recaptcha-consent>
        }
        <button (click)="goNext()" [disabled]="form.invalid" appearance="secondary" class="mt-4" wvButton>
          {{ t('ransom-clause.text-confirmation.confirm-label') }}
        </button>
      </form>
    </wv-card>
  </div>
</ng-container>
