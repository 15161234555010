import {Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges} from '@angular/core';
import { environment } from '@env';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { insuranceNumberRegexPattern } from '../../utils/regex-patterns';
import { RansomClauseDto } from '../ransom-clause.dto';
import {CustomValidators} from "../../identity/custom.validator";

@Component({
  selector: 'app-ransom-clause-intro',
  templateUrl: './ransom-clause-intro.component.html',
  styleUrls: ['./ransom-clause-intro.component.scss']
})
export class RansomClauseIntroComponent implements OnChanges {
  form: UntypedFormGroup;
  @Input() data: RansomClauseDto;
  @Output() dataChange = new EventEmitter<RansomClauseDto>();
  @Output() next = new EventEmitter<void>();
  coItLogoPath = environment.assets.logo;
  wvLogoPath = environment.assets.wvLogo;
  environment = environment;
  protected readonly acceptedRecaptcha = signal(false);

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.buildForm();
  }

  goNext() {
    this.dataChange.emit({ underwriterEmail: this.data.underwriterEmail, ...this.form.value });
    this.next.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.form.patchValue(this.data);
    }
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      insuranceNumber: ['', [Validators.required, Validators.pattern(insuranceNumberRegexPattern)]],
      policyHolderEmail: ['', [Validators.required, CustomValidators.email]],
      companyName: ['', [Validators.required]],
      privacy: ['', [Validators.required]],
      recaptcha: ['', [Validators.required]]
    });
  }
}
