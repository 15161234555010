<app-back-layout
  [backUrl]="urlBuilder.goToHome()"
  [title]="'userProfile.edit.title' | transloco"
>
  <div
    *ngIf="userData$ | async as userData"
    class="content"
  >
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header data-testid="edit-gender-panel">
          {{ 'userProfile.edit.changeSalutation' | transloco }}
        </mat-expansion-panel-header>
        <app-user-edit-gender-form
          (submitForm)="updateGender($event)"
          [isFemale]="userData.isFemale"
        ></app-user-edit-gender-form>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header data-testid="edit-firstName-panel">
          {{ 'userProfile.edit.changeFirstName' | transloco }}
        </mat-expansion-panel-header>
        <app-user-edit-first-name-form
          (submitForm)="updateFirstName($event)"
          [firstName]="userData.firstName"
        ></app-user-edit-first-name-form>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header data-testid="edit-lastName-panel">
          {{ 'userProfile.edit.changeLastName' | transloco }}
        </mat-expansion-panel-header>
        <app-user-edit-last-name-form
          (submitForm)="updateLastName($event)"
          [lastName]="userData.lastName"
        ></app-user-edit-last-name-form>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{ 'userProfile.edit.changeEmail' | transloco }}</mat-expansion-panel-header>
        <app-user-edit-email-form
          (submitForm)="updateEmail($event)"
          [email]="userData.email"
        ></app-user-edit-email-form>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header data-testid="edit-department-panel">
          {{ 'userProfile.edit.changeDepartment' | transloco }}
        </mat-expansion-panel-header>
        <app-user-edit-department-form
          (submitForm)="updateDepartment($event)"
          [department]="userData.department"
        ></app-user-edit-department-form>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{ 'userProfile.edit.changePassword' | transloco }}</mat-expansion-panel-header>
        <app-user-edit-password-form
          (submitForm)="updatePassword($event)"
          *ngIf="this.passwordPolicy$ | async as passwordPolicy"
          [passwordPolicy]="passwordPolicy"
        ></app-user-edit-password-form>
      </mat-expansion-panel>
    </mat-accordion>
    <app-self-delete-user></app-self-delete-user>
    <wv-card
      [title]="'userProfile.gdprExport.subtitle' | transloco"
      [overline]="'userProfile.gdprExport.title' | transloco"
    >
      <p>{{ 'userProfile.gdprExport.description' | transloco | renderHTML }}</p>
      <button
        (click)="requestUserData()"
        appearance="secondary"
        wvButton
      >
        {{ 'userProfile.gdprExport.submitButton' | transloco }}
      </button>
    </wv-card>
  </div>
</app-back-layout>
