import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRolesService } from '../../identity/user-roles.service';
import { AdministrationService } from '../administration.service';
import {CustomValidators} from "../../identity/custom.validator";

@Component({
  selector: 'app-admin-management-user',
  templateUrl: './admin-management-user.component.html',
  styleUrls: ['./admin-management-user.component.scss']
})
export class AdminManagementUserComponent {
  userForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private administrationService: AdministrationService
  ) {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email, CustomValidators.username]]
    });
  }

  deleteUser() {
    const email = this.userForm.get('email').value;

    this.administrationService.deleteUserByEmail(email).subscribe();
  }
}
