import { CheckResultDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class CourseExamReporter {
  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService) {}

  report(checkResultDto: CheckResultDto): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http
      .put<string>(`${environment.apiUrl}${environment.apiRoutes.courses}/${checkResultDto.courseId}/Exam`, {
        numberOfCorrectAnswers: checkResultDto.correctAnswers,
        submittedAt: moment().toISOString(true)
      })
      .pipe(
        tap({
          next: message =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: message
            })
        }),
        catchError(message => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: message.error
          });
          return EMPTY;
        })
      );
  }
}
