import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatSort, MatSortHeader} from "@angular/material/sort";

@Component({
  selector: 'app-partner-latest-resource-clicks-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, MatTable, MatSort, MatColumnDef, MatHeaderCell, MatCell, MatHeaderRow, MatRow, MatSortHeader, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef],
  templateUrl: './partner-latest-resource-clicks-statistic.component.html',
  styleUrl: './partner-latest-resource-clicks-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerLatestResourceClicksStatisticComponent implements AfterViewInit {
  displayedColumns: string[] = ['name', 'businessSector', 'department', 'date'];
  data = [
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Informationstechnologie",
      department: "IT",
      date: "2025-02-01T08:30:33",
    },
    {
      name: "Vorlage Auftragsverarbeitungsvertrag",
      businessSector: "Finanzen",
      department: "Rechtsabteilung",
      date: "2025-02-01T09:15:12",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Gesundheitswesen",
      department: "IT",
      date: "2025-02-01T10:00:52",
    },
    {
      name: "Musterrichtlinie zur IT-Sicherheit",
      businessSector: "Automobilindustrie",
      department: "IT",
      date: "2025-02-01T11:45:23",
    },
    {
      name: "Checkliste für Datenschutz-Audits",
      businessSector: "Pharmazie",
      department: "Qualitätssicherung",
      date: "2025-02-01T13:30:11",
    },
    {
      name: "Vorlage für Datenschutzerklärungen",
      businessSector: "Telekommunikation",
      department: "Marketingabteilung",
      date: "2025-02-01T14:20:03",
    },
    {
      name: "Handbuch zur Incident-Response",
      businessSector: "Versicherung",
      department: "IT",
      date: "2025-02-01T15:10:55",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Bildung",
      department: "Personalabteilung",
      date: "2025-02-01T16:00:32",
    },
    {
      name: "Checkliste für sichere Cloud-Nutzung",
      businessSector: "Dienstleistungen",
      department: "IT",
      date: "2025-02-02T08:45:54",
    },
    {
      name: "Vorlage Auftragsverarbeitungsvertrag",
      businessSector: "Chemie",
      department: "Rechtsabteilung",
      date: "2025-02-02T09:30:22",
    },
    {
      name: "Leitfaden Datenschutz-Grundverordnung",
      businessSector: "Logistik",
      department: "Rechtsabteilung",
      date: "2025-02-02T10:15:31",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Elektronik",
      department: "IT",
      date: "2025-02-02T11:00:13",
    },
    {
      name: "Musterrichtlinie zur IT-Sicherheit",
      businessSector: "Maschinenbau",
      department: "IT",
      date: "2025-02-02T12:45:00",
    },
    {
      name: "Checkliste für Datenschutz-Audits",
      businessSector: "Lebensmittelindustrie",
      department: "Qualitätssicherung",
      date: "2025-02-02T13:30:00",
    },
    {
      name: "Vorlage für Datenschutzerklärungen",
      businessSector: "Tourismus",
      department: "Marketingabteilung",
      date: "2025-02-02T14:20:00",
    },
    {
      name: "Handbuch zur Incident-Response",
      businessSector: "Luft- und Raumfahrt",
      department: "IT",
      date: "2025-02-02T15:10:00",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Textil- und Bekleidungs",
      department: "Personalabteilung",
      date: "2025-02-02T16:00:49",
    },
    {
      name: "Checkliste für sichere Cloud-Nutzung",
      businessSector: "Energie",
      department: "IT",
      date: "2025-02-03T08:30:19",
    },
    {
      name: "Vorlage Auftragsverarbeitungsvertrag",
      businessSector: "Immobilien",
      department: "Rechtsabteilung",
      date: "2025-02-03T09:15:06",
    },
    {
      name: "Leitfaden Datenschutz-Grundverordnung",
      businessSector: "Freizeit",
      department: "Rechtsabteilung",
      date: "2025-02-03T10:00:03",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Handel",
      department: "IT",
      date: "2025-02-03T11:45:21",
    },
    {
      name: "Musterrichtlinie zur IT-Sicherheit",
      businessSector: "Hotelgewerbe",
      department: "IT",
      date: "2025-02-03T13:30:33",
    },
    {
      name: "Checkliste für Datenschutz-Audits",
      businessSector: "Medizintechnik",
      department: "Qualitätssicherung",
      date: "2025-02-03T14:20:32",
    },
    {
      name: "Vorlage für Datenschutzerklärungen",
      businessSector: "Spielzeug",
      department: "Marketingabteilung",
      date: "2025-02-03T15:10:22",
    },
    {
      name: "Handbuch zur Incident-Response",
      businessSector: "Sport",
      department: "IT",
      date: "2025-02-03T16:00:54",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Schiffbau",
      department: "Personalabteilung",
      date: "2025-02-04T08:45:44",
    },
    {
      name: "Checkliste für sichere Cloud-Nutzung",
      businessSector: "Wasser und Abwasser",
      department: "IT",
      date: "2025-02-04T09:30:51",
    },
    {
      name: "Vorlage Auftragsverarbeitungsvertrag",
      businessSector: "Entsorgung",
      department: "Rechtsabteilung",
      date: "2025-02-04T10:15:12",
    },
    {
      name: "Leitfaden Datenschutz-Grundverordnung",
      businessSector: "Sonstiges",
      department: "Rechtsabteilung",
      date: "2025-02-04T11:00:32",
    },
    {
      name: "Checkliste Cyber-Angriff",
      businessSector: "Beauty",
      department: "IT",
      date: "2025-02-04T12:45:33",
    },
  ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  dataSource = new MatTableDataSource(this.data);

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
