import { Injectable } from '@angular/core';
import browserUpdate from "browser-update";

@Injectable({ providedIn: 'root' })
export class BrowserUpdateService {
  configure() {
    browserUpdate({
      required: {
        e: -1,
        f: -1,
        o: -1,
        s: -1,
        c: -1
      },
      insecure: true,
      unsupported: true,
      mobile: false,
      style: 'corner',
      api: 2021.03,
      l: 'de',
      notify_esr: true,
      shift_page_down: false
    }, false);

    // remove inline styles from browser update plugin
    this.cleanBrowserUpdate();
    document.getElementById('buorg')?.addEventListener('click', this.cleanBrowserUpdate);
  }

  cleanBrowserUpdate() {
    const element = document
      .getElementById('buorg');

    element
      ?.querySelectorAll('style')
      .forEach(style => style.remove());

    element?.removeAttribute("style");
  }
}
