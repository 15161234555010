import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoadingDialogService} from '../layouts/loading-dialog/loading-dialog.service';
import {EMPTY, Observable} from 'rxjs';
import {LoadingDialogComponent} from '../layouts/loading-dialog/loading-dialog.component';
import {environment} from '@env';
import {catchError, tap} from 'rxjs/operators';
import {LoadingDialogState} from '../layouts/loading-dialog/loading-dialog-state.enum';
import {QuickBulkInviteDto} from "./quick-invitation/quick-bulk-invit.dto";

@Injectable({ providedIn: 'root' })
export class AdministrationService {
  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService) {}

  assignRoleByEmail(email: string, role: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http
      .post<string>(
        environment.apiUrl + environment.apiRoutes.administration + '/Users/' + email + '/Roles?role=' + role,
        {}
      )
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  removeRoleByEmail(email: string, role: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http
      .delete<string>(
        environment.apiUrl + environment.apiRoutes.administration + '/Users/' + email + '/Roles?role=' + role
      )
      .pipe(
        tap({
          next: res =>
            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content: res,
              afterClose: async () => {
                return true;
              }
            })
        }),
        catchError(res => {
          this.loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: res.error
          });
          return EMPTY;
        })
      );
  }

  deleteUserByEmail(email: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http.delete<string>(environment.apiUrl + environment.apiRoutes.administration + '/Users/' + email).pipe(
      tap({
        next: res =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: res,
            afterClose: async () => {
              return true;
            }
          })
      }),
      catchError(res => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: res.error
        });
        return EMPTY;
      })
    );
  }

  quickInvite(dto: QuickBulkInviteDto): Observable<string>{
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http
        .post<string>(
            environment.apiUrl + environment.apiRoutes.administration + '/QuickInvite', dto
        )
        .pipe(
            tap({
              next: res =>
                  this.loadingDialog.setData({
                    data: LoadingDialogState.Success,
                    content: res,
                    afterClose: async () => {
                      return true;
                    }
                  })
            }),
            catchError(res => {
              this.loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: res.error
              });
              return EMPTY;
            })
        );
  }
}
