import { ChangeDetectionStrategy, Component, effect, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { DefaultChartOptionsService } from '../../../../phishing-mails/default-chart-options.service';

@Component({
  selector: 'app-partner-courses-business-sector-usage-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-courses-business-sector-usage-statistic.component.html',
  styleUrl: './partner-courses-business-sector-usage-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerCoursesBusinessSectorUsageStatisticComponent {
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      }
    },
    scales: {
      x: {
        title: {
          text: 'Kurs',
          display: false
        }
      },
      y: {
        title: {
          text: 'Teilnehmer',
          display: true
        }
      }
    }
  };

  coursePaticipants = [
    {
      course: 'Datenschutz',
      departments: [
        { name: 'Finanzwesen', clicks: 200 },
        { name: 'Logistik', clicks: 360 },
        { name: 'Maschinenbau', clicks: 623 }
      ]
    },
    {
      course: 'Compliance',
      departments: [
        { name: 'Schiffbau', clicks: 340 },
        { name: 'Logistik', clicks: 424 },
        { name: 'Entsorgung', clicks: 62 }
      ]
    },
    {
      course: 'Künstliche Intelligenz',
      departments: [
        { name: 'Immobilien', clicks: 624 },
        { name: 'Tourismus', clicks: 623 },
        { name: 'Schiffbau', clicks: 444 }
      ]
    }
  ].sort((a, b) => b.departments[0].clicks - a.departments[0].clicks);

  charts = this.coursePaticipants.map(toolkitUsage => ({
    chartType: this.chartType,
    chartOptions: this.chartOptions,
    chartData: {
      labels: toolkitUsage.departments.map(x => x.name),
      datasets: [
        {
          data: toolkitUsage.departments.map(x => x.clicks),
          backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor,
          hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor,
          hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
        }
      ]
    },
    name: toolkitUsage.course
  }));
}
