<mat-card>
  <form (ngSubmit)="onSubmit(sendNewsletterForm, $event)" [formGroup]="sendNewsletterForm">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.sendGridTemplateId' | transloco }}</mat-label>
      <input formControlName="sendGridTemplateId" matInput />
      <mat-error *ngIf="sendNewsletterForm.get('sendGridTemplateId').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="sendNewsletterForm.get('sendGridTemplateId').errors?.pattern">
        {{ 'form.error.pattern' | transloco: {pattern: 'd-XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'} }}
      </mat-error>
    </mat-form-field>
    <button [disabled]="sendNewsletterForm.invalid" appearance="secondary" wvButton>
      {{ 'newsletter.submitButton' | transloco }}
    </button>
  </form>
</mat-card>
