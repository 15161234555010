import { CompanyRegistrationDetailsDto } from '@ac/models';
import { Component, EventEmitter, Output } from '@angular/core';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-company-registration-details',
  templateUrl: './company-registration-details.component.html',
  styleUrls: ['./company-registration-details.component.scss']
})
export class CompanyRegistrationDetailsComponent {
  environment = environment;

  @Output() companyDetails = new EventEmitter<CompanyRegistrationDetailsDto>();

  video: VideoDto = {
    captions: [
      {
        language: 'DE',
        label: 'Deutsch',
        source: environment.resources.helpVideoAccountantRegistrationSubtitles
      }
    ],
    streamUrl: environment.resources.helpVideoAccountantRegistration,
    downloadUrl: null
  };

  emitCompanyDetails(dto: CompanyRegistrationDetailsDto): void {
    this.companyDetails.emit(dto);
  }
}
