import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../../identity/identity.service';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { AdministrationModule } from '../administration.module';
import { TranslocoPipe } from '@ngneat/transloco';
import { environment } from '@env';
import { LayoutsModule } from '../../layouts/layouts.module';
import { CompanySubscriptionsComponent } from '../../subscriptions/company-subscriptions/company-subscriptions.component';
import { PhishingMailsManagementComponent } from './phishing-mails-management/phishing-mails-management.component';
import { QuickInvitationComponent } from '@app/administration/administration-coit/quick-invitation/quick-invitation.component';
import { CyberPortalModule } from '@app/cyber-portal/cyber-portal.module';

@Component({
  selector: 'app-administration-coit',
  standalone: true,
  imports: [
    CommonModule,
    MatTab,
    AdministrationModule,
    MatTabGroup,
    TranslocoPipe,
    MatTabContent,
    LayoutsModule,
    CompanySubscriptionsComponent,
    PhishingMailsManagementComponent,
    QuickInvitationComponent,
    AdministrationModule,
    CyberPortalModule
  ],
  templateUrl: './administration-coit.component.html',
  styleUrl: './administration-coit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationCoitComponent {
  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}

  protected readonly environment = environment;
}
