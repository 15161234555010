import { environment } from '@env';
import { NgcCookieConsentConfig, NgcCookiePosition, NgcCookieTheme, NgcCookieType } from 'ngx-cookieconsent';
import { TranslocoService } from '@ngneat/transloco';
import { take, tap } from 'rxjs/operators';

export const cookieConsentConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: (() => {
      const url: URL = new URL(environment.baseUrl);

      return url.hostname;
    })()
  },
  palette: environment.config.cookieConsent.palette,
  theme: environment.config.cookieConsent.theme as NgcCookieTheme,
  type: environment.config.cookieConsent.type as NgcCookieType,
  position: environment.config.cookieConsent.position as NgcCookiePosition,
  content: {
    href: environment.urls.privacy
  }
};

export function cookieConsentConfigFactory(config: NgcCookieConsentConfig, transloco: TranslocoService) {
  return () =>
    transloco
      .selectTranslateObject('cookieConsent')
      .pipe(
        take(1),
        tap(translations => {
          config.elements = {
            dismiss: `<button data-testid="button-accept-cookie" aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss">${translations.dismiss}</button>`
          };
          config.content.message = translations.message;
          config.content.link = translations.link;
        })
      )
      .toPromise();
}
