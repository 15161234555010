import { Component } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-toolkit',
  templateUrl: './toolkit.component.html',
  styleUrls: ['./toolkit.component.scss']
})
export class ToolkitComponent {
  constructor(public urlBuilder: UrlBuilder) {}
}
