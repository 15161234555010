import { CourseDetailsCourseDto, CourseOverviewCourseDto, CoursePlayerCourseDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoDto } from '../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class CompanyCoursesApiService {
  #http = inject(HttpClient);

  getCourseOverview(companyId: string): Observable<Array<CourseOverviewCourseDto>> {
    return this.#http.get<CourseOverviewCourseDto[]>(
      `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}${environment.apiRoutes.courseOverviewCourses}`
    );
  }

  getCourseDetails(companyId: string, courseId: string): Observable<CourseDetailsCourseDto> {
    return this.#http.get<CourseDetailsCourseDto>(
      `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}${environment.apiRoutes.courses}/${courseId}`
    );
  }

  getCoursePlayer(companyId: string, courseId: string): Observable<CoursePlayerCourseDto> {
    return this.#http.get<CoursePlayerCourseDto>(
      `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}${environment.apiRoutes.courses}/${courseId}/Player`
    );
  }

  completeVideo(companyId: string, lessonId: string): Observable<VideoDto[]> {
    return this.#http.post<VideoDto[]>(
      `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}${environment.apiRoutes.coursePlayerWatchedVideos}`,
      JSON.stringify(lessonId)
    );
  }
}
