<awareness-center-statistic-card text="ℹ️ Konzentrieren Sie sich bei Ihren nächsten Kursen auf diese Themen." heading="Gewünschte Themen">
  <div class="relative w-full min-h-[22rem]" statistic>
    <canvas
      [data]="chartData"
      [options]="chartOptions"
      [type]="chartType"
      baseChart
    ></canvas>
  </div>
</awareness-center-statistic-card>


