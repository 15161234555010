<form (ngSubmit)="onSubmit(userEditEmailForm, $event)" [formGroup]="userEditEmailForm">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
    <input formControlName="email" matInput type="email" />
    <mat-error *ngIf="userEditEmailForm.get('email').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
    <mat-error *ngIf="userEditEmailForm.get('email').errors?.email">
      {{ 'form.error.invalidEmail' | transloco }}
    </mat-error>
    <mat-error *ngIf="userEditEmailForm.get('email').errors?.username">
      {{ 'form.error.invalidUserName' | transloco }}
    </mat-error>
  </mat-form-field>
  <button [disabled]="userEditEmailForm.invalid" appearance="primary" wvButton>
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
