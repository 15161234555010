<form (ngSubmit)="onSubmit(accountantRegistrationForm, $event)" [formGroup]="accountantRegistrationForm">
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.salutation' | transloco }}</mat-label>
      <mat-select data-test="select-accountant-registration-details-gender" formControlName="gender">
        <mat-option [value]="'female'">{{ 'form.selection.salutation.female' | transloco }}</mat-option>
        <mat-option [value]="'male'">{{ 'form.selection.salutation.male' | transloco }}</mat-option>
      </mat-select>
      <mat-error *ngIf="accountantRegistrationForm.get('gender').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [gdpr]="
        ('info-box.common.phishingSimulationGdpr' | transloco) + '<br>' + ('info-box.common.userDataGdpr' | transloco)
      "
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.firstName' | transloco }}</mat-label>
      <input data-test="input-accountant-registration-details-first-name" formControlName="firstName" matInput />
      <mat-error *ngIf="accountantRegistrationForm.get('firstName').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [gdpr]="
        ('info-box.common.nameGdpr' | transloco) +
        '<br>' +
        ('info-box.common.phishingSimulationGdpr' | transloco) +
        '<br>' +
        ('info-box.common.userDataGdpr' | transloco)
      "
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.lastName' | transloco }}</mat-label>
      <input data-test="input-accountant-registration-details-last-name" formControlName="lastName" matInput />
      <mat-error *ngIf="accountantRegistrationForm.get('lastName').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [gdpr]="
        ('info-box.common.nameGdpr' | transloco) +
        '<br>' +
        ('info-box.common.phishingSimulationGdpr' | transloco) +
        '<br>' +
        ('info-box.common.userDataGdpr' | transloco)
      "
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.department' | transloco }}</mat-label>
      <input
        [matAutocomplete]="auto"
        data-test="input-accountant-registration-details-department"
        formControlName="department"
        matInput
        type="text"
      />
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="accountantRegistrationForm.get('department').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="accountantRegistrationForm.get('department').errors?.requireMatch">
        {{ 'form.error.requireMatch' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [gdpr]="
        ('info-box.common.phishingSimulationGdpr' | transloco) + '<br>' + ('info-box.common.userDataGdpr' | transloco)
      "
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field (focusin)="openPasswordCheckPanel()" appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.password' | transloco }}</mat-label>
      <input
        [type]="passwordHide ? 'password' : 'text'"
        data-test="input-accountant-registration-details-password"
        formControlName="password"
        matInput
      />
      <button
        (click)="passwordHide = !passwordHide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="passwordHide"
        [tabIndex]="-1"
        mat-icon-button
        matSuffix
        type="button"
      >
        <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="accountantRegistrationForm.get('password').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.password.description' | transloco"
      [gdpr]="'info-box.common.userDataGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.repeatPassword' | transloco }}</mat-label>
      <input
        [type]="passwordHide ? 'password' : 'text'"
        data-test="input-accountant-registration-details-repeat-password"
        formControlName="password2"
        matInput
      />
      <button
        (click)="passwordHide = !passwordHide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="passwordHide"
        [tabIndex]="-1"
        mat-icon-button
        matSuffix
        type="button"
      >
        <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="accountantRegistrationForm.get('password2').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="accountantRegistrationForm.get('password2').errors?.notEqual">
        {{ 'form.error.notEqual' | transloco }}
      </mat-error>
    </mat-form-field>
  </div>
  <ng-container *ngIf="passwordPolicy !== undefined">
    <mat-expansion-panel #passwordCheckPanel hideToggle>
      <ac-password-check
        [errors]="passwordValidationErrors$ | async"
        [passwordPolicy]="passwordPolicy"
        class="passwordCheck"
      ></ac-password-check>
    </mat-expansion-panel>
  </ng-container>
  <div class="check-boxes">
    <div class="checkbox-info-wrapper">
      <mat-checkbox
        class="full-width"
        color="primary"
        data-test="checkbox-accountant-registration-details-privacy"
        formControlName="privacy"
        required
      >
        {{ 'form.label.privacy' | transloco: {link: environment.urls.privacy} | renderHTML }}
      </mat-checkbox>
      <app-info-box [gdpr]="'info-box.common.consentGdpr' | transloco"></app-info-box>
    </div>
    <div class="checkbox-info-wrapper">
      <mat-checkbox
        class="full-width"
        color="primary"
        data-test="checkbox-accountant-registration-details-termsOfUse"
        formControlName="termsOfUse"
        required
      >
        {{ 'form.label.termsOfUse' | transloco: {link: environment.urls.termsOfUse} | renderHTML }}
      </mat-checkbox>
      <app-info-box [gdpr]="'info-box.common.consentGdpr' | transloco"></app-info-box>
    </div>
  </div>
  <div class="actions">
    <button
      (click)="goBack()"
      appearance="outline-secondary"
      class="submit-form-button"
      data-test="button-accountant-registration-details-back"
      type="button"
      wvButton
    >
      {{ 'control.backButton' | transloco }}
    </button>
    <button
      [disabled]="accountantRegistrationForm.invalid || isPasswordSpecificationNotSatisfied"
      appearance="primary"
      class="submit-form-button"
      data-test="button-accountant-registration-details-register"
      wvButton
    >
      {{ 'registration.accountant.submitButton' | transloco }}
    </button>
  </div>
</form>
