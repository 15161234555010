import { CourseDetailsModuleDto } from '@ac/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-details-playlist-module',
  templateUrl: './course-details-playlist-module.component.html',
  styleUrls: ['./course-details-playlist-module.component.scss']
})
export class CourseDetailsPlaylistModuleComponent {
  @Input() module: CourseDetailsModuleDto;
}
