import { FeedbackDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FeedbackApiService } from '../../feedback/feedback-api.service';
import { Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-partner-news',
  templateUrl: './partner-news.component.html',
  styleUrls: ['./partner-news.component.scss'],
  imports: [CommonModule, ColibriModule, MatTableModule, CdkTextareaAutosize, MatFormField, MatIcon],
  standalone: true
})
export class PartnerNewsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = ['subject', 'company', 'branch', 'companySize', 'dateTime'];

  constructor(
    private feedbackApi: FeedbackApiService,
    private router: Router,
    private urlBuilder: UrlBuilder
  ) {}

  ngOnInit(): void {
    this.dataSource.data = [
      {
        subject: 'So schützen Sie Ihre Privatsphäre online',
        company: 'GrüneEnergie GmbH',
        branch: 'Erneuerbare Energien',
        companySize: 50,
        dateTime: '2025-02-01T00:00:00Z'
      },
      {
        subject: 'Die häufigsten Cyber-Bedrohungen und wie man sich schützt',
        company: 'KreativWerkstatt',
        branch: 'Design und Kunst',
        companySize: 10,
        dateTime: '2025-02-02T00:00:00Z'
      },
      {
        subject: 'Passwortsicherheit: Tipps für starke Passwörter',
        company: 'GesundheitPlus',
        branch: 'Gesundheitswesen',
        companySize: 100,
        dateTime: '2025-02-03T00:00:00Z'
      },
      {
        subject: 'Phishing-Angriffe erkennen und abwehren',
        company: 'TechInnovationen',
        branch: 'Technologie',
        companySize: 5,
        dateTime: '2025-02-04T00:00:00Z'
      },
      {
        subject: 'Sichere Nutzung von öffentlichem WLAN',
        company: 'Hausgemachte Leckereien',
        branch: 'Catering',
        companySize: 20,
        dateTime: '2025-02-05T00:00:00Z'
      },
      {
        subject: 'Die Bedeutung von Software-Updates für Ihre Sicherheit',
        company: 'Nachhaltige Mode',
        branch: 'Mode',
        companySize: 30,
        dateTime: '2025-02-06T00:00:00Z'
      },
      {
        subject: 'Datenschutz-Grundverordnung: Was Sie wissen sollten',
        company: 'ReiseTraum GmbH',
        branch: 'Reise- und Tourismus',
        companySize: 15,
        dateTime: '2025-02-07T00:00:00Z'
      },
      {
        subject: 'Wie Sie sich vor Ransomware schützen',
        company: 'PixelArt Studios',
        branch: 'Grafikdesign',
        companySize: 8,
        dateTime: '2025-02-08T00:00:00Z'
      },
      {
        subject: 'Social Engineering: Manipulation im digitalen Zeitalter',
        company: 'BücherWelt',
        branch: 'Einzelhandel',
        companySize: 12,
        dateTime: '2025-02-09T00:00:00Z'
      },
      {
        subject: 'Kindersicherheit im Internet: Tipps für Eltern',
        company: 'MöbelManufaktur',
        branch: 'Möbelherstellung',
        companySize: 45,
        dateTime: '2025-02-10T00:00:00Z'
      }
    ];
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  goToFeedbackDetails(feedback: FeedbackDto) {
    this.router.navigateByUrl(this.urlBuilder.goToFeedbackDetails(feedback.id));
  }

  deleteFeedback(feedback: FeedbackDto, $event: MouseEvent) {
    this.feedbackApi.deleteFeedback(feedback.id).subscribe();
    $event.stopPropagation();
  }
}
