import { PhishingMailsCompanyStatisticDto, PhishingCompanyStatisticOptionsDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { injectQuery, queryOptions } from '@ngneat/query';
import { DateRange } from '@ac/colibri';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { catchError, map } from 'rxjs/operators';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { EMPTY } from 'rxjs';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';

@Injectable({ providedIn: 'root' })
export class PhishingMailsCompanyStatisticService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #loadingDialog = inject(LoadingDialogService);

  getStatisticOptionsQueryOptions(companyId: string) {
    return queryOptions({
      queryKey: ['companies', companyId, 'phishingMailsStatistics', 'options'],
      queryFn: () =>
        this.#http
          .get<PhishingCompanyStatisticOptionsDto>(
            `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}/PhishingMailsStatistics/Options`
          )
          .pipe(
            map(options => {
              options.min = new Date(options.min);
              options.max = new Date(options.max);
              options.defaultRange.from = new Date(options.defaultRange.from);
              options.defaultRange.to = new Date(options.defaultRange.to);
              return options;
            }),
            catchError(message => {
              this.#loadingDialog.open(LoadingDialogComponent);
              this.#loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: message.error
              });
              return EMPTY;
            })
          ),
      retry: 0
    });
  }

  getStatisticOptions(userId: string) {
    return this.#query(this.getStatisticOptionsQueryOptions(userId));
  }

  getStatisticsQueryOptions(companyId: string, range?: DateRange) {
    return queryOptions({
      queryKey: ['companies', companyId, 'phishingMailsStatistics', range],
      queryFn: () =>
        this.#http
          .get<PhishingMailsCompanyStatisticDto>(
            `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}/PhishingMailsStatistics?from=${range?.from?.toISOString() || ''}&to=${range?.to?.toISOString() || ''}`
          )
          .pipe(
            catchError(message => {
              this.#loadingDialog.open(LoadingDialogComponent);
              this.#loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: message.error
              });
              return EMPTY;
            })
          ),
      retry: 0,
      enabled: !!range
    });
  }

  getStatistics(companyId: string) {
    return this.#query(this.getStatisticsQueryOptions(companyId));
  }
}
