import { PasswordPolicyDto, RegisterUserDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '../../../environments/environment';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  passwordPolicy$: Observable<PasswordPolicyDto>;
  environment = environment;
  token: string;
  userId: string;
  video: VideoDto = {
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoUserRegistrationSubtitles
      }
    ],
    streamUrl: environment.resources.helpVideoUserRegistration,
    downloadUrl: null
  };

  constructor(
    private identityService: IdentityService,
    private router: Router,
    private urlBuilder: UrlBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const token = decodeURIComponent(queryParams.token);
      const userId = decodeURIComponent(queryParams.userId);

      if (token === 'undefined' || userId === 'undefined' || !token || !userId) {
        this.router.navigateByUrl(this.urlBuilder.goToLogin());
      }

      this.identityService.checkInvitation(userId).subscribe();

      this.token = token;
      this.userId = userId;
    });

    this.passwordPolicy$ = this.identityService.getPasswordPolicy();
  }

  registerUser(dto: RegisterUserDto): void {
    this.identityService.registerUser(dto).subscribe(() => {
      this.router.navigateByUrl(this.urlBuilder.goToLogin());
    });
  }
}
