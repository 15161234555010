import { inject, Injectable } from '@angular/core';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env';
import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import { companySubscriptionsQueryKey } from './company-subscribtions-query-key';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #queryClient = injectQueryClient();
  #loadingDialog = inject(LoadingDialogService);

  getSubscriptions() {
    return this.#query({
      queryKey: [companySubscriptionsQueryKey] as const,
      queryFn: () => {
        return this.#http.get<ReadCompanySubscriptionDto[]>(
          environment.apiUrl + environment.apiRoutes.companySubscriptions
        );
      }
    });
  }

  findByCompany(companyId: string) {
    return this.#query({
      queryKey: [companySubscriptionsQueryKey, 'companies', companyId] as const,
      queryFn: () => {
        return this.#http.get<ReadCompanySubscriptionDto>(
          `${environment.apiUrl}${environment.apiRoutes.companies}/${companyId}/subscription`
        );
      }
    });
  }

  createSubscription() {
    return this.#mutation({
      mutationFn: (dto: CreateCompanySubscriptionDto) =>
        this.#http.post<ReadCompanySubscriptionDto>(
          environment.apiUrl + environment.apiRoutes.companySubscriptions,
          dto
        ),
      onSuccess: async () => {
        alert('Erfolgreich');
        await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey] });
      },
      onError: () => alert('Fehler')
    });
  }

  updateSubscription() {
    return this.#mutation({
      mutationFn: (props: { id: string; dto: UpdateCompanySubscriptionDto }) =>
        this.#http.put<ReadCompanySubscriptionDto>(
          `${environment.apiUrl + environment.apiRoutes.companySubscriptions}/${props.id}`,
          props.dto
        ),
      onSuccess: async (_data, variables) => {
        alert('Erfolgreich');
        await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, variables.id] });
      },
      onError: () => alert('Fehler')
    });
  }

  pausePhishing() {
    this.#loadingDialog.open(LoadingDialogComponent);
    return this.#mutation({
      mutationFn: (props: { id: string }) =>
        this.#http.post<string>(
          `${environment.apiUrl + environment.apiRoutes.companySubscriptions}/${props.id}/phishing/pause`,
          {}
        ),
      onSuccess: async (data, variables) => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data,
          afterClose: async () => {
            await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, variables.id] });
            await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, 'companies'] });
            return true;
          }
        });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }

  continuePhishing() {
    this.#loadingDialog.open(LoadingDialogComponent);
    return this.#mutation({
      mutationFn: (props: { id: string }) =>
        this.#http.post<string>(
          `${environment.apiUrl + environment.apiRoutes.companySubscriptions}/${props.id}/phishing/continue`,
          {}
        ),
      onSuccess: async (data, variables) => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data,
          afterClose: async () => {
            await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, variables.id] });
            await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, 'companies'] });
            return true;
          }
        });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }
}

export interface ReadCompanySubscriptionDto {
  companyId: string;
  companyName: string;
  companyBusinessSector: string;
  companyCreatedAt: string;
  id: string;
  isBillable: boolean;
  runningContract: ReadContractDto;
  followUpContract: ReadContractDto;
  phishing: ReadPhishingDto;
}

export interface ReadPhishingDto {
  isPaused: boolean;
  redirectUrl?: string;
  allowCustomRedirectUrl: boolean;
  simulationCount: number;
  randomizationLabel: string;
}

export interface ReadContractDto {
  packageName: string;
  packageValue: number;
  periodStart: string;
  periodEnd: string;
  licenseCosts: number;
  minimumNumberOfUsers: number;
}

export interface CreateCompanySubscriptionDto {
  companyId: string;
  packageValue: number;
  from: string;
  durationInMonths: number;
  licenseCosts: number;
  minimumQuantity: number;
}

export interface UpdateCompanySubscriptionDto {
  packageValue: number;
  from: string;
  durationInMonths: number;
  licenseCosts: number;
  minimumQuantity: number;
}

export interface UpdateSubscriptionPhishingConfigurationDto {
  phishingRedirectUrl?: string;
}
