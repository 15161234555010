import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IdentityService } from '../identity/identity.service';
import { UrlBuilder } from '../url-builder.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private identityService: IdentityService, private router: Router, private urlBuilder: UrlBuilder) {}

  canActivate(): boolean {
    if (this.identityService.isTokenExpired()) {
      this.router.navigateByUrl(this.urlBuilder.goToLogin());
      console.warn('Insufficient Permissions');
      return false;
    }

    return true;
  }
}
