import { TextCollectionDiffer } from '@ac/colibri';
import { UserDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserRolesService {
  private endpoint = environment.apiUrl + environment.apiRoutes.users;

  constructor(private http: HttpClient, private differ: TextCollectionDiffer) {}

  /*
   * Provides all roles the logged in user is allowed to mange.
   */
  loadManagedRoles(userId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.endpoint}/${userId}/ManagedRoles`);
  }

  /*
   * Updates the roles of a user.
   */
  update(user: UserDto, roles: string[], managedRoles: string[]): Observable<void> {
    const changeableRoles = user.assignedRoles.filter(role => managedRoles.includes(role));
    const changes = this.differ.diff(changeableRoles, roles);

    const addRoleRequests = changes.additions.map(role =>
      this.http.post<void>(`${this.endpoint}/${user.id}/Roles/${role}`, {})
    );

    const deleteRoleRequests = changes.removals.map(role =>
      this.http.delete<void>(`${this.endpoint}/${user.id}/Roles/${role}`)
    );

    return forkJoin([...addRoleRequests, ...deleteRoleRequests]).pipe(
      map(() => {
        return;
      })
    );
  }
}
