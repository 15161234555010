import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { PromotionComponent } from './promotion/promotion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {ConfirmNavigationDirective} from "../../../../../libs/colibri/src/lib/directives/confirm-navigation.directive";

@NgModule({
  declarations: [PromotionDetailsComponent, PromotionComponent],
    imports: [
        CommonModule,
        ColibriModule,
        MatIconModule,
        ReactiveFormsModule,
        LayoutsModule,
        TranslateModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ConfirmNavigationDirective
    ],
  exports: [PromotionDetailsComponent, PromotionComponent]
})
export class PromotionModule {}
