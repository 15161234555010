<form (ngSubmit)="onSubmit(userEditPasswordForm, $event)" [formGroup]="userEditPasswordForm">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{ 'form.label.oldPassword' | transloco }}</mat-label>
    <input [type]="passwordHide ? 'password' : 'text'" formControlName="oldPassword" matInput />
    <button
      (click)="passwordHide = !passwordHide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="passwordHide"
      [tabIndex]="-1"
      mat-icon-button
      matSuffix
      type="button"
    >
      <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="userEditPasswordForm.get('oldPassword').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
  </mat-form-field>
  <mat-form-field (focusin)="openPasswordCheckPanel()" appearance="outline" class="full-width">
    <mat-label>{{ 'form.label.newPassword' | transloco }}</mat-label>
    <input [type]="passwordHide ? 'password' : 'text'" formControlName="password" matInput />
    <button
      (click)="passwordHide = !passwordHide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="passwordHide"
      [tabIndex]="-1"
      mat-icon-button
      matSuffix
      type="button"
    >
      <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="userEditPasswordForm.get('password').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
    <mat-error *ngIf="userEditPasswordForm.get('password').errors?.notEqual">
      {{ 'form.error.notEqual' | transloco }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{ 'form.label.repeatNewPassword' | transloco }}</mat-label>
    <input [type]="passwordHide ? 'password' : 'text'" formControlName="password2" matInput />
    <button
      (click)="passwordHide = !passwordHide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="passwordHide"
      [tabIndex]="-1"
      mat-icon-button
      matSuffix
      type="button"
    >
      <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="userEditPasswordForm.get('password2').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
    <mat-error *ngIf="userEditPasswordForm.get('password2').errors?.notEqual">
      {{ 'form.error.notEqual' | transloco }}
    </mat-error>
  </mat-form-field>
  <ng-container *ngIf="passwordPolicy !== undefined">
    <mat-expansion-panel #passwordCheckPanel hideToggle>
      <ac-password-check
        [errors]="passwordValidationErrors$ | async"
        [passwordPolicy]="passwordPolicy"
        class="passwordCheck"
      ></ac-password-check>
    </mat-expansion-panel>
  </ng-container>
  <button
    [disabled]="userEditPasswordForm.invalid || isPasswordSpecificationNotSatisfied"
    appearance="primary"
    wvButton
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
