<h3 class="p-0" mat-dialog-title>Freigaberegeln bestätigen</h3>
<div mat-dialog-content>
  <div class="grid gap-4">
    <p class="p-0">
      {{ 'subscription.phishing.resume-simulation-confirmation-condition-hint' | transloco }}
    </p>
    <div class="flex gap-4">
      <mat-checkbox
        [(ngModel)]="configurationConfirmed"
        class="pl-1"
      ></mat-checkbox>
      <div
        class="grid">
        <p class="p-0">
          {{ 'subscription.configurationConfirmed' | transloco: {url: environment.resources.helpAdminConfiguration} | renderHTML }}
        </p>
        <p class="text-xs">
          Senden Sie sich zur Überprüfung Ihrer Freigaberegeln <span
          (click)="sendTestPhishingMail()"
          class="underline text-primary cursor-pointer">hiermit</span> eine E-Mail zu.
        </p>
      </div>
    </div>
    <div class="flex gap-4">
      <button (click)="cancel()" appearance="outline-secondary"
              wvButton>{{ 'common.cancel' | transloco }}
      </button>
      <button (click)="confirm()" [disabled]="!configurationConfirmed()" appearance="secondary"
              wvButton>{{ 'subscription.phishing.continue' | transloco }}
      </button>
    </div>
  </div>
</div>
