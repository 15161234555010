<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Württembergische Versicherung - Zugänge verwalten'"
>
<button
  (click)="openInvitationDialog()"
  appearance="secondary"
  wvButton
  data-testid="button-create-access"
>
  {{ 'whitelistedAccesses.addAccessButton' | transloco }}
</button>
<app-whitelisted-accesses-overview></app-whitelisted-accesses-overview>
</app-back-layout>
