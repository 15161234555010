<table *ngIf="dataSource" [dataSource]="dataSource" mat-table matSort matSortActive="dateTime" matSortDirection="desc">
  <ng-container matColumnDef="id">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.id' | transloco }}</th>
    <td *matCellDef="let feedback" mat-cell>{{ feedback.id }}</td>
  </ng-container>
  <ng-container matColumnDef="subject">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.subject' | transloco }}</th>
    <td *matCellDef="let feedback" mat-cell>{{ feedback.subject }}</td>
  </ng-container>
  <ng-container matColumnDef="dateTime">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.dateTime' | transloco }}</th>
    <td *matCellDef="let feedback" mat-cell>
      {{ feedback.dateTime | date: 'dd.MM.yyyy, HH:mm' }} {{ 'time' | transloco }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let feedback" mat-cell>
      <div class="table-actions">
        <button
          (click)="deleteFeedback(feedback, $event)"
          [matTooltip]="'tooltip.delete' | transloco"
          color="accent"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr (click)="goToFeedbackDetails(feedback)" *matRowDef="let feedback; columns: displayedColumns" mat-row></tr>
</table>
