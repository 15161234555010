import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NewsletterService } from './newsletter.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
  sendNewsletterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private newsletterService: NewsletterService) {
    this.sendNewsletterForm = this.formBuilder.group({
      sendGridTemplateId: ['', [Validators.required, Validators.pattern('^d-[abcdef0-9]{32}$')]]
    });
  }

  onSubmit(sendNewsletterForm: UntypedFormGroup, $event: Event) {
    $event.preventDefault();

    if (sendNewsletterForm.invalid) {
      return;
    }

    const templateId = sendNewsletterForm.get('sendGridTemplateId').value;

    this.newsletterService.sendNewsletter(templateId).subscribe();
  }
}
