<div class="grid gap-8 p-2">
  <section class="grid gap-4">
    <h3 class="p-0">Nutzung Ihrer Materialien</h3>
    <div class="grid grid-cols-2 gap-8">
      <app-partner-resource-clicks-statistic />
      <app-partner-latest-resource-clicks-statistic />
    </div>
  </section>
  <section class="grid gap-4">
    <h3 class="p-0">Antworten zu Ihren Umfragen</h3>
    <div class="grid grid-cols-3 gap-8">
      <app-partner-kurse-bewertung />
    </div>
  </section>
  <section class="grid gap-4">
    <h3 class="p-0">Einsatz Ihrer Werkzeuge</h3>
    <div class="grid grid-cols-2 gap-8">
      <app-partner-toolkit-business-sector-usage-statistic class="col-span-2" />
      <app-partner-toolkit-user-usage-statistic />
    </div>
  </section>
  <section class="grid gap-4">
    <h3 class="p-0">Analyse Ihrer Webinare</h3>
    <div class="grid gap-8 grid-cols-2">
      <app-partner-webinar-statistic class="col-span-2"s/>
      <app-partner-webinar-statistic-circle-progress />
    </div>
  </section>
</div>
