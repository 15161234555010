import { Component, Input, OnInit } from '@angular/core';
import { RansomClausesService } from '../ransom-clauses.service';
import { EMPTY, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { RansomClauseDto } from '../ransom-clause.dto';

@Component({
  selector: 'app-ransom-clause-test-passed',
  templateUrl: './ransom-clause-test-passed.component.html',
  styleUrls: ['./ransom-clause-test-passed.component.scss']
})
export class RansomClauseTestPassedComponent implements OnInit {
  @Input() data: RansomClauseDto;
  confirmed = false;

  constructor(private ransomClausesService: RansomClausesService) {}

  ngOnInit(): void {
    if (this.data) {
      this.ransomClausesService
        .confirmRansomClause(this.data)
        .pipe(
          first(),
          tap(() => (this.confirmed = true)),
          catchError(() => {
            return of(EMPTY);
          })
        )
        .subscribe();
    }
  }
}
