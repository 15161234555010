import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NonNullableFormBuilder} from "@angular/forms";
import {CustomValidator} from "@ac/colibri";
import {
  ReadCompanySubscriptionDto,
  UpdateSubscriptionPhishingConfigurationDto
} from "../company-subscriptions/subscriptions.service";

@Component({
  selector: 'app-company-edit-phishing-form',
  templateUrl: './subscription-edit-phishing-configuration-form.component.html',
  styleUrl: './subscription-edit-phishing-configuration-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionEditPhishingConfigurationForm implements OnInit {
  @Input({required: true}) subscription: ReadCompanySubscriptionDto;
  @Output() submitForm = new EventEmitter<UpdateSubscriptionPhishingConfigurationDto>();
  #formBuilder = inject(NonNullableFormBuilder);
  protected form = this.#formBuilder.group({
    phishingRedirectUrl: ['', [CustomValidator.url()]],
  });

  ngOnInit(): void {
    this.form.patchValue({
      phishingRedirectUrl: this.subscription.phishing.redirectUrl
    });
  }

  save() {
    if (this.form.invalid)
      return;

    const dto = this.form.value as UpdateSubscriptionPhishingConfigurationDto;

    this.submitForm.emit(dto);
  }
}
