<awareness-center-resource-card
  data-testid="help-document-card"
  *ngIf="identityService.hasRole('Cyber-Verantwortlicher')"
  [description]="'help.document.adminConfiguration.description' | transloco"
  [title]="'help.document.adminConfiguration.title' | transloco"
  [url]="environment.resources.helpAdminConfiguration"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.privacy.description' | transloco"
  [title]="'help.document.privacy.title' | transloco"
  [url]="environment.urls.privacy"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.termsOfUse.description' | transloco"
  [title]="'help.document.termsOfUse.title' | transloco"
  [url]="environment.urls.termsOfUse"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.scopeOfServices.description' | transloco"
  [title]="'help.document.scopeOfServices.title' | transloco"
  [url]="environment.urls.scopeOfServices"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'Sie sind von einem Cyber-Angriff betroffen? Orientieren Sie sich an dieser Checkliste, um sich in dieser Situation richtig zu verhalten. Die Liste wird Ihnen von unserem Partner Vogel & Partner zur Verfügung gestellt.'"
  [title]="'Checkliste: Cyber-Angriff'"
  [url]="'https://www.vogel-partner.eu/'"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'Nutzen Sie diese von Vogel & Partner zur Verfügung gestellte Vorlage zur Erstellung Ihres Auftragsverarbeitungsvertrags.'"
  [title]="'AVV'"
  [url]="'https://www.vogel-partner.eu/'"
></awareness-center-resource-card>

