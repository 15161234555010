<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Agenturen - Beratungsgespräch'"
>
<p>Im folgenden Video sehen Sie ein fiktives Beratungsgespräch mit einem potenziellen Versicherungsnehmer zur Cyber-Police.<br>Nutzen Sie dieses gerne als Inspiration für eigene Beratungsgespräche mit Ihren Interessenten.</p>
<app-videoplayer
  class="shadow-xl w-1/2"
  [autoplay]="false"
  [resetOnEnd]="true"
  [video]="video"
  data-testid="help-video-player"
></app-videoplayer>
</app-back-layout>
