import { inject, Injectable } from '@angular/core';
import { LoadingDialogComponent } from '../../../layouts/loading-dialog/loading-dialog.component';
import { environment } from '@env';
import { LoadingDialogState } from '../../../layouts/loading-dialog/loading-dialog-state.enum';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UpdateSubscriptionPhishingConfigurationDto } from '../../company-subscriptions/subscriptions.service';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { LoadingDialogService } from '../../../layouts/loading-dialog/loading-dialog.service';
import { companySubscriptionsQueryKey } from '../../company-subscriptions/company-subscribtions-query-key';

@Injectable()
export class ChangeAwarenessPageClient {
  #http = inject(HttpClient);
  #createMutation = injectMutation();
  #queryClient = injectQueryClient();
  #loadingDialog = inject(LoadingDialogService);

  changeAwarenessPage() {
    return this.#createMutation({
      mutationFn: (props: ChangeAwarenessPageDto) => {
        this.#loadingDialog.open(LoadingDialogComponent);

        return this.#http.put<string>(
          `${environment.apiUrl}${environment.apiRoutes.companySubscriptions}/${props.subscriptionId}/phishing`,
          props
        );
      },
      onSuccess: async (data, variables) => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data,
          afterClose: async () => {
            await this.#queryClient.invalidateQueries({
              queryKey: [companySubscriptionsQueryKey, variables.subscriptionId]
            });
            await this.#queryClient.invalidateQueries({ queryKey: [companySubscriptionsQueryKey, 'companies'] });
            return true;
          }
        });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }
}

export type ChangeAwarenessPageDto = {
  subscriptionId: string;
  phishingRedirectUrl: string;
};
