<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Vogel & Partner'"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="w-full"
    color="accent"
  >
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'Meine Daten'"
    >
      <ng-template matTabContent>
        <app-partner-profile></app-partner-profile>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'Nachrichten'"
    >
      <ng-template matTabContent>
        <app-partner-news />
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'Integrationen'"
    >
      <ng-template matTabContent>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'Statistiken'"
    >
      <ng-template matTabContent>
        <app-partner-statistics></app-partner-statistics>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
