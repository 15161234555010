import { CourseDetailsCourseDto, CourseOverviewCourseDto, CoursePlayerCourseDto } from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoDto } from '../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CourseApiService {
  #http = inject(HttpClient);

  getCourseOverview(): Observable<Array<CourseOverviewCourseDto>> {
    return this.#http.get<CourseOverviewCourseDto[]>(environment.apiUrl + environment.apiRoutes.courseOverviewCourses);
  }

  getCourseDetails(id: string): Observable<CourseDetailsCourseDto> {
    return this.#http.get<CourseDetailsCourseDto>(`${environment.apiUrl}${environment.apiRoutes.courses}/${id}`);
  }

  getCoursePlayer(id: string): Observable<CoursePlayerCourseDto> {
    return this.#http.get<CoursePlayerCourseDto>(
      `${environment.apiUrl}${environment.apiRoutes.courses}/${id}${environment.apiRoutes.coursePlayerCourses}`
    );
  }

  completeVideo(lessonId: string): Observable<VideoDto[]> {
    return this.#http.post<VideoDto[]>(
      environment.apiUrl + environment.apiRoutes.coursePlayerWatchedVideos,
      JSON.stringify(lessonId)
    );
  }
}
