<awareness-center-statistic-card heading="Interesse erkennen"
                                 text="Bauen Sie Ihr Bewusstsein für die Bedürfnisse Ihrer Partner aus.">
  <div class="relative w-full grid grid-cols-3" statistic>
    <div class="text-center">
      <h4>KI und Cybersecurity</h4>
      <awareness-center-percentage-animation
        [animation]="false"
        [percentage]="kurse1()"
      ></awareness-center-percentage-animation>
    </div>

    <div class="text-center">
      <h4>ChatGPT in Geschäft</h4>
      <awareness-center-percentage-animation
        [animation]="false"
        [percentage]="kurse2()"
      ></awareness-center-percentage-animation>
    </div>

    <div class="text-center">
      <h4>Webinar 3</h4>
      <awareness-center-percentage-animation
        [animation]="false"
        [percentage]="kurse3()"
      ></awareness-center-percentage-animation>
    </div>
  </div>
</awareness-center-statistic-card>

