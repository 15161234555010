<ac-task-header [configuration]="configuration"></ac-task-header>

<ul>
  <li *ngFor="let option of configuration.options" class="answer">
    <mat-checkbox
      (change)="toggleAnswer(option, $event.checked)"
      color="accent"
      data-test="option-checkbox"
      data-testid="option-checkbox"
    >
      {{ option.label }}
    </mat-checkbox>
  </li>
</ul>
<div class="commands">
  <button
    (click)="submitAnswer()"
    [disabled]="formSubmitted"
    appearance="secondary"
    data-test="button-submit-solution"
    data-testid="button-submit-solution"
    wvButton
  >
    {{ 'exam.submitAnswerButton' | transloco }}
  </button>
</div>
