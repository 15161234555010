<ng-container *ngIf="feedback$ | async as feedback">
  <app-back-layout
    [backUrl]="urlBuilder.goToAdministration()"
    [title]="'feedbackDetails.title' | transloco: { feedbackId: feedback.id }"
  >
    <ul>
      <li>{{ 'feedbackDetails.id' | transloco }}: {{ feedback.id }}</li>
      <li>{{ 'feedbackDetails.subject' | transloco }}: {{ feedback.subject }}</li>
      <li>{{ 'feedbackDetails.message' | transloco }}: {{ feedback.message }}</li>
      <li>{{ 'feedbackDetails.dateTime' | transloco }}: {{ feedback.dateTime | date: 'dd.MM.yyyy HH:mm' }} Uhr</li>
      <li>
        {{ 'feedbackDetails.email' | transloco }}:
        <a [href]="createMailToLink(feedback.email, feedback.id)">{{ feedback.email }}</a>
      </li>
      <li>{{ 'feedbackDetails.userId' | transloco }}: {{ feedback.userId }}</li>
      <li>{{ 'feedbackDetails.operatingSystem' | transloco }}: {{ feedback.operatingSystem }}</li>
      <li>
        {{ 'feedbackDetails.browser' | transloco }}: {{ feedback.browserName }} {{ feedback.browserVersion }} ({{
        feedback.browserLanguage }})
      </li>
      <li>{{ 'feedbackDetails.screenSize' | transloco }}: {{ feedback.screenWidth }} x {{ feedback.screenHeight }}</li>
      <li>
        {{ 'feedbackDetails.nativeResolution' | transloco }}: {{ feedback.nativeScreenWidth }} x {{
        feedback.nativeScreenHeight }}
      </li>
      <li>{{ 'feedbackDetails.currentPath' | transloco }}: {{ feedback.currentPath }}</li>
      <li>{{ 'feedbackDetails.ipAddress' | transloco }}: {{ feedback.ipAddress }}</li>
    </ul>
  </app-back-layout>
</ng-container>
