import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ransom-clause-test-failed',
  templateUrl: './ransom-clause-test-failed.component.html',
  styleUrls: ['./ransom-clause-test-failed.component.scss']
})
export class RansomClauseTestFailedComponent {
  @Input() correctTasks: number;
  @Input() requiredCorrectTasks: number;

  retry(): void {
    location.reload();
  }
}
