<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - co-IT'"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="w-full"
    color="accent"
  >
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.feedbackManagement' | transloco"
    >
      <ng-template matTabContent>
        <app-feedback-overview></app-feedback-overview>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.subscriptions-management' | transloco"
    >
      <ng-template matTabContent>
        <app-company-subscriptions></app-company-subscriptions>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.phishing-mails-management' | transloco"
    >
      <ng-template matTabContent>
        <app-phishing-mails-management></app-phishing-mails-management>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.quickInvitation' | transloco"
    >
      <ng-template matTabContent>
        <app-quick-invitation></app-quick-invitation>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.management' | transloco"
    >
      <ng-template matTabContent>
        <app-admin-management></app-admin-management>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.newsletterManagement' | transloco"
    >
      <ng-template matTabContent>
        <app-newsletter></app-newsletter>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
