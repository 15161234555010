import { QuestionAndAnswer, TaskCompletion, TaskComponent } from '@ac/exam';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'ac-question-and-answer',
  templateUrl: './question-and-answer.component.html',
  styleUrls: ['./question-and-answer.component.scss']
})
export class QuestionAndAnswerComponent implements TaskComponent<QuestionAndAnswer> {
  configuration: QuestionAndAnswer;
  form: UntypedFormGroup;
  isAnswerSubmitted = false;
  private complete$$ = new Subject<TaskCompletion>();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.buildForm();
    this.form.updateValueAndValidity();
  }

  complete = () => this.complete$$.asObservable();

  prepare(configuration: QuestionAndAnswer): void {
    this.configuration = configuration;
  }

  submitAnswer() {
    this.isAnswerSubmitted = true;

    if (this.form.get('sentence').value === this.configuration.solution) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({ isCorrect: false });
    }
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        sentence: ['', [Validators.required]]
      },
      { updateOn: 'change' }
    );
  }
}
