import {
  PhishingMailsUserStatisticDto,
  PhishingCompanyStatisticOptionsDto,
  PhishingUserStatisticOptionsDto
} from '@ac/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { injectQuery, queryOptions } from '@ngneat/query';
import { DateRange } from '@ac/colibri';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { catchError, map } from 'rxjs/operators';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { EMPTY } from 'rxjs';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';

@Injectable({ providedIn: 'root' })
export class PhishingMailsUserStatisticService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #loadingDialog = inject(LoadingDialogService);

  getStatisticOptionsQueryOptions(userId: string) {
    return queryOptions({
      queryKey: ['users', userId, 'phishingMailsStatistics', 'options'],
      queryFn: () =>
        this.#http
          .get<PhishingUserStatisticOptionsDto>(
            `${environment.apiUrl}${environment.apiRoutes.users}/${userId}/PhishingMailsStatistics/Options`
          )
          .pipe(
            map(options => {
              options.min = new Date(options.min);
              options.max = new Date(options.max);
              options.defaultRange.from = new Date(options.defaultRange.from);
              options.defaultRange.to = new Date(options.defaultRange.to);
              return options;
            }),
            catchError(message => {
              this.#loadingDialog.open(LoadingDialogComponent);
              this.#loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: message.error
              });
              return EMPTY;
            })
          ),
      retry: 0
    });
  }

  getStatisticOptions(userId: string) {
    return this.#query(this.getStatisticOptionsQueryOptions(userId));
  }

  getStatisticsQueryOptions(userId: string, range?: DateRange) {
    return queryOptions({
      queryKey: ['users', userId, 'phishingMailsStatistics', range],
      queryFn: () =>
        this.#http
          .get<PhishingMailsUserStatisticDto>(
            `${environment.apiUrl}${environment.apiRoutes.users}/${userId}/PhishingMailsStatistics?from=${range?.from?.toISOString() || ''}&to=${range?.to?.toISOString() || ''}`
          )
          .pipe(
            catchError(message => {
              this.#loadingDialog.open(LoadingDialogComponent);
              this.#loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: message.error
              });
              return EMPTY;
            })
          ),
      retry: 0,
      enabled: !!range
    });
  }

  getStatistics(userId: string) {
    return this.#query(this.getStatisticsQueryOptions(userId));
  }
}
