<awareness-center-statistic-card heading="Entscheider verstehen"
                                 text="ℹ️ Bieten Sie eine Erstberatungen zum reduzierten Preis an.">
  <div class="relative w-full min-h-[22rem]" statistic>
    <canvas
      [data]="chartData"
      [options]="chartOptions"
      [type]="chartType"
      baseChart
    ></canvas>
  </div>
</awareness-center-statistic-card>

