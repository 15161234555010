import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';

@Component({
  selector: 'app-partner-webinar-statistic-circle-progress',
  standalone: true,
  imports: [CommonModule, ColibriModule],
  templateUrl: './partner-webinar-statistic-circle-progress.component.html',
  styleUrl: './partner-webinar-statistic-circle-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerWebinarStatisticCircleProgressComponent {
  kurse1 = signal(90);
  kurse2 = signal(72);
  kurse3 = signal(58);
}
