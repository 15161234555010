<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Württembergische Versicherung'"
>
<mat-tab-group
  [disableRipple]="true"
  [dynamicHeight]="true"
  class="w-full"
  color="accent"
>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
    [label]="'administration.tabLabel.whitelistedAccesses' | transloco"
  >
    <ng-template matTabContent>
      <app-whitelisted-accesses></app-whitelisted-accesses>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
    [label]="'administration.tabLabel.insurancesImport' | transloco"
  >
    <ng-template matTabContent>
      <app-insurances-import></app-insurances-import>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
    [label]="'Versicherungsnehmer'"
  >
    <ng-template matTabContent>
      <app-company-management></app-company-management>
    </ng-template>
  </mat-tab>
  <mat-tab
    [label]="'Assekuradeure verwalten'"
  >
    <ng-template matTabContent>
      <div class="grid gap-4 grid-cols-4">
      <wv-card [title]="'Assekuradeure freischalten'" class="m-2">
        <mat-form-field [appearance]="'outline'" class="w-full">
          <mat-label>E-Mail</mat-label>
          <input matInput class="w-full">
        </mat-form-field>
        <button wvButton>Freischalten</button>
      </wv-card>
      <wv-card [title]="'Assekuradeure entfernen'" class="m-2">
        <mat-form-field [appearance]="'outline'" class="w-full">
          <mat-label>E-Mail</mat-label>
          <input matInput class="w-full">
        </mat-form-field>
        <button wvButton>Entfernen</button>
      </wv-card>
        <wv-card [title]="'Assekuradeure'" class="m-2 row-start-2 col-span-2">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
               Name
              </th>
              <td mat-cell *matCellDef="let assekuradeur"> {{assekuradeur.name}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>
                E-Mail
              </th>
              <td mat-cell *matCellDef="let assekuradeur"> {{assekuradeur.email}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </wv-card>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager')"
    [label]="'administration.tabLabel.ransom-clause' | transloco"
  >
    <ng-template matTabContent>
      <app-ransom-clause-initialization></app-ransom-clause-initialization>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager')"
    [label]="'Nachrichten'"
  >
    <ng-template matTabContent>
      <app-feedback-overview-wv></app-feedback-overview-wv>
    </ng-template>
  </mat-tab>
</mat-tab-group>
</app-back-layout>
