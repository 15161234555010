import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ColibriModule} from "@ac/colibri";

@Component({
  selector: 'app-phishing-mails-total-clicks-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule],
  templateUrl: './phishing-mails-total-clicks-statistic.component.html',
  styleUrl: './phishing-mails-total-clicks-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhishingMailsTotalClicksStatisticComponent {
  clickCount = input.required<number>();
  sentCount = input.required<number>();
}
