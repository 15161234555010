import { Component, Input } from '@angular/core';
import { CourseDetailsCourseDto } from '@ac/models';
import {hexToRGB} from "../../utils/hex-to-rgb";

@Component({
  selector: 'app-course-stats-progress',
  templateUrl: './course-stats-progress.component.html',
  styleUrls: ['./course-stats-progress.component.scss']
})
export class CourseStatsProgressComponent {
  @Input() courses: Array<CourseDetailsCourseDto>;
  innerStrokeColor = hexToRGB(getComputedStyle(document.documentElement).getPropertyValue('--on-surface'), 0.2);

  calculateProgress(): number {
    const durationSum = this.courses.reduce(
      (sum, current) =>
        sum +
        current.modules.reduce(
          (sum2, current2) => sum2 + current2.lessons.reduce((sum3, current3) => sum3 + current3.duration, 0),
          0
        ),
      0
    );
    const durationCompleted = this.courses.reduce(
      (sum, current) =>
        sum +
        current.modules.reduce(
          (sum2, current2) =>
            sum2 + current2.lessons.filter(x => x.completed).reduce((sum3, current3) => sum3 + current3.duration, 0),
          0
        ),
      0
    );

    return (100 / durationSum) * durationCompleted;
  }
}
