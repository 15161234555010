import { Component } from '@angular/core';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-self-delete-user',
  templateUrl: './self-delete-user.component.html',
  styleUrls: ['./self-delete-user.component.scss']
})
export class SelfDeleteUserComponent {
  constructor(private identityService: IdentityService) {}

  selfDeleteUser() {
    const userId = this.identityService.getUserId();

    this.identityService.deleteUser(userId).subscribe(() => this.identityService.logout());
  }
}
