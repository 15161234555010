import { FileInputValidator } from '@ac/colibri';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-user-invitation-file-form',
  templateUrl: './user-invitation-file-form.component.html',
  styleUrls: ['./user-invitation-file-form.component.scss']
})
export class UserInvitationFileFormComponent {
  bulkInviteUserFileForm: UntypedFormGroup;
  public allowedFileTypes = [
    '.txt',
    '.csv',
    'text/comma-separated-values',
    'text/plain',
    'text/richtext',
    'text/rtf',
    'application/vnd.ms-excel'
  ];
  file: File;
  private maxFileSize = 0.256 * 2 ** 20; //256 KB

  constructor(private formBuilder: UntypedFormBuilder, private identityService: IdentityService) {
    this.bulkInviteUserFileForm = this.formBuilder.group({
      file: [
        '',
        [
          Validators.required,
          FileValidator.maxContentSize(this.maxFileSize),
          FileInputValidator.allowedFileTypes(this.allowedFileTypes)
        ]
      ]
    });
  }

  onSubmitFile(bulkInviteUserFileForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (bulkInviteUserFileForm.invalid) {
      return;
    }

    const formData = new FormData();

    formData.append('file', this.file, this.file.name);

    this.identityService.inviteUserFile(formData).subscribe();
  }

  setFile($event: any) {
    this.file = <File>$event.target.files[0];
  }
}
