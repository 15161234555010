import { RequestAccessDto } from '@ac/models';
import { Component, EventEmitter, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { environment } from '@env';
import { insuranceNumberRegexPattern } from '../../utils/regex-patterns';

@Component({
  selector: 'app-victor-company-registration-request-access-form',
  templateUrl: './victor-company-registration-request-access-form.component.html',
  styleUrls: ['./victor-company-registration-request-access-form.component.scss']
})
export class VictorCompanyRegistrationRequestAccessFormComponent {
  @Output() submitForm = new EventEmitter<Partial<RequestAccessDto>>();
  environment = environment;
  insuranceNumberPattern = insuranceNumberRegexPattern;
  companyRegistrationRequestForm: UntypedFormGroup;
  /*
   * Since we use MatMomentDateAdapter the datepicker delivers a moment object.
   * We need a date object.
   * That's why we convert the moment object to a Date
   */
  maxDate = new Date();
  minDate = new Date('1990-01-01');
  protected readonly acceptedRecaptcha = signal(false);

  constructor(private formBuilder: UntypedFormBuilder) {
    this.companyRegistrationRequestForm = this.formBuilder.group({
      insuranceNumber: ['', [Validators.required, Validators.pattern(this.insuranceNumberPattern)]],
      insuranceDate: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      privacy: ['', [Validators.required]],
      agb: ['', [Validators.required]],
      recaptcha: ['', [Validators.required]]
    });
  }

  emitRegistrationRequestData(companyRegistrationRequestForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (companyRegistrationRequestForm.invalid) {
      return;
    }

    const dto = { ...companyRegistrationRequestForm.value };

    dto.insuranceDate = moment(dto.insuranceDate).toDate();

    this.submitForm.emit(dto);
  }
}
