import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-logout',
  template: ``
})
export class LogoutComponent implements OnInit {
  constructor(private identityService: IdentityService, private router: Router, private urlBuilder: UrlBuilder) {}

  ngOnInit(): void {
    this.identityService.logout();
    this.router.navigateByUrl(this.urlBuilder.goToLogin());
  }
}
