import {ChangeDetectionStrategy, Component, effect, inject, viewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {DefaultChartOptionsService} from "../../../../phishing-mails/default-chart-options.service";

@Component({
  selector: 'app-partner-resource-clicks-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-resource-clicks-statistic.component.html',
  styleUrl: './partner-resource-clicks-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerResourceClicksStatisticComponent {
  chart = viewChild(BaseChartDirective);
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          text: "Klicks",
          display: true
        },
      },
      y: {
        title: {
          text: "Ressource",
          display: true
        }
      }
    }
  };

  constructor() {
    const resourceClicks = [
      { resource: "Checkliste Cyber-Angriff", clicks: 1500 },
      { resource: "Vorlage Auftragsverarbeitungsvertrag", clicks: 2800 },
      { resource: "Leitfaden Datenschutz-Grundverordnung (DSGVO)", clicks: 1200 },
      { resource: "Muster für eine Datenschutzerklärung", clicks: 900 },
      { resource: "Handbuch zur Erkennung von Phishing-Angriffen", clicks: 2500 },
      { resource: "Checkliste für die Erstellung eines Notfallplans", clicks: 1700 },
      { resource: "Leitfaden zur sicheren Datenlöschung", clicks: 3000 },
      { resource: "Vorlage für ein Informationssicherheitskonzept", clicks: 2000 }
    ].sort((a, b) => b.clicks - a.clicks);

    const backgroundColor = this.#defaultChartOptions.getDefaultColors().backgroundColor[0];
    const hoverBackgroundColor = this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[0];
    const hoverBorderColor = this.#defaultChartOptions.getDefaultColors().hoverBorderColor;

    this.chartData.datasets =  [{
      data: resourceClicks.map((resourceClick) => resourceClick.clicks),
      backgroundColor,
      hoverBackgroundColor,
      hoverBorderColor
    }];
    this.chartData.labels = resourceClicks.map((resourceClick) => resourceClick.resource);

    effect(() => {
      if (this.chart()) {
        this.chart().update();
      }
    })
  }
}
