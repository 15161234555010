import {ChangeDetectionStrategy, Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UrlBuilder} from "../../url-builder.service";
import {IdentityService} from "../../identity/identity.service";
import {AdministrationModule} from "../administration.module";
import {TranslocoPipe} from "@ngneat/transloco";
import { environment } from '@env';
import {LayoutsModule} from "../../layouts/layouts.module";
import {ColibriModule} from "@ac/colibri";
import { CyberPortalModule } from '@app/cyber-portal/cyber-portal.module';

@Component({
  selector: 'app-administration-wv',
  standalone: true,
  imports: [
    CommonModule,
    AdministrationModule,
    TranslocoPipe,
    LayoutsModule,
    ColibriModule,
    CyberPortalModule
  ],
  templateUrl: './administration-wv.component.html',
  styleUrl: './administration-wv.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationWvComponent  {
  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}

  protected readonly environment = environment;
}
