import { CourseDetailsCourseDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CertificatesService } from '../../certificates.service';
import { IdentityService } from '../../identity/identity.service';
import { UrlBuilder } from '../../url-builder.service';
import { CourseApiService } from '../course-api.service';
import { CourseResourceCollector, CourseResourceProjection } from '../course-resource-collector.service';
import { CourseContextService } from '../course-context.service';
import { CompanyCoursesApiService } from '../company-courses-api.service';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent {
  course$: Observable<CourseDetailsCourseDto> = this.getCourse();
  courseResources: CourseResourceProjection[];

  constructor(
    private route: ActivatedRoute,
    private courseApiService: CourseApiService,
    private companyCoursesApiService: CompanyCoursesApiService,
    private courseResourcecollector: CourseResourceCollector,
    public urlBuilder: UrlBuilder,
    private identityService: IdentityService,
    private certificatesService: CertificatesService,
    private courseContextService: CourseContextService
  ) {}

  requestCertificate(courseId: string): void {
    const userId = this.identityService.getUserId();

    this.certificatesService.requestCertificate(userId, courseId).subscribe();
  }

  private getCourse(): Observable<CourseDetailsCourseDto> {
    const context = this.courseContextService.getContext();

    if (context === 'Course') {
      return this.route.params.pipe(
        switchMap(params => this.courseApiService.getCourseDetails(params.courseId)),
        tap(course => (!course ? window.history.back() : {})),
        tap(course => (this.courseResources = this.courseResourcecollector.collect(course)))
      );
    } else if (context === 'CompanyCourse') {
      return this.route.params.pipe(
        switchMap(params => this.companyCoursesApiService.getCourseDetails(params.companyId, params.courseId)),
        tap(course => (!course ? window.history.back() : {})),
        tap(course => (this.courseResources = this.courseResourcecollector.collect(course)))
      );
    }
  }

  continueLink$: Observable<string> = this.course$.pipe(
    switchMap(course => {
      const context = this.courseContextService.getContext();

      if (context === 'Course') {
        return of(this.urlBuilder.goToVideo(course.id, course.nextLessonId));
      } else if (context === 'CompanyCourse') {
        return this.route.params.pipe(
          map(params => this.urlBuilder.goToCompanyVideo(params.companyId, course.id, course.nextLessonId))
        );
      }
    })
  );
}
