import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cyber-portal-overview-item',
  templateUrl: './cyber-portal-overview-item.component.html',
  styleUrls: ['./cyber-portal-overview-item.component.scss']
})
export class CyberPortalOverviewItemComponent {
  @Input() title: string;
  @Input() imgUrl: string;
  @Input() link: string;
}
