import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { EMPTY, Observable } from 'rxjs';
import { LoadingDialogComponent } from '../../../layouts/loading-dialog/loading-dialog.component';
import { catchError, tap } from 'rxjs/operators';
import { LoadingDialogState } from '../../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogService } from '../../../layouts/loading-dialog/loading-dialog.service';

@Injectable({ providedIn: 'root' })
export class NewsletterService {
  private endpoint = environment.apiUrl + environment.apiRoutes.newsletters;

  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService) {}

  sendNewsletter(sendGridTemplateId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.post<string>(`${this.endpoint}/${sendGridTemplateId}/send`, {}).pipe(
      tap({
        next: message =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: message
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        return EMPTY;
      })
    );
  }
}
