import { CoursePlayerCourseDto, CoursePlayerLessonDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { UrlBuilder } from '../../url-builder.service';
import { CourseApiService } from '../course-api.service';
import { CourseContextService } from '../course-context.service';
import { CompanyCoursesApiService } from '../company-courses-api.service';

@Component({
  selector: 'app-course-player',
  templateUrl: './course-player.component.html',
  styleUrls: ['./course-player.component.scss']
})
export class CoursePlayerComponent implements OnInit {
  currentLesson: CoursePlayerLessonDto;
  currentVideo: VideoDto;
  course: CoursePlayerCourseDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseApiService: CourseApiService,
    private companyCoursesApiService: CompanyCoursesApiService,
    private courseContextService: CourseContextService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap(({ contentId, courseId, companyId }) => {
          const context = this.courseContextService.getContext();

          if (context === 'Course') {
            return this.courseApiService.getCoursePlayer(courseId).pipe(map(course => ({ course, contentId })));
          } else if (context === 'CompanyCourse') {
            return this.companyCoursesApiService
              .getCoursePlayer(companyId, courseId)
              .pipe(map(course => ({ course, contentId })));
          }
        }),
        tap(({ course, contentId }) => {
          const activatedContentId = contentId;

          this.course = course;

          if (course.disabled) {
            window.history.back();
          }

          course.modules.forEach(module =>
            module.lessons.forEach(lesson => {
              if (lesson.id === activatedContentId) {
                this.currentLesson = lesson;
                this.currentVideo = {
                  captions: lesson.captions,
                  streamUrl: lesson.streamUrl,
                  downloadUrl: null
                };
              }
            })
          );
          if (this.currentLesson === undefined) {
            this.router.navigate(['..', course.modules[0].lessons[0].id], { relativeTo: this.route });
          }
        })
      )
      .subscribe();
  }

  public handleVideoEnd(): void {
    this.postCompleteVideo();
  }

  handleSpinnerEnd() {
    this.playNextVideoInPlaylist();
  }

  private playNextVideoInPlaylist() {
    let newContent: CoursePlayerLessonDto;

    this.course.modules.forEach(lesson => {
      if (newContent !== undefined) {
        return;
      }
      const lessonIndex = lesson.lessons.findIndex(video => video.id === this.currentLesson.id);
      const moduleIndex = this.course.modules.findIndex(lesson2 => lesson2.id === lesson.id);

      if (lessonIndex === -1) {
        return;
      }
      if (lesson.lessons.length - 1 === lessonIndex) {
        if (this.course.modules.length - 1 === moduleIndex) {
          return;
        }
        newContent = this.course.modules[moduleIndex + 1].lessons[0];
        return;
      }
      newContent = this.course.modules[moduleIndex].lessons[lessonIndex + 1];
    });

    if (newContent !== undefined) {
      this.router.navigate(['..', newContent.id], { relativeTo: this.route });
    } else {
      this.router.navigate(['../..'], { relativeTo: this.route });
    }
  }

  private postCompleteVideo() {
    const context = this.courseContextService.getContext();

    if (context === 'Course') {
      this.courseApiService.completeVideo(this.currentLesson.id).subscribe();
    } else if (context === 'CompanyCourse') {
      this.route.params
        .pipe(switchMap(params => this.companyCoursesApiService.completeVideo(params.companyId, this.currentLesson.id)))
        .subscribe();
    }
  }
}
