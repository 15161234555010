import {Directive, HostListener, inject, input} from '@angular/core';
import {ConfirmationService} from "../confirmation-dialog/confirmation.service";
import {TranslocoService} from "@ngneat/transloco";

@Directive({
  standalone: true,
  selector: 'a[confirmNavigation]'
})
export class ConfirmNavigationDirective {
  message = input<string>()

  confirmationService = inject(ConfirmationService);
  #transloco = inject(TranslocoService);

  @HostListener('click', ['$event'])
  async handleClick(event: PointerEvent) {
    event.preventDefault();

    let anchorElement: HTMLAnchorElement | null = event.currentTarget as HTMLAnchorElement;

    if (!anchorElement) {
      // Traverse up the DOM tree to find the anchor element
      anchorElement = (event.target as HTMLElement).closest('a');
    }

    if(!anchorElement)
      return;

    const href = anchorElement.href;

    if (!href)
      return;

    const target = (event.target as HTMLAnchorElement).target;

    const confirmed = await this.confirmationService.confirm({
      title: this.#transloco.translate('confirm-navigation.title'),
      message: this.message() || this.#transloco.translate('confirm-navigation.message'),
      cancelLabel: 'Abbrechen',
      confirmLabel: 'Weiter'
    });

    if (!confirmed)
      return;

    window.open(href, target);
  }
}
