<app-back-layout [backUrl]="urlBuilder.goToLogin()" title="Passwort zurücksetzen">
  <div class="form-container">
    <wv-card [title]="'passwordReset.subtitle' | transloco" [overline]="'passwordReset.title' | transloco">
      <form (ngSubmit)="onSubmit(passwordResetForm, $event)" [formGroup]="passwordResetForm">
        <mat-form-field (focusin)="openPasswordCheckPanel()" appearance="outline" class="full-width">
          <mat-label>{{ 'form.label.password' | transloco }}</mat-label>
          <input [type]="passwordHide ? 'password' : 'text'" formControlName="password" matInput />
          <button
            (click)="passwordHide = !passwordHide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="passwordHide"
            [tabIndex]="-1"
            mat-icon-button
            matSuffix
            type="button"
          >
            <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="passwordResetForm.get('password').errors?.required">
            {{ 'form.error.required' | transloco }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'form.label.repeatPassword' | transloco }}</mat-label>
          <input [type]="passwordHide ? 'password' : 'text'" formControlName="password2" matInput />
          <button
            (click)="passwordHide = !passwordHide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="passwordHide"
            [tabIndex]="-1"
            mat-icon-button
            matSuffix
            type="button"
          >
            <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="passwordResetForm.get('password2').errors?.required">
            {{ 'form.error.required' | transloco }}
          </mat-error>
          <mat-error *ngIf="passwordResetForm.get('password2').errors?.notEqual">
            {{ 'form.error.notEqual' | transloco }}
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="passwordPolicy !== undefined">
          <mat-expansion-panel #passwordCheckPanel hideToggle>
            <ac-password-check
              [errors]="passwordValidationErrors$ | async"
              [passwordPolicy]="passwordPolicy"
              class="passwordCheck"
            ></ac-password-check>
          </mat-expansion-panel>
        </ng-container>
        <button [disabled]="passwordResetForm.invalid || isPasswordSpecificationNotSatisfied" appearance="primary" wvButton>
          {{ 'passwordReset.submitButton' | transloco }}
        </button>
      </form>
    </wv-card>
  </div>
</app-back-layout>
