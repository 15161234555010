import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '@env';
import { PlainStepperComponent } from '../../../../../../libs/colibri/src/lib/plain-stepper/plain-stepper.component';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ransomClauseExam } from '../ransom-clause.exam';
import { RansomClauseDto } from '../ransom-clause.dto';

@Component({
  selector: 'app-ransom-clause',
  templateUrl: './ransom-clause.component.html',
  styleUrls: ['./ransom-clause.component.scss']
})
export class RansomClauseComponent implements OnInit {
  data: RansomClauseDto;
  examConfiguration = ransomClauseExam;
  ransomClauseTestResult: { correctAnswers: number; passed: boolean } = {
    correctAnswers: 0,
    passed: false
  };
  video: VideoDto = {
    streamUrl: environment.resources.ransomClause,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.ransomClauseSubtitles
      }
    ],
    downloadUrl: null
  };
  environment = environment;
  @ViewChild('stepper') private stepper: PlainStepperComponent;

  constructor(private route: ActivatedRoute) {}

  completeExam(results: { correctAnswers: number }) {
    this.ransomClauseTestResult.correctAnswers = results.correctAnswers;
    this.ransomClauseTestResult.passed = this.examConfiguration.general.requiredCorrectTasks <= results.correctAnswers;
    this.nextStep();
  }

  nextStep() {
    this.stepper.next();
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(queryParams => {
          this.data = {
            insuranceNumber: queryParams.insuranceNumber || '',
            policyHolderEmail: queryParams.policyHolderEmail || '',
            underwriterEmail: queryParams.underwriterEmail || '',
            companyName: queryParams.companyName || ''
          };
        })
      )
      .subscribe();
  }
}
