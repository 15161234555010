<awareness-center-statistic-card heading="Branchen verstehen"
                                 text="ℹ️ Stellen Sie Branchen-spezifische Kurse zur Verfügung.">
  <div class="grid gap-4 grid-cols-[repeat(auto-fit,minmax(260px,1fr))] w-full" statistic>
    @for (chart of charts; track chart.name) {
      <div class="w-full">
        <h4 class="text-center">{{ chart.name }}</h4>
        <div class="relative w-full min-h-[22rem]">
          <canvas
            [data]="chart.chartData"
            [options]="chart.chartOptions"
            [type]="chart.chartType"
            baseChart
          ></canvas>
        </div>
      </div>
    }
  </div>
</awareness-center-statistic-card>

