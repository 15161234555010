<a [routerLink]="link">
  <mat-card class="mat-elevation-z2">
    <ac-image-faded [src]="imgUrl" mat-card-image></ac-image-faded>
    <mat-card-content>
      <mat-card-title class="course-card-title">
        <h3 class="course-title">{{ title }}</h3>
      </mat-card-title>
    </mat-card-content>
  </mat-card>
</a>
