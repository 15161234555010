<ac-task-header [configuration]="configuration"></ac-task-header>

<form (ngSubmit)="submitAnswer()" [formGroup]="form" class="task-box">
  <mat-form-field appearance="outline" class="exam__form-field">
    <mat-label>{{ 'exam.answer' | transloco }}</mat-label>
    <input [attr.disabled]="isAnswerSubmitted" formControlName="sentence" matInput type="text" />
  </mat-form-field>
  <div class="commands">
    <button [disabled]="isAnswerSubmitted || form.invalid" appearance="secondary" wvButton>
      {{ 'exam.submitAnswerButton' | transloco }}
    </button>
  </div>
</form>
