import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerLatestResourceClicksStatisticComponent } from './partner-latest-resource-clicks-statistic/partner-latest-resource-clicks-statistic.component';
import { PartnerResourceClicksStatisticComponent } from './partner-resource-clicks-statistic/partner-resource-clicks-statistic.component';
import { PartnerStatisticsKurseBewertungComponent } from './partner-statistics-kurse-bewertung/partner-statistics-kurse-bewertung.component';
import { PartnerWebinarStatisticComponent } from './partner-webinar-statistic/partner-webinar-statistic.component';
import { PartnerToolkitUserUsageStatisticComponent } from './partner-toolkit-user-usage-statistic/partner-toolkit-user-usage-statistic.component';
import { PartnerToolkitBusinessSectorUsageStatisticComponent } from './partner-toolkit-business-sector-usage-statistic/partner-toolkit-business-sector-usage-statistic.component';
import { PartnerWebinarStatisticCircleProgressComponent } from './partner-webinar-statistic-circle-progress/partner-webinar-statistic-circle-progress.component';
import { LayoutsModule } from '../../../layouts/layouts.module';
import { PartnerCoursesBusinessSectorUsageStatisticComponent } from './partner-courses-business-sector-usage-statistic/partner-courses-business-sector-usage-statistic.component';
import { PartnerCoursesUserUsageStatisticComponent } from './partner-courses-user-usage-statistic/partner-courses-user-usage-statistic.component';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { PartnerStatisticsNaechstenThemenComponent } from './partner-statistics-naechsten-themen/partner-statistics-naechsten-themen.component';
import { PartnerStatisticsFehlendenRessourcenComponent } from './partner-statistics-fehlenden-ressourcen/partner-statistics-fehlenden-ressourcen.component';
import {PartnerPlannedWebinarStatisticComponent} from "./partner-planned-webinar-statistic/partner-planned-webinar-statistic.component";

@Component({
  selector: 'app-partner-statistics',
  standalone: true,
  imports: [
    CommonModule,
    PartnerLatestResourceClicksStatisticComponent,
    PartnerResourceClicksStatisticComponent,
    PartnerStatisticsKurseBewertungComponent,
    PartnerWebinarStatisticComponent,
    PartnerToolkitUserUsageStatisticComponent,
    PartnerToolkitBusinessSectorUsageStatisticComponent,
    PartnerWebinarStatisticCircleProgressComponent,
    LayoutsModule,
    PartnerCoursesBusinessSectorUsageStatisticComponent,
    PartnerCoursesUserUsageStatisticComponent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    PartnerStatisticsNaechstenThemenComponent,
    PartnerStatisticsFehlendenRessourcenComponent,
    PartnerPlannedWebinarStatisticComponent
  ],
  templateUrl: './partner-statistics.component.html',
  styleUrl: './partner-statistics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerStatisticsComponent {}
