import { SelectOptionsService } from '@ac/colibri';
import { UpdateGenderDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-edit-gender-form',
  templateUrl: './user-edit-gender-form.component.html',
  styleUrls: ['./user-edit-gender-form.component.scss']
})
export class UserEditGenderFormComponent implements OnInit {
  @Output() submitForm = new EventEmitter<UpdateGenderDto>();
  @Input() isFemale: boolean;
  userEditGenderForm: UntypedFormGroup;

  constructor(private selectOptionsService: SelectOptionsService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.userEditGenderForm = this.formBuilder.group({
      gender: ['', [Validators.required]]
    });

    this.userEditGenderForm.get('gender').setValue(this.isFemale ? 'female' : 'male');
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UpdateGenderDto = {
      isFemale: userData.get('gender').value !== 'male'
    };

    this.submitForm.emit(dto);
  }
}
