<app-back-layout [backUrl]="urlBuilder.goToHome()" [title]="'company.edit.title' | transloco">
  <div class="grid gap-12 max-w-4xl">
    <wv-card
      [title]="'company.edit.changeInformation' | transloco"
    >
      @if (company().isLoading) {
        <mat-spinner></mat-spinner>
      }
      @if (company().data; as company) {
        <app-company-edit-information-form
          (submitForm)="updateInformation($event)"
          [company]="company"
        ></app-company-edit-information-form>
      }
    </wv-card>
    <wv-card
      [title]="'company.edit.delete' | transloco"
    >
      <p>{{ 'company.edit.deleteInfo' | transloco }}</p>
      <button (click)="deleteCompany()" appearance="primary" wvButton>
        {{ 'company.edit.deleteButton' | transloco }}
      </button>
    </wv-card>
  </div>
</app-back-layout>
