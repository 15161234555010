<ac-box-centered>
  <awareness-center-wv-hero-banner [scrollTarget]="scrollTarget"></awareness-center-wv-hero-banner>
  <div class="bottom-container">
    <div #scrollTarget class="text-container" data-test="box-introduction">
      <div class="text-headings">
        <h3 class="secondary">{{ 'app.title' | transloco | renderHTML }}</h3>
        <h2>{{ 'app.slogan' | transloco | renderHTML }}</h2>
      </div>
      <p>{{ 'app.disclaimer' | transloco | renderHTML }}</p>
      <p>{{ 'login.processInfo' | transloco | renderHTML }}</p>
      <p>{{ 'app.consentInfo' | transloco | renderHTML }}</p>
    </div>
    <div class="form-logo-container">
      <img [src]="environment.assets.logo" />
    </div>
    <div class="forms-container">
      <wv-card [title]="'login.loginForm.subtitle' | transloco" [overline]="'login.loginForm.title' | transloco" class="h-max">
        <app-user-login-form (submitForm)="login($event)" class="form"></app-user-login-form>
      </wv-card>
      <wv-card
        [title]="'login.registrationRequestForm.subtitle' | transloco"
        [overline]="'login.registrationRequestForm.title' | transloco"
      >
        <app-company-registration-request-access-form
          (submitForm)="requestAccessForCompany($event)"
          class="form"
        ></app-company-registration-request-access-form>
      </wv-card>
    </div>
  </div>
</ac-box-centered>
