import { Component, Input } from '@angular/core';
import { CourseResourceProjection } from '../course-resource-collector.service';

@Component({
  selector: 'app-course-details-files',
  templateUrl: './course-details-files.component.html',
  styleUrls: ['./course-details-files.component.scss']
})
export class CourseDetailsFilesComponent {
  @Input() files: CourseResourceProjection[];
}
