import { FeedbackDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FeedbackApiService } from '../../../feedback/feedback-api.service';
import { UrlBuilder } from '../../../url-builder.service';

@Component({
  selector: 'app-feedback-overview-wv',
  templateUrl: './feedback-overview-wv.component.html',
  styleUrls: ['./feedback-overview-wv.component.scss']
})
export class FeedbackOverviewWvComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<FeedbackDto & {companyName: string}> = new MatTableDataSource<FeedbackDto & {companyName: string}>();
  displayedColumns = ['subject', 'companyName', 'email', 'dateTime', 'actions'];

  constructor(
    private feedbackApi: FeedbackApiService,
    private router: Router,
    private urlBuilder: UrlBuilder
  ) {}

  ngOnInit(): void {
    this.dataSource.data = [
      {
        id: "1234",
        userId: "asdf",
        email: "info@sonnenschein.de",
        browserName: "Chrome",
        browserVersion: "103.0.0.0",
        operatingSystem: "Windows 10 64-bit",
        screenWidth: 1835,
        screenHeight: 1080,
        nativeScreenWidth: 1920,
        nativeScreenHeight: 1080,
        browserLanguage: "de-DE",
        currentPath: "https://localhost:4200/cyber-portal",
        dateTime: "2022-07-27T10:32:50.0754772+02:00",
        subject: "Registrierung fehlgeschlagen - Zugang belegt",
        message: "Test",
        ipAddress: "::1",
        companyName: "Sonnenschein Apotheke"
      },
      {
        id: "12345",
        userId: "asdf5",
        email: "info@berger.de",
        browserName: "Chrome",
        browserVersion: "103.0.0.0",
        operatingSystem: "Windows 10 64-bit",
        screenWidth: 1835,
        screenHeight: 1080,
        nativeScreenWidth: 1920,
        nativeScreenHeight: 1080,
        browserLanguage: "de-DE",
        currentPath: "https://localhost:4200/cyber-portal",
        dateTime: "2022-06-13T15:34:23.0754772+02:00",
        subject: "Registrierung fehlgeschlagen - Zugangsdaten unbekannt",
        message: "Test",
        ipAddress: "::1",
        companyName: "Berger Metalltechnik GmbH"
      }
    ]
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  goToFeedbackDetails(feedback: FeedbackDto) {
    this.router.navigateByUrl(this.urlBuilder.goToFeedbackDetails(feedback.id));
  }

  deleteFeedback(feedback: FeedbackDto, $event: MouseEvent) {
    this.feedbackApi.deleteFeedback(feedback.id).subscribe();
    $event.stopPropagation();
  }
}
