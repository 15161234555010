import { CourseOverviewCourseDto } from '@ac/models';
import { Component, Input } from '@angular/core';
import { CertificatesService } from '../../certificates.service';
import { IdentityService } from '../../identity/identity.service';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-course-overview-course',
  templateUrl: './course-overview-course.component.html',
  styleUrls: ['./course-overview-course.component.scss']
})
export class CourseOverviewCourseComponent {
  @Input() course: CourseOverviewCourseDto;
  @Input() link: string;

  constructor(
    public urlBuilder: UrlBuilder,
    private identityService: IdentityService,
    private loadingDialog: LoadingDialogService,
    private certificatesService: CertificatesService
  ) {}

  requestCertificate(courseId: string): void {
    const userId = this.identityService.getUserId();

    this.certificatesService.requestCertificate(userId, courseId).subscribe();
  }

  showDeactivationReason() {
    this.loadingDialog.open(LoadingDialogComponent);
    //todo: timeout entfernen
    setTimeout(
      () =>
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: this.course.reasonForDeactivation
        }),
      30
    );
  }
}
