import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';

export class BearerTokenInterceptor implements HttpInterceptor {
  private static getToken(): string {
    return localStorage.getItem('ac-auth_token');
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestUrl = req.url.toLocaleLowerCase();
    const endpointUrl = environment.apiUrl.toLocaleLowerCase();

    if (!requestUrl.startsWith(endpointUrl)) {
      return next.handle(req);
    }

    const bearerToken = BearerTokenInterceptor.getToken();

    if (!bearerToken) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      headers: req.headers.append('Authorization', `Bearer ${bearerToken}`)
    });

    return next.handle(clonedRequest);
  }
}
