import { ColibriModule } from '@ac/colibri';
import { ExamModule } from '@ac/exam';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LayoutsModule } from '../layouts/layouts.module';
import { NavigationModule } from '../navigation/navigation.module';
import { TranslateModule } from '../translate.module';
import { CourseDetailsDescriptionComponent } from './course-details-description/course-details-description.component';
import { CourseDetailsFilesComponent } from './course-details-files/course-details-files.component';
import { CourseDetailsHeaderComponent } from './course-details-header/course-details-header.component';
import { CourseDetailsPlaylistLessonComponent } from './course-details-playlist-lesson/course-details-playlist-lesson.component';
import { CourseDetailsPlaylistModuleComponent } from './course-details-playlist-module/course-details-playlist-module.component';
import { CourseDetailsPlaylistComponent } from './course-details-playlist/course-details-playlist.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CourseExamComponent } from './course-exam/course-exam.component';
import { CourseOverviewCourseComponent } from './course-overview-course/course-overview-course.component';
import { CourseOverviewComponent } from './course-overview/course-overview.component';
import { CoursePlayerPlaylistLessonComponent } from './course-player-playlist-lesson/course-player-playlist-lesson.component';
import { CoursePlayerPlaylistModuleComponent } from './course-player-playlist-module/course-player-playlist-module.component';
import { CoursePlayerPlaylistComponent } from './course-player-playlist/course-player-playlist.component';
import { CoursePlayerComponent } from './course-player/course-player.component';
import { CourseRoutingModule } from './course-routing.module';
import { CourseStatsProgressComponent } from './course-stats-progress/course-stats-progress.component';
import { CourseStatsSuggestionComponent } from './course-stats-suggestion/course-stats-suggestion.component';
import { CourseStatsComponent } from './course-stats/course-stats.component';
import { CourseContext } from './course-context';

@NgModule({
  declarations: [
    CoursePlayerPlaylistComponent,
    CoursePlayerComponent,
    CourseDetailsHeaderComponent,
    CourseDetailsComponent,
    CoursePlayerPlaylistModuleComponent,
    CoursePlayerPlaylistLessonComponent,
    CourseDetailsPlaylistComponent,
    CourseDetailsFilesComponent,
    CourseDetailsDescriptionComponent,
    CourseOverviewComponent,
    CourseOverviewCourseComponent,
    CourseStatsComponent,
    CourseStatsProgressComponent,
    CourseStatsSuggestionComponent,
    CourseDetailsPlaylistModuleComponent,
    CourseDetailsPlaylistLessonComponent,
    CourseExamComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgCircleProgressModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatCardModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    ColibriModule,
    ExamModule,
    NavigationModule,
    CourseRoutingModule,
    LayoutsModule,
    TranslateModule
  ],
  exports: [
    CoursePlayerPlaylistComponent,
    CoursePlayerComponent,
    CourseDetailsHeaderComponent,
    CourseDetailsComponent,
    CoursePlayerPlaylistModuleComponent,
    CoursePlayerPlaylistLessonComponent,
    CourseDetailsPlaylistComponent,
    CourseDetailsFilesComponent,
    CourseDetailsDescriptionComponent,
    CourseOverviewComponent,
    CourseOverviewCourseComponent,
    CourseStatsComponent,
    CourseStatsProgressComponent,
    CourseStatsSuggestionComponent,
    CourseDetailsPlaylistModuleComponent,
    CourseDetailsPlaylistLessonComponent,
    CourseExamComponent
  ],
  providers: [{ provide: Window, useValue: window }]
})
export class CourseModule {}
