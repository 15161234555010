import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingDialogState } from './layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from './layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from './layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from './url-builder.service';

@Injectable({ providedIn: 'root' })
export class CertificatesService {
  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService, private urlBuilder: UrlBuilder) {}

  requestCertificate(userId: string, courseId: string): Observable<string> {
    this.loadingDialog.open(LoadingDialogComponent);

    return this.http.get<string>(this.urlBuilder.downloadCertificate(userId, courseId)).pipe(
      tap({
        next: message =>
          this.loadingDialog.setData({
            data: LoadingDialogState.Success,
            content: message
          })
      }),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error
        });
        return EMPTY;
      })
    );
  }
}
