import { FeedbackDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { FeedbackApiService } from '../../feedback/feedback-api.service';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-feedback-overview',
  templateUrl: './feedback-overview.component.html',
  styleUrls: ['./feedback-overview.component.scss']
})
export class FeedbackOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<FeedbackDto> = new MatTableDataSource<FeedbackDto>();
  displayedColumns = ['id', 'subject', 'dateTime', 'actions'];

  constructor(
    private feedbackApi: FeedbackApiService,
    private router: Router,
    private urlBuilder: UrlBuilder
  ) {}

  ngOnInit(): void {
    this.feedbackApi.getFeedbacks().subscribe(feedbacks => (this.dataSource.data = feedbacks));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  goToFeedbackDetails(feedback: FeedbackDto) {
    this.router.navigateByUrl(this.urlBuilder.goToFeedbackDetails(feedback.id));
  }

  deleteFeedback(feedback: FeedbackDto, $event: MouseEvent) {
    this.feedbackApi.deleteFeedback(feedback.id).subscribe();
    $event.stopPropagation();
  }
}
