import { PasswordValidationErrors, PasswordValidators } from '@ac/colibri';
import { PasswordPolicyDto, ResetPasswordCommand } from '@ac/models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IdentityService } from '../identity.service';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordValidationErrors$: Observable<PasswordValidationErrors>;
  userId: string;
  token: string;
  passwordResetForm: UntypedFormGroup;
  passwordHide = true;
  passwordPolicy: PasswordPolicyDto;
  isPasswordSpecificationNotSatisfied = true;
  @ViewChild(MatExpansionPanel) passwordCheckPanel: MatExpansionPanel;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    public urlBuilder: UrlBuilder
  ) {
    this.passwordResetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        password2: ['', [Validators.required]]
      },
      { validators: PasswordValidators.equalPasswords }
    );
  }

  ngOnInit(): void {
    this.passwordValidationErrors$ = this.passwordResetForm.get('password').valueChanges.pipe(
      map(() => Object.keys(this.passwordResetForm.get('password').errors || []) as PasswordValidationErrors),
      tap(errors => {
        /**
         *
         * Somehow the control error of password is not populated to the form.
         * The form indicates itself as valid, although password contains errors.
         *
         * Until we found the root cause for this behaviour we have to use additional
         * state to disable the submit button.
         */
        if (errors.length) {
          this.isPasswordSpecificationNotSatisfied = true;
        } else {
          this.isPasswordSpecificationNotSatisfied = false;
        }
      })
    );

    this.route.queryParams.subscribe(queryParams => {
      this.token = decodeURIComponent(queryParams.token);
    });
    this.route.params.subscribe(params => {
      this.userId = decodeURIComponent(params.userId);
    });

    this.identityService.getPasswordPolicy().subscribe(policy => {
      this.passwordPolicy = policy;
      this.passwordResetForm.get('password').setValidators([Validators.required, PasswordValidators.policy(policy)]);
      this.passwordResetForm.get('password').updateValueAndValidity();
      this.passwordResetForm.get('password2').setValidators([Validators.required, PasswordValidators.policy(policy)]);
      this.passwordResetForm.get('password2').updateValueAndValidity();
    });
  }

  onSubmit(passwordResetForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (passwordResetForm.invalid) {
      return;
    }

    const resetPasswordCommand: ResetPasswordCommand = {
      password: passwordResetForm.get('password').value,
      token: this.token,
      userId: this.userId
    };

    this.identityService.resetPassword(resetPasswordCommand).subscribe();
  }

  openPasswordCheckPanel(): void {
    this.passwordCheckPanel?.open();
  }
}
