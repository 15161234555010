<ng-container *ngIf="!examStarted; else exam">
  <p>{{ 'course.exam.hint' | transloco | renderHTML }}</p>
  <p>
    {{ 'course.exam.info' | transloco: {numberOfTasks: numberOfTasks, requiredNumberOfCorrectAnswers:
    requiredNumberOfCorrectAnswers} | renderHTML }}
  </p>
  <p>{{ 'course.exam.repeatInfo' | transloco | renderHTML }}</p>
  <button (click)="startExam()" appearance="secondary" wvButton data-testid="course-exam-start">
    {{ 'course.exam.startButton' | transloco }}
  </button>
</ng-container>

<ng-template #exam>
  <ac-exam (complete)="reportResults($event)" [configuration]="examinationConfiguration"></ac-exam>
</ng-template>
