<mat-drawer-container>
  <mat-drawer
    mode="side"
    opened
  >
    <app-course-player-playlist
      *ngIf="course"
      [course]="course"
    ></app-course-player-playlist>
  </mat-drawer>
  <mat-drawer-content>
      <app-videoplayer
        (spinnerEnded)="handleSpinnerEnd()"
        (videoEnded)="handleVideoEnd()"
        *ngIf="currentVideo"
        [delay]="3"
        [video]="currentVideo"
        [title]="currentLesson.title"
        [autoplay]="true"
      ></app-videoplayer>
  </mat-drawer-content>
</mat-drawer-container>
