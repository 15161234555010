import { ExaminationTask } from '@ac/exam';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ac-task-header',
  template: `
    <section class="exam__typography">
      <h3 data-test="title-task">{{ configuration.heading }}</h3>
      <img *ngIf="configuration.imageUrl" [src]="configuration.imageUrl" alt="image for task" />
      <p [innerHTML]="configuration.description | safe"></p>
    </section>
  `
})
export class TaskHeaderComponent {
  @Input() configuration: ExaminationTask;
}
