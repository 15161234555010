<a
  routerLink="../{{ lesson.id }}"
  routerLinkActive="active"
>
  <div
    data-testid="player-playlist-lesson"
    class="player-playlist-lesson"
  >
    <div class="player-playlist-lesson-status">
      <mat-icon
        data-testid="icon-lesson-completed"
        *ngIf="lesson.completed"
        class="icon-done"
      >
        done
      </mat-icon>
      <mat-icon
        *ngIf="!lesson.completed"
      >
        play_circle
      </mat-icon>
    </div>
    <div
      class="player-playlist-lesson-title"
      data-testid="player-module-lesson-title"
    >
      <span title="{{ lesson.title }}">{{ lesson.title }}</span>
    </div>
    <div class="player-playlist-lesson-duration">
      <span>{{ lesson.duration | duration }}</span>
    </div>
  </div>
</a>
