import { inject, Injectable } from '@angular/core';
import { CourseContext } from './course-context';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CourseContextService {
  #location = inject(Location);

  getContext(): CourseContext {
    return this.#location.path().startsWith('/companies/') ? 'CompanyCourse' : 'Course';
  }
}
