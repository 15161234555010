<ac-box-centered *ngIf="token && userId" class="container">
  <div class="text-container">
    <h1>{{ 'registration.user.title' | transloco | renderHTML }}</h1>
    <p>{{ 'registration.user.description' | transloco | renderHTML }}</p>
  </div>
  <div class="form-logo-container">
    <img [src]="environment.assets.logo" />
  </div>
  <div class="content-container">
    <wv-card
      [title]="'registration.user.subtitle' | transloco"
      [overline]="'registration.stepper.step1.label' | transloco"
    >
      <div class="form-container">
        <app-user-registration-form
          (submitForm)="registerUser($event)"
          *ngIf="passwordPolicy$ | async as passwordPolicy"
          [passwordPolicy]="passwordPolicy"
          [token]="token"
          [userId]="userId"
        ></app-user-registration-form>
      </div>
    </wv-card>
    <div class="video-container">
      <app-videoplayer
        class="shadow-xl"
        *ngIf="video"
        [autoplay]="false"
        [delay]="0"
        [resetOnEnd]="true"
        [showPoster]="false"
        [video]="video"
      ></app-videoplayer>
      <p>{{ 'registration.userVideoDescription' | transloco | renderHTML }}</p>
    </div>
  </div>
</ac-box-centered>
