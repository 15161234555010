import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';

@Component({
  selector: 'app-video-confirmation',
  templateUrl: './video-confirmation.component.html',
  styleUrls: ['./video-confirmation.component.scss']
})
export class VideoConfirmationComponent {
  @Input() video: VideoDto;
  @Input() confirmationLabel: string | null;
  @Input() heading: string;
  @Input() description: string;
  @Output() confirmed = new EventEmitter<boolean>();
  videoFinished = false;

  confirmVideoFinish() {
    this.confirmed.emit(true);
  }

  markVideoAsFinished() {
    this.videoFinished = true;
  }
}
