<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Württembergische Versicherung - Versicherungsnehmer importieren'"
>
<p>{{ 'insurancesImport.description' | transloco | renderHTML }}</p>
<wv-card class="card m-2">
  <form (ngSubmit)="onSubmitFile(importInsurancesForm, $event)" [formGroup]="importInsurancesForm">
    <mat-form-field appearance="outline" class="file-input">
      <mat-label>{{ 'form.label.selectFile' | transloco }}</mat-label>
      <mat-icon matSuffix>file_upload</mat-icon>
      <ngx-mat-file-input [accept]="allowedFileTypes.join(',')" formControlName="file"></ngx-mat-file-input>
      <mat-error *ngIf="importInsurancesForm.get('file').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="importInsurancesForm.get('file').errors?.maxContentSize">
        {{ 'form.error.maxFileSize' | transloco: {maxFileSize: '250 KB'} }}
      </mat-error>
      <mat-error *ngIf="importInsurancesForm.get('file').errors?.wrongFileType">
        {{ 'form.error.invalidFileType' | transloco }}
      </mat-error>
    </mat-form-field>
    <button [disabled]="importInsurancesForm.invalid" appearance="secondary" wvButton>
      {{ 'insurancesImport.submitButton' | transloco }}
    </button>
  </form>
</wv-card>

<ng-container *ngIf="importResult">
  <mat-divider></mat-divider>
  <div *ngIf="getNewAccesses().length > 0">
    <h4>{{ 'insurancesImport.newAccesses' | transloco }} ({{ getNewAccesses().length }})</h4>
    <ul>
      <li *ngFor="let access of getNewAccesses()">{{ access[0] }} - {{ access[1] | date: 'dd.MM.yyyy' }}</li>
    </ul>
  </div>
  <div *ngIf="importResult.missingCompanies.length > 0">
    <h4>{{ 'insurancesImport.missingCompanies' | transloco }} ({{ importResult.missingCompanies.length }})</h4>
    <ul>
      <li *ngFor="let access of importResult.missingCompanies">{{ access ? access : 'unbekannt' }}</li>
    </ul>
  </div>
</ng-container>
</app-back-layout>
