import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-confirmation',
  templateUrl: './text-confirmation.component.html',
  styleUrls: ['./text-confirmation.component.scss']
})
export class TextConfirmationComponent {
  @Input() text: string;
  @Output() confirmed = new EventEmitter<boolean>();
  textRead = false;

  confirmText() {
    this.confirmed.emit(true);
  }
}
