import {Component, OnInit} from '@angular/core';

import {environment} from '@env';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {BrowserUpdateService} from './browser-update.service';
import {IconRegistrationService} from './icon-registration.service';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="isMaintenance(); else prod">
      <router-outlet>
        <app-maintenance></app-maintenance>
      </router-outlet>
    </ng-container>
    <ng-template #prod>
      <router-outlet></router-outlet>
    </ng-template>
  `
})
export class AppComponent implements OnInit {
  constructor(
      private cookieConsentService: NgcCookieConsentService,
      private browserUpdateService: BrowserUpdateService,
      private iconRegistrationService: IconRegistrationService
  ) {
  }

  ngOnInit(): void {
    this.iconRegistrationService.configure();
    this.browserUpdateService.configure();
  }

  isMaintenance(): boolean {
    return environment.features?.MAINTENANCE || false;
  }
}
