<mat-expansion-panel
  [disabled]="false"
  [expanded]="true"
  [hideToggle]="false"
>
  <mat-expansion-panel-header>
    <div class="details-playlist-module">
      <div class="details-playlist-module-play-icon">
        <a [routerLink]="'./player/' + module.lessons[0].id">
          <mat-icon>play_circle_outline</mat-icon>
        </a>
      </div>
      <div class="details-playlist-module-title">
        <a [routerLink]="'./player/' + module.lessons[0].id"><h4 data-testid="module-title">{{ module.title }}</h4></a>
      </div>
      <div class="details-playlist-module-status-icon">
        <mat-icon
          *ngIf="module.completed"
        >
          done_all
        </mat-icon>
      </div>
      <div class="details-playlist-module-duration">
        <div class="details-playlist-module-duration-icon mr-1">
            <mat-icon>schedule</mat-icon>
        </div>
        <span class="details-playlist-module-duration-text">
          {{ module.duration | duration }}
        </span>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div>
    <app-course-details-playlist-lesson
      *ngFor="let content of module.lessons"
      [lesson]="content"
    ></app-course-details-playlist-lesson>
  </div>
</mat-expansion-panel>
