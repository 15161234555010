import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LeftOrRightChoiceTask, TaskCompletion, TaskComponent, TaskOption } from '../../models';

interface TaskOptionWithPosition extends TaskOption {
  position: number;
}

@Component({
  selector: 'ac-single-choice',
  templateUrl: './left-or-right-choice.component.html',
  styleUrls: ['./left-or-right-choice.component.scss']
})
export class LeftOrRightChoiceComponent implements TaskComponent<LeftOrRightChoiceTask> {
  configuration: LeftOrRightChoiceTask;
  answer: {
    left: TaskOptionWithPosition[];
    right: TaskOptionWithPosition[];
  } = {
    left: [],
    right: []
  };
  formSubmitted = false;
  private complete$$ = new Subject<TaskCompletion>();

  private _options: TaskOptionWithPosition[] = [];

  get options(): TaskOptionWithPosition[] {
    return this._options.sort(this.byPositionAsc);
  }

  set options(value: TaskOptionWithPosition[]) {
    this._options = value;
  }

  private _optionsLeft: TaskOptionWithPosition[] = [];

  get optionsLeft(): TaskOptionWithPosition[] {
    return this._optionsLeft.sort(this.byPositionAsc);
  }

  set optionsLeft(value: TaskOptionWithPosition[]) {
    this._optionsLeft = value;
  }

  private _optionsRight: TaskOptionWithPosition[] = [];

  get optionsRight(): TaskOptionWithPosition[] {
    return this._optionsRight.sort(this.byPositionAsc);
  }

  set optionsRight(value: TaskOptionWithPosition[]) {
    this._optionsRight = value;
  }

  complete = () => this.complete$$.asObservable();

  prepare(configuration: LeftOrRightChoiceTask): void {
    this.configuration = configuration;
    this.options = this.configuration.options.map((option, index) => ({
      ...option,
      position: index + 1
    }));
  }

  submitAnswer() {
    this.formSubmitted = true;

    if (this.isCorrect()) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({ isCorrect: false });
    }
  }

  drop(event: CdkDragDrop<TaskOptionWithPosition[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.answer = {
      left: this.optionsLeft,
      right: this.optionsRight
    };
  }

  private isCorrect(): boolean {
    return (
      containsCorrectItemsInList(this.configuration.solution.left, this.answer.left) &&
      containsCorrectItemsInList(this.configuration.solution.right, this.answer.right)
    );

    function containsCorrectItemsInList(solution: TaskOption[], answer: TaskOptionWithPosition[]): boolean {
      return (
        solution.length === answer.length &&
        solution.every(solutionEntry => answer.some(entry => entry.label === solutionEntry.label))
      );
    }
  }

  private byPositionAsc(previous: TaskOptionWithPosition, current: TaskOptionWithPosition): number {
    return previous.position - current.position;
  }
}
