@if (privacyIssue) {
  <p class="italic">{{ 'phishingSimulations.statistic.company.departmentsPrivacyIssue' | transloco }}</p>
} @else if (packageTooLow) {
  <p [needsPackageUpgrade]="true"
     class="italic underline">{{ 'phishingSimulations.statistic.company.departmentsPackageTooLow' | transloco }}</p>
} @else {
  <div class="relative w-full" [style.min-height]="minHeight()">
    <canvas
      [data]="chartData"
      [options]="chartOptions"
      [type]="chartType"
      baseChart
    ></canvas>
  </div>
}
