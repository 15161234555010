<form
  (ngSubmit)="onSubmit(userEditDepartmentForm, $event)"
  [formGroup]="userEditDepartmentForm"
>
  <mat-form-field
    appearance="outline"
    class="full-width"
  >
    <mat-label>{{ 'form.label.department' | transloco }}</mat-label>
    <input
      data-testid="input-department"
      [matAutocomplete]="auto"
      formControlName="department"
      matInput
      type="text"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="userEditDepartmentForm.get('department').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
    <mat-error *ngIf="userEditDepartmentForm.get('department').errors?.requireMatch">
      {{ 'form.error.requireMatch' | transloco }}
    </mat-error>
  </mat-form-field>
  <button
    data-testid="button-safe-department"
    [disabled]="userEditDepartmentForm.invalid"
    appearance="primary"
    wvButton
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
