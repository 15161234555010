<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - co-IT - ' + ('administration.tabLabel.management' | transloco)"
>
<div class="grid gap-8 p-2 max-w-[50rem]">
  <app-admin-management-roles></app-admin-management-roles>
  <app-admin-management-user></app-admin-management-user>
  <app-admin-management-company></app-admin-management-company>
</div>
</app-back-layout>
