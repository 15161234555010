import { Component } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';
import { UrlBuilder } from '../../url-builder.service';
import {environment} from "@env";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent {
  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}

    protected readonly environment = environment;
}
