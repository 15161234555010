import {Injectable} from '@angular/core';
import {Color} from 'chart.js';
import {hexToRGB} from "../utils/hex-to-rgb";

@Injectable({providedIn: 'root'})
export class DefaultChartOptionsService {
  getDefaultColors(): { backgroundColor: Color[]; hoverBackgroundColor: Color[]; hoverBorderColor: Color } {
    const hexColors = ['#007a8c','#f84914',  '#b5694e', '#7f746d', '#e3572b', '#cd623d', '#9c6f5e'];
    let rgbColors = hexColors.map(hex => hexToRGB(hex, 1));
    rgbColors.splice(1,0, hexToRGB(getComputedStyle(document.documentElement).getPropertyValue('--on-surface'), 0.2))
    return {
      backgroundColor: rgbColors,
      hoverBackgroundColor: rgbColors,
      hoverBorderColor: 'transparent'
    };
  }
}
