import { Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { ContentCreatorGuard } from './guards/content-creator.guard';
import { NavigationLayoutComponent } from './layouts/navigation-layout/navigation-layout.component';
import { CyberPortalOverviewComponent } from './cyber-portal/cyber-portal-overview/cyber-portal-overview.component';
import { CourseOverviewComponent } from './course/course-overview/course-overview.component';
import { CourseDetailsComponent } from './course/course-details/course-details.component';
import { CompanyRegistrationComponent } from './identity/company-registration/company-registration.component';
import { UserRegistrationComponent } from './identity/user-registration/user-registration.component';
import { UserManagementComponent } from './identity/user-management/user-management.component';
import { AccountantGuard } from './guards/accountant.guard';
import { UserEditComponent } from './identity/user-edit/user-edit.component';
import { ResetPasswordRequestComponent } from './identity/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './identity/reset-password/reset-password.component';
import { UserEditEmailLandingPageComponent } from './identity/user-edit-email-landing-page/user-edit-email-landing-page.component';
import { PhishingMailsComponent } from './phishing-mails/phishing-mails/phishing-mails.component';
import { PhishingMailsLandingPageComponent } from './phishing-mails/phishing-mails-landing-page/phishing-mails-landing-page.component';
import { UserInvitationSelfDeleteLandingPageComponent } from './identity/user-invitation-self-delete-landing-page/user-invitation-self-delete-landing-page.component';
import { HelpComponent } from './help/help/help.component';
import { AdministrationComponent } from './administration/administration.component';
import { AdministratorGuard } from './guards/administrator.guard';
import { FeedbackDetailsComponent } from './administration/administration-coit/feedback-details/feedback-details.component';
import { ToolkitComponent } from './toolkit/toolkit/toolkit.component';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';
import { LoginComponent } from './identity/login/login.component';
import { CoursePlayerComponent } from './course/course-player/course-player.component';
import { LogoutComponent } from './identity/logout/logout.component';
import { InfoVideosComponent } from '@app/help/info-videos/info-videos.component';
import { IntroVideosComponent } from './help/intro-videos/intro-videos.component';
import { RansomClauseComponent } from '@app/ransom-clauses/ransom-clause/ransom-clause.component';
import { CompanyEditComponent } from './identity/company-edit/company-edit.component';
import { SubscriptionOverviewComponent } from './subscriptions/subscription-overview/subscription-overview.component';
import { PhishingMailsUserStatisticComponent } from './phishing-mails/phishing-mails-user-statistic/phishing-mails-user-statistic.component';
import { PhishingMailsCompanyStatisticComponent } from './phishing-mails/phishing-mails-company-statistic/phishing-mails-company-statistic.component';
import { PromotionComponent } from './promotion/promotion/promotion.component';
import { AdministrationWvComponent } from './administration/administration-wv/administration-wv.component';
import { AdministrationCoitComponent } from './administration/administration-coit/administration-coit.component';
import { AdministrationAssekuradeureComponent } from './administration/administration-assekuradeure/administration-assekuradeure.component';
import { AdministrationAgenturenComponent } from './administration/administration-agenturen/administration-agenturen.component';
import { AdministrationPartnerComponent } from './administration/administration-partner/administration-partner.component';
import { WhitelistedAccessesComponent } from './administration/administration-wv/whitelisted-accesses/whitelisted-accesses.component';
import { InsurancesImportComponent } from './administration/administration-wv/insurances-import/insurances-import.component';
import { CompanyManagementComponent } from './administration/administration-wv/company-management/company-management.component';
import { AssekuradeurManagementComponent } from './administration/administration-wv/assekuradeur-management/assekuradeur-management.component';
import { RansomClauseInitializationComponent } from './administration/administration-wv/ransom-clause-initialization/ransom-clause-initialization.component';
import { FeedbackOverviewWvComponent } from './administration/administration-wv/feedback-overview-wv/feedback-overview-wv.component';
import { PartnerProfileComponent } from './administration/administration-partner/partner-profile/partner-profile.component';
import { PartnerNewsComponent } from './administration/administration-partner/partner-news/partner-news.component';
import { PartnerIntegrationsComponent } from './administration/administration-partner/partner-integrations/partner-integrations.component';
import { PartnerStatisticsComponent } from './administration/administration-partner/partner-statistics/partner-statistics.component';
import { ConsultationAgenturenComponent } from './administration/administration-agenturen/consultation-agenturen/consultation-agenturen.component';
import { PromotionalMaterialsAgenturenComponent } from './administration/administration-agenturen/promotional-materials-agenturen/promotional-materials-agenturen.component';
import { CompanyManagementAssekuradeurComponent } from './administration/administration-assekuradeure/company-management-assekuradeur/company-management-assekuradeur.component';
import { InsurancesImportAssekuradeureComponent } from './administration/administration-assekuradeure/insurances-import-assekudadeure/insurances-import-assekuradeure.component';
import { FeedbackOverviewComponent } from './administration/administration-coit/feedback-overview/feedback-overview.component';
import { PhishingMailsManagementComponent } from './administration/administration-coit/phishing-mails-management/phishing-mails-management.component';
import { QuickInvitationComponent } from './administration/administration-coit/quick-invitation/quick-invitation.component';
import { AdminManagementComponent } from './administration/administration-coit/admin-management/admin-management.component';
import { NewsletterComponent } from './administration/administration-coit/newsletter/newsletter.component';
import { CompanySubscriptionsComponent } from './subscriptions/company-subscriptions/company-subscriptions.component';
import { CourseRatingGuard } from './administration/administration-partner/course-rating.guard';
import {LoginVictorComponent} from "./identity/login-victor/login-victor.component";

export const appRoutes: Routes = [
  {
    path: 'cyber-portal/inhaltspflege',
    loadChildren: () => import('./cms/cms.module').then(module => module.CmsModule),
    canActivate: [LoggedInGuard, ContentCreatorGuard]
  },
  {
    path: 'cyber-portal',
    component: NavigationLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CyberPortalOverviewComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'course-overview',
        component: CourseOverviewComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'courses/:courseId',
        component: CourseDetailsComponent,
        canActivate: [LoggedInGuard],
        canDeactivate: [CourseRatingGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId',
        component: CourseDetailsComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'register-company-details/:accessCode',
        component: CompanyRegistrationComponent
      },
      {
        path: 'register-user',
        component: UserRegistrationComponent
      },
      {
        path: 'manage-users',
        component: UserManagementComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'edit-user',
        component: UserEditComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'edit-company',
        component: CompanyEditComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'reset-password',
        component: ResetPasswordRequestComponent
      },
      {
        path: 'reset-password/:userId',
        component: ResetPasswordComponent
      },
      {
        path: 'update-email',
        component: UserEditEmailLandingPageComponent
      },
      {
        path: 'phishing-mails',
        component: PhishingMailsComponent,
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
          },
          {
            path: 'users',
            component: PhishingMailsUserStatisticComponent,
            canActivate: [LoggedInGuard]
          },
          {
            path: 'users/:userId',
            component: PhishingMailsUserStatisticComponent,
            canActivate: [LoggedInGuard]
          },
          {
            path: 'companies',
            component: PhishingMailsCompanyStatisticComponent,
            canActivate: [AccountantGuard]
          },
          {
            path: 'companies/:companyId',
            component: PhishingMailsCompanyStatisticComponent,
            canActivate: [AccountantGuard]
          }
        ]
      },
      {
        path: 'phishing-mail-clicked',
        component: PhishingMailsLandingPageComponent
      },
      {
        path: 'self-delete-invitation',
        component: UserInvitationSelfDeleteLandingPageComponent
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv',
        component: AdministrationWvComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/whitelisted-accesses',
        component: WhitelistedAccessesComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/insurances-import',
        component: InsurancesImportComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/company-management',
        component: CompanyManagementComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/assekuradeur-management',
        component: AssekuradeurManagementComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/ransom-clause-initialization',
        component: RansomClauseInitializationComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/wv/messages',
        component: FeedbackOverviewWvComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit',
        component: AdministrationCoitComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/feedback-overview',
        component: FeedbackOverviewComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/company-subscriptions',
        component: CompanySubscriptionsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/phishing-mails-management',
        component: PhishingMailsManagementComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/quick-invitation',
        component: QuickInvitationComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/admin-management',
        component: AdminManagementComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit/newsletter',
        component: NewsletterComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner',
        component: AdministrationPartnerComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner/partner-profile',
        component: PartnerProfileComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner/partner-news',
        component: PartnerNewsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner/integrations',
        component: PartnerIntegrationsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner/statistics',
        component: PartnerStatisticsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/assekuradeure',
        component: AdministrationAssekuradeureComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/assekuradeure/insurances-import',
        component: InsurancesImportAssekuradeureComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/assekuradeure/policyholders',
        component: CompanyManagementAssekuradeurComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/agenturen',
        component: AdministrationAgenturenComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/agenturen/consultation',
        component: ConsultationAgenturenComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/agenturen/promotional-materials',
        component: PromotionalMaterialsAgenturenComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'feedbacks/:feedbackId',
        component: FeedbackDetailsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'toolkit',
        component: ToolkitComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'subscription',
        component: SubscriptionOverviewComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'promotion',
        component: PromotionComponent,
        canActivate: [LoggedInGuard]
      }
    ]
  },
  {
    path: 'cyber-portal',
    component: PlainLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'login-victor',
        component: LoginVictorComponent
      },
      {
        path: 'hilfe-videos/:video',
        component: InfoVideosComponent
      },
      {
        path: 'intro-videos/:course',
        component: IntroVideosComponent
      }
    ]
  },
  {
    path: 'cyber-portal',
    children: [
      {
        path: 'courses/:courseId/player/:contentId',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'courses/:courseId/player',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId/player/:contentId',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId/player',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'logout',
        component: LogoutComponent
      }
    ]
  },
  {
    path: 'ransom-clause',
    component: PlainLayoutComponent,
    children: [
      {
        path: '',
        component: RansomClauseComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'cyber-portal'
  }
];
