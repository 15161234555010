import { PasswordValidationErrors, PasswordValidators } from '@ac/colibri';
import { PasswordPolicyDto, UpdatePasswordDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit-password-form',
  templateUrl: './user-edit-password-form.component.html',
  styleUrls: ['./user-edit-password-form.component.scss']
})
export class UserEditPasswordFormComponent implements OnInit {
  @Output() submitForm = new EventEmitter<UpdatePasswordDto>();
  userEditPasswordForm: UntypedFormGroup;
  passwordValidationErrors$: Observable<PasswordValidationErrors>;
  @ViewChild(MatExpansionPanel) passwordCheckPanel: MatExpansionPanel;
  passwordHide = true;
  @Input() passwordPolicy: PasswordPolicyDto;
  isPasswordSpecificationNotSatisfied = true;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.userEditPasswordForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        password: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]],
        password2: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]]
      },
      { validators: PasswordValidators.equalPasswords }
    );

    this.passwordValidationErrors$ = this.userEditPasswordForm.get('password').valueChanges.pipe(
      map(() => Object.keys(this.userEditPasswordForm.get('password').errors || []) as PasswordValidationErrors),
      tap(errors => {
        /**
         *
         * Somehow the control error of password is not populated to the form.
         * The form indicates itself as valid, although password contains errors.
         *
         * Until we found the root cause for this behaviour we have to use additional
         * state to disable the registration button.
         */
        if (errors.length) {
          this.isPasswordSpecificationNotSatisfied = true;
        } else {
          this.isPasswordSpecificationNotSatisfied = false;
        }
      })
    );
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UpdatePasswordDto = {
      newPassword: userData.get('password').value,
      oldPassword: userData.get('oldPassword').value
    };

    this.submitForm.emit(dto);
  }

  openPasswordCheckPanel(): void {
    this.passwordCheckPanel?.open();
  }
}
