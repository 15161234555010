import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { PasswordCheckComponent } from './password-check/password-check.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ToolkitComponent } from './toolkit/toolkit.component';
import { AiActCheckComponent } from './ai-act-check/ai-act-check.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { Nis2CheckComponent } from './nis2-check/nis2-check.component';
import {MatTooltip} from "@angular/material/tooltip";
import {ConfirmNavigationDirective} from "../../../../../libs/colibri/src/lib/directives/confirm-navigation.directive";
import {PartnerButtonComponent} from "../../../../../libs/colibri/src/lib/partner-button/partner-button.component";

@NgModule({
  declarations: [PasswordCheckComponent, ToolkitComponent, AiActCheckComponent, Nis2CheckComponent],
    imports: [
        CommonModule,
        ColibriModule,
        MatIconModule,
        ReactiveFormsModule,
        LayoutsModule,
        TranslateModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatListModule,
        MatCheckboxModule,
        MatTooltip,
        ConfirmNavigationDirective,
        PartnerButtonComponent
    ],
  exports: [PasswordCheckComponent, ToolkitComponent, AiActCheckComponent, Nis2CheckComponent]
})
export class ToolkitModule {}
