import {ChangeDetectionStrategy, Component, effect, inject, viewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {DefaultChartOptionsService} from "../../../../phishing-mails/default-chart-options.service";

@Component({
  selector: 'app-partner-toolkit-business-sector-usage-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-toolkit-business-sector-usage-statistic.component.html',
  styleUrl: './partner-toolkit-business-sector-usage-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerToolkitBusinessSectorUsageStatisticComponent {
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      }
    },
    scales: {
      x: {
        title: {
          text: "Branche",
          display: false
        },
      },
      y: {
        title: {
          text: "Nutzungshäufigkeit",
          display: true
        }
      }
    }
  };

  toolkitUsage = [
    { toolkit: "AI-Act-Check", departments: [{name: "Finanzwesen", clicks: 300}, {name: "Logistik", clicks: 200}, {name: "Maschinenbau", clicks: 200}] },
    { toolkit: "NIS2-Check", departments: [{name: "Schiffbau", clicks: 250}, {name: "Logistik", clicks: 180}, {name: "Handwerk", clicks: 50}] },
    { toolkit: "Data-Act-Check", departments: [{name: "Immobilien", clicks: 600}, {name: "Tourismus", clicks: 450}, {name: "Entsorgung", clicks: 111}] },
    { toolkit: "Smart-Product-Impact-Assessment", departments: [{name: "Finanzwesen", clicks: 360}, {name: "Handwerk", clicks: 220}, {name: "Schiffbau", clicks: 100}] },
    { toolkit: "SCC-Generator", departments: [{name: "Unterhaltung", clicks: 300}, {name: "Handel", clicks: 290}, {name: "Metallindustrie", clicks: 280}] },
  ].sort((a, b) => b.departments[0].clicks - a.departments[0].clicks);

  charts = this.toolkitUsage.map(toolkitUsage => ({
    chartType: this.chartType,
    chartOptions: this.chartOptions,
    chartData: {
      labels: toolkitUsage.departments.map(x => x.name),
      datasets:[ {
        data:  toolkitUsage.departments.map(x => x.clicks),
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor,
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor,
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      }]
    },
    name: toolkitUsage.toolkit
  }));
}
