import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UrlBuilder} from "../../url-builder.service";
import {IdentityService} from "../../identity/identity.service";
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {AdministrationModule} from "../administration.module";
import {TranslocoPipe} from "@ngneat/transloco";
import { environment } from '@env';
import {LayoutsModule} from "../../layouts/layouts.module";
import {CompanySubscriptionsComponent} from "../../subscriptions/company-subscriptions/company-subscriptions.component";
import {PhishingMailsManagementComponent} from "../phishing-mails-management/phishing-mails-management.component";
import {QuickInvitationComponent} from "../quick-invitation/quick-invitation.component";
import {ColibriModule} from "@ac/colibri";
import {VideoDto} from "../../../../../../libs/models/src/lib/course-player/video.dto";

@Component({
  selector: 'app-administration-agenturen',
  standalone: true,
  imports: [CommonModule, MatTab, AdministrationModule, MatTabGroup, TranslocoPipe, MatTabContent, LayoutsModule, CompanySubscriptionsComponent, PhishingMailsManagementComponent, QuickInvitationComponent, ColibriModule],
  templateUrl: './administration-agenturen.component.html',
  styleUrl: './administration-agenturen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationAgenturenComponent {
  constructor(
      public urlBuilder: UrlBuilder,
      public identityService: IdentityService
  ) {}

  protected readonly environment = environment;
  video: VideoDto = {
    streamUrl: environment.resources.helpVideoAgencies,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoAgenciesSubtitles
      }
    ],
    downloadUrl: null
  };
}
