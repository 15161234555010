import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UserRolesService } from '../../../../identity/user-roles.service';
import { AdministrationService } from '../../../administration.service';
import { IdentityService } from '../../../../identity/identity.service';
import {CustomValidators} from "../../../../identity/custom.validator";

@Component({
  selector: 'app-admin-management-roles',
  templateUrl: './admin-management-roles.component.html',
  styleUrls: ['./admin-management-roles.component.scss']
})
export class AdminManagementRolesComponent implements OnInit {
  assignRolesForm: UntypedFormGroup;
  managedRoles: string[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userRoles: UserRolesService,
    private administrationService: AdministrationService,
    private identityService: IdentityService
  ) {
    this.assignRolesForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email, CustomValidators.username]],
      role: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.loadRoles();
  }

  addRole() {
    const role = this.getCurrentSelectedRole();
    const email = this.assignRolesForm.get('email').value;

    this.administrationService.assignRoleByEmail(email, role).subscribe();
  }

  removeRole() {
    const role = this.getCurrentSelectedRole();
    const email = this.assignRolesForm.get('email').value;

    this.administrationService.removeRoleByEmail(email, role).subscribe();
  }

  private loadRoles() {
    this.userRoles
      .loadManagedRoles(this.identityService.getUserId())
      .pipe(tap(roles => (this.managedRoles = roles)))
      .subscribe();
  }

  private getCurrentSelectedRole() {
    return this.assignRolesForm.get('role').value;
  }
}
