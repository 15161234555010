<awareness-center-statistic-card heading="Trends erkennen"
                                 text="Schaffen Sie mit Webinaren Anknüpfungspunkte.">
  <div class="relative w-full min-h-[22rem]" statistic>
    <canvas
      [data]="chartData"
      [options]="chartOptions"
      [type]="chartType"
      baseChart
    ></canvas>
  </div>
</awareness-center-statistic-card>

