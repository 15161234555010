import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UrlBuilder } from '../../url-builder.service';
import { UserInvitationDialogComponent } from '../user-invitation-dialog/user-invitation-dialog.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {
  constructor(public urlBuilder: UrlBuilder, private dialog: MatDialog) {}

  openInvitationDialog(): void {
    this.dialog.open(UserInvitationDialogComponent, { autoFocus: false, minWidth: 700 });
  }
}
