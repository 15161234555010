import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountantGuard } from './guards/accountant.guard';
import { AdministratorGuard } from './guards/administrator.guard';
import { ContentCreatorGuard } from './guards/content-creator.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { appRoutes } from './app.routes';

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AccountantGuard, LoggedInGuard, ContentCreatorGuard, AdministratorGuard]
})
export class AppRoutingModule {}
