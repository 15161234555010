<form (ngSubmit)="emitRegistrationData(companyRegistrationForm, $event)" [formGroup]="companyRegistrationForm">
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.companyName' | transloco }}</mat-label>
      <input data-test="input-company-registration-details-company-name" formControlName="companyName" matInput />
      <mat-error *ngIf="companyRegistrationForm.get('companyName').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationForm.get('companyName').errors?.pattern">
        {{ 'form.error.invalidCompanyName' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.companyDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.businessSector' | transloco }}</mat-label>
      <input
        [matAutocomplete]="auto"
        data-test="input-company-registration-details-business-sector"
        formControlName="businessSector"
        matInput
        type="text"
      />
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="companyRegistrationForm.get('businessSector').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationForm.get('businessSector').errors?.requireMatch">
        {{ 'form.error.requireMatch' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.companyDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.employeeCount' | transloco }}</mat-label>
      <input
        data-test="input-company-registration-details-employee-count"
        formControlName="employeeCount"
        matInput
        type="number"
      />
      <mat-error *ngIf="companyRegistrationForm.get('employeeCount').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationForm.get('employeeCount').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.employeeCountDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.officeEmployeeCount' | transloco }}</mat-label>
      <input
        data-test="input-company-registration-details-office-employee-count"
        formControlName="officeEmployeeCount"
        matInput
        type="number"
      />
      <mat-error *ngIf="companyRegistrationForm.get('officeEmployeeCount').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationForm.get('officeEmployeeCount').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.employeeCountDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <button
    [disabled]="companyRegistrationForm.invalid"
    appearance="primary"
    data-test="button-company-registration-details-next"
    matStepperNext
    wvButton
  >
    {{ 'registration.company.submitButton' | transloco }}
  </button>
</form>
