@if (phishingMailTemplates().isLoading){
  <mat-spinner></mat-spinner>
} @else if (phishingMailTemplates().data){
  <wv-card
    [title]="'administration.phishingMailsManagement.sendTitle' | transloco"
  >
    <p>
      {{'administration.phishingMailsManagement.sendDescription' | transloco}}
    </p>
    <form [formGroup]="sendForm" class="grid gap-4">
      <mat-form-field appearance="outline">
        <mat-label>SendGridTemplateId</mat-label>
        <input matInput formControlName="sendGridTemplateId" [matAutocomplete]="templateAutoComplete" (input)="filterTemplates($any($event.target).value)"
               (focus)="filterTemplates($any($event.target).value)">
        <mat-autocomplete requireSelection #templateAutoComplete="matAutocomplete">
          @for (template of filteredTemplates(); track template) {
            <mat-option [value]="template.sendGridTemplateId">{{template.sendGridTemplateId}} ({{template.title}})</mat-option>
          }
        </mat-autocomplete>
        <mat-error *ngIf="sendForm.get('sendGridTemplateId').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>E-Mail-Adresse</mat-label>
        <input formControlName="email" matInput />
        <mat-error *ngIf="sendForm.get('email').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
        <mat-error *ngIf="sendForm.get('email').errors?.email">
          {{ 'form.error.invalidEmail' | transloco }}
        </mat-error>
      </mat-form-field>
      <button wvButton [disabled]="sendForm.invalid" (click)="send()">
        {{'administration.phishingMailsManagement.sendButton' | transloco}}
      </button>
    </form>
  </wv-card>

  <table mat-table matSort [dataSource]="dataSource()" class="mat-elevation-z8">
    <ng-container matColumnDef="sendGridTemplateId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> SendGridTemplateId </th>
      <td mat-cell *matCellDef="let element" class="text-nowrap"> {{element.sendGridTemplateId}} </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Titel </th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>
    <ng-container matColumnDef="senderEmail">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> SenderEmail </th>
      <td mat-cell *matCellDef="let element"> {{element.senderEmail}} </td>
    </ng-container>
    <ng-container matColumnDef="senderName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> SenderName </th>
      <td mat-cell *matCellDef="let element"> {{element.senderName}} </td>
    </ng-container>
    <ng-container matColumnDef="link">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Link </th>
      <td mat-cell *matCellDef="let element"> {{element.link}} </td>
    </ng-container>
    <ng-container matColumnDef="levelOfDifficulty">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Schwierigkeit </th>
      <td mat-cell *matCellDef="let element"> {{'phishingMailTemplates.levelOfDifficulty.' + element.levelOfDifficulty | transloco}} </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Erstellt am </th>
      <td mat-cell *matCellDef="let element"> {{element.created | date: "dd.MM.yyyy"}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
}


