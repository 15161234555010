import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-details-description',
  templateUrl: './course-details-description.component.html',
  styleUrls: ['./course-details-description.component.scss']
})
export class CourseDetailsDescriptionComponent {
  @Input() description: string;
}
