<app-info-video-layout [subtitle]="'help.instructions' | transloco" [title]="infoVideo[1] | transloco">
  <app-videoplayer
    class="shadow-xl"
    *ngIf="infoVideo[2]"
    [autoplay]="false"
    [delay]="0"
    [resetOnEnd]="true"
    [showPoster]="false"
    [video]="infoVideo[2]"
    [title]="infoVideo[1] | transloco"
  ></app-videoplayer>
</app-info-video-layout>
