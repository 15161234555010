import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RansomClauseComponent } from './ransom-clause/ransom-clause.component';
import { ExamModule } from '@ac/exam';
import {ColibriModule, RecaptchaConsentComponent} from '@ac/colibri';
import { TextConfirmationComponent } from './text-confirmation/text-confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { _MatLegacyCheckboxRequiredValidatorModule as _MatCheckboxRequiredValidatorModule, MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoConfirmationComponent } from './video-confirmation/video-confirmation.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { RansomClauseIntroComponent } from './ransom-clause-intro/ransom-clause-intro.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RansomClauseTestPassedComponent } from './ransom-clause-test-passed/ransom-clause-test-passed.component';
import { RansomClauseTestFailedComponent } from './ransom-clause-test-failed/ransom-clause-test-failed.component';

@NgModule({
  declarations: [
    RansomClauseComponent,
    TextConfirmationComponent,
    VideoConfirmationComponent,
    RansomClauseIntroComponent,
    RansomClauseTestPassedComponent,
    RansomClauseTestFailedComponent
  ],
    imports: [
        CommonModule,
        ExamModule,
        ColibriModule,
        MatIconModule,
        _MatCheckboxRequiredValidatorModule,
        TranslocoModule,
        MatCheckboxModule,
        FormsModule,
        CdkStepperModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        RecaptchaConsentComponent
    ],
  exports: []
})
export class RansomClausesModule {}
