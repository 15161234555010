import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../../identity/identity.service';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { AdministrationModule } from '../administration.module';
import { environment } from '@env';
import { LayoutsModule } from '../../layouts/layouts.module';
import { PartnerProfileComponent } from '../partner-profile/partner-profile.component';
import { PartnerStatisticsComponent } from '../partner-statistics/partner-statistics.component';
import { PartnerNewsComponent } from '../partner-news/partner-news.component';

@Component({
  selector: 'app-administration-partner',
  standalone: true,
  imports: [
    CommonModule,
    MatTab,
    AdministrationModule,
    MatTabGroup,
    MatTabContent,
    LayoutsModule,
    AdministrationModule,
    PartnerProfileComponent,
    PartnerStatisticsComponent,
    PartnerNewsComponent
  ],
  templateUrl: './administration-partner.component.html',
  styleUrl: './administration-partner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationPartnerComponent {
  protected readonly environment = environment;

  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}
}
