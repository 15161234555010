import {inject, Injectable } from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, GuardResult, MaybeAsync, RouterStateSnapshot} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {CourseRatingDialogComponent} from "./partner-statistics/course-rating-dialog/course-rating-dialog.component";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CourseRatingGuard implements CanDeactivate<void> {
    #dialog = inject(MatDialog);

    canDeactivate(component: void, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): MaybeAsync<GuardResult> {
      const dialog = this.#dialog.open(CourseRatingDialogComponent);

      return dialog.afterClosed().pipe(map(() => true));
    }

}
