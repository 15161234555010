<mat-card
  [class.disabled]="course.isDisabled"
  class="mat-elevation-z2"
>
  <!--  todo: Aufräumen, keine doppelten DOM Elemente-->
  <a *ngIf="!course.isDisabled" [routerLink]="link">
    <ac-image-faded [src]="urlBuilder.getImageVariation(course.imageUrl, 'card')" mat-card-image></ac-image-faded>
  </a>
  <ac-image-faded
    (click)="showDeactivationReason()"
    *ngIf="course.isDisabled"
    [src]="urlBuilder.getImageVariation(course.imageUrl, 'card')"
    class="pointer"
    mat-card-image
  ></ac-image-faded>
  <a
    *ngIf="!course.isDisabled"
    [routerLink]="link"
  >
    <mat-card-content>
      <mat-card-title>
        <h3
          data-testid="heading-course-title"
          class="course-title"
        >
          {{ course.title }}
        </h3>
      </mat-card-title>
      <div class="course-duration">
        <div class="course-duration-icon">
          <mat-icon inline="true">schedule</mat-icon>
        </div>
        <div
          class="course-duration-text"
          data-testid="course-duration"
        >
          {{ course.duration | duration }}
        </div>
      </div>
      <div class="course-progress">
        <div class="course-progress-icon">
          <mat-icon inline="true">trending_up</mat-icon>
        </div>
        <div class="course-progress-label">
          <span>{{ 'course.overview.progress' | transloco }}:</span>
        </div>
        <div class="course-progress-bar">
          <mat-progress-bar
            [value]="course.progress"
            class="progress-bar"
            color="accent"
            mode="determinate"
          ></mat-progress-bar>
        </div>
      </div>
    </mat-card-content>
  </a>
  <mat-card-content
    (click)="showDeactivationReason()"
    *ngIf="course.isDisabled"
    class="pointer"
  >
    <mat-card-title>
      <h3 class="course-title">{{ course.title }}</h3>
    </mat-card-title>
    <div class="course-duration">
      <div class="course-duration-icon">
        <mat-icon>schedule</mat-icon>
      </div>
      <div class="course-duration-text">{{ course.duration | duration }}</div>
    </div>
    <div class="course-progress">
      <div class="course-progress-icon">
        <mat-icon>trending_up</mat-icon>
      </div>
      <div class="course-progress-label">
        <span>{{ 'course.overview.progress' | transloco }}:</span>
      </div>
      <div class="course-progress-bar">
        <mat-progress-bar
          [value]="course.progress"
          class="progress-bar"
          color="primary"
          mode="determinate"
        ></mat-progress-bar>
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button
      (click)="requestCertificate(course.id)"
      *ngIf="course.hasExam && course.examPassed"
      [matTooltip]="'course.requestCertificate' | transloco"
      mat-icon-button
      matTooltipPosition="right"
      data-testid="button-exam-passed"
    >
      <mat-icon class="icon-filled">verified</mat-icon>
    </button>
    <button
      *ngIf="course.hasExam && !course.examPassed"
      [disableRipple]="true"
      [matTooltip]="'course.overview.cantRequestCertificate' | transloco"
      class="default-cursor"
      mat-icon-button
      matTooltipPosition="right"
    >
      <mat-icon>verified</mat-icon>
    </button>
    <button *ngIf="!course.hasExam" [disableRipple]="true" class="default-cursor" mat-icon-button style="visibility: hidden">
      <mat-icon></mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
