import { createTaskOption, createTaskOptionLabelsOnly, ExaminationConfiguration } from '@ac/exam';

export const course1Exam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 2 },
  tasks: [
    {
      type: 'single-choice',
      task: {
        heading: 'Links verstehen',
        description:
          'Sie sind in Ihrem Unternehmen als Einkäufer für Büromaterial zuständig. Auf Ihre Anfragen erhalten Sie von vier Lieferanten Angebote per E-Mail.<br><br>Welches der Angebote ist seriös?',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-linktypes-1/Linkart-1.png',
            'https://amaz0n.de'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-linktypes-1/Linkart-2.png',
            'http://adresse.unsicher.de/schadsoftware'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-linktypes-1/Linkart-3.png',
            'https://amazon.de'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-linktypes-1/Linkart-4.png',
            'https://amazon.de.shopping.com'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-linktypes-1/Linkart-3.png',
          'https://amazon.de'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
          Ihre Antwort ist leider falsch. Entscheidend sind in dieser Aufgabe die Zieladressen des Links.
          Wenn Sie Ihre Maus über den Link halten, zeigt Ihr Browser die Zieladresse in der linken unteren Ecke des Fensters an.<br>
          Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>E-Mail 1: Unseriös, da die Domäne nicht korrekt geschrieben ist. Vergleichbar mit einer falschen Adresse eines Hauses.</li>
            <li>E-Mail 2: Unseriös, da dieser Link auf eine unsichere Seite verweist.</li>
            <li>E-Mail 3: Der einzig richtige Link.</li>
            <li>E-Mail 4: Unserös, da amazon.de durch das nachfolgende shopping.com zu einer Unterdomäne wird. Vergleichbar mit einer Wohnung eines Hauses.</li>
            </ul>
          `
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Versenden von geschäftlichen E-Mails',
        description: `Das Versenden von E-Mails über Ihre geschäftliche E-Mail-Adresse ist aktuell nicht möglich. Daher wollen Sie eine geschäftliche E-Mail über Ihren privaten Account versenden.<br><br>
Welche der folgenden Problemfälle können hierbei auftreten?`,
        options: createTaskOptionLabelsOnly(
          'Die E-Mail-Signatur des Unternehmens fehlt',
          'Die Sicherheitsmechanismen des Unternehmens greifen nicht',
          'Anhänge werden nicht verschickt'
        ),
        solution: createTaskOptionLabelsOnly(
          'Die E-Mail-Signatur des Unternehmens fehlt',
          'Die Sicherheitsmechanismen des Unternehmens greifen nicht'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
          Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Möglichkeit 1: Korrekt, beim Versenden über private Accounts wird keine Signatur erstellt.</li>
            <li>Möglichkeit 2: Korrekt, die Sicherheitsmechanismen des Unternehmens können nicht greifen.</li>
            <li>Möglichkeit 3: Falsch, auch über private Accounts werden Anhänge versendet.</li>
            </ul>
          `
        }
      }
    },
    {
      type: 'harmful-files-removal',
      task: {
        heading: 'Gefährliche Dateien identifizieren',
        description:
          'Sie finden mehrere Dateien auf Ihrem Desktop, die Sie nicht zuordnen können. Entscheiden Sie, welche dieser Dateien Sie in den Papierkorb verschieben sollten.<br><br>Ziehen Sie hierzu die von Ihnen als gefährlich eingeschätzten Dateien per Drag & Drop auf den Papierkorb.',
        icons: [
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/pdf-Logo.png',
            caption: 'Abschluss.pdf',
            tooltip: 'Abschluss.pdf'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/pptx-Logo.png',
            caption: 'Präsentation.pptx',
            tooltip: 'Präsentation.pptx'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/Txt-Icon.png',
            caption: 'Gesprächsnotizen.txt',
            tooltip: 'Gesprächsnotizen.txt'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/zip-Logo.png',
            caption: 'Bilder.zip',
            tooltip: 'Bilder.zip'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/xlsx-Logo.png',
            caption: 'Jahresabschluss.xlsx...',
            tooltip: 'Jahresabschluss.xlsx...............exe'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/docx-Logo.png',
            caption: 'Notizen.docx',
            tooltip: 'Notizen.docx'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/xlsm-Logo.png',
            caption: 'Berechnungs.xlsm',
            tooltip: 'Berechnungs.xlsm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/docm-Logo.png',
            caption: 'Bilanz.docm',
            tooltip: 'Bilanz.docm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/pptm-Logo.png',
            caption: 'Produktvorstellung.pptm',
            tooltip: 'Produktvorstellung.pptm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/Abschlussfotos.png',
            caption: 'Abschlussfotos.png.exe',
            tooltip: 'Abschlussfotos.png.exe'
          }
        ],
        solution: [
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/xlsx-Logo.png',
            caption: 'Jahresabschluss.xlsx...',
            tooltip: 'Jahresabschluss.xlsx...............exe'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/zip-Logo.png',
            caption: 'Bilder.zip',
            tooltip: 'Bilder.zip'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/docm-Logo.png',
            caption: 'Bilanz.docm',
            tooltip: 'Bilanz.docm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/Abschlussfotos.png',
            caption: 'Abschlussfotos.png.exe',
            tooltip: 'Abschlussfotos.png.exe'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/pptm-Logo.png',
            caption: 'Produktvorstellung.pptm',
            tooltip: 'Produktvorstellung.pptm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-1/xlsm-Logo.png',
            caption: 'Berechnungs.xlsm',
            tooltip: 'Berechnungs.xlsm'
          }
        ],
        summary: {
          correct: 'Super, Sie haben alle gefährlichen Dateitypen in den Papierkorb verschoben.',
          wrong: `
          Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Abschlussfotos.png.exe: Gefährlich, da sich die Datei als png ausgibt, jedoch in Wirklichkeit eine ausführbare Datei ist.</li>
          <li>Produktvorstellung.pptm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Bilanz.docm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Berechnung.xlsm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Bilder.zip: Gefährlich, da ein ZIP-Archiv gefährliche Dateien enthalten kann.</li>
          <li>Jahresabschluss.xlsx...: Gefährlich, da der Dateiname zu viele Leerzeichen enthält, um vollständig angezeigt zu werden.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'harmful-files-removal',
      task: {
        heading: 'Gefährliche Dateien identifizieren',
        description:
          'Sie finden mehrere Dateien auf Ihrem Desktop, die Sie nicht zuordnen können. Entscheiden Sie, welche dieser Dateien Sie in den Papierkorb verschieben sollten.<br><br>Ziehen Sie hierzu die von Ihnen als gefährlich eingeschätzten Dateien per Drag & Drop auf den Papierkorb.',
        icons: [
          {
            imageUrl: 'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/stones.jpg',
            caption: 'Prospekt-Hintergrund.jpg',
            tooltip: 'Prospekt-Hintergrund.jpg'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/xlsm-Logo.png',
            caption: 'Finanz-Berechnung.xlsm',
            tooltip: 'Finanz-Berechnung.xlsm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/docx-Logo.png',
            caption: 'Anmeldung.docx',
            tooltip: 'Anmeldung.docx'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/pptx-Logo.png',
            caption: 'Vorstellung.pptx',
            tooltip: 'Vorstellung.pptx'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/zip-Logo.png',
            caption: 'Programm.zip',
            tooltip: 'Programm.zip'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/xlsx-Logo.png',
            caption: 'Preisliste.xlsx...',
            tooltip: 'Preisliste.xlsx.......exe'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/pdf-Logo.png',
            caption: 'Anschreiben.pdf',
            tooltip: 'Anschreiben.pdf'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/Txt-Icon.png',
            caption: 'Notizen.txt',
            tooltip: 'Notizen.txt'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/docm-Logo.png',
            caption: 'Jahresabschluss.docm',
            tooltip: 'Jahresabschluss.docm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/pptm-Logo.png',
            caption: 'Präsentation.pptm',
            tooltip: 'Präsentation.pptm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/Tier-Fotos.png',
            caption: 'Tier-Fotos.png.exe',
            tooltip: 'Tier-Fotos.png.exe'
          }
        ],
        solution: [
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/Tier-Fotos.png',
            caption: 'Tier-Fotos.png.exe',
            tooltip: 'Tier-Fotos.png.exe'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/pptm-Logo.png',
            caption: 'Präsentation.pptm',
            tooltip: 'Präsentation.pptm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/docm-Logo.png',
            caption: 'Jahresabschluss.docm',
            tooltip: 'Jahresabschluss.docm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/xlsm-Logo.png',
            caption: 'Finanz-Berechnung.xlsm',
            tooltip: 'Finanz-Berechnung.xlsm'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/zip-Logo.png',
            caption: 'Programm.zip',
            tooltip: 'Programm.zip'
          },
          {
            imageUrl:
              'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/harmful-files-2/xlsx-Logo.png',
            caption: 'Preisliste.xlsx...',
            tooltip: 'Preisliste.xlsx.......exe'
          }
        ],
        summary: {
          correct: 'Super, Sie haben alle gefährlichen Dateitypen in den Papierkorb verschoben.',
          wrong: `
          Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Tier-Fotos.png.exe: Gefährlich, da sich die Datei als png ausgibt, jedoch in Wirklichkeit eine ausführbare Datei ist.</li>
          <li>Präsentation.pptm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Jahresabschluss.docm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Finanz-Berechnung.xlsm: Gefährlich, da diese Datei schädlichen Programmcode enthalten kann.</li>
          <li>Programm.zip: Gefährlich, da ein ZIP-Archiv gefährliche Dateien enthalten kann.</li>
          <li>Preisliste.xlsx...: Gefährlich, da der Dateiname zu viele Leerzeichen enthält, um vollständig angezeigt zu werden.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'E-Mail Absender korrekt erkennen',
        description: `Sie haben sich bei dem Newsletter der IG Metall registriert.
Als Sie in Ihr E-Mail-Postfach schauen, sehen Sie vier neue E-Mails der folgenden Absender.<br><br>
Welche der Absender-E-Mail-Adressen stammt wirklich von der IG Metall?`,
        options: createTaskOptionLabelsOnly(
          'igmetall@newsletter.de',
          'newsletter@igmetall.de',
          'igmetal@newsletter.de',
          'newsletter@lgmetall.de'
        ),
        solution: createTaskOptionLabelsOnly('newsletter@igmetall.de'),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
          Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Adresse 1: Die Domäne newsletter.de steht in keiner offensichtlichen Verbindung zur IG Metall.</li>
            <li>Adresse 2: Die einzig korrekte Adresse.</li>
            <li>Adresse 3: Die Domäne newsletter.de steht in keiner offensichtlichen Verbindung zur IG Metall, zusätzlich fehlt bei igmetall das zweite “l”.</li>
            <li>Adresse 4: Die Adresse gibt sich als igmetall aus, jedoch wurde hier das “i” durch ein kleines “L” ausgetauscht.</li>
            </ul>
          `
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Richtiger Umgang mit gefundenen Wechseldatenträgern',
        description: `Vor der Eingangstür ihres Unternehmens finden Sie einen auf dem Boden liegenden USB-Stick.
Sie beschließen diesen mitzunehmen.<br><br>
          Wählen Sie die korrekten Vorgehensweisen aus.`,
        options: createTaskOptionLabelsOnly(
          'Ich stecke den USB-Stick nicht in den PC ein, da dieser Schadsoftware enthalten kann.',
          'Ich stecke den USB-Stick in den PC ein, um so den ursprünglichen Besitzer zu ermitteln.',
          'Ich stecke den USB-Stick in den PC ein, um die bestehenden Daten zu löschen, sodass ich diesen weiter benutzen kann.',
          'Ich gebe den USB-Stick ohne diesen zu verwenden in der IT-Abteilung ab.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Ich stecke den USB-Stick nicht in den PC ein, da dieser Schadsoftware enthalten kann.',
          'Ich gebe den USB-Stick ohne diesen zu verwenden in der IT-Abteilung ab.'
        ),
        summary: {
          correct:
            'Korrekt, Sie sollten gefundene USB-Sticks niemals an Ihren PC anschließen. Geben Sie diese direkt bei Ihrer IT-Abteilung ab.',
          wrong:
            'Gefundene USB-Sticks sollten grundsätzlich nie in den PC eingesteckt werden, da eine Infektion schon beim bloßen Einstecken möglich ist. Daher sollten Sie gefundene Speichermedien immer direkt bei Ihrer IT-Abteilung abgeben.'
        }
      }
    },
    {
      type: 'left-or-right-choice',
      task: {
        heading: 'Zuordnung von URLs',
        description: `
        Unter Ihren Browserlesezeichen finden Sie einige unbekannte URLs.<br><br>
        Entscheiden Sie, welche der Adressen Sie nicht öffnen sollten. Ziehen Sie hierzu per Drag-and-Drop alle URLs in die entsprechenden Spalten.
        `,
        headings: {
          center: 'Auswahl',
          left: 'unseriöse Adresse',
          right: 'seriöse Adresse'
        },
        options: createTaskOptionLabelsOnly(
          'http://amazn.de/cart',
          'https://login.xing.portal.com/',
          'https://download.versandhaus.de/bedienungsanleitung.exe',
          'https://www.linkedin.com/login',
          'https://smile.amazon.de',
          'https://www.linkedn.com',
          'https://download.versandhaus.de/rechnung.pdf',
          'https://google.suche.com'
        ),
        solution: {
          left: createTaskOptionLabelsOnly(
            'http://amazn.de/cart',
            'https://login.xing.portal.com/',
            'https://download.versandhaus.de/bedienungsanleitung.exe',
            'https://www.linkedn.com',
            'https://google.suche.com'
          ),
          right: createTaskOptionLabelsOnly(
            'https://www.linkedin.com/login',
            'https://smile.amazon.de',
            'https://download.versandhaus.de/rechnung.pdf'
          )
        },
        summary: {
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Adresse 1: Unseriös, da die Domäne versucht Amazon.de zu kopieren um Sie so zu täuschen.</li>
            <li>Adresse 2: Unseriös, da die Unter-Domäne vortäuschen soll, dass es sich um Xing handelt.</li>
            <li>Adresse 3: Unseriös, da eine Bedienungsanleitung in der Regel keine Exe-Datei ist.</li>
            <li>Adresse 4: Seriös, da es sich dabei um eine korrekte Adresse von LinkedIn handelt.</li>
            <li>Adresse 5: Seriös, da es sich dabei um eine korrekte Adresse von Amazon handelt.</li>
            <li>Adresse 6: Unseriös, da die Unter-Domäne vortäuschen soll, dass es sich um LinkedIn handelt.</li>
            <li>Adresse 7: Seriös, da Rechnungen oft als PDF-Datei heruntergeladen werden.</li>
            <li>Adresse 8: Unseriös, da die Unter-Domäne vortäuschen soll, dass es sich um Google handelt.</li>
            </ul>
          `,
          correct: 'Super, Sie haben alle Adressen korrekt zugeordnet.'
        }
      }
    },
    {
      type: 'left-or-right-choice',
      task: {
        heading: 'Zuordnung von URLs',
        description: `
        Unter Ihren Browserlesezeichen finden Sie einige unbekannte URLs.<br><br>
        Entscheiden Sie, welche der Adressen Sie nicht öffnen sollten. Ziehen Sie hierzu per Drag-and-Drop alle URLs in die entsprechenden Spalten.
        `,
        headings: {
          center: 'Auswahl',
          left: 'unseriöse Adresse',
          right: 'seriöse Adresse'
        },
        options: createTaskOptionLabelsOnly(
          'http://microsof.com/cart',
          'https://support.microsoft.com',
          'https://rechnung.kundenportal.de/rechnung.pdf.exe',
          'https://teams.microsoft.com',
          'https://login.linkedin.portal.de',
          'https://www.xlng.com',
          'https://download.versandhaus.de/Bedienungsanleitung.pdf',
          'https://amazon.shopping.com'
        ),
        solution: {
          left: createTaskOptionLabelsOnly(
            'http://microsof.com/cart',
            'https://rechnung.kundenportal.de/rechnung.pdf.exe',
            'https://login.linkedin.portal.de',
            'https://www.xlng.com',
            'https://amazon.shopping.com'
          ),
          right: createTaskOptionLabelsOnly(
            'https://support.microsoft.com',
            'https://teams.microsoft.com',
            'https://download.versandhaus.de/Bedienungsanleitung.pdf'
          )
        },
        summary: {
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Adresse 1: Unseriös, da die Domäne versucht Microsoft.com zu kopieren um Sie so zu täuschen.</li>
            <li>Adresse 2: Seriös, da es sich dabei um eine korrekte Adresse von Microsoft handelt.</li>
            <li>Adresse 3: Unseriös, da der Nutzer durch eine doppelte Dateiendungen getäuscht werden soll.</li>
            <li>Adresse 4: Seriös, da es sich dabei um eine korrekte Adresse von Microsoft handelt.</li>
            <li>Adresse 5: Unseriös, da die Unter-Domäne vortäuschen soll, dass es sich um LinkedIn handelt.</li>
            <li>Adresse 6: Unseriös, da die Domäne versucht xing.com zu kopieren um Sie so zu täuschen.</li>
            <li>Adresse 7: Seriös, da Bedienungsanleitungen oft als PDF-Datei heruntergeladen werden.</li>
            <li>Adresse 8: Unseriös, da die Unter-Domäne vortäuschen soll, dass es sich um Amazon handelt.</li>
            </ul>
          `,
          correct: 'Super, Sie haben alle Adressen korrekt zugeordnet.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Versteckte Dateinamen entlarven',
        description: `
         Sie finden eine Datei auf Ihrem Desktop, die Sie nicht zuordnen können. Diese ist mit drei Punkten gekennzeichnet. Sie können also darauf schließen, dass hier nicht der gesamte Dateiname angezeigt wird.<br><br>
         Wie können Sie den gesamten Dateinamen anzeigen lassen? Wählen Sie die korrekte Vorgehensweise.
        `,
        options: createTaskOptionLabelsOnly(
          'Doppelklick auf die Datei',
          'Rechtsklick auf die Datei + Umbenennen',
          'Linksklick auf die Datei + Enter-Taste'
        ),
        solution: createTaskOptionLabelsOnly('Rechtsklick auf die Datei + Umbenennen'),
        summary: {
          correct: 'Super, Sie haben die korrekten Möglichkeiten ausgewählt.',
          wrong: `
            Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Möglichkeit 1: Falsch, mit einem Doppelklick öffen Sie die Datei.</li>
            <li>Möglichkeit 2: Richtig, so bekommen Sie den kompletten Dateinamen angezeigt.</li>
            <li>Möglichkeit 3: Falsch, hiermit markieren Sie die Datei und öffnen Sie anschließend.</li>
            </ul>
            `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Gefährliche Office-Dateien erkennen',
        description: `
          Ein Vertreter sendet Ihnen Informationen zu einem neuen Produkt. Im Anhang finden Sie zwei Word-Dateien mit den folgenden Datei-Icons.<br><br>
          Entscheiden Sie, welche der Dateien potenziell gefährlich ist, da diese ausführbaren Code (Makros) enthalten könnte.
        `,
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-1/docm-Logo.png',
            'docm-Logo'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-1/docx-Logo.png',
            'docx-Logo'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-1/docm-Logo.png',
          'docm-Logo'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `Falsch, Dateien mit diesem Datei-Icon können keine Makros enthalten. Dateien die Makros enthalten können erkennen Sie an dem Ausrufezeichen unten rechts.`
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Gefährliche Office-Dateien erkennen',
        description: `
          Ein Vertreter sendet Ihnen eine Präsentation zu einem neuen Produkt. Im Anhang finden Sie zwei Powerpoint-Dateien mit den folgenden Datei-Icons.<br><br>
          Entscheiden Sie, welche der Dateien potenziell gefährlich ist, da diese ausführbaren Code (Makros) enthalten könnte.
        `,
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-2/pptx-logo.png',
            'pptx-logo'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-2/pptm-Logo.png',
            'pptm-Logo'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-2/pptm-Logo.png',
          'pptm-Logo'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `Falsch, Dateien mit diesem Datei-Icon können keine Makros enthalten. Dateien die Makros enthalten können erkennen Sie an dem Ausrufezeichen unten rechts.`
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Gefährliche Office-Dateien erkennen',
        description: `
          Ein Vertreter sendet Ihnen eine Preisauskunft zu einem neuen Produkt für das Sie sich interessieren. Im Anhang finden Sie zwei Excel-Dateien mit den folgenden Datei-Icons.<br><br>
          Entscheiden Sie, welche der Dateien potenziell gefährlich ist, da diese ausführbaren Code (Makros) enthalten könnte.
        `,
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-3/xlsx-Logo.png',
            'xlsx-Logo'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-3/xlsm-logo.png',
            'xlsm-logo'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course1/single-choice-word-3/xlsm-logo.png',
          'xlsm-logo'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `Falsch, Dateien mit diesem Datei-Icon können keine Makros enthalten. Dateien die Makros enthalten können erkennen Sie an dem Ausrufezeichen unten rechts.`
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Richtiger Umgang mit Sicherheitsfragen',
        description: `
          Bei der Registrierung zu einem neuen Kollaborationswerkzeug werden Sie aufgefordert Sicherheitsfragen zu hinterlegen.<br><br>
          Wählen Sie alle richtigen Aussagen bezüglich Sicherheitsfragen aus.
        `,
        options: createTaskOptionLabelsOnly(
          'Sicherheitsfragen immer wahrheitsgemäß beantworten.',
          'Keine persönlichen Daten verwenden. ',
          'Immer Antworten geben, die nicht einfach herausgefunden werden können.',
          'Die Antworten möglichst kryptisch gestalten.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Keine persönlichen Daten verwenden. ',
          'Immer Antworten geben, die nicht einfach herausgefunden werden können.',
          'Die Antworten möglichst kryptisch gestalten.'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekten Möglichkeiten ausgewählt.',
          wrong:
            'Sie sollten bei Sicherheitsfragen nie persönliche Daten angeben, da diese für Angreifer leicht herauszufinden sind. Ebenfalls sollten Sie immer möglichst kryptische Antworten geben, sowie Antworten, die nicht zur Sicherheitsfrage passen. So wird es potenziellen Angreifern möglichst schwer gemacht.'
        }
      }
    }
  ]
};
