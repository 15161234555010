import { ChangeDetectionStrategy, Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LayoutsModule} from "../../../layouts/layouts.module";
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-partner-integrations',
  standalone: true,
  imports: [CommonModule, LayoutsModule, MatTab, MatTabContent, MatTabGroup, NgOptimizedImage],
  templateUrl: './partner-integrations.component.html',
  styleUrl: './partner-integrations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerIntegrationsComponent {}
