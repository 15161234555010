import { Component, Input } from '@angular/core';
import { CourseOverviewCourseDto } from '@ac/models';

@Component({
  selector: 'app-stats-overview',
  templateUrl: './course-stats.component.html',
  styleUrls: ['./course-stats.component.scss']
})
export class CourseStatsComponent {
  @Input() courses: Array<CourseOverviewCourseDto>;
}
