import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfirmNavigationDirective} from "../directives/confirm-navigation.directive";
import {MatIcon} from "@angular/material/icon";
import {MatIconAnchor} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {takeUntilResultError} from "@ngneat/query";

@Component({
  selector: 'ac-partner-button',
  standalone: true,
    imports: [CommonModule, ConfirmNavigationDirective, MatIcon, MatIconAnchor, MatTooltip, ConfirmNavigationDirective],
  templateUrl: './partner-button.component.html',
  styleUrl: './partner-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerButtonComponent {

  name = input<string>();
  href = input<string>();
  tooltip = input<string>();
  target = input<"_self" | "_blank" | "_parent" | "_top">("_self");
  confirmMessage = input<string>();
}
