<app-back-layout
  [align]="false"
  [backUrl]="urlBuilder.goToHome()"
  [title]="'navigation.menuItem.administration' | transloco"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="mat-x-tab-group-navigation w-full"
    color="accent"
  >
    <mat-tab
      *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.whitelistedAccesses' | transloco"
    >
      <ng-template matTabContent>
        <app-whitelisted-accesses></app-whitelisted-accesses>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.insurancesImport' | transloco"
    >
      <ng-template matTabContent>
        <app-insurances-import></app-insurances-import>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.management' | transloco"
    >
      <ng-template matTabContent>
        <app-admin-management></app-admin-management>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.feedbackManagement' | transloco"
    >
      <ng-template matTabContent>
        <app-feedback-overview></app-feedback-overview>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.subscriptions-management' | transloco"
    >
      <ng-template matTabContent>
        <app-company-subscriptions></app-company-subscriptions>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.phishing-mails-management' | transloco"
    >
      <ng-template matTabContent>
        <app-phishing-mails-management></app-phishing-mails-management>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.companyManagement' | transloco"
    >
      <ng-template matTabContent>
        <app-company-management></app-company-management>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.quickInvitation' | transloco"
    >
      <ng-template matTabContent>
        <app-quick-invitation></app-quick-invitation>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Globaler Administrator')"
      [label]="'administration.tabLabel.newsletterManagement' | transloco"
    >
      <ng-template matTabContent>
        <app-newsletter></app-newsletter>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="identityService.hasRole('Produkt-Manager')"
      [label]="'administration.tabLabel.ransom-clause' | transloco"
    >
      <ng-template matTabContent>
        <app-ransom-clause-initialization></app-ransom-clause-initialization>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
