<ng-container *transloco="let t">
  <wv-card [title]="'invitation.delete' | transloco" class="card">
    <p>{{ t('invitation.delete-question') }}</p>
    <div class="form relative">
      <div class="recaptcha">
        @if (acceptedRecaptcha()) {
          <re-captcha (resolved)="solveCaptcha()"></re-captcha>
        } @else {
          <ac-recaptcha-consent (accepted)="acceptedRecaptcha.set(true)"></ac-recaptcha-consent>
        }
      </div>
      <div class="actions">
        <button (click)="cancel()" appearance="outline-secondary" wvButton>{{ t('invitation.cancel-delete') }}</button>
        <button (click)="delete()" [disabled]="!captchaSolved" appearance="primary" wvButton>
          {{ t('invitation.confirm-delete') }}
        </button>
      </div>
    </div>
  </wv-card>
</ng-container>
