import {ChangeDetectionStrategy, Component, computed, effect, input, output, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIcon} from "@angular/material/icon";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ColibriModule} from "@ac/colibri";
import {FormsModule} from "@angular/forms";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";

@Component({
  selector: 'app-course-rating-dialog',
  standalone: true,
  imports: [CommonModule, MatIcon, MatFormField, MatInput, ColibriModule, FormsModule, MatLabel, MatDialogTitle, MatDialogContent, MatDialogClose, MatDialogActions],
  templateUrl: './course-rating-dialog.component.html',
  styleUrl: './course-rating-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseRatingDialogComponent {
  starCount = input<number>(5);
  stars = computed(() => Array.from({length: this.starCount()}, (_, i) => i + 1));
  rating = signal<number>(0);
  comment = signal<string>('');
  feedback = output<{rating: number, comment: string}>();

  submit() {
    this.feedback.emit({rating: this.rating(), comment: this.comment()});
  }
}
