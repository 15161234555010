<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Assekuradeure'"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/insurances-import.webp'"
      imgAlt="TODO"
      [link]="'./insurances-import'"
      [title]="'administration.tabLabel.insurancesImport' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/policyholders.webp'"
      imgAlt="TODO"
      [link]="'./policyholders'"
      [title]="'Meine Versicherungsnehmer'"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
