<form
  (ngSubmit)="onSubmit(userEditGenderForm, $event)"
  [formGroup]="userEditGenderForm"
>
  <mat-form-field
    appearance="outline"
    class="full-width"
  >
    <mat-label>{{ 'form.label.salutation' | transloco }}</mat-label>
    <mat-select
      data-testid="gender-control"
      formControlName="gender"
    >
      <mat-option [value]="'female'">{{ 'form.selection.salutation.female' | transloco }}</mat-option>
      <mat-option [value]="'male'">{{ 'form.selection.salutation.male' | transloco }}</mat-option>
    </mat-select>
    <mat-error *ngIf="userEditGenderForm.get('gender').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
  </mat-form-field>
  <button
    data-testid="save-gender"
    [disabled]="userEditGenderForm.invalid"
    appearance="primary"
    wvButton
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
