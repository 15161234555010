<ac-task-header [configuration]="configuration"></ac-task-header>

<div
  cdkDropListGroup
  class="virtual-desktop"
>
  <div class="desktop-files">
    <section
      [cdkDropListData]="desktopFileIcons"
      [cdkDropListDisabled]="formSubmitted"
      cdkDropList
      class="files"
    >
      <div
        *ngFor="let icon of desktopFileIcons"
        [cdkDragData]="icon"
        [matTooltip]="icon.tooltip"
        cdkDrag
        cdkDragBoundary=".virtual-desktop"
        class="file-icon"
        matTooltipPosition="below"
        data-testid="desktop-icon"
      >
        <img
          [attr.alt]="icon.caption"
          [src]="icon.imageUrl"
        />
        <span class="file-icon__caption">{{ icon.caption }}</span>
      </div>
    </section>
  </div>
  <section
    (cdkDropListDropped)="moveToBin($event)"
    [cdkDropListData]="binFileIcons"
    [cdkDropListDisabled]="formSubmitted"
    cdkDropList
    class="bin"
  >
    <div
      class="file-icon"
      data-test="bin"
      data-testid="bin"
    >
      <img
        *ngIf="binFileIcons.length === 0; else binFull"
        alt="bin"
        src="https://dl.co-it.eu/wuerttembergische/awareness-center/exams/assets/Papierkorb-leer-Icon.png"
      />
      <ng-template #binFull>
        <img
          alt="bin"
          src="https://dl.co-it.eu/wuerttembergische/awareness-center/exams/assets/Papierkorb-voll-Icon.png"
        />
      </ng-template>
      <span>{{ 'exam.harmfulFilesRemoval.trashbin' | transloco }}</span>
    </div>
  </section>

  <div class="taskbar">
    <div class="start">
      <img
        alt="windows button"
        class="taskbar-icon"
        src="https://dl.co-it.eu/wuerttembergische/awareness-center/exams/assets/windows.svg"
      />
    </div>
    <div class="apps">
      <img
        alt="system volume"
        class="taskbar-icon"
        src="https://dl.co-it.eu/wuerttembergische/awareness-center/exams/assets/loud-speaker.svg"
      />
      <span class="taskbar-time">09:30</span>
      <img
        alt="system notifications"
        class="taskbar-icon"
        src="https://dl.co-it.eu/wuerttembergische/awareness-center/exams/assets/conversation.svg"
      />
    </div>
  </div>
</div>

<section class="commands">
  <button
    (click)="submitAnswer()"
    [disabled]="formSubmitted"
    appearance="secondary"
    data-test="button-submit-solution"
    data-testid="button-submit-solution"
    wvButton
  >
    {{ 'exam.submitAnswerButton' | transloco }}
  </button>
</section>
