import { createTaskOptionLabelsOnly, ExaminationConfiguration } from '@ac/exam';

export const course6Exam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 5 },
  tasks: [
    {
      type: 'left-or-right-choice',
      task: {
        heading: 'Personenbezogene Daten',
        description: `Welche der folgenden Daten sind personenbezogen? Ziehen Sie hierzu per Drag-and-Drop alle Einträge in die entsprechenden Spalten.`,
        headings: {
          center: 'Auswahl',
          left: 'personenbezogene Daten',
          right: 'keine personenbezogenen Daten'
        },
        options: createTaskOptionLabelsOnly(
          'IP-Adresse',
          'Name',
          'E-Mail-Adresse',
          'Beschäftigungsverhältnis',
          'Unternehmen',
          'Vorlieben (geht gerne ins Theater)',
          'Religionszugehörigkeit',
          'Anonymisierte Wohnanschrift'
        ),
        solution: {
          left: createTaskOptionLabelsOnly(
            'IP-Adresse',
            'Name',
            'E-Mail-Adresse',
            'Beschäftigungsverhältnis',
            'Unternehmen',
            'Vorlieben (geht gerne ins Theater)',
            'Religionszugehörigkeit'
          ),
          right: createTaskOptionLabelsOnly('Anonymisierte Wohnanschrift')
        },
        summary: {
          wrong: `Das ist leider falsch. Nur die anonymisierte Wohnanschrift lässt keine Rückschlüsse auf eine natürliche Person zu.`
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Rechtsgrundlagen',
        description: `Wählen Sie alle Rechtsgrundlagen aus, die laut DSGVO für die Datenverarbeitung zulässig sind.`,
        options: createTaskOptionLabelsOnly(
          'Einwilligung',
          'Berechtigte Bedenken',
          'Vorbehaltliches Interesse',
          'Vertragserfüllung',
          'Erfüllung rechtlicher Verpflichtungen'
        ),
        solution: createTaskOptionLabelsOnly(
          'Einwilligung',
          'Vertragserfüllung',
          'Erfüllung rechtlicher Verpflichtungen'
        ),
        summary: {
          wrong:
            'Berechtigte Bedenken und vorbehaltliches Interesse sind keine Rechtsgrundlagen. Meinten Sie vielleicht berechtigtes Interesse?',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Rechte der Personen',
        description: 'Eine der folgenden Aussagen ist falsch, wählen Sie die entsprechende aus.',
        options: createTaskOptionLabelsOnly(
          'Sie haben Anspruch auf Auskunft über die von Ihnen gesammelten personenbezogenen Daten. „Auskunftsrecht“',
          'Sie dürfen der Verarbeitung Ihrer personenbezogenen Daten jederzeit widersprechen. „Widerspruchsrecht“',
          'Sie dürfen von dem Pflichteninhaber unverzüglich die Berichtigung falscher personenbezogener Daten verlangen. „Recht auf Berichtigung“',
          'Sie dürfen die Löschung ihrer personenbezogenen Daten verlangen. Diese müssen unverzüglich gelöscht werden. „Recht auf Vergessen werden“',
          'Sie dürfen verlangen, dass die Daten gesperrt werden. Damit dürfen die personenbezogenen Daten nur noch mit der Einwilligung der betroffenen Person, oder aufgrund gesetzlicher Vorschriften verarbeitet werden.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Sie dürfen die Löschung ihrer personenbezogenen Daten verlangen. Diese müssen unverzüglich gelöscht werden. „Recht auf Vergessen werden“'
        ),
        summary: {
          wrong:
            'Sie haben das Recht, Ihre Daten gemäß dem "Recht auf Vergessenwerden" zu löschen. Allerdings ist dies nur unter der Bedingung möglich, dass keine anderen gesetzlichen Bestimmungen diesem Löschvorgang entgegenstehen.',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Personenbezogene Daten',
        description:
          'Um welche Art von Daten handelt es sich bei einem Namenskürzel wie "UA" oder "UAR"?<br><br>Wählen Sie alle richtigen Antworten aus:',
        options: createTaskOptionLabelsOnly(
          'Personenbezogene Daten',
          'Pseudonymisierte Daten',
          'Anonymisierte Daten',
          'Keine personenbezogenen Daten im Sinne der DSGVO'
        ),
        solution: createTaskOptionLabelsOnly('Personenbezogene Daten', 'Pseudonymisierte Daten'),
        summary: {
          wrong:
            'Bei dem Kürzel handelt es sich um ein Pseudonym. Aber auch pseudonymisierte Daten sind personenbezogene Daten.',

          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Rechtsgrundlagen',
        description: 'Wann dürfen personenbezogene Daten verarbeitet werden?',
        options: createTaskOptionLabelsOnly(
          'Wenn eine Einwilligung vorliegt',
          'Wenn eine Rechtspflicht hierfür besteht',
          'Wenn ein berechtigtes Interesse besteht',
          'Wenn es zur Vertragserfüllung notwendig ist',
          'Wenn es sich um weniger sensible Daten handelt'
        ),
        solution: createTaskOptionLabelsOnly(
          'Wenn eine Einwilligung vorliegt',
          'Wenn eine Rechtspflicht hierfür besteht',
          'Wenn ein berechtigtes Interesse besteht',
          'Wenn es zur Vertragserfüllung notwendig ist'
        ),
        summary: {
          wrong:
            'Alle Antworten sind richig, außer die letzte, denn auch bei weniger sensiblen Daten braucht es eine Rechtsgrundlage wie eine Einwilligung.',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Transparenz',
        description:
          'Wie müssen Informationen formuliert sein, um als transparent zu gelten? Mehrere Antworten sind richtig:',
        options: createTaskOptionLabelsOnly(
          'Die Informationen müssen leicht zugänglich sein.',
          'Die Informationen müssen präzise sein.',
          'Die Informationen müssen in einfacher Sprache sein.',
          'Die Informationen müssen leicht verständlich sein.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Die Informationen müssen leicht zugänglich sein.',
          'Die Informationen müssen präzise sein.',
          'Die Informationen müssen in einfacher Sprache sein.',
          'Die Informationen müssen leicht verständlich sein.'
        ),
        summary: {
          wrong:
            'Alle Antworten sind richig. Nur wenn jeder die zur Verfügung gestellten Informationen verstehen kann, wurde der Transparenzpflicht nachgekommen.',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Datenverarbeitung',
        description: 'Wann handelt es sich gemäß DSGVO um um eine Datenverarbeitung?.',
        options: createTaskOptionLabelsOnly(
          'Wenn Daten manuell erhoben werden',
          'Wenn Daten automatisiert gelesen werden',
          'Wenn Daten durch Sachbearbeiter geändert werden',
          'Wenn Daten automatisiert gelöscht werden',
          'Wenn Daten durchsucht, aber nicht verändert werden',
          'Wenn Daten ohne zu lesen weitergeleitet werden',
          'Wenn Daten kopiert werden',
          'Immer, wenn Daten manuell von Menschen gelesen oder verändert werden',
          'Immer, wenn Daten maschinell automatisiert ausgelesen oder verändert werden'
        ),
        solution: createTaskOptionLabelsOnly(
          'Wenn Daten manuell erhoben werden',
          'Wenn Daten automatisiert gelesen werden',
          'Wenn Daten durch Sachbearbeiter geändert werden',
          'Wenn Daten automatisiert gelöscht werden',
          'Wenn Daten durchsucht, aber nicht verändert werden',
          'Wenn Daten ohne zu lesen weitergeleitet werden',
          'Wenn Daten kopiert werden',
          'Immer, wenn Daten manuell von Menschen gelesen oder verändert werden',
          'Immer, wenn Daten maschinell automatisiert ausgelesen oder verändert werden'
        ),
        summary: {
          wrong:
            'Tatsächlich sind alle Antworten richig, denn es handelt sich immer um eine Datenverarbeitung gemäß DSGVO.',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Einwilligung als rechtliche Grundlage',
        description: 'Kreuzen Sie alle korrekte Aussagen an.',
        options: createTaskOptionLabelsOnly(
          'Eine Einwilligung muss freiwillig erfolgen.',
          'Eine Vorselektierung der Einwilligung ist in bestimmten Situationen zulässig.',
          'Eine Einwilligung kann rückwirkend eingeholt werden.',
          'Eine Einwilligung erfordert ein aktives Verhalten (z.B. anhaken einer Box).',
          'Eine Benachteiligung bei Nichtzustimmung ist zulässig.',
          'Eine Generaleinwilligung kann mehrere einzelne Einwilligungen ersetzen.',
          'Mit einer Einwilligung dürfen grundsätzlich jegliche Daten verarbeitet werden.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Eine Einwilligung muss freiwillig erfolgen.',
          'Eine Einwilligung erfordert ein aktives Verhalten (z.B. anhaken einer Box).'
        ),
        summary: {
          wrong:
            'An eine Einwilligung sind rechtlich hohe Hürden gesetzt. Darum sind nur die zwei Antworten<br>' +
            '- Eine Einwilligung muss freiwillig erfolgen.<br>' +
            '- Eine Einwilligung erfordert ein aktives Verhalten<br>' +
            'korrekt. Alle anderen Antworten hingegen würden die betroffene Person zu stark benachteiligen.',
          correct: 'Super, Ihre Antwort(en) sind korrekt.'
        }
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: 'Pflichten und Prinzipien',
        description:
          'Sie sind Einkäufer in Ihrem Unternehmen und Ihr Lieferant fordert Sie bei der Bestellung auf zusätzlich private Kontaktdaten anzugeben: Gegen welches Prinzip wird dabei verstoßen?',
        options: createTaskOptionLabelsOnly(
          'Prinzip der Datenminimierung',
          'Prinzip der Speicherbegrenzung',
          'Prinzip der Integrität und Vertraulichkeit'
        ),
        solution: createTaskOptionLabelsOnly('Prinzip der Datenminimierung'),
        summary: {
          wrong:
            'Er verstößt gegen das Prinzip der Datenminierung, das besagt, dass nur die absolut notwendigen Daten erfasst werden dürfen.<br>' +
            'Das Prinzip der Speicherbegrenzung kommt nicht zum Tragen, da für die Erfassung keine Rechtsgrundlage besteht und diese somit auch nicht wegfallen kann.' +
            'Über das Prinzip der Integrität und Vertraulichkeit kann im vorliegenden Fall nichts ausgesagt werden, da unklar ist wie die Daten geschützt werden.',
          correct: 'Super, Ihr Antwort ist korrekt.'
        }
      }
    }
  ]
};
