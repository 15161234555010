<ng-container *transloco="let t">
  <header class="flex gap-4 items-baseline">
    <date-range-input
      [(range)]="range"
      [disabled]="dateRangeDisabled()"
      [max]="max()"
      [min]="min()"
      [presets]="presets()"
      class="w-fit"
    ></date-range-input>
    @if (dateRangeDisabled()) {
      <button
        wvButton
        materialIcon="diamond"
        [needsPackageUpgrade]="true"
      >
        {{ t('common.upgrade') }}
      </button>
    }
  </header>

  <div class="grid gap-8">
    <div class="cards">
      <awareness-center-statistic-card
        [heading]="'phishingSimulations.statistic.user.generalHintsHeading' | transloco"
        [text]="''"
      >
        <p
          class="p-0 self-start"
          statistic
        >
          {{ 'phishingSimulations.statistic.user.generalHintsText' | transloco }}
        </p>
        <ac-help-button info>
          <p class="p-0">
            {{ 'phishingSimulations.statistic.user.generalHintsInfo' | transloco }}
          </p>
        </ac-help-button>
      </awareness-center-statistic-card>
    </div>

    <mat-divider></mat-divider>

    @if (phishingMailsUserStatistic().isLoading) {
      <mat-spinner></mat-spinner>
    }
    @if (phishingMailsUserStatistic().data; as phishingMailsUserStatistic) {
      @if (phishingMailsUserStatistic.phishingMailsSent === 0) {
        <p class="p-4 bg-secondary text-on-secondary max-w-max">
          {{ 'phishingSimulations.statistic.noData' | transloco }}
        </p>
      } @else {
        <div class="cards">
          <awareness-center-statistic-card
            [heading]="'phishingSimulations.statistic.user.totalClicksHeading' | transloco"
            [text]="
              'phishingSimulations.statistic.user.totalClicksText'
                | transloco
                  : {
                      sentCount: phishingMailsUserStatistic.phishingMailsSent,
                      clickCount: phishingMailsUserStatistic.phishingMailsClicked
                    }
            "
          >
            <ac-help-button info>
              <p class="p-0">
                {{ 'phishingSimulations.statistic.user.totalClicksInfo' | transloco | renderHTML }}
              </p>
            </ac-help-button>
            <app-phishing-mails-total-clicks-statistic
              statistic
              [clickCount]="phishingMailsUserStatistic.phishingMailsClicked"
              [sentCount]="phishingMailsUserStatistic.phishingMailsSent"
            ></app-phishing-mails-total-clicks-statistic>
          </awareness-center-statistic-card>

          <awareness-center-statistic-card
            [heading]="'phishingSimulations.statistic.user.clickRateHeading' | transloco"
            [text]="'phishingSimulations.statistic.user.clickRateText' | transloco"
          >
            <ac-help-button info>
              <p class="p-0">
                {{ 'phishingSimulations.statistic.user.clickRateInfo' | transloco }}
              </p>
            </ac-help-button>
            <awareness-center-percentage-animation
              statistic
              [percentage]="phishingMailsUserStatistic.phishingMailsClickRate"
            ></awareness-center-percentage-animation>
          </awareness-center-statistic-card>

          <!--  <awareness-center-statistic-card-->
          <!--    heading="Trend"-->
          <!--    text="Nur ein Beispiel"-->
          <!--    icon="https://dl.co-it.eu/wuerttembergische/awareness-center/images/others/bear_market.svg"-->
          <!--    ><awareness-center-trend [title]="'Positiver Trend'" [value]="2" [isPositive]="true"></awareness-center-trend-->
          <!--    ><awareness-center-trend [title]="'Negativer Trend'" [value]="-2" [isPositive]="false"></awareness-center-trend-->
          <!--  ></awareness-center-statistic-card>-->
        </div>
      }
    }
  </div>
</ng-container>
