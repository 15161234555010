<wv-card [title]="'Unternehmensprofil'" class="m-2 w-fit min-w-[32rem]">
  <div class="grid grid-cols-[max-content_1fr] gap-2 items-center gap-x-3 [&_mat-icon]:-mt-6">
    <mat-icon>titlecase</mat-icon>

    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput value="Vogel & Partner">
    </mat-form-field>
    <mat-icon>home</mat-icon>
    <mat-form-field appearance="outline">
      <mat-label>Webseite</mat-label>
      <input matInput type="url" value="https://www.vogel-partner.eu">
    </mat-form-field>
    <mat-icon>contact_support</mat-icon>
    <mat-form-field appearance="outline">
      <mat-label>Terminbuchungsseite</mat-label>
      <input matInput type="url" value="https://www.vogel-partner.eu/kontakt">
    </mat-form-field>
    <mat-icon>email</mat-icon>
    <mat-form-field appearance="outline">
      <mat-label>E-Mail</mat-label>
      <input matInput type="email" value="info+cyber-portal@vogel-partner.eu">
    </mat-form-field>
    <mat-icon>phone</mat-icon>
    <mat-form-field appearance="outline">
      <mat-label>Telefon</mat-label>
      <input matInput type="tel" value="+49 (0) 721 78 20 27-0">
    </mat-form-field>
    <mat-icon>location_on</mat-icon>
    <mat-form-field appearance="outline">
      <mat-label>Adresse</mat-label>
      <input matInput value="Erbprinzenstraße 27, Karlsruhe">
    </mat-form-field>
    <mat-icon>description</mat-icon>

    <mat-form-field appearance="outline">
      <mat-label>Beschreibung</mat-label>
      <textarea cdkAutosizeMinRows="4"
                cdkTextareaAutosize matInput
                value="Vogel & Partner ist eine renommierte Kanzlei, die Unternehmen in den Bereichen Technologie und Medien rechtlich unterstützt. Mit ihrer Expertise in IT-Recht, Datenschutz, IP, Medien-, Arbeits- und Wirtschaftsrecht helfen sie ihren Mandanten, ihre Position am Markt zu sichern und weiter auszubauen. Gemeinsam setzen wir auf rechtssichere Lösungen, um Innovationen erfolgreich voranzutreiben."></textarea>
    </mat-form-field>


  </div>
  <button wvButton>Speichern</button>
</wv-card>
