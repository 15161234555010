import { DeleteWhitelistedAccessDto, ResendWhitelistedAccessDto, WhitelistedAccessDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { IdentityService } from '../../../identity/identity.service';

@Component({
  selector: 'app-whitelisted-accesses-overview',
  templateUrl: './whitelisted-accesses-overview.component.html',
  styleUrls: ['./whitelisted-accesses-overview.component.scss']
})
export class WhitelistedAccessesOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<WhitelistedAccessDto> = new MatTableDataSource<WhitelistedAccessDto>();
  displayedColumns = ['accountantEmail', 'companyName', 'isRegistrationPending', 'actions'];

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.identityService.getWhitelistedAccesses().subscribe(next => {
      this.dataSource.data = next;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  resendInvitation(access: WhitelistedAccessDto) {
    const dto: ResendWhitelistedAccessDto = {
      email: access.accountantEmail
    };

    this.identityService.resendWhitelistInvitation(dto).subscribe();
  }

  deleteInvitation(access: WhitelistedAccessDto) {
    const dto: DeleteWhitelistedAccessDto = {
      email: access.accountantEmail
    };

    this.identityService.deleteWhitelistInvitation(dto).subscribe();
  }
}
