<ac-task-header [configuration]="configuration"></ac-task-header>

<form [formGroup]="formQuestionOne" class="exam__typography">
  <p [innerHTML]="configuration.questionOne.description | safe"></p>

  <mat-form-field
    *ngIf="configuration.questionOne.inputField.type === 'textarea'; else input"
    appearance="outline"
    class="exam__form-field"
  >
    <mat-label>{{ 'exam.answer' | transloco }}</mat-label>
    <textarea [attr.disabled]="formsSubmitted().one" formControlName="answer" matInput type="text"></textarea>
    <mat-error *ngIf="formQuestionOne.get('answer').hasError('reject')">
      {{ configuration.questionOne.inputField.validationErrorMessage }}
    </mat-error>
  </mat-form-field>

  <ng-template #input>
    <mat-form-field appearance="outline" class="exam__form-field">
      <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
      <input formControlName="answer" matInput type="email" />
    </mat-form-field>
  </ng-template>
  <div class="button-wrapper">
    <button
      (click)="submitAnswerOne()"
      [disabled]="formQuestionOne.invalid"
      appearance="secondary"
      type="button"
      wvButton
    >
      {{ 'exam.checkButton' | transloco }}
    </button>
  </div>
</form>

<form
  (ngSubmit)="submitAnswerTwo()"
  *ngIf="formsSubmitted().one"
  [formGroup]="formQuestionTwo"
  class="exam__typography second-part"
  #answerTwoForm
>
  <p [innerHTML]="configuration.questionTwo.description | safe"></p>
  <p>
    <small>{{ 'exam.temporaryEmail.submittedText' | transloco: {email: submittedEmail} | renderHTML }}</small>
  </p>

  <mat-form-field
    *ngIf="configuration.questionTwo.inputField.type === 'textarea'; else input"
    appearance="outline"
    class="exam__form-field"
  >
    <mat-label>{{ 'exam.answer' | transloco }}</mat-label>
    <textarea [attr.disabled]="formsSubmitted().two" formControlName="answer" matInput type="text"></textarea>
    <mat-error *ngIf="formQuestionTwo.get('answer').hasError('reject')">
      {{ configuration.questionTwo.inputField.validationErrorMessage }}
    </mat-error>
  </mat-form-field>

  <ng-template #input>
    <mat-form-field appearance="outline" class="exam__form-field">
      <input [attr.disabled]="formsSubmitted().two" formControlName="answer" label="Antwort" matInput type="text" />
    </mat-form-field>
  </ng-template>

  <div class="button-wrapper">
    <button [disabled]="formsSubmitted().two" appearance="secondary" wvButton>
      {{ 'exam.submitAnswerButton' | transloco }}
    </button>
  </div>
</form>
