<div class="flex gap-2 items-center w-full h-full">
  <div class="relative w-full h-full">
    <canvas
      [data]="chartData"
      [options]="chartOptions"
      [type]="chartType"
      baseChart
    ></canvas>
  </div>
  <p class="italic">{{ 'phishingSimulations.statistic.company.clickComparisonHint' | transloco }}</p>
</div>

