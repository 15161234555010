import { Component, Input } from '@angular/core';
import { CourseOverviewCourseDto } from '@ac/models';

@Component({
  selector: 'app-course-stats-suggestion',
  templateUrl: './course-stats-suggestion.component.html',
  styleUrls: ['./course-stats-suggestion.component.scss']
})
export class CourseStatsSuggestionComponent {
  @Input() courses: Array<CourseOverviewCourseDto>;

  getNearlyCompletedCourse(): CourseOverviewCourseDto {
    return this.courses
      .filter(x => x.progress !== 100)
      .sort(x => x.progress)
      .reverse()[0];
  }
}
