<wv-card>
  <div class="flex justify-between items-center">
    <h3 class="p-0">AI Act Check</h3>
    <ac-partner-button
      [confirmMessage]="'Sie verlassen unser Portal und werden auf die externe Website unseres Partners Vogel & Partner weitergeleitet. Bitte beachten Sie, dass die dort angebotenen Leistungen unabhängig von unserem Portal sind und nicht zu unserem Angebot gehören.'"
      [href]="'https://www.vogel-partner.eu/'"
      [name]="'Vogel & Partner'"
      [target]="'_blank'"
      [tooltip]="'Dieser Check wurde in Zusammenarbeit mit unserem Partner Vogel & Partner erstellt.'"
    >
    </ac-partner-button>
  </div>
  <h4>Ist Ihr System/Ihre Software eine KI nach der KI-Verordnung?</h4>
  <p>Um welche Art von KI handelt es sich bei Ihrem System / Ihrer Software?</p>
  <p>Hinweis: Sollte es sich bei Ihrem System/Ihrer Software um ein KI-System handeln, in welches eigene KI-Modelle integriert werden, wählen Sie bitte KI-System aus.</p>

  <div>
    <mat-list>
      <mat-checkbox>KI-System</mat-checkbox>
      <mat-checkbox>KI-System mit allgemeinem Verwendungszweck</mat-checkbox>
      <mat-checkbox>KI-Modell mit allgemeinem Verwendungszweck</mat-checkbox>
      <mat-checkbox>KI-System oder KI-Modell im Rahmen von Forschungs- und Entwicklungstätigkeiten</mat-checkbox>
      <mat-checkbox>Herkömmliche Software</mat-checkbox>
    </mat-list>
  </div>

  <button
    appearance="secondary"
    wvButton
  >
    Weiter
  </button>
</wv-card>
