<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Württembergische Versicherung'"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/whitelisted-accesses.webp'"
      imgAlt="TODO"
      [link]="'./whitelisted-accesses'"
      [title]="'administration.tabLabel.whitelistedAccesses' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/versicherungsnehmer.webp'"
      imgAlt="TODO"
      [link]="'./insurances-import'"
      [title]="'administration.tabLabel.insurancesImport' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/company-management.webp'"
      imgAlt="TODO"
      [link]="'./company-management'"
      [title]="'Versicherungsnehmer'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/assekuradeur-management.webp'"
      imgAlt="TODO"
      [link]="'./assekuradeur-management'"
      [title]="'Assekuradeure verwalten'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/ransom.webp'"
      imgAlt="TODO"
      [link]="'./ransom-clause-initialization'"
      [title]="'administration.tabLabel.ransom-clause' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/user-feedback.webp'"
      imgAlt="TODO"
      [link]="'./messages'"
      [title]="'Nachrichten'"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
