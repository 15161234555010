import { TaskResult } from '@ac/exam';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ac-exam-summary',
  template: `
    <h3>Prüfung abgeschlossen</h3>
    <p>
      Die Antworten zur Prüfung wurden erfolgreich übermittelt. Sie erhalten eine E-Mail mit Ihrem Ergebnis, sobald
      dieses geprüft wurde. Bei Nichtbestehen können Sie die Prüfung am Folgetag erneut ablegen. Bei Bestehen erhalten
      Sie Ihr Zertifikat per E-Mail.
    </p>

    <h4>Zusammenfassung</h4>
    <ul>
      <li>Absolvierte Aufgaben: {{ results.length }}</li>
      <li>Korrekte Aufgaben: {{ correctTasksCount }}</li>
      <li>Inkorrekte Aufgaben: {{ incorrectTasksCount }}</li>
    </ul>
  `
})
export class ExamSummaryComponent {
  @Input() results: TaskResult[];

  get correctTasksCount(): number {
    return this.results.filter(result => result.isCorrect).length;
  }

  get incorrectTasksCount(): number {
    return this.results.filter(result => !result.isCorrect).length;
  }
}
