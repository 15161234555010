<wv-card class="m-2" [title]="'administration.quickInvitation.title' | transloco">
  <p>{{ 'administration.quickInvitation.description' | transloco }}</p>
  <pre>
    {{ 'administration.quickInvitation.format' | transloco }}
  </pre>
  {{ 'administration.quickInvitation.example' | transloco }}
  <pre>
    {{ 'administration.quickInvitation.example1' | transloco }}
    {{ 'administration.quickInvitation.example2' | transloco }}
  </pre>
  <form [formGroup]="inviteForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.label.company' | transloco }}</mat-label>
      <mat-select formControlName="companyId">
        <mat-option
          *ngFor="let company of companies()"
          [value]="company.id"
        >
          {{ company.name }} : {{ company.accountantEmail }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="inviteForm.get('companyId').errors?.required">{{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.label.csv' | transloco }}</mat-label>
      <textarea formControlName="csv" matInput></textarea>
      <mat-error *ngIf="inviteForm.get('csv').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
      <mat-error *ngIf="inviteForm.get('csv').errors?.quickInviteCsv">{{ 'form.error.format' | transloco }}</mat-error>
    </mat-form-field>
  </form>
  <div class="actions">
    <button (click)="inviteUsers()" [disabled]="inviteForm.invalid" appearance="secondary" wvButton>
      {{ 'administration.quickInvitation.inviteUsersButton' | transloco }}
    </button>
  </div>
</wv-card>
