import { Component, Input } from '@angular/core';
import { TaskResult } from './models';

@Component({
  selector: 'ac-task-summary',
  template: `
    <ng-container *ngIf="state.isComplete">
      <p
        data-test="text-task-summary"
        data-testid="text-task-summary"
        [class.correct]="state.isCorrect"
        [class.wrong]="!state.isCorrect"
        [innerHTML]="state.summary | safe"
      ></p>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        margin: 1rem 0 1rem 0;
      }

      .correct {
        border: 2px solid var(--success);
        color: var(--success);
        border-radius: 3px;
        display: block;
        padding: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .wrong {
        border: 2px solid var(--error);
        backdrop-filter: opacity(0.3);
        color: var(--error);
        border-radius: 3px;
        display: block;
        padding: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    `
  ]
})
export class TaskSummaryComponent {
  @Input() state: TaskResult;
}
