import { CustomValidator, SelectOptionsService } from '@ac/colibri';
import { UpdateDepartmentDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit-department-form',
  templateUrl: './user-edit-department-form.component.html',
  styleUrls: ['./user-edit-department-form.component.scss']
})
export class UserEditDepartmentFormComponent implements OnInit {
  @Input() department: string;
  @Output() submitForm = new EventEmitter<UpdateDepartmentDto>();
  userEditDepartmentForm: UntypedFormGroup;
  options = this.selectOptionsService.departments();
  filteredOptions: Observable<string[]>;

  constructor(private selectOptionsService: SelectOptionsService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.userEditDepartmentForm = this.formBuilder.group({
      department: ['', [Validators.required, CustomValidator.requireMatch(this.options)]]
    });

    this.userEditDepartmentForm.get('department').setValue(this.department);

    this.filteredOptions = this.userEditDepartmentForm.get('department').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UpdateDepartmentDto = {
      department: userData.get('department').value
    };

    this.submitForm.emit(dto);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
