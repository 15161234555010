import { CourseDetailsCourseDto } from '@ac/models';
import { Injectable } from '@angular/core';

export interface CourseResourceProjection {
  description: string;
  name: string;
  moduleTitle: string;
  lessonTitle: string;
  url: string;
}

@Injectable({ providedIn: 'root' })
export class CourseResourceCollector {
  collect(course: CourseDetailsCourseDto): CourseResourceProjection[] {
    const result = course.modules.reduce((resources, module) => {
      return [
        ...resources,
        ...module.lessons.map(lesson =>
          lesson.resources.map(resource => ({
            ...resource,
            moduleTitle: module.title,
            lessonTitle: lesson.title
          }))
        )
      ];
    }, []);

    return result?.flat() || [];
  }
}
