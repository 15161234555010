import { ChangeDetectionStrategy, Component, effect, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { DefaultChartOptionsService } from '../../../../phishing-mails/default-chart-options.service';

@Component({
  selector: 'app-partner-courses-user-usage-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-courses-user-usage-statistic.component.html',
  styleUrl: './partner-courses-user-usage-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerCoursesUserUsageStatisticComponent {
  chart = viewChild(BaseChartDirective);
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          text: 'Teilnehmer',
          display: true
        }
      },
      y: {
        title: {
          text: 'Kurs',
          display: true
        }
      }
    }
  };

  constructor() {
    const toolkitUsage = [
      { course: 'Datenschutz', clicksTotal: 2111, clicksCV: 144, clicksGV: 70 },
      { course: 'Compliance', clicksTotal: 1265, clicksCV: 453, clicksGV: 200 },
      { course: 'Künstliche Intelligenz', clicksTotal: 2222, clicksCV: 523, clicksGV: 234 }
    ].sort((a, b) => b.clicksTotal - a.clicksTotal);

    this.chartData.datasets = [
      {
        data: toolkitUsage.map(resourceClick => resourceClick.clicksTotal),
        label: 'Alle Benutzer',
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[0],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[0],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      },
      {
        data: toolkitUsage.map(resourceClick => resourceClick.clicksCV),
        label: 'Cyber-Verantwortliche',
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[1],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[1],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      },
      {
        data: toolkitUsage.map(resourceClick => resourceClick.clicksGV),
        label: 'Geschäftsführer',
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[2],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[2],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      }
    ];
    this.chartData.labels = toolkitUsage.map(resourceClick => resourceClick.course);

    effect(() => {
      if (this.chart()) {
        this.chart().update();
      }
    });
  }
}
