import { CompanyListDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IdentityService } from '../../../identity/identity.service';
import { MatPaginator } from '@angular/material/paginator';
import {randomElement} from "../../../utils/random-element";
import { map } from 'rxjs';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  roles: string[];
  dataSource: MatTableDataSource<CompanyListDto & {package: string, assekuradeur: string}> = new MatTableDataSource<CompanyListDto & {package: string, assekuradeur: string}>();
  displayedColumns = [
    'name',
    'businessSector',
    'createdAt',
    'accountantName',
    'assekuradeur',
    'officeEmployeeCount',
    'employeeCount',
    'numberOfPendingRegistrations',
    'numberOfUsers',
    'package'
  ];

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.identityService.getCompanies().pipe(map(companies => companies.map(company => ({...company, package: randomElement(['Basis', 'Profi', 'Experte']), assekuradeur: randomElement(['Meistermakler GmbH', 'Assek AG', 'My Vermittler Inc.', 'Cyber Agentur KG'])})))).subscribe(companies => (this.dataSource.data = companies));
  }

  getCompanyCount(): number {
    return this.dataSource.data.length;
  }

  getTotalUserCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfUsers, 0);
  }

  getTotalEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.employeeCount, 0);
  }

  getTotalOfficeEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.officeEmployeeCount, 0);
  }

  getTotalPendingRegistrationsCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfPendingRegistrations, 0);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  protected readonly randomElement = randomElement;
}
