<div class="details-playlist-item-title">
  <a
    data-testid="lesson-link"
    [routerLink]="'./player/' + lesson.id"
  >
    {{ lesson.title }}
  </a>
</div>
<div class="details-playlist-item-status-icon">
  <mat-icon
    data-testid="course-overview-icon-lesson-completed"
    *ngIf="lesson.completed"
  >
    done
  </mat-icon>
</div>
<div class="details-playlist-item-duration">
  <div class="details-playlist-item-duration-icon ml-1 mr-2">
    <mat-icon inline="true">schedule</mat-icon>
  </div>
  <div class="details-playlist-item-duration-text">{{ lesson.duration | duration }}</div>
</div>
