<awareness-center-statistic-card text="ℹ️ Fügen Sie weiterführende Materialien zur Lead-Generierung ein." heading="Feedbacks verarbeiten">
  <div statistic>
    <table
      *ngIf="dataSource"
      [dataSource]="dataSource"
      mat-table
    >
      <ng-container [matColumnDef]="'kurse'">
        <th
          *matHeaderCellDef
          mat-header-cell
        >
          Kurse
        </th>
        <td
          *matCellDef="let data"
          mat-cell
        >
          {{data.kurse}}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'bewertung'">
        <th
          *matHeaderCellDef
          mat-header-cell
        >
          Bewertung
        </th>
        <td
          *matCellDef="let data"
          mat-cell
        >
          @for (star of data.bewertung; track star) {
            <mat-icon [color]="'accent'" [class.icon-filled]="star == 1">star</mat-icon>
          }
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="displayedColumns()"
        mat-header-row
      ></tr>
      <tr
        *matRowDef="let data; columns: displayedColumns()"
        mat-row
      ></tr>
    </table>
  </div>
</awareness-center-statistic-card>


