import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource
} from '@angular/material/table';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import {LayoutsModule} from "../../../layouts/layouts.module";

@Component({
  selector: 'app-assekuradeur-management',
  standalone: true,
  imports: [
    CommonModule,
    ColibriModule,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatFormField,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatInput,
    MatLabel,
    MatRow,
    MatRowDef,
    MatSort,
    MatTable,
    MatHeaderCellDef,
    LayoutsModule
  ],
  templateUrl: './assekuradeur-management.component.html',
  styleUrl: './assekuradeur-management.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssekuradeurManagementComponent implements AfterViewInit {
  displayedColumns: string[] = ['name', 'email', 'referrals'];
  dataSource = new MatTableDataSource([
    { name: 'Meistermakler GmbH', email: 'info@meister-makler.de', referrals: 86 },
    { name: 'Assek AG', email: 'info@assek.de', referrals: 505 },
    { name: 'My Vermittler Inc.', email: 'contact@my-vermittler.com', referrals: 630 }
  ]);

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
