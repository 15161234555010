import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {DefaultChartOptionsService} from "../../../../phishing-mails/default-chart-options.service";
import {BaseChartDirective} from "ng2-charts";

@Component({
  selector: 'app-partner-planned-webinar-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-planned-webinar-statistic.component.html',
  styleUrl: './partner-planned-webinar-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerPlannedWebinarStatisticComponent {
  chartType: ChartType = 'doughnut';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    }
  };

  webinarParticipants = [
    {
      webinar: 'NIS2 für Apotheken',
      participants: {
          users: 30,
          cv: 3,
          ceo: 5
      }
    },
    {
      webinar: 'KRITIS für Versorger',
      participants: {
        users: 30,
        cv: 3,
        ceo: 5
      }
    }
    ];

  charts = this.webinarParticipants.map(toolkitUsage => ({
    chartType: this.chartType,
    chartOptions: this.chartOptions,
    chartData: {
      labels: ['Benutzer', 'Cyber-Verantwortliche', 'Geschäftsführer'],
      datasets: [
        {
          data: Object.values(toolkitUsage.participants),
          backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor,
          hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor,
          hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
        }
      ]
    },
    name: toolkitUsage.webinar
  }));
}
