<mat-accordion [multi]="true">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Frage 1?</mat-panel-title>
    </mat-expansion-panel-header>
    Antwort 1
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Frage 2?</mat-panel-title>
    </mat-expansion-panel-header>
    Antwort 2
  </mat-expansion-panel>
</mat-accordion>
