<app-info-video-layout *ngIf="introVideo" [description]="introVideo.courseDescription" [title]="introVideo.courseTitle">
  <app-videoplayer
    class="shadow-xl"
    *ngIf="video"
    [autoplay]="false"
    [delay]="0"
    [resetOnEnd]="true"
    [showPoster]="false"
    [video]="video"
    [title]="introVideo.courseTitle"
  ></app-videoplayer>
</app-info-video-layout>
