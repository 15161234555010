<ng-container *transloco="let t">
  <app-videoplayer (videoEnded)="markVideoAsFinished()" [video]="video"></app-videoplayer>
  <button (click)="confirmVideoFinish()" [disabled]="!videoFinished" appearance="secondary" wvButton>
    {{ confirmationLabel ?? t('ransom-clause.video-confirmation.confirm-label')}}
  </button>
  <div class="description">
    <h3>{{heading}}</h3>
    <div [innerHTML]="description"></div>
  </div>
</ng-container>
