import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class JsonContentTypeInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.headers.has('enctype')) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      headers: req.headers.append('Content-Type', 'application/json; charset=UTF-8')
    });

    return next.handle(clonedRequest);
  }
}
