<awareness-center-statistic-card text="Verfolgen Sie chronologisch die Klicks von Branchen und Abteilungen." heading="Trends analysieren">
  <table mat-table [dataSource]="dataSource" matSort statistic class="block overflow-auto max-h-96">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let resourceClick"> {{resourceClick.name}} </td>
    </ng-container>
    <ng-container matColumnDef="businessSector">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Branche
      </th>
      <td mat-cell *matCellDef="let resourceClick"> {{resourceClick.businessSector}} </td>
    </ng-container>
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Abteilung
      </th>
      <td mat-cell *matCellDef="let resourceClick"> {{resourceClick.department}} </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Zeitpunkt
      </th>
      <td mat-cell *matCellDef="let resourceClick"> {{resourceClick.date | date: "dd.MM.yyyy"}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</awareness-center-statistic-card>

