import { createTaskOptionLabelsOnly, ExaminationConfiguration } from '@ac/exam';

export const ransomClauseExam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 9 },
  tasks: [
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Was ist Ransomware?',
        options: createTaskOptionLabelsOnly(
          'Ein Programm, das befallene Systeme zur Generierung von Kryptowährungen nutzt.',
          'Eine Schadsoftware, die darauf abzielt Lösegeld vom Opfer zu erpressen.',
          'Eine Technologie zur Vermeidung von SPAM-E-Mails.'
        ),
        solution: createTaskOptionLabelsOnly('Eine Schadsoftware, die darauf abzielt Lösegeld vom Opfer zu erpressen.')
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Bei einer Ransomware-Infektion sollte ich...',
        options: createTaskOptionLabelsOnly(
          'Ruhe bewahren.',
          'Die Netzwerkverbindung betroffener Geräte trennen.',
          'Experten & Behörden kontaktieren.',
          'Das Notfallkonzept durchführen.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Ruhe bewahren.',
          'Die Netzwerkverbindung betroffener Geräte trennen.',
          'Experten & Behörden kontaktieren.',
          'Das Notfallkonzept durchführen.'
        )
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Wie sensibilisiere ich meine Mitarbeiter?',
        options: createTaskOptionLabelsOnly(
          'Mit Hilfe von Awareness-Kursen.',
          'Gar nicht. Sensibilisierung wird nur für Mitarbeiter in der IT benötigt.',
          'Durch Vermittlung von Sicherheitsgrundlagen.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Mit Hilfe von Awareness-Kursen.',
          'Durch Vermittlung von Sicherheitsgrundlagen.'
        )
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Welche Daumenregel gilt für Berechtigungen?',
        options: createTaskOptionLabelsOnly(
          'So viel wie nötig, aber so wenig wie möglich.',
          'Alles oder nichts.',
          'Die 3-2-1-0 Regel.'
        ),
        solution: createTaskOptionLabelsOnly('So viel wie nötig, aber so wenig wie möglich.')
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Die Installation von Updates sollte...',
        options: createTaskOptionLabelsOnly(
          'systematisiert sein.',
          'automatisiert sein.',
          'grundsätzlich vermieden werden.'
        ),
        solution: createTaskOptionLabelsOnly('systematisiert sein.', 'automatisiert sein.')
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Welche gesetzlichen Pflichten gelten im Angriffsfall?',
        options: createTaskOptionLabelsOnly(
          'Meldepflicht nach Artikel 33',
          'Löschpflicht nach Artikel 10',
          'Benachrichtigungspflicht nach Artikel 34'
        ),
        solution: createTaskOptionLabelsOnly('Meldepflicht nach Artikel 33', 'Benachrichtigungspflicht nach Artikel 34')
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Wofür benötige ich Backups?',
        options: createTaskOptionLabelsOnly(
          'Zur Verschlüsselung des E-Mail-Verkehrs.',
          'Zur Überwachung meines Netzwerkes.',
          'Zur Sicherung und Wiederherstellung.'
        ),
        solution: createTaskOptionLabelsOnly('Zur Sicherung und Wiederherstellung.')
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Wie sollte mit der Cyber-Versicherung umgegangen werden?',
        options: createTaskOptionLabelsOnly(
          'Der Abschluss einer solchen Versicherung eignet sich großartig fürs Marketing.',
          'Die Versicherung macht ein IT-Sicherheitskonzept überflüssig.',
          'Es sollten höchstens drei Personen im Unternehmen von der vertraulichen Versicherung erfahren.'
        ),
        solution: createTaskOptionLabelsOnly(
          'Es sollten höchstens drei Personen im Unternehmen von der vertraulichen Versicherung erfahren.'
        )
      }
    },
    {
      type: 'multiple-choice',
      task: {
        heading: '',
        description: 'Worauf kann man sich nach der Zahlung des Lösegelds bei einem Ransom-Angriff nicht verlassen?',
        options: createTaskOptionLabelsOnly(
          'Entschlüsselung der Daten',
          'Vollständigkeit der Daten',
          'Korrektheit der Daten'
        ),
        solution: createTaskOptionLabelsOnly(
          'Entschlüsselung der Daten',
          'Vollständigkeit der Daten',
          'Korrektheit der Daten'
        )
      }
    }
  ]
};
