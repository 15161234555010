import {Component, signal} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IdentityService } from '../identity.service';
import { UrlBuilder } from '../../url-builder.service';
import {CustomValidators} from "../custom.validator";

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent {
  passwordResetRequestForm: UntypedFormGroup;
  protected readonly acceptedRecaptcha = signal(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    public urlBuilder: UrlBuilder
  ) {
    this.passwordResetRequestForm = this.formBuilder.group({
      email: ['', [CustomValidators.email, CustomValidators.username, Validators.required]],
      recaptcha: ['', [Validators.required]]
    });
  }

  onSubmit(passwordResetRequestForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (passwordResetRequestForm.invalid) {
      return;
    }

    this.identityService.requestPasswordReset(passwordResetRequestForm.getRawValue()).subscribe();
  }
}
