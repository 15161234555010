import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IntroVideoDto } from '@ac/models';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';

@Component({
  selector: 'app-intro-videos',
  templateUrl: './intro-videos.component.html',
  styleUrls: ['./intro-videos.component.scss']
})
export class IntroVideosComponent implements OnInit {
  introVideo: IntroVideoDto;
  video: VideoDto;
  courses: [courseName: string, courseId: string][] = [
    ['kurs1', 'bbc368ea-0de3-4476-b87b-1a0ee45713e1'],
    ['kurs2', '626970b6-9abf-47ed-b0f8-398df94fbd45'],
    ['kurs3', '03bf441e-8a9e-49a3-8217-ec51bc4f829f'],
    ['kurs4', 'e592c346-f359-46ad-aa57-dcec81bdf638'],
    ['kurs5', '9b91ee08-1884-498d-811e-8c44eee9c8b8'],
    ['kurs6', '7fa01a9b-f6f9-45e0-9c3c-782ef497913b']
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private urlBuilder: UrlBuilder,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      const courseParam = params.course;

      const courseId = this.courses.filter(course => course[0] === courseParam)[0][1];

      if (!courseId) {
        this.router.navigateByUrl(this.urlBuilder.goToHome());
      }

      this.http.get<IntroVideoDto>(environment.apiUrl + '/IntroVideos/' + courseId).subscribe(next => {
        this.introVideo = next;
        this.video = {
          captions: [
            {
              label: 'DE',
              language: 'Deutsch',
              source: next.subtitlesUrl
            }
          ],
          downloadUrl: null,
          streamUrl: next.streamUrl
        };
      });
    });
  }
}
