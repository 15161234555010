<div><h3>Fortschritt:</h3></div>
<div class="dashboard-progress-chart">
  <div class="circle-progress">
    <circle-progress
      [animateTitle]="true"
      [animation]="true"
      [innerStrokeColor]="innerStrokeColor"
      [outerStrokeColor]="'var(--primary)'"
      [percent]="calculateProgress()"
      [responsive]="true"
      [showSubtitle]="false"
      [showZeroOuterStroke]="true"
      [space]="-6"
      [titleColor]="'var(--on-background)'"
      [titleFontSize]="'100'"
      [titleFontWeight]="'bold'"
      [unitsColor]="'var(--on-background)'"
      [unitsFontSize]="'50'"
      [unitsFontWeight]="'bold'"
    ></circle-progress>
  </div>
</div>
