<form
  (ngSubmit)="onSubmit(userEditFirstNameForm, $event)"
  [formGroup]="userEditFirstNameForm"
>
  <mat-form-field
    appearance="outline"
    class="full-width"
  >
    <mat-label data-testid="label-firstName">{{ 'form.label.firstName' | transloco }}</mat-label>
    <input
      data-testid="input-firstName"
      formControlName="firstName"
      matInput
    />
    <mat-error *ngIf="userEditFirstNameForm.get('firstName').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
  </mat-form-field>
  <button
    data-testid="button-safe-firstName"
    [disabled]="userEditFirstNameForm.invalid"
    appearance="primary"
    wvButton
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
