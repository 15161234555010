import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { injectMutation, injectQuery } from '@ngneat/query';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { PhishingMailTemplateDto } from '@ac/models';

@Injectable({ providedIn: 'root' })
export class PhishingMailsService {
  #http = inject(HttpClient);
  #mutation = injectMutation();
  #query = injectQuery();
  #loadingDialog = inject(LoadingDialogService);

  getTemplates() {
    return this.#query({
      queryKey: ['phishing-mails'],
      queryFn: () =>
        this.#http.get<PhishingMailTemplateDto[]>(`${environment.apiUrl + environment.apiRoutes.phishingMails}`)
    });
  }

  sendMail() {
    this.#loadingDialog.open(LoadingDialogComponent);

    return this.#mutation({
      mutationFn: (props: { templateId: string; dto: SendPhishingMailDto }) =>
        this.#http.post<string>(
          `${environment.apiUrl + environment.apiRoutes.phishingMails}/${props.templateId}/send`,
          props.dto
        ),
      onSuccess: async data => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data
        });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }

  sendConfigurationTestMail() {
    this.#loadingDialog.open(LoadingDialogComponent);

    return this.#mutation({
      mutationFn: () =>
        this.#http.post<string>(
          `${environment.apiUrl + environment.apiRoutes.phishingMails}/configuration-test-mail`,
          {}
        ),
      onSuccess: async data => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data
        });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }
}

export interface SendPhishingMailDto {
  email: string;
}
