<div
  [style.backgroundImage]="
    'linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.7)), url(' + urlBuilder.getImageVariation(imageUrl, 'banner') + ')'
  "
  class="header"
>
  <div class="header-content">
    <div class="header-title-button">
      <h1>
        <app-back-arrow-button [url]="urlBuilder.goToCourseOverview()"></app-back-arrow-button>
      </h1>
    </div>
    <div class="header-title-text">
      <h1 data-test="title-course">{{ title }}</h1>
    </div>
    <div class="header-description grid gap-4">
      <p class="p-0">{{ description }}</p>

      @if (title === 'Effektives Basiswissen') {
        <div class="flex gap-2 items-center">
          <ac-partner-button
            [confirmMessage]="'Sie verlassen unser Portal und werden auf die externe Website unseres Partners Vogel & Partner weitergeleitet. Bitte beachten Sie, dass die dort angebotenen Leistungen unabhängig von unserem Portal sind und nicht zu unserem Angebot gehören.'"
            [href]="'https://www.vogel-partner.eu/'"
            [name]="'Vogel & Partner'"
            [target]="'_blank'"
          >
          </ac-partner-button>
          <p class="p-0">Dieser Kurs wurde in Zusammenarbeit mit unserem Partner <a confirmNavigation [message]="'Sie verlassen unser Portal und werden auf die externe Website unseres Partners Vogel & Partner weitergeleitet. Bitte beachten Sie, dass die dort angebotenen Leistungen unabhängig von unserem Portal sind und nicht zu unserem Angebot gehören.'" target="_blank"
                                                                                    href="https://www.vogel-partner.eu/">Vogel
            & Partner</a> erstellt.</p>
        </div>
      }
    </div>
    <button (click)="handleButtonClick()" appearance="primary" class="header-button" wvButton>{{ buttonText }}</button>
  </div>
</div>
