<app-back-layout
  [backUrl]="urlBuilder.goToHome()"
  [title]="'navigation.menuItem.administration' | transloco"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/admin-wv.jpg'"
      imgAlt="TODO"
      [link]="'./wv'"
      [title]="'Württembergische Versicherung'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/admin-agentur.png'"
      imgAlt="TODO"
      [link]="'./agenturen'"
      [title]="'Agenturen'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/admin-assekuradeur.png'"
      imgAlt="TODO"
      [link]="'./assekuradeure'"
      [title]="'Assekuradeure'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/vogel-partner-law.jpeg'"
      imgAlt="TODO"
      [link]="'./partner'"
      [title]="'Vogel & Partner'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/admin-coit.webp'"
      imgAlt="TODO"
      [link]="'./coit'"
      [title]="'co-IT'"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
