import { PasswordValidators, TextValidator } from '@ac/colibri';
import { ExaminationTask, TaskCompletion, TaskComponent } from '@ac/exam';
import { Component, effect, ElementRef, viewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { extractPassword } from '../../utils';

@Component({
  selector: 'ac-password-memorization',
  templateUrl: './password-memorization.component.html',
  styleUrls: ['./password-memorization.component.scss']
})
export class PasswordMemorizationComponent implements TaskComponent<ExaminationTask> {
  configuration: ExaminationTask;
  passwordMemorizationSentence: UntypedFormGroup;
  passwordMemorizationExercise: UntypedFormGroup;
  formsSubmitted = { exercise: false };
  sentenceSubmitted = false;
  answerTwoForm = viewChild<ElementRef>('answerTwoForm');
  private complete$$ = new Subject<TaskCompletion>();
  private passwordFromMemorizationSentence = '';

  constructor(private formBuilder: UntypedFormBuilder) {
    this.passwordMemorizationSentence = this.buildSentenceForm();
    this.passwordMemorizationExercise = this.buildExerciseForm();

    effect(() => {
      if (this.answerTwoForm())
        this.answerTwoForm().nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }); //scroll to second form when first form is submitted
  }

  complete = () => this.complete$$.asObservable();

  prepare(configuration: ExaminationTask): void {
    this.configuration = configuration;
  }

  hasSentenceError(errorKey: string): boolean {
    return (
      !!Object.values(this.passwordMemorizationSentence.get('sentence').errors || {})?.some(
        error => error === errorKey
      ) || false
    );
  }

  extractPasswordFromSentence() {
    if (this.passwordMemorizationSentence.invalid) {
      return;
    }
    this.passwordFromMemorizationSentence = extractPassword(this.passwordMemorizationSentence.value.sentence);
    this.sentenceSubmitted = true;
  }

  submitAnswer() {
    this.formsSubmitted.exercise = true;

    if (this.passwordFromMemorizationSentence === this.passwordMemorizationExercise.value.password) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({
        isCorrect: false,
        summary: {
          wrong: `Das abgeleitete Passwort lautet: "${this.passwordFromMemorizationSentence}".`
        }
      });
    }
  }

  private buildSentenceForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        sentence: [
          '',
          [
            PasswordValidators.policy({
              requireDigit: true,
              requiredLength: 0,
              requireLowercase: true,
              requireUppercase: true,
              requiredUniqueChars: 0,
              requireNonAlphanumeric: true
            }),
            TextValidator.wordCount(10),
            Validators.required
          ]
        ]
      },
      { updateOn: 'change' }
    );
  }

  private buildExerciseForm(): UntypedFormGroup {
    return this.formBuilder.group({ password: ['', [Validators.required]] });
  }
}
