<form
  (ngSubmit)="onSubmitList(bulkInviteUserListForm, $event)"
  [formGroup]="bulkInviteUserListForm"
>
  <div class="textarea-info-wrapper">
    <mat-form-field
      appearance="outline"
      class="full-width"
    >
      <textarea
        data-testid="invitation-textfield"
        formControlName="list"
        matInput
        placeholder="max.mustermann@firma.de; maxima.mustermann@firma.de"
        rows="10"
      ></textarea>
      <mat-error *ngIf="bulkInviteUserListForm.get('list').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="
        ('info-box.userInvitationList.description' | transloco) +
        '<br>' +
        ('info-box.common.invitationDescription' | transloco)
      "
      [gdpr]="'info-box.common.invitationGdpr' | transloco"
    ></app-info-box>
  </div>
  <button
    [disabled]="bulkInviteUserListForm.invalid"
    appearance="secondary"
    wvButton
    data-testid="button-send-invitation"
  >
    {{ 'userManagement.invitation.list.submitButton' | transloco }}
  </button>
</form>
