<div class="player-playlist-heading" data-test="list-player">
  <div class="player-playlist-heading-icon">
    <h3>
      <app-back-arrow-button [url]="backLink$ | async"></app-back-arrow-button>
    </h3>
  </div>
  <div class="player-playlist-heading-text">
    <h3>{{ course.title }}</h3>
  </div>
</div>
<ng-template [ngForOf]="course.modules" [ngForTrackBy]="trackByLessonId" let-i="index" let-last="last" let-lesson ngFor>
  <div class="player-playlist-divider"></div>
  <app-course-player-playlist-module [module]="lesson" [position]="i + 1"></app-course-player-playlist-module>
  <ng-container *ngIf="last">
    <div class="player-playlist-divider"></div>
  </ng-container>
</ng-template>
