import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IdentityService } from '../identity/identity.service';
import { UrlBuilder } from '../url-builder.service';

@Injectable()
export class AdministratorGuard implements CanActivate {
  constructor(
    private identityService: IdentityService,
    private router: Router,
    private urlBuilder: UrlBuilder
  ) {}

  canActivate(): boolean {
    if (!this.identityService.hasRole('Globaler Administrator') && !this.identityService.hasRole('Produkt-Manager')) {
      this.router.navigateByUrl(this.urlBuilder.goToLogin());
      console.warn('Insufficient Permissions');
      return false;
    }

    return true;
  }
}
