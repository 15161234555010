import { Injectable } from '@angular/core';
import { sha512 } from 'js-sha512';
import * as XLSX from 'xlsx';
import { Access, ErrorType, ImportEntry, Options } from './insurance-import';
import { insuranceNumberRegexPattern } from '../../utils/regex-patterns';

@Injectable({ providedIn: 'root' })
export class ExcelImportService {
  accesses: Access[];
  errors: ErrorType[] = [];
  options: Options = {
    mandatoryHeaders: ['VSNR', 'Beginn Police'],
    dateFormat: 'dd.MM.yyyy',
    dateMin: '1990-01-01',
    upperBound: 10000,
    lowerBound: 700
  };

  createAccessesFromExcel(excelData: string | ArrayBuffer): Access[] {
    this.errors = [];

    const lines = this.extractLines(excelData);
    const cleanLines = this.cleanupLines(lines);
    const entries = this.splitLines(cleanLines);

    this.checkColumnCount(entries);
    this.checkHeader(entries[0]);
    this.removeHeader(entries);

    const mappedEntries = this.mapEntries(entries);

    this.checkMappedEntryCount(mappedEntries);

    const accesses = this.createAccesses(mappedEntries);

    return accesses;
  }

  getErrors(): ErrorType[] {
    return this.errors;
  }

  getFirstError(): ErrorType {
    return this.errors.shift();
  }

  hasErrors(): boolean {
    return this.errors.length > 0;
  }

  getOptions(): Options {
    return this.options;
  }

  setOptions(options: Options): void {
    this.options = options;
  }

  createAccesses(importEntries: ImportEntry[]): Access[] {
    return importEntries.map(entry => {
      if (!entry.insuranceDate || !entry.insuranceNumber) {
        this.setError('INVALID_ENTRIES');
      }

      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(entry.insuranceDate);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(entry.insuranceDate);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(entry.insuranceDate);
      const dateString: string = entry.insuranceDate ? [day, month, year].join('.') : null;
      const value = dateString + entry.insuranceNumber;
      const hash = sha512
        .array(value)
        .map(byte => byte.toString(16).padStart(2, '0').toUpperCase())
        .join('-');

      return { hash, insuranceNumber: entry.insuranceNumber, insuranceDate: entry.insuranceDate };
    });
  }

  checkHeader(headers: string[]) {
    if (JSON.stringify(headers) !== JSON.stringify(this.options.mandatoryHeaders)) {
      this.setError('INVALID_HEADERS');
    }
  }

  splitLines(lines: string[]): string[][] {
    const entries = lines.map(line => line.split(',').map(entry => entry.trim()));

    if (!entries.every(entry => entry.every(x => x.length > 0))) {
      this.setError('EMPTY_CELL');
    }

    return entries;
  }

  checkColumnCount(entries: string[][]) {
    if (entries.length === 0 || !entries.every(entry => entry.length == 2)) {
      this.setError('INVALID_COLUMN_COUNT');
    }
  }

  // this wants en locale
  createDate(dateString: string): Date {
    const date = new Date(dateString);

    if (!dateString || !(date instanceof Date) || isNaN(date.getTime())) {
      this.setError('INVALID_DATE');
    } else if (date > new Date()) {
      this.setError('INVALID_FUTURE_DATE');
    } else if (date <= new Date(this.options.dateMin)) {
      this.setError('INVALID_PAST_DATE');
    }

    return date;
  }

  checkInsuranceNumber(insuranceNumber: string) {
    const regex = new RegExp(insuranceNumberRegexPattern);

    if (!regex.test(insuranceNumber)) {
      this.setError('INVALID_INSURANCE_NUMBER');
    }

    return insuranceNumber;
  }

  checkMappedEntryCount(mappedEntries: ImportEntry[]) {
    if (mappedEntries.length < this.options.lowerBound) {
      this.setError('LOW_ROW_COUNT');
    }
    if (mappedEntries.length > this.options.upperBound) {
      this.setError('HIGH_ROW_COUNT');
    }
  }

  private setError(error: ErrorType): void {
    this.errors.push(error);
  }

  private removeHeader(entries: string[][]) {
    entries.shift();
  }

  private cleanupLines(entries: string[]) {
    return entries.filter(entry => entry).filter((entry, index) => entries.indexOf(entry) === index);
  }

  private mapEntries(entries: string[][]): ImportEntry[] {
    return entries.map(entry => {
      const insuranceNumber = this.checkInsuranceNumber(entry[0]);
      const insuranceDate = this.createDate(entry[1]);

      return { insuranceNumber, insuranceDate };
    });
  }

  private extractLines(data: string | ArrayBuffer): string[] {
    if (!data) {
      this.setError('EMPTY_FILE');
    }
    try {
      const workbook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const sheetDataCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], {
        rawNumbers: false,
        dateNF: 'yyyy-mm-dd'
      });
      const sheetData = sheetDataCsv.split('\n');

      if (sheetData.length === 0) {
        this.setError('EMPTY_SHEET');
      }
      return sheetData;
    } catch {
      this.setError('CANNOT_PARSE_FILE');
    }
    return null;
  }
}
