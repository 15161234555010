import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule} from "@angular/material/legacy-input";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslocoPipe} from "@ngneat/transloco";
import {AdministrationService} from "../administration.service";
import {QuickBulkInviteDto} from "./quick-bulk-invit.dto";
import {IdentityService} from "../../identity/identity.service";
import {MatLegacyOptionModule} from "@angular/material/legacy-core";
import {MatLegacyProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatLegacySelectModule} from "@angular/material/legacy-select";
import {CompanyListDto} from "@ac/models";
import {CustomValidators} from "../../identity/custom.validator";
import {map, tap} from "rxjs/operators";

@Component({
  selector: 'app-quick-invitation',
  standalone: true,
  imports: [CommonModule, ColibriModule, MatLegacyCardModule, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, TranslocoPipe, MatLegacyOptionModule, MatLegacyProgressBarModule, MatLegacySelectModule],
  templateUrl: './quick-invitation.component.html',
  styleUrl: './quick-invitation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickInvitationComponent implements OnInit {
  companies = signal<CompanyListDto[]>([])

  private readonly formBuilder = inject(FormBuilder);
  inviteForm = this.formBuilder.group({
    companyId: ['', [Validators.required]],
    csv: ['', [Validators.required, CustomValidators.quickInviteCsv]]
  });

  private readonly administrationService = inject(AdministrationService);
  private readonly identityService = inject(IdentityService);

  ngOnInit(): void {
    this.identityService.getCompanies()
        .pipe(
            map(companies => companies.sort((x,y) => x.name.localeCompare(y.name))),
            tap(companies => this.companies.set(companies))
        )
        .subscribe();
  }

  inviteUsers() {
    if (this.inviteForm.invalid)
      return;

    const formValue = this.inviteForm.value;

    const companyId = formValue.companyId;
    const csv = formValue.csv;

    const inviteDtos = csv
        .split("\n")
        .map(line => {
              const columns = line
                  .split(",");

              return {
                email: columns[0],
                firstName: columns[1],
                lastName: columns[2],
                department: columns[3],
                isFemale: columns[4] == "true"
              }
            }
        );

    const dto: QuickBulkInviteDto = {
      companyId: companyId,
      invitations: inviteDtos
    }

    this.administrationService.quickInvite(dto).subscribe()
  }
}
