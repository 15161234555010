<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Württembergische Versicherung - Erweiterte Erpressungsklausel'"
>
<ng-container *transloco="let t">
  <h2>{{t('ransom-clause.inform')}}</h2>
  <p>{{t('ransom-clause.inform-description')}}</p>
  <wv-card class="block m-2 max-w-[40rem]" [title]="t('ransom-clause.inform')">
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'form.label.insuranceNumber' | transloco }}</mat-label>
        <input formControlName="insuranceNumber" matInput />
        <mat-error *ngIf="form.get('insuranceNumber').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
        <mat-error *ngIf="form.get('insuranceNumber').errors?.pattern">
          {{ 'form.error.invalidInsuranceNumber' | transloco | renderHTML }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
        <input formControlName="policyHolderEmail" matInput type="email" />
        <mat-error *ngIf="form.get('policyHolderEmail').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
        <mat-error *ngIf="form.get('policyHolderEmail').errors?.email">
          {{ 'form.error.invalidEmail' | transloco }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'form.label.companyName' | transloco }}</mat-label>
        <input formControlName="companyName" matInput />
        <mat-error *ngIf="form.get('companyName').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'form.label.name' | transloco }}</mat-label>
        <input formControlName="name" matInput />
        <mat-error *ngIf="form.get('name').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
      </mat-form-field>
      <button (click)="initialize()" [disabled]="form.invalid" appearance="primary" wvButton>
        {{ 'ransom-clause.initialize' | transloco }}
      </button>
    </form>
  </wv-card>
</ng-container>
</app-back-layout>
