import { CourseDetailsModuleDto, CoursePlayerCourseDto } from '@ac/models';
import { Component, Input, OnInit } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseContextService } from '../course-context.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-course-player-playlist',
  templateUrl: './course-player-playlist.component.html',
  styleUrls: ['./course-player-playlist.component.scss']
})
export class CoursePlayerPlaylistComponent implements OnInit {
  @Input({ required: true }) course: CoursePlayerCourseDto;
  backLink$: Observable<string>;

  constructor(
    public urlBuilder: UrlBuilder,
    private courseContextService: CourseContextService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.backLink$ =
      this.courseContextService.getContext() === 'Course'
        ? of(this.urlBuilder.goToCourse(this.course.id))
        : this.activatedRoute.params.pipe(
            map(params => this.urlBuilder.goToCompanyCourse(params.companyId, this.course.id))
          );
  }

  trackByLessonId(index: number, lesson: CourseDetailsModuleDto): string {
    return lesson.id;
  }
}
