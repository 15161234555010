import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { environment } from '@env';
import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';

export interface CompanyDto {
  id: string;
  name: string;
  employeeCount: number;
  officeEmployeeCount: number;
  businessSector: string;
  createdAt: Date;
}

export interface UpdateCompanyDto {
  name: string;
  employeeCount: number;
  officeEmployeeCount: number;
  businessSector: string;
}

export interface DeleteCompanyDto {
  deletePhishingMailInformation: boolean;
}

@Injectable({ providedIn: 'root' })
export class CompanyService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #queryClient = injectQueryClient();
  #loadingDialog = inject(LoadingDialogService);

  private readonly _queryKey = 'companies';

  findCompany(id: string) {
    return this.#query({
      queryKey: [this._queryKey, id] as const,
      queryFn: () => this.#http.get<CompanyDto>(`${environment.apiUrl}${environment.apiRoutes.companies}/${id}`)
    });
  }

  updateInformation() {
    this.#loadingDialog.open(LoadingDialogComponent);
    return this.#mutation({
      mutationFn: (props: { id: string; dto: UpdateCompanyDto }) =>
        this.#http.put<string>(`${environment.apiUrl}${environment.apiRoutes.companies}/${props.id}`, props.dto),
      onSuccess: async (data, variables) => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data,
          afterClose: async () => {
            return true;
          }
        });
        await this.#queryClient.invalidateQueries({ queryKey: [this._queryKey, variables.id] });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }

  delete() {
    this.#loadingDialog.open(LoadingDialogComponent);
    return this.#mutation({
      mutationFn: (props: { id: string; dto: DeleteCompanyDto }) =>
        this.#http.delete<string>(`${environment.apiUrl}${environment.apiRoutes.companies}/${props.id}`, {
          body: props.dto
        }),
      onSuccess: async (data, variables) => {
        this.#loadingDialog.setData({
          data: LoadingDialogState.Success,
          content: data,
          afterClose: async () => {
            return true;
          }
        });
        await this.#queryClient.invalidateQueries({ queryKey: [this._queryKey, variables.id] });
      },
      onError: (error, _variables) => {
        const errorMessage = (error as HttpErrorResponse).error;

        if (errorMessage) {
          this.#loadingDialog.setData({
            data: LoadingDialogState.Error,
            content: (error as HttpErrorResponse).error
          });
        }
      }
    });
  }
}
