<awareness-center-resource-card
  data-testid="help-document-card"
  *ngIf="identityService.hasRole('Cyber-Verantwortlicher')"
  [description]="'help.document.adminConfiguration.description' | transloco"
  [title]="'help.document.adminConfiguration.title' | transloco"
  [url]="environment.resources.helpAdminConfiguration"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.privacy.description' | transloco"
  [title]="'help.document.privacy.title' | transloco"
  [url]="environment.urls.privacy"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.termsOfUse.description' | transloco"
  [title]="'help.document.termsOfUse.title' | transloco"
  [url]="environment.urls.termsOfUse"
></awareness-center-resource-card>
<awareness-center-resource-card
  data-testid="help-document-card"
  [description]="'help.document.scopeOfServices.description' | transloco"
  [title]="'help.document.scopeOfServices.title' | transloco"
  [url]="environment.urls.scopeOfServices"
></awareness-center-resource-card>
