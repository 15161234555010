import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-course-header',
  templateUrl: './course-details-header.component.html',
  styleUrls: ['./course-details-header.component.scss']
})
export class CourseDetailsHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() imageUrl: string;
  @Input() continueLink: string;
  @Input() isInProgress: boolean;
  buttonText: string;

  constructor(private router: Router, public urlBuilder: UrlBuilder) {}

  ngOnInit(): void {
    this.buttonText = this.getButtonText();
  }

  handleButtonClick(): void {
    this.router.navigateByUrl(this.continueLink);
  }

  private getButtonText(): string {
    if (this.isInProgress) {
      return 'Kurs fortsetzen';
    }
    return 'Kurs starten';
  }
}
