import {
  PasswordPolicyDto,
  UpdateDepartmentDto,
  UpdateEmailRequestDto,
  UpdateFirstNameDto,
  UpdateGenderDto,
  UpdateLastNameDto,
  UpdatePasswordDto
} from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDataDto } from '../../../../../../libs/models/src/lib/identity/user-data.dto';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  userData$: Observable<UserDataDto>;
  public passwordPolicy$: Observable<PasswordPolicyDto>;

  constructor(public urlBuilder: UrlBuilder, private identityService: IdentityService) {}

  ngOnInit(): void {
    this.userData$ = this.identityService.getUserData();
    this.passwordPolicy$ = this.identityService.getPasswordPolicy();
  }

  updateGender(updateGenderDto: UpdateGenderDto) {
    this.identityService.updateGender(updateGenderDto).subscribe();
  }

  updateFirstName(updateFirstNameDto: UpdateFirstNameDto) {
    this.identityService.updateFirstName(updateFirstNameDto).subscribe();
  }

  updateLastName(updateLastNameDto: UpdateLastNameDto) {
    this.identityService.updateLastName(updateLastNameDto).subscribe();
  }

  updateEmail(updateEmailRequestDto: UpdateEmailRequestDto) {
    this.identityService.sendUpdateEmailRequest(updateEmailRequestDto).subscribe();
  }

  updateDepartment(updateDepartmentDto: UpdateDepartmentDto) {
    this.identityService.updateDepartment(updateDepartmentDto).subscribe();
  }

  updatePassword(updatePasswordDto: UpdatePasswordDto) {
    this.identityService.updatePassword(updatePasswordDto).subscribe();
  }

  requestUserData(): void {
    this.identityService.requestUserData().subscribe();
  }
}
