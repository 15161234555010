<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - co-IT'"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/feedback-management.webp'"
      imgAlt="TODO"
      [link]="'./feedback-overview'"
      [title]="'administration.tabLabel.feedbackManagement' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/subscriptions-management.webp'"
      imgAlt="TODO"
      [link]="'./company-subscriptions'"
      [title]="'administration.tabLabel.subscriptions-management' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/phishing-mails-management.webp'"
      imgAlt="TODO"
      [link]="'./phishing-mails-management'"
      [title]="'administration.tabLabel.phishing-mails-management' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/quick-invitation.webp'"
      imgAlt="TODO"
      [link]="'./quick-invitation'"
      [title]="'administration.tabLabel.quickInvitation' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/admin-management.webp'"
      imgAlt="TODO"
      [link]="'./admin-management'"
      [title]="'administration.tabLabel.management' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/newsletter.webp'"
      imgAlt="TODO"
      [link]="'./newsletter'"
      [title]="'administration.tabLabel.newsletterManagement' | transloco"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
