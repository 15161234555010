import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../../identity/identity.service';
import { MatTab, MatTabContent, MatTabGroup } from '@angular/material/tabs';
import { AdministrationModule } from '../administration.module';
import { TranslocoPipe } from '@ngneat/transloco';
import { environment } from '@env';
import { LayoutsModule } from '../../layouts/layouts.module';
import { CompanyManagementAssekuradeurComponent } from '@app/administration/administration-assekuradeure/company-management-assekuradeur/company-management-assekuradeur.component';
import { CyberPortalModule } from '../../cyber-portal/cyber-portal.module';

@Component({
  selector: 'app-administration-assekuradeure',
  standalone: true,
  imports: [
    CommonModule,
    MatTab,
    AdministrationModule,
    MatTabGroup,
    TranslocoPipe,
    MatTabContent,
    LayoutsModule,
    CompanyManagementAssekuradeurComponent,
    CyberPortalModule
  ],
  templateUrl: './administration-assekuradeure.component.html',
  styleUrl: './administration-assekuradeure.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationAssekuradeureComponent {
  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService
  ) {}

  protected readonly environment = environment;
}
