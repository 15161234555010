import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { insuranceNumberRegexPattern } from '../../../utils/regex-patterns';
import { environment } from '@env';
import { TranslocoService } from '@ngneat/transloco';
import { IdentityService } from '../../../identity/identity.service';
import {CustomValidators} from "../../../identity/custom.validator";

@Component({
  selector: 'app-ransom-clause-initialization',
  templateUrl: './ransom-clause-initialization.component.html',
  styleUrls: ['./ransom-clause-initialization.component.scss']
})
export class RansomClauseInitializationComponent {
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private transloco: TranslocoService,
    private identityService: IdentityService
  ) {
    this.form = this.buildForm();
  }

  initialize() {
    const name = this.form.get('name').value;
    const policyHolderEmail = this.form.get('policyHolderEmail').value;
    const insuranceNumber = this.form.get('insuranceNumber').value;
    const companyName = this.form.get('companyName').value;
    const underwriterEmail = this.identityService.getUserEmail();

    const url = this.getRansomClauseUrl(insuranceNumber, policyHolderEmail, underwriterEmail, companyName);
    const body = this.buildEmailBody(name, url);
    const subject = this.transloco.translate('ransom-clause.email.subject');
    const mailToLink = this.buildMailToLink(policyHolderEmail, subject, body);

    window.open(mailToLink);
  }

  private getRansomClauseUrl(
    insuranceNumber: string,
    policyHolderEmail: string,
    underwriterEmail: string,
    companyName: string
  ): string {
    const encodedInsuranceNumber = encodeURIComponent(insuranceNumber);
    const encodedPolicyHolderEmail = encodeURIComponent(policyHolderEmail);
    const encodedUnderwriterEmail = encodeURIComponent(underwriterEmail);
    const encodedCompanyName = encodeURIComponent(companyName);

    return `${environment.baseUrl}/ransom-clause?insuranceNumber=${encodedInsuranceNumber}&policyHolderEmail=${encodedPolicyHolderEmail}&underwriterEmail=${encodedUnderwriterEmail}&companyName=${encodedCompanyName}`;
  }

  private buildMailToLink(email: string, subject: string, body: string): string {
    const encodedEmail = encodeURIComponent(email);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    return `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
  }

  private buildEmailBody(name: string, url: string): string {
    return this.transloco.translate('ransom-clause.email.body', { name, url });
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      insuranceNumber: ['', [Validators.required, Validators.pattern(insuranceNumberRegexPattern)]],
      policyHolderEmail: ['', [Validators.required, CustomValidators.email]],
      name: ['', [Validators.required]],
      companyName: ['', [Validators.required]]
    });
  }
}
