import { CompanyListDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatColumnDef,
  MatFooterCell,
  MatFooterRow,
  MatFooterRowDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
  MatTableModule
} from '@angular/material/table';
import { IdentityService } from '../../../identity/identity.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { randomElement } from '../../../utils/random-element';
import { TranslocoPipe } from '@ngneat/transloco';
import { DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { LayoutsModule } from '../../../layouts/layouts.module';

@Component({
  selector: 'app-company-management-assekuradeur',
  templateUrl: './company-management-assekuradeur.component.html',
  standalone: true,
  imports: [
    MatTable,
    TranslocoPipe,
    DecimalPipe,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatFooterCell,
    MatHeaderRowDef,
    MatRowDef,
    MatFooterRowDef,
    MatPaginator,
    MatRow,
    MatHeaderRow,
    MatFooterRow,
    MatSort,
    MatTableModule,
    MatSortHeader,
    MatPaginatorModule,
    NgIf,
    DatePipe,
    LayoutsModule
  ],
  styleUrls: ['./company-management-assekuradeur.component.scss']
})
export class CompanyManagementAssekuradeurComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  roles: string[];
  dataSource: MatTableDataSource<CompanyListDto> = new MatTableDataSource<CompanyListDto>();
  displayedColumns = [
    'name',
    'accountantName',
    'employeeCount',
    'officeEmployeeCount',
    'numberOfPendingRegistrations',
    'numberOfUsers',
    'businessSector',
    'package',
    'createdAt'
  ];

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.identityService.getCompanies().subscribe(companies => (this.dataSource.data = companies));
  }

  getCompanyCount(): number {
    return this.dataSource.data.length;
  }

  getTotalUserCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfUsers, 0);
  }

  getTotalEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.employeeCount, 0);
  }

  getTotalOfficeEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.officeEmployeeCount, 0);
  }

  getTotalPendingRegistrationsCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfPendingRegistrations, 0);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  protected readonly randomElement = randomElement;
}
