import { UserInvitationListDto } from '@ac/models';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-user-invitation-list-form',
  templateUrl: './user-invitation-list-form.component.html',
  styleUrls: ['./user-invitation-list-form.component.scss']
})
export class UserInvitationListFormComponent {
  bulkInviteUserListForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private identityService: IdentityService) {
    this.bulkInviteUserListForm = this.formBuilder.group({
      list: ['', [Validators.required]]
    });
  }

  onSubmitList(bulkInviteUserListForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (bulkInviteUserListForm.invalid) {
      return;
    }

    const userInvitationBulkListDto: UserInvitationListDto = {
      emails: bulkInviteUserListForm
        .get('list')
        .value.split(';')
        .map(email => email.trim())
    };

    this.identityService.inviteUserList(userInvitationBulkListDto).subscribe();
  }
}
