import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UrlBuilder} from "../../url-builder.service";
import {IdentityService} from "../../identity/identity.service";
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {AdministrationModule} from "../administration.module";
import {TranslocoPipe} from "@ngneat/transloco";
import { environment } from '@env';
import {LayoutsModule} from "../../layouts/layouts.module";
import {ColibriModule} from "@ac/colibri";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-administration-wv',
  standalone: true,
  imports: [CommonModule, MatTab, AdministrationModule, MatTabGroup, TranslocoPipe, MatTabContent, LayoutsModule, ColibriModule, MatFormField, MatInput, MatLabel, MatTable, MatSort, MatColumnDef, MatHeaderCell, MatCell, MatCellDef, MatHeaderCellDef, MatHeaderRow, MatRow, MatHeaderRowDef, MatRowDef],
  templateUrl: './administration-wv.component.html',
  styleUrl: './administration-wv.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationWvComponent implements AfterViewInit {
  constructor(
      public urlBuilder: UrlBuilder,
      public identityService: IdentityService
  ) {}
  displayedColumns: string[] = ['name', 'email'];
  dataSource = new MatTableDataSource([{name: "Master Assekuradeur GmbH", email: "info@master-assekuradeur.com"}, {name: "Assek AG", email: "info@assek.de"}, {name: "My Cyber KG", email: "contact@my-cyber.eu"}]);

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  protected readonly environment = environment;
}
