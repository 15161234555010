import { RequestAccessDto, UserLoginCommand } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-login-victor',
  templateUrl: './login-victor.component.html',
  styleUrls: ['./login-victor.component.scss']
})
export class LoginVictorComponent implements OnInit {
  environment = environment;

  constructor(
    private identityService: IdentityService,
    private router: Router,
    private urlBuilder: UrlBuilder,
    private loadingDialog: LoadingDialogService
  ) {}

  ngOnInit(): void {
    if (this.identityService.isLoggedIn()) {
      this.router.navigateByUrl(this.urlBuilder.goToHome());
    }
  }

  login(dto: UserLoginCommand): void {
    this.identityService.login(dto).subscribe(
      () => {
        this.router.navigateByUrl(this.urlBuilder.goToHome());
      },
      error => {
        this.loadingDialog.open(LoadingDialogComponent);
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: error
        });
      }
    );
  }

  requestAccessForCompany(dto: Partial<RequestAccessDto>): void {
    this.identityService.requestAccessForCompany(dto).subscribe();
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}
