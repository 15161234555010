import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../../identity/identity.service';
import { ReadCompanySubscriptionDto, SubscriptionsService } from '../company-subscriptions/subscriptions.service';
import { environment } from '@env';
import { ChangeAwarenessPageDialogComponent } from '../uses-cases/change-awareness-page/change-awareness-page-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { ConfirmMailConfigurationDialogComponent } from '../uses-cases/confirm-mail-configuration-dialog/confirm-mail-configuration-dialog.component';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-overview',
  templateUrl: './subscription-overview.component.html',
  styleUrl: './subscription-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionOverviewComponent {
  protected urlBuilder = inject(UrlBuilder);
  protected readonly environment = environment;
  protected defaultAwarenessPageRedirectUrl = environment.subscriptions.defaultAwarenessPageRedirectUrl;
  protected video: VideoDto = {
    captions: [
      {
        language: 'DE',
        label: 'Deutsch',
        source: environment.resources.helpVideoPricedPackagesFeaturesSubtitles
      }
    ],
    streamUrl: environment.resources.helpVideoPricedPackagesFeatures,
    downloadUrl: null
  };
  #companyId = inject(IdentityService).getCompanyId();
  #subscriptionService = inject(SubscriptionsService);
  protected subscription = this.#subscriptionService.findByCompany(this.#companyId).result;
  #dialog = inject(MatDialog);

  protected openChangeAwarenessPageDialog(subscription: ReadCompanySubscriptionDto) {
    this.#dialog.open(ChangeAwarenessPageDialogComponent, {
      data: subscription
    });
  }

  protected continuePhishing() {
    this.#dialog
      .open(ConfirmMailConfigurationDialogComponent)
      .afterClosed()
      .pipe(
        filter(confirmed => confirmed),
        tap(() => this.#subscriptionService.continuePhishing().mutate({ id: this.subscription().data.id }))
      )
      .subscribe();
  }

  protected pausePhishing() {
    this.#subscriptionService.pausePhishing().mutate({ id: this.subscription().data.id });
  }
}
