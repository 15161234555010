<form
  (ngSubmit)="onSubmit(userLoginForm, $event)"
  [formGroup]="userLoginForm"
  class="login-form"
  data-test="form-login"
>
  <mat-form-field
    appearance="outline"
    class="full-width"
  >
    <mat-label>{{ 'form.label.email' | transloco | renderHTML }}</mat-label>
    <input
      data-testid="input-login-email"
      data-test="input-login-email"
      formControlName="email"
      matInput
      type="email"
    />
    <mat-error *ngIf="userLoginForm.get('email').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
    <mat-error *ngIf="userLoginForm.get('email').errors?.email">{{ 'form.error.invalidEmail' | transloco }}</mat-error>
    <mat-error *ngIf="userLoginForm.get('email').errors?.username">{{ 'form.error.invalidUserName' | transloco }}</mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="full-width"
  >
    <mat-label>{{ 'form.label.password' | transloco }}</mat-label>
    <input
      [type]="passwordHide ? 'password' : 'text'"
      data-test="input-login-password"
      data-testid="input-login-password"
      formControlName="password"
      matInput
    />
    <button
      (click)="passwordHide = !passwordHide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="passwordHide"
      [tabIndex]="-1"
      mat-icon-button
      matSuffix
      type="button"
    >
      <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="userLoginForm.get('password').errors?.required">
      {{ 'form.error.required' | transloco }}
    </mat-error>
  </mat-form-field>
  <button
    [disabled]="userLoginForm.invalid"
    class="login-button"
    appearance="primary"
    data-test="button-login-login"
    data-testid="button-login"
    wvButton
  >
    {{ 'login.loginForm.submitButton' | transloco }}
  </button>
  <div class="links">
    <awareness-center-wv-chevron-link
      [text]="'login.loginForm.forgotPassword' | transloco"
      [url]="urlBuilder.goToPasswordReset()"
      appearance="default"
    ></awareness-center-wv-chevron-link>
    <awareness-center-wv-chevron-link
      (click)="openDialog()"
      [text]="'login.loginForm.noAccessLink' | transloco"
      appearance="default"
    ></awareness-center-wv-chevron-link>
  </div>
</form>
