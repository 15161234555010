import { NgModule } from '@angular/core';
import { SubscriptionOverviewComponent } from './subscription-overview/subscription-overview.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslocoPipe } from '@ngneat/transloco';
import { ColibriModule } from '@ac/colibri';
import { CurrencyPipe, DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { DateDistancePipe } from './subscription-overview/date-distance.pipe';
import { MatIconModule } from '@angular/material/icon';
import { DateDistanceToNowPipe } from './subscription-overview/date-distance-to-now.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PackageInfoHintComponent } from './package-info/package-info-hint.component';
import { SubscriptionEditPhishingConfigurationForm } from './subscription-edit-phishing-configuration-form/subscription-edit-phishing-configuration-form.component';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ChangeAwarenessPageDialogComponent } from './uses-cases/change-awareness-page/change-awareness-page-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    SubscriptionOverviewComponent,
    DateDistancePipe,
    DateDistanceToNowPipe,
    SubscriptionEditPhishingConfigurationForm
  ],
  imports: [
    MatDialogModule,
    MatLegacyProgressSpinnerModule,
    LayoutsModule,
    TranslocoPipe,
    ColibriModule,
    DatePipe,
    MatIconModule,
    CurrencyPipe,
    NgOptimizedImage,
    MatLegacyCheckboxModule,
    FormsModule,
    PackageInfoHintComponent,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    NgIf,
    MatLegacyButtonModule,
    MatLegacySlideToggleModule,

    ChangeAwarenessPageDialogComponent,
    MatLegacyTooltipModule
  ],
  exports: []
})
export class SubscriptionModule {}
