<table *ngIf="dataSource" [dataSource]="dataSource" mat-table matSort>
  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.companyName' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.name }}</td>
    <td *matFooterCellDef mat-footer-cell>Gesamtzahl: {{ getCompanyCount() | number }}</td>
  </ng-container>
  <ng-container matColumnDef="accountantName">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.accountantName' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>
      <a [href]="'mailto:' + company.accountantEmail">{{ company.accountantName }}</a>
    </td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="employeeCount">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.employeeCount' | transloco }}</th>
    <td *matCellDef="let company" mat-cell><div class="text-end">{{ company.employeeCount }}</div></td>
    <td *matFooterCellDef mat-footer-cell class="text-end"><div class="text-end">{{ getTotalEmployeeCount() | number }}</div></td>
  </ng-container>
  <ng-container matColumnDef="officeEmployeeCount">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.officeEmployeeCount' | transloco }}</th>
    <td *matCellDef="let company" mat-cell><div class="text-end">{{ company.officeEmployeeCount }}</div></td>
    <td *matFooterCellDef mat-footer-cell><div class="text-end">{{ getTotalOfficeEmployeeCount() | number }}</div></td>
  </ng-container>
  <ng-container matColumnDef="businessSector">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.businessSector' | transloco }}</th>
    <td *matCellDef="let company" mat-cell>{{ company.businessSector }}</td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="numberOfUsers">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.numberOfUsers' | transloco }}</th>
    <td *matCellDef="let company" mat-cell><div class="text-end">{{ company.numberOfUsers }}</div></td>
    <td *matFooterCellDef mat-footer-cell><div class="text-end">{{ getTotalUserCount() | number }}</div></td>
  </ng-container>
  <ng-container matColumnDef="numberOfPendingRegistrations">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>
      {{ 'tableColumn.numberOfPendingRegistrations' | transloco }}
    </th>
    <td *matCellDef="let company" mat-cell><div class="text-end">{{ company.numberOfPendingRegistrations }}</div></td>
    <td *matFooterCellDef mat-footer-cell><div class="text-end">{{ getTotalPendingRegistrationsCount() | number }}</div></td>
  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'tableColumn.createdAt' | transloco }}</th>
    <td *matCellDef="let company" mat-cell><div class="text-end">{{ company.createdAt | date: 'dd.MM.yyyy' }}</div></td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="package">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Paket</th>
    <td *matCellDef="let company" mat-cell><div>{{randomElement(['Basis', 'Profi', 'Experte'])}}</div></td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>
  <ng-container matColumnDef="assekuradeur">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Assekuradeur</th>
    <td *matCellDef="let company" mat-cell><div>{{randomElement(['Assekuradeur GmbH', 'Assek AG', 'Insurance Agent GmbH'])}}</div></td>
    <td *matFooterCellDef mat-footer-cell></td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  <tr *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
