import { CourseOverviewCourseDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { UrlBuilder } from '../../url-builder.service';
import { CourseApiService } from '../course-api.service';
import { CompanyCoursesApiService } from '../company-courses-api.service';
import { IdentityService } from '../../identity/identity.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-course-overview',
  templateUrl: './course-overview.component.html',
  styleUrls: ['./course-overview.component.scss']
})
export class CourseOverviewComponent implements OnInit {
  courses$: Observable<Array<CourseOverviewCourseDto>>;
  companyCourses$: Observable<Array<CourseOverviewCourseDto>>;

  constructor(
    private courseApiService: CourseApiService,
    private companyCoursesApiService: CompanyCoursesApiService,
    public urlBuilder: UrlBuilder,
    protected identity: IdentityService
  ) {}

  ngOnInit(): void {
    this.courses$ = this.courseApiService.getCourseOverview();
    this.companyCourses$ = this.companyCoursesApiService.getCourseOverview(this.identity.getCompanyId());
  }
}
