<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Agenturen'"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="w-full"
    color="accent"
  >
    <mat-tab
      [label]="'Beratungsgespräch'"
    >
      <ng-template matTabContent>
          <p>Im folgenden Video sehen Sie ein fiktives Beratungsgespräch mit einem potenziellen Versicherungsnehmer zur Cyber-Police.<br>Nutzen Sie dieses gerne als Inspiration für eigene Beratungsgespräche mit Ihren Interessenten.</p>
        <app-videoplayer
          class="shadow-xl w-1/2"
          [autoplay]="false"
          [resetOnEnd]="true"
          [video]="video"
          data-testid="help-video-player"
        ></app-videoplayer>
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Werbematerialien'"
    >
      <ng-template matTabContent>
        <div class="grid grid-cols-[repeat(auto-fill,_minmax(260px,_1fr))] gap-4 p-2">
          <awareness-center-resource-card
            data-testid="help-document-card"
            [description]="'Anleitung über den gesamten Funktionsumfang des Cyber Portals.'"
            [title]="'Benutzerhandbuch'"
            [url]="environment.resources.helpAdminConfiguration"
          ></awareness-center-resource-card>
          <awareness-center-resource-card
            data-testid="help-document-card"
            [description]="'Die wichtigsten Inhalte und Funktionen des Cyber Portals auf einen Blick.'"
            [title]="'Schnelleinstieg'"
            [url]="environment.urls.privacy"
          ></awareness-center-resource-card>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
