<mat-expansion-panel
  (closed)="opened = false"
  (opened)="opened = true"
  [disabled]="active"
  [expanded]="active || opened"
  [hideToggle]="active"
>
  <mat-expansion-panel-header>
    <div
      class="player-playlist-module"
      data-testid="player-playlist-module"
    >
      <div class="player-playlist-module-number">
        <circle-progress
          [animation]="true"
          [innerStrokeColor]="innerStrokeColor"
          [innerStrokeWidth]="13"
          [outerStrokeColor]="'var(--secondary)'"
          [outerStrokeWidth]="13"
          [percent]="module.progress"
          [responsive]="true"
          [showInnerStroke]="true"
          [showSubtitle]="false"
          [showUnits]="false"
          [showZeroOuterStroke]="true"
          [space]="-13"
          [titleColor]="'var(--on-background)'"
          [titleFontSize]="100"
          [title]="position.toString()"
        ></circle-progress>
      </div>
      <div
        class="player-playlist-module-title"
        data-testid="player-module-title"
      >
        <div [title]="module.title">{{ module.title }}</div>
      </div>
      <div class="player-playlist-module-duration">
        <div class="player-playlist-module-duration-icon">
          <mat-icon inline="true">schedule</mat-icon>
        </div>
        <div class="player-playlist-module-duration-text">{{ module.duration | duration }}</div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="player-playlist-module-body">
    <app-course-player-playlist-lesson
      *ngFor="let lesson of module.lessons"
      [lesson]="lesson"
    ></app-course-player-playlist-lesson>
  </div>
</mat-expansion-panel>
