<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-card-header">
    <mat-card-title><h2>Ihre Übersicht</h2></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <hr />
    <app-course-stats-progress [courses]="courses"></app-course-stats-progress>
    <!--    <hr>-->
    <!--    <app-course-stats-suggestion [courses]="courses"></app-course-stats-suggestion>-->
  </mat-card-content>
</mat-card>
