import {Component, OnInit, signal} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {forkJoin} from 'rxjs';
import {first, take, tap} from 'rxjs/operators';
import {LoadingDialogState} from '../../layouts/loading-dialog/loading-dialog-state.enum';
import {LoadingDialogComponent} from '../../layouts/loading-dialog/loading-dialog.component';
import {LoadingDialogService} from '../../layouts/loading-dialog/loading-dialog.service';
import {UrlBuilder} from '../../url-builder.service';
import {IdentityService} from '../identity.service';

@Component({
  selector: 'app-user-invitation-self-delete-landing-page',
  templateUrl: 'user-invitation-self-delete-landing-page.html',
  styleUrls: ['user-invitation-self-delete-landing-page.scss']
})
export class UserInvitationSelfDeleteLandingPageComponent implements OnInit {
  captchaSolved = false;
  private userId = '';
  private token = '';
  protected readonly acceptedRecaptcha = signal(false);

  constructor(
    private route: ActivatedRoute,
    private loadingDialog: LoadingDialogService,
    private router: Router,
    private urlBuilder: UrlBuilder,
    private identityService: IdentityService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = params.userId;
      this.token = encodeURIComponent(params.token);

      if (!this.userId || !this.token) {
        forkJoin({
          title: this.translocoService.selectTranslate('error.failure.title').pipe(take(1)),
          description: this.translocoService.selectTranslate('userProfile.selfDelete.error').pipe(take(1))
        })
          .pipe(
            first(),
            tap(translations => {
              this.loadingDialog.open(LoadingDialogComponent, {
                title: translations.title,
                content: translations.description,
                data: LoadingDialogState.Error,
                afterClose: () => this.router.navigateByUrl(this.urlBuilder.goToLogin())
              });
            })
          )
          .subscribe();
      }
    });
  }

  delete() {
    this.identityService.selfDeleteUser(this.userId, this.token).subscribe();
  }

  cancel() {
    this.router.navigateByUrl(this.urlBuilder.goToLogin());
  }

  solveCaptcha() {
    this.captchaSolved = true;
  }
}
