<ng-container *transloco="let t">
  <ng-container *ngIf="data.underwriterEmail; else error">
    <awareness-center-plain-stepper #stepper>
      <cdk-step>
        <h2>{{t('ransom-clause.intro.heading')}}</h2>
        <app-ransom-clause-intro (next)="nextStep()" [(data)]="data"></app-ransom-clause-intro>
      </cdk-step>
      <cdk-step>
        <h2>{{t('ransom-clause.disclaimer.heading')}}</h2>
        <app-text-confirmation
          (confirmed)="nextStep()"
          [text]="t('ransom-clause.disclaimer.text')"
        ></app-text-confirmation>
      </cdk-step>
      <cdk-step>
        <h2>{{t('ransom-clause.extended-clause.heading')}}</h2>
        <app-text-confirmation
          (confirmed)="nextStep()"
          [text]="t('ransom-clause.extended-clause.text', {url: environment.assets.ransomClause.src})"
        ></app-text-confirmation>
      </cdk-step>
      <cdk-step>
        <h2>{{t('ransom-clause.context.heading')}}</h2>
        <app-text-confirmation
          (confirmed)="nextStep()"
          [text]="t('ransom-clause.context.text')"
        ></app-text-confirmation>
      </cdk-step>
      <cdk-step #video_step>
        <h2>{{t('ransom-clause.video.heading')}}</h2>
        <app-video-confirmation
          (confirmed)="nextStep()"
          *ngIf="stepper.selected === video_step"
          [confirmationLabel]="t('ransom-clause.video.confirmation-label')"
          [description]="t('ransom-clause.video.description')"
          [heading]="t('ransom-clause.video.description-heading')"
          [video]="video"
        ></app-video-confirmation>
      </cdk-step>
      <cdk-step>
        <h2>{{t('ransom-clause.exam.heading')}}</h2>
        <ac-exam (complete)="completeExam($event)" [configuration]="examConfiguration"></ac-exam>
      </cdk-step>
      <cdk-step>
        <h2>{{t('ransom-clause.result.heading')}}</h2>
        <app-ransom-clause-test-passed
          *ngIf="ransomClauseTestResult.passed"
          [data]="data"
        ></app-ransom-clause-test-passed>
        <app-ransom-clause-test-failed
          *ngIf="!ransomClauseTestResult.passed"
          [correctTasks]="ransomClauseTestResult.correctAnswers"
          [requiredCorrectTasks]="examConfiguration.general.requiredCorrectTasks"
        ></app-ransom-clause-test-failed>
      </cdk-step>
    </awareness-center-plain-stepper>
  </ng-container>
  <ng-template #error>{{t('ransom-clause.link-invalid')}}</ng-template>
</ng-container>
