import { PasswordPolicyDto } from '@ac/models';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-password-policy-dialog',
  templateUrl: './password-policy-dialog.component.html',
  styleUrls: ['./password-policy-dialog.component.scss']
})
export class PasswordPolicyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PasswordPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordPolicyDto
  ) {}
}
