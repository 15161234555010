<wv-card>
  <div class="flex justify-between items-center">
    <h3 class="p-0">NIS2-Check</h3>
    <ac-partner-button
      [confirmMessage]="'Sie verlassen unser Portal und werden auf die externe Website unseres Partners Vogel & Partner weitergeleitet. Bitte beachten Sie, dass die dort angebotenen Leistungen unabhängig von unserem Portal sind und nicht zu unserem Angebot gehören.'"
      [href]="'https://www.vogel-partner.eu/'"
      [name]="'Vogel & Partner'"
      [target]="'_blank'"
      [tooltip]="'Dieser Check wurde in Zusammenarbeit mit unserem Partner Vogel & Partner erstellt.'"
    >
    </ac-partner-button>
  </div>
  <h4>Sektoren gem. Anhang I</h4>
  <p>Erbringt Ihr Unternehmen Leistungen in einem der folgenden Sektoren oder übt Ihr Unternehmen eine Tätigkeit aus,
    die in einen der folgenden Sektoren fällt?</p>

  <div>
    <mat-list>
      <mat-checkbox>Energie</mat-checkbox>
      <mat-checkbox>Transport und Verkehr</mat-checkbox>
      <mat-checkbox>Finanzwesen</mat-checkbox>
      <mat-checkbox>Gesundheit</mat-checkbox>
      <mat-checkbox>Wasser</mat-checkbox>
      <mat-checkbox>Digitale Infrastruktur</mat-checkbox>
      <mat-checkbox>Weltraum</mat-checkbox>
      <mat-checkbox>Einrichtungen der Bundesverwaltung</mat-checkbox>
      <mat-checkbox>Sozialversicherungsträger sowie Grundsicherung für Arbeitssuchende</mat-checkbox>
      <mat-checkbox>Nein</mat-checkbox>
    </mat-list>
  </div>

  <button
    appearance="secondary"
    wvButton
  >
    Weiter
  </button>
</wv-card>
