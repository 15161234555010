import { Component, OnInit } from '@angular/core';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';

type InfoVideo = [url: string, translocoKey: string, video: VideoDto];

@Component({
  selector: 'app-info-videos',
  templateUrl: './info-videos.component.html',
  styleUrls: ['./info-videos.component.scss']
})
export class InfoVideosComponent implements OnInit {
  infoVideo: InfoVideo;
  infoVideos: InfoVideo[] = [
    [
      'agentur',
      'help.video.agency.title',
      {
        streamUrl: environment.resources.helpVideoAgencies,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoAgenciesSubtitles
          }
        ],
        downloadUrl: null
      }
    ],
    [
      'funktionen-cyber-verantwortlicher',
      'help.video.accountantFeatures.title',
      {
        streamUrl: environment.resources.helpVideoAccountantFeatures,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoAccountantFeaturesSubtitles
          }
        ],
        downloadUrl: null
      }
    ],
    [
      'funktionen-kostenpflichtige-pakete',
      'help.video.pricedPackages.title',
      {
        streamUrl: environment.resources.helpVideoPricedPackagesFeatures,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoPricedPackagesFeaturesSubtitles
          }
        ],
        downloadUrl: null
      }
    ],
    [
      'funktionen-benutzer',
      'help.video.userFeatures.title',
      {
        streamUrl: environment.resources.helpVideoUserFeatures,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoUserFeaturesSubtitles
          }
        ],
        downloadUrl: null
      }
    ],
    [
      'registrierung-cyber-verantwortlicher',
      'help.video.accountantRegistration.title',
      {
        streamUrl: environment.resources.helpVideoAccountantRegistration,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoAccountantRegistrationSubtitles
          }
        ],
        downloadUrl: null
      }
    ],
    [
      'registrierung-benutzer',
      'help.video.userRegistration.title',
      {
        streamUrl: environment.resources.helpVideoUserRegistration,
        captions: [
          {
            label: 'Deutsch',
            language: 'DE',
            source: environment.resources.helpVideoUserRegistrationSubtitles
          }
        ],
        downloadUrl: null
      }
    ]
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private urlBuilder: UrlBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      const videoParam = params.video;

      this.infoVideo = this.infoVideos.filter(infoVideo => infoVideo[0] === videoParam)[0];

      if (!this.infoVideo) {
        this.router.navigateByUrl(this.urlBuilder.goToHome());
      }
    });
  }
}
