export function extractPassword(sentence: string): string {
  return sentence
    .split(' ')
    .map(word =>
      concatExtractions(
        extractFirstCharacterOrEmpty(word),
        extractNumberOrEmpty(word),
        extractSpecialCharactersAndCharacterAfterOrEmpty(word)
      )
    )
    .join('');
}

function concatExtractions(...words: string[]): string {
  return words.filter(word => !!word).join('');
}

export function extractFirstCharacterOrEmpty(word: string): string {
  return /^[a-zA-ZöäüÖÄÜ]/.test(word) ? word[0] : '';
}

export function extractSpecialCharactersAndCharacterAfterOrEmpty(word: string): string {
  const alphaNumericPattern = /[A-Za-z0-9öüäÖÄÜ\s]/g;

  let match = '';

  let specialCharacters = word.replace(alphaNumericPattern, '');

  specialCharacters = specialCharacters.replace('ß', '');
  [...specialCharacters].forEach(character => {
    const index = word.indexOf(character);

    match += character;

    if (index !== word.length - 1 && !specialCharacters.includes(word[index + 1])) {
      match += word[index + 1];
    }

    word = word.slice(0, index) + word.slice(index + 1);
  });

  return match;
}

export function extractNumberOrEmpty(word: string): string {
  const numericPattern = /^\d+/;
  const [numeric] = word.match(numericPattern) || [''];

  return numeric || '';
}
