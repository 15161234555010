<wv-card [title]="'Benutzer'">
  <form [formGroup]="userForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
      <input formControlName="email" matInput type="email" />
      <mat-error *ngIf="userForm.get('email').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
      <mat-error *ngIf="userForm.get('email').errors?.email">{{ 'form.error.invalidEmail' | transloco }}</mat-error>
      <mat-error *ngIf="userForm.get('email').errors?.username">{{ 'form.error.invalidUserName' | transloco }}</mat-error>
    </mat-form-field>
  </form>
  <div class="actions">
    <button (click)="deleteUser()" [disabled]="userForm.invalid" appearance="secondary" wvButton>
      {{ 'administration.userManagement.deleteUserButton' | transloco }}
    </button>
  </div>
</wv-card>
