import sha1 from 'js-sha1';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToolkitApiService } from '../toolkit-api.service';
import { tap } from 'rxjs/operators';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-password-check',
  templateUrl: './password-check.component.html',
  styleUrls: ['./password-check.component.scss']
})
export class PasswordCheckComponent {
  passwordCheckForm: UntypedFormGroup;
  passwordHide = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toolkitApi: ToolkitApiService,
    private loadingDialog: LoadingDialogService,
    private transloco: TranslocoService
  ) {
    this.passwordCheckForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
  }

  checkPassword(): void {
    const password = this.passwordCheckForm.get('password').value;

    const hash = sha1(password).toUpperCase();
    const hashPrefix = hash.substring(0, 5);
    const hashSuffix = hash.substring(5);

    this.toolkitApi
      .checkPassword(hashPrefix)
      .pipe(
        tap({
          next: result => {
            const leakCount = this.getPasswordLeakCount(result, hashSuffix);

            this.loadingDialog.setData({
              data: LoadingDialogState.Success,
              content:
                leakCount === 0
                  ? this.transloco.translate('toolkit.passwordCheck.no-leak-found')
                  : this.transloco.translate('toolkit.passwordCheck.leak-found', {
                      leakCount: leakCount.toLocaleString('de-DE')
                    })
            });
          }
        })
      )
      .subscribe();
  }

  private getPasswordLeakCount(result: { [hashSuffix: string]: number }, hashSuffix: string): number {
    return result[hashSuffix] ?? 0;
  }
}
