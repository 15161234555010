import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {VideoDto} from "../../../../../../../libs/models/src/lib/course-player/video.dto";
import { environment } from '@env';
import {LayoutsModule} from "../../../layouts/layouts.module";

@Component({
  selector: 'app-consultation-agenturen',
  standalone: true,
  imports: [CommonModule, ColibriModule, LayoutsModule],
  templateUrl: './consultation-agenturen.component.html',
  styleUrl: './consultation-agenturen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsultationAgenturenComponent {
  protected readonly environment = environment;
  video: VideoDto = {
    streamUrl: environment.resources.helpVideoAgencies,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoAgenciesSubtitles
      }
    ],
    downloadUrl: null
  };
}
