import { FileIcon, HarmfulFilesRemovalTask, TaskCompletion, TaskComponent } from '@ac/exam';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ac-harmful-files-removal',
  templateUrl: './harmful-files-removal.component.html',
  styleUrls: ['./harmful-files-removal.component.scss']
})
export class HarmfulFilesRemovalComponent implements TaskComponent<HarmfulFilesRemovalTask> {
  configuration: HarmfulFilesRemovalTask;
  desktopFileIcons: FileIcon[] = [];
  binFileIcons: FileIcon[] = [];
  formSubmitted = false;
  private complete$$ = new Subject<TaskCompletion>();

  complete(): Observable<TaskCompletion> {
    return this.complete$$.asObservable();
  }

  prepare(configuration: HarmfulFilesRemovalTask): void {
    this.configuration = configuration;
    this.desktopFileIcons = [...this.configuration.icons];
  }

  moveToBin(event: CdkDragDrop<FileIcon[]>) {
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  }

  submitAnswer(): void {
    this.formSubmitted = true;

    if (this.isCorrect()) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({ isCorrect: false });
    }
  }

  private isCorrect(): boolean {
    const x =
      this.configuration.solution.length === this.binFileIcons.length &&
      this.configuration.solution.every(solution =>
        this.binFileIcons.some(icon => icon.imageUrl === solution.imageUrl)
      );

    return x;
  }
}
