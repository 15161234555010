<awareness-center-statistic-card heading="Ihre durchgeführten Webinare"
                                 text="Analysieren Sie die Teilnahmequote gemessen an den Registrierungen zum Webinar.">
  <div class="self-start w-full grid grid-cols-3" statistic>
    <div class="text-center">
      <h4>ChatGTP & Compliance</h4>
      <awareness-center-percentage-animation
        class="p-8"
        responsive="true"
        [animation]="false"
        [percentage]="kurse1()"
      ></awareness-center-percentage-animation>
    </div>

    <div class="text-center">
      <h4>DSGVO im Gesundheitswesen</h4>
      <awareness-center-percentage-animation
        class="p-8"
        responsive="true"
        [animation]="false"
        [percentage]="kurse2()"
      ></awareness-center-percentage-animation>
    </div>

    <div class="text-center">
      <h4>Auftragsdatenvereinbarung</h4>
      <awareness-center-percentage-animation
        class="p-8"
        responsive="true"
        [animation]="false"
        [percentage]="kurse3()"
      ></awareness-center-percentage-animation>
    </div>
  </div>
</awareness-center-statistic-card>

