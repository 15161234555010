import { createTaskOptionLabelsOnly, ExaminationConfiguration } from '@ac/exam';

export const course3Exam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 2 },
  tasks: [
    {
      type: 'multiple-choice',
      task: {
        heading: 'Bestandteile sicherer Passwörter',
        description: `
Ihre IT-Abteilung ordnet unternehmensweit an, neue Passwörter zu vergeben. Welche Merkmale sollten sichere Passwörter aufweisen?<br><br>
Wählen Sie zwei richtige Antworten:
`,
        options: createTaskOptionLabelsOnly(
          'Mindestens 6 Zeichen',
          'Zahlenfolgen verwenden',
          'Keine Namen',
          'Sonderzeichen verwenden',
          'Keine Groß- & Kleinbuchstaben',
          'Wörter aus dem Duden'
        ),
        solution: createTaskOptionLabelsOnly('Sonderzeichen verwenden', 'Keine Namen'),
        summary: {
          wrong:
            'Ein sicheres Passwort sollte immer mindestens 16 Zeichen enthalten sowie Sonderzeichen und  Groß- & Kleinbuchstaben. Vermeiden Sie für ein sicheres Passwort Namen, Zahlenfolgen sowie Wörter aus dem Duden.',
          correct: 'Super, Sie haben die korrekten Möglichkeiten ausgewählt.'
        }
      }
    },
    {
      type: 'question-and-answer',
      task: {
        heading: 'Merktechnik trainieren',
        description: `
          Aufgrund einer Änderung der Passwort-Richtlinien bei Xing können Sie Ihr altes Passwort nicht mehr verwenden. Daher müssen Sie sich ein neues Passwort erstellen.<br>
          Nutzen Sie hierzu die gelernte Passwort-Merktechnik.<br><br>
          In diesem Fall unterstützen wir Sie mit einem vorgegebenen Satz. Bitte leiten Sie anhand der gelernten Merktechnik aus dem Satz das Passwort ab:
          <p>
            <b>Ich heiße Martin Klein & ich habe die 4 Klasse der Ludwig Uhland Grundschule besucht.</b>
          </p>
        `,
        solution: 'IhMK&ihd4KdLUGb.',
        summary: {
          wrong: 'Das abgeleitete Passwort lautet: <i>IhMK&ihd4KdLUGb.</i>'
        }
      }
    },
    {
      type: 'question-and-answer',
      task: {
        heading: 'Merktechnik trainieren',
        description: `
          Aufgrund einer Änderung der Passwort-Richtlinien bei Xing können Sie Ihr altes Passwort nicht mehr verwenden. Daher müssen Sie sich ein neues Passwort erstellen.<br>
          Nutzen Sie hierzu die gelernte Passwort-Merktechnik.<br><br>
          In diesem Fall unterstützen wir Sie mit einem vorgegebenen Satz. Bitte leiten Sie anhand der gelernten Merktechnik aus dem Satz das Passwort ab:
          <p>
            <b>Mein Name ist Florian Bool & ich wohne seit 6 Jahren in Karlsruhe.</b>
          </p>
        `,
        solution: 'MNiFB&iws6JiK.',
        summary: {
          wrong: 'Das abgeleitete Passwort lautet: <i>MNiFB&iws6JiK.</i>'
        }
      }
    },
    {
      type: 'question-and-answer',
      task: {
        heading: 'Merktechnik trainieren',
        description: `
          Aufgrund einer Änderung der Passwort-Richtlinien bei Xing können Sie Ihr altes Passwort nicht mehr verwenden. Daher müssen Sie sich ein neues Passwort erstellen.<br>
          Nutzen Sie hierzu die gelernte Passwort-Merktechnik.<br><br>
          In diesem Fall unterstützen wir Sie mit einem vorgegebenen Satz. Bitte leiten Sie anhand der gelernten Merktechnik aus dem Satz das Passwort ab:
          <p>
            <b>Mein Name ist Annette Burghart & ich bin seit 8 Jahren Beamte.</b>
          </p>
        `,
        solution: 'MNiAB&ibs8JB.',
        summary: {
          wrong: 'Das abgeleitete Passwort lautet: <i>MNiAB&ibs8JB.</i>'
        }
      }
    },
    {
      type: 'password-memorization',
      task: {
        heading: 'Merktechnik trainieren',
        description: `
<p>Aufgrund einer Änderung der Passwort-Richtlinien bei Xing können Sie Ihr altes Passwort nicht mehr verwenden. Daher müssen Sie sich ein neues Passwort erstellen.<br>
         Nutzen Sie hierzu die gelernte Passwort-Merktechnik.</p>
         Überlegen Sie sich zunächst einen Satz, den Sie sich gut merken können, und geben Sie ihn in folgende Textbox ein.
         <br><br>
Verwenden Sie in dem Satz:
         <ul>
           <li>wenigstens 10 Wörter mit Groß- und Kleinschreibung,</li>
           <li>Zahlen</li>
           <li>und mindestens 1 Satzzeichen.</li>
         </ul>
       `
      }
    },
    {
      type: 'left-or-right-choice',
      task: {
        heading: 'Sichere Passwörter zuordnen',
        description: `
          Sie haben sich einen Passwort-Tresor erstellt und dort alle Ihre Passwörter hinterlegt. Welche der Passwörter sind zu schwach und sollten geändert werden?<br><br>
          Ziehen Sie hierzu alle Passwörter per Drag & Drop in die entprechenden Spalten.
        `,
        headings: {
          center: 'Auswahl',
          left: 'Passwort unsicher',
          right: 'Passwort sicher'
        },
        options: createTaskOptionLabelsOnly(
          'Windows10Pro',
          'Pfauenfedern',
          'X17l24!dI%fhg2sW.',
          'AbCdEfg123456789',
          'rbCHmzmJdaUS',
          'mA_!B%6',
          'P4s5!w0rt',
          'Abschluss2020'
        ),
        solution: {
          left: createTaskOptionLabelsOnly(
            'Windows10Pro',
            'Pfauenfedern',
            'AbCdEfg123456789',
            'mA_!B%6',
            'rbCHmzmJdaUS',
            'P4s5!w0rt',
            'Abschluss2020'
          ),
          right: createTaskOptionLabelsOnly('X17l24!dI%fhg2sW.')
        },
        summary: {
          wrong: `
            Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
              <li>Passwort 1: Unsicher, da es sich um einen bekannten Produktnamen handelt.</li>
              <li>Passwort 2: Unsicher, da es sich um ein reales Wort handelt.</li>
              <li>Passwort 3: Ist das einzige sichere Passwort, da hier sämtliche Sicherheits-Anforderungen erfüllt werden.</li>
              <li>Passwort 4: Unsicher, da klar erkennbare Zeichenfolgen enthalten sind.</li>
              <li>Passwort 5: Unsicher, da nur Buchstaben verwendet werden.</li>
              <li>Passwort 6: Unsicher, da nur 7 Zeichen verwendet werden.</li>
              <li>Passwort 7: Unsicher, da nur 9 Zeichen verwendet werden.</li>
              <li>Passwort 8: Unsicher, da es sich aus einem Wort und einer Zahlenfolge zusammensetzt.</li>
            </ul>
            `
        }
      }
    },
    {
      type: 'left-or-right-choice',
      task: {
        heading: 'Sichere Passwörter zuordnen',
        description: `
          Sie haben sich einen Passwort-Tresor erstellt und dort alle Ihre Passwörter hinterlegt. Welche der Passwörter sind zu schwach und sollten geändert werden?<br><br>
          Ziehen Sie hierzu alle Passwörter per Drag & Drop in die entprechenden Spalten.
        `,
        headings: {
          center: 'Auswahl',
          left: 'Passwort unsicher',
          right: 'Passwort sicher'
        },
        options: createTaskOptionLabelsOnly(
          'Microsoft-Teams',
          'Baumschule',
          'Xx2_hA%&s2.!23X.',
          'XyZ1234567',
          'ajMmdKtzYca',
          'zt2Y%.',
          'P4s5.W0r1',
          'Gehalt2021'
        ),
        solution: {
          left: createTaskOptionLabelsOnly(
            'Microsoft-Teams',
            'Baumschule',
            'XyZ1234567',
            'ajMmdKtzYca',
            'zt2Y%.',
            'P4s5.W0r1',
            'Gehalt2021'
          ),
          right: createTaskOptionLabelsOnly('Xx2_hA%&s2.!23X.')
        },
        summary: {
          wrong: `
            Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
              <li>Passwort 1: Unsicher, da es sich um einen bekannten Produktnamen handelt.</li>
              <li>Passwort 2: Unsicher, da es sich um ein reales Wort handelt.</li>
              <li>Passwort 3: Ist das einzige sichere Passwort, da hier sämtliche Sicherheits-Anforderungen erfüllt werden.</li>
              <li>Passwort 4: Unsicher, da klar erkennbare Zeichenfolgen enthalten sind.</li>
              <li>Passwort 5: Unsicher, da nur Buchstaben verwendet werden.</li>
              <li>Passwort 6: Unsicher, da nur 7 Zeichen verwendet werden.</li>
              <li>Passwort 7: Unsicher, da nur 9 Zeichen verwendet werden.</li>
              <li>Passwort 8: Unsicher, da es sich aus einem Wort und einer Zahlenfolge zusammensetzt.</li>
            </ul>
            `
        }
      }
    }
  ]
};
