import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[clUrl]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UrlValidatorDirective,
      multi: true
    }
  ]
})
export class UrlValidatorDirective implements Validator {
  #urlIsValid = null;
  #urlValidationError = { url: true };

  validate(control: AbstractControl): ValidationErrors | null {
    try {
      new URL(control.value);
      return this.#urlIsValid;
    } catch {
      return this.#urlValidationError;
    }
  }
}
