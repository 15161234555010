import { Component, OnInit } from '@angular/core';
import { VideoDto } from '../../../../../../libs/models/src/lib/course-player/video.dto';
import { environment } from '@env';
import { IdentityService } from '../../identity/identity.service';

@Component({
  selector: 'app-help-videos',
  templateUrl: './help-videos.component.html',
  styleUrls: ['./help-videos.component.scss']
})
export class HelpVideosComponent implements OnInit {
  currentVideo: VideoDto;
  videos = new Map<VideoDto, string>();
  videoFeaturesAccountant: VideoDto = {
    streamUrl: environment.resources.helpVideoAccountantFeatures,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoAccountantFeaturesSubtitles
      }
    ],
    downloadUrl: null
  };
  videoAgencies: VideoDto = {
    streamUrl: environment.resources.helpVideoAgencies,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoAgenciesSubtitles
      }
    ],
    downloadUrl: null
  };
  videoFeaturesUser: VideoDto = {
    streamUrl: environment.resources.helpVideoUserFeatures,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoUserFeaturesSubtitles
      }
    ],
    downloadUrl: null
  };
  videoRegistrationAccountant: VideoDto = {
    streamUrl: environment.resources.helpVideoAccountantRegistration,
    captions: [
      {
        language: 'DE',
        label: 'Deutsch',
        source: environment.resources.helpVideoAccountantRegistrationSubtitles
      }
    ],
    downloadUrl: null
  };
  videoRegistrationUser: VideoDto = {
    streamUrl: environment.resources.helpVideoUserRegistration,
    captions: [
      {
        label: 'Deutsch',
        language: 'DE',
        source: environment.resources.helpVideoUserRegistrationSubtitles
      }
    ],
    downloadUrl: null
  };

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    //todo: Wann soll das Video angezeigt werden?
    //this.videos.set(this.videoAgencies, 'Was ist das Cyber Portal?');

    if (this.identityService.hasRole('Cyber-Verantwortlicher')) {
      this.videos.set(this.videoFeaturesAccountant, 'Funktionen für Cyber-Verantwortliche');
      this.videos.set(this.videoRegistrationUser, 'Registrierung eines Benutzers');
    }

    if (!this.identityService.hasRole('Cyber-Verantwortlicher')) {
      this.videos.set(this.videoFeaturesUser, 'Funktionen für Benutzer');
    }

    this.selectVideo(this.videos.keys().next().value);
  }

  selectVideo(video: VideoDto) {
    this.currentVideo = video;
  }
}
