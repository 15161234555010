import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CircleProgressOptions, NgCircleProgressModule } from 'ng-circle-progress';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_SETTINGS, RecaptchaLoaderOptions, RecaptchaSettings } from 'ng-recaptcha';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '@env';
import { AdministrationModule } from './administration/administration.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CmsModule } from './cms/cms.module';
import { cookieConsentConfig, cookieConsentConfigFactory } from './cookie-consent.config';
import { CourseModule } from './course';
import { CyberPortalModule } from './cyber-portal/cyber-portal.module';
import { FeedbackModule } from './feedback/feedback.module';
import { HelpModule } from './help/help.module';
import { IdentityModule } from './identity/identity.module';
import { BearerTokenInterceptor } from './interceptors/bearer-token.interceptor';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { JsonContentTypeInterceptor } from './interceptors/json-content-type.interceptor';
import { LayoutsModule } from './layouts/layouts.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NavigationModule } from './navigation/navigation.module';
import { PhishingMailsModule } from './phishing-mails/phishing-mails.module';
import { RansomClausesModule } from './ransom-clauses/ransom-clauses.module';
import { ToolkitModule } from './toolkit/toolkit.module';
import { TranslateModule } from './translate.module';
import { provideQueryClientOptions } from '@ngneat/query';
import { SubscriptionModule } from './subscriptions/subscription.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatButtonModule } from '@angular/material/button';
import { PromotionModule } from "./promotion/promotion.module";

registerLocaleData(localeDe);

@NgModule({
  declarations: [MaintenanceComponent, AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    MatButtonToggleModule,
    MatIconModule,
    FeedbackModule,
    CourseModule,
    HelpModule,
    LayoutsModule,
    IdentityModule,
    AdministrationModule,
    PhishingMailsModule,
    BrowserAnimationsModule,
    NgCircleProgressModule,
    ToolkitModule,
    PromotionModule,
    CyberPortalModule,
    NgcCookieConsentModule.forRoot(cookieConsentConfig),
    NavigationModule,
    CmsModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({ logOnly: !isDevMode() }),
    EffectsModule.forRoot([]),
    TranslateModule,
    RansomClausesModule,
    SubscriptionModule,
    MatButtonModule
  ],
  providers: [
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false
        }
      }
    }),
    CircleProgressOptions,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonContentTypeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentConfigFactory,
      deps: [NgcCookieConsentConfig, TranslocoService],
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.secrets.RECAPTCHAV2KEY,
        theme: environment.config.reCaptcha.theme
      } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useValue: {
        onBeforeLoad(_) {
          return {
            url: new URL('https://www.recaptcha.net/recaptcha/api.js') // use recaptcha.net instead of google.com
          };
        }
      } as RecaptchaLoaderOptions
    },
    DatePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
