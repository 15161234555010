import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env';
import {catchError} from "rxjs/operators";
import {LoadingDialogState} from "../layouts/loading-dialog/loading-dialog-state.enum";
import {LoadingDialogService} from "../layouts/loading-dialog/loading-dialog.service";
import {LoadingDialogComponent} from "../layouts/loading-dialog/loading-dialog.component";

@Injectable({providedIn: 'root'})
export class UserManagementService {
  private endpoint = environment.apiUrl + environment.apiRoutes.users;

  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService) {
  }

  updateDepartment(userId: string, department: string): Observable<string> {
    return this.http.patch<string>(`${this.endpoint}/${userId}/management/update-department`, {department})
        .pipe(
            catchError(message => {
              this.loadingDialog.open(LoadingDialogComponent);
              this.loadingDialog.setData({
                data: LoadingDialogState.Error,
                content: message.error
              });
              throw message;
            })
        );
  }
}
