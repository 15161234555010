<div
  [style.backgroundImage]="
    'linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.7)), url(' + urlBuilder.getImageVariation(imageUrl, 'banner') + ')'
  "
  class="header"
>
  <div class="header-content">
    <div class="header-title-button">
      <h1>
        <app-back-arrow-button [url]="urlBuilder.goToCourseOverview()"></app-back-arrow-button>
      </h1>
    </div>
    <div class="header-title-text">
      <h1 data-test="title-course">{{ title }}</h1>
    </div>
    <div class="header-description">
      <p>{{ description }}</p>
    </div>
    <button (click)="handleButtonClick()" appearance="primary" class="header-button" wvButton>{{ buttonText }}</button>
  </div>
</div>
