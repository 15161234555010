<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Assekuradeure'"
>
<mat-tab-group
  [disableRipple]="true"
  [dynamicHeight]="true"
  class="w-full"
  color="accent"
>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
    [label]="'administration.tabLabel.insurancesImport' | transloco"
  >
    <ng-template matTabContent>
      <app-insurances-import></app-insurances-import>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="identityService.hasRole('Produkt-Manager') || identityService.hasRole('Globaler Administrator')"
    [label]="'Meine Versicherungsnehmer'"
  >
    <ng-template matTabContent>
      <app-company-management-assekuradeur></app-company-management-assekuradeur>
    </ng-template>
  </mat-tab>
</mat-tab-group>
</app-back-layout>
