<app-back-layout
  [backUrl]="urlBuilder.goToHome()"
  [title]="'userManagement.title' | transloco"
>
  <button
    data-testid="button-invite-user"
    (click)="openInvitationDialog()"
    appearance="secondary"
    wvButton
  >
    {{ 'userManagement.inviteUserButton' | transloco }}
  </button>
  <app-user-management-overview></app-user-management-overview>
</app-back-layout>
