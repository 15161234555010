import { FileInputValidator } from '@ac/colibri';
import { ImportInsurancesResultDto } from '@ac/models';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';

import { LoadingDialogComponent } from '../../layouts/loading-dialog/loading-dialog.component';
import { LoadingDialogState } from '../../layouts/loading-dialog/loading-dialog-state.enum';
import { LoadingDialogService } from '../../layouts/loading-dialog/loading-dialog.service';

import { IdentityService } from '../../identity/identity.service';
import { ExcelImportService } from './excel-import.service';
import { Access, ImportEntry } from './insurance-import';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-insurances-import',
  templateUrl: './insurances-import.component.html',
  styleUrls: ['./insurances-import.component.scss']
})
export class InsurancesImportComponent {
  importResult: ImportInsurancesResultDto;
  importInsurancesForm: UntypedFormGroup;
  public allowedFileTypes = ['.xlsb', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'];
  private maxFileSize = 0.256 * 2 ** 20; //256 KB
  private accesses: Access[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    private excelImportService: ExcelImportService,
    private loadingDialog: LoadingDialogService,
    private transloco: TranslocoService
  ) {
    this.importInsurancesForm = this.formBuilder.group({
      file: [
        '',
        [
          Validators.required,
          FileValidator.maxContentSize(this.maxFileSize),
          FileInputValidator.allowedFileTypes(this.allowedFileTypes)
        ]
      ]
    });
  }

  getNewAccesses(): ImportEntry[] {
    return this.accesses
      .filter(access => this.importResult.newHashes.includes(access.hash))
      .map(
        access =>
          <ImportEntry>{
            insuranceNumber: access.insuranceNumber,
            insuranceDate: access.insuranceDate
          }
      );
  }

  onSubmitFile(importInsurancesForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (importInsurancesForm.invalid) {
      return;
    }

    const reader = new FileReader();

    const [file] = this.importInsurancesForm.get('file').value.files;

    reader.readAsBinaryString(file);

    reader.onload = event => {
      const excelData = event.target.result;

      this.accesses = this.excelImportService.createAccessesFromExcel(excelData);

      if (this.excelImportService.hasErrors()) {
        this.loadingDialog.open(LoadingDialogComponent, {
          data: LoadingDialogState.Error,
          content: this.transloco.translate('insurancesImport.error.' + this.excelImportService.getFirstError())
        });
      } else {
        const hashes = this.accesses.map(access => access.hash);

        this.identityService.importInsurances(hashes).subscribe(next => {
          this.importResult = next;
        });
      }
    };
  }
}
