<form [formGroup]="form" class="grid gap-2">
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'subscription.phishing.redirectUrl' | transloco }}</mat-label>
      <input formControlName="phishingRedirectUrl" matInput placeholder="https://" />
      <mat-error *ngIf="form.get('phishingRedirectUrl').errors?.url">
        {{ 'form.error.url' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.companyDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <button
    [disabled]="form.invalid"
    appearance="primary"
    wvButton
    (click)="save()"
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
