import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { CyberPortalOverviewItemComponent } from './cyber-portal-overview-item/cyber-portal-overview-item.component';
import { CyberPortalOverviewComponent } from './cyber-portal-overview/cyber-portal-overview.component';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [CyberPortalOverviewComponent, CyberPortalOverviewItemComponent],
  imports: [RouterModule, CommonModule, LayoutsModule, MatCardModule, ColibriModule, TranslateModule],
  exports: [CyberPortalOverviewItemComponent, CyberPortalOverviewComponent]
})
export class CyberPortalModule {}
