<h3>{{ 'passwordPolicy.title' | transloco }}</h3>
<mat-dialog-content>
  <ul>
    <li>{{ 'passwordPolicy.requiredLength' | transloco }}: {{ data.requiredLength }}</li>
    <li>
      {{ 'passwordPolicy.requireUppercase' | transloco }}: {{ data.requireUppercase ? ('yes' | transloco) : ('no' |
      transloco) }}
    </li>
    <li>
      {{ 'passwordPolicy.requireLowercase' | transloco }}: {{ data.requireLowercase ? ('yes' | transloco) : ('no' |
      transloco) }}
    </li>
    <li>
      {{ 'passwordPolicy.requireDigit' | transloco }}: {{ data.requireDigit ? ('yes' | transloco) : ('no' | transloco)
      }}
    </li>
    <li>
      {{ 'passwordPolicy.requireNonAlphanumeric' | transloco }}: {{ data.requireNonAlphanumeric ? ('yes' | transloco) :
      ('no' | transloco) }}
    </li>
    <li>{{ 'passwordPolicy.requiredUniqueChars' | transloco }}: {{ data.requiredUniqueChars }}</li>
  </ul>
  <div class="box">
    <p>{{ 'passwordPolicy.hint' | transloco | renderHTML }}</p>
  </div>
</mat-dialog-content>
