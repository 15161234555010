import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconRegistrationService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  configure(): void {
    this.matIconRegistry.setDefaultFontSetClass("material-symbols-outlined");

    ['email-sync', 'menu'].map(icon =>
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/' + icon + '.svg')
      )
    );
  }
}
