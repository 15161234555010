<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Agenturen - Werbematerialien'"
>
<div class="grid grid-cols-[repeat(auto-fill,_minmax(260px,_1fr))] gap-4 p-2">
  <awareness-center-resource-card
    data-testid="help-document-card"
    [description]="'Anleitung über den gesamten Funktionsumfang des Cyber Portals.'"
    [title]="'Benutzerhandbuch'"
    [url]="environment.resources.helpAdminConfiguration"
  ></awareness-center-resource-card>
  <awareness-center-resource-card
    data-testid="help-document-card"
    [description]="'Die wichtigsten Inhalte und Funktionen des Cyber Portals auf einen Blick.'"
    [title]="'Schnelleinstieg'"
    [url]="environment.urls.privacy"
  ></awareness-center-resource-card>
</div>
</app-back-layout>
