import { Component, Input } from '@angular/core';
import { CourseDetailsCourseDto } from '@ac/models';

@Component({
  selector: 'app-course-details-playlist',
  templateUrl: './course-details-playlist.component.html',
  styleUrls: ['./course-details-playlist.component.scss']
})
export class CourseDetailsPlaylistComponent {
  @Input() course: CourseDetailsCourseDto;
}
