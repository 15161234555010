import {ChangeDetectionStrategy, Component, effect, inject, viewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {DefaultChartOptionsService} from "../../phishing-mails/default-chart-options.service";

@Component({
  selector: 'app-partner-toolkit-user-usage-statistic',
  standalone: true,
  imports: [CommonModule, ColibriModule, BaseChartDirective],
  templateUrl: './partner-toolkit-user-usage-statistic.component.html',
  styleUrl: './partner-toolkit-user-usage-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerToolkitUserUsageStatisticComponent {
  chart = viewChild(BaseChartDirective);
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          text: "Anwendungszahl",
          display: true
        },
      },
      y: {
        title: {
          text: "Werkzeug",
          display: true
        }
      }
    }
  };

  constructor() {
    const toolkitUsage = [
      { toolkit: "AI-Act-Check", clicksTotal: 2899, clicksCV: 1000, clicksGV: 500 },
      { toolkit: "NIS2-Check", clicksTotal: 1265, clicksCV: 453, clicksGV: 200 },
      { toolkit: "Data-Act-Check", clicksTotal: 2600, clicksCV: 320, clicksGV: 100 },
      { toolkit: "Smart-Product-Impact-Assessment", clicksTotal: 700, clicksCV: 160, clicksGV: 70 },
      { toolkit: "SCC-Generator", clicksTotal: 150, clicksCV: 140, clicksGV: 7 },
    ].sort((a, b) => b.clicksTotal - a.clicksTotal);

    this.chartData.datasets =  [{
      data: toolkitUsage.map((resourceClick) => resourceClick.clicksTotal),
      label: "Alle Benutzer",
      backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[0],
      hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[0],
      hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
    },
      {
        data: toolkitUsage.map((resourceClick) => resourceClick.clicksCV),
        label: "Cyber-Verantwortliche",
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[1],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[1],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      },
      {
        data: toolkitUsage.map((resourceClick) => resourceClick.clicksGV),
        label: "Geschäftsführer",
        backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[2],
        hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[2],
        hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
      }];
    this.chartData.labels = toolkitUsage.map((resourceClick) => resourceClick.toolkit);

    effect(() => {
      if (this.chart()) {
        this.chart().update();
      }
    })
  }
}
