import { Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { ContentCreatorGuard } from './guards/content-creator.guard';
import { NavigationLayoutComponent } from './layouts/navigation-layout/navigation-layout.component';
import { CyberPortalOverviewComponent } from './cyber-portal/cyber-portal-overview/cyber-portal-overview.component';
import { CourseOverviewComponent } from './course/course-overview/course-overview.component';
import { CourseDetailsComponent } from './course/course-details/course-details.component';
import { CompanyRegistrationComponent } from './identity/company-registration/company-registration.component';
import { UserRegistrationComponent } from './identity/user-registration/user-registration.component';
import { UserManagementComponent } from './identity/user-management/user-management.component';
import { AccountantGuard } from './guards/accountant.guard';
import { UserEditComponent } from './identity/user-edit/user-edit.component';
import { ResetPasswordRequestComponent } from './identity/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './identity/reset-password/reset-password.component';
import { UserEditEmailLandingPageComponent } from './identity/user-edit-email-landing-page/user-edit-email-landing-page.component';
import { PhishingMailsComponent } from './phishing-mails/phishing-mails/phishing-mails.component';
import { PhishingMailsLandingPageComponent } from './phishing-mails/phishing-mails-landing-page/phishing-mails-landing-page.component';
import { UserInvitationSelfDeleteLandingPageComponent } from './identity/user-invitation-self-delete-landing-page/user-invitation-self-delete-landing-page.component';
import { HelpComponent } from './help/help/help.component';
import { AdministrationComponent } from './administration/administration/administration.component';
import { AdministratorGuard } from './guards/administrator.guard';
import { FeedbackDetailsComponent } from './administration/feedback-details/feedback-details.component';
import { ToolkitComponent } from './toolkit/toolkit/toolkit.component';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';
import { LoginComponent } from './identity/login/login.component';
import { CoursePlayerComponent } from './course/course-player/course-player.component';
import { LogoutComponent } from './identity/logout/logout.component';
import { InfoVideosComponent } from '@app/help/info-videos/info-videos.component';
import { IntroVideosComponent } from './help/intro-videos/intro-videos.component';
import { RansomClauseComponent } from '@app/ransom-clauses/ransom-clause/ransom-clause.component';
import { CompanyEditComponent } from './identity/company-edit/company-edit.component';
import { SubscriptionOverviewComponent } from './subscriptions/subscription-overview/subscription-overview.component';
import { PhishingMailsUserStatisticComponent } from './phishing-mails/phishing-mails-user-statistic/phishing-mails-user-statistic.component';
import { PhishingMailsCompanyStatisticComponent } from './phishing-mails/phishing-mails-company-statistic/phishing-mails-company-statistic.component';
import { PromotionComponent } from "./promotion/promotion/promotion.component";
import {AdministrationWvComponent} from "./administration/administration-wv/administration-wv.component";
import {AdministrationCoitComponent} from "./administration/administration-coit/administration-coit.component";
import {AdministrationAssekuradeureComponent} from "./administration/administration-assekuradeure/administration-assekuradeure.component";
import {AdministrationAgenturenComponent} from "./administration/administration-agenturen/administration-agenturen.component";
import {AdministrationPartnerComponent} from "./administration/administration-partner/administration-partner.component";

export const appRoutes: Routes = [
  {
    path: 'cyber-portal/inhaltspflege',
    loadChildren: () => import('./cms/cms.module').then(module => module.CmsModule),
    canActivate: [LoggedInGuard, ContentCreatorGuard]
  },
  {
    path: 'cyber-portal',
    component: NavigationLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CyberPortalOverviewComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'course-overview',
        component: CourseOverviewComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'courses/:courseId',
        component: CourseDetailsComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId',
        component: CourseDetailsComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'register-company-details/:accessCode',
        component: CompanyRegistrationComponent
      },
      {
        path: 'register-user',
        component: UserRegistrationComponent
      },
      {
        path: 'manage-users',
        component: UserManagementComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'edit-user',
        component: UserEditComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'edit-company',
        component: CompanyEditComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'reset-password',
        component: ResetPasswordRequestComponent
      },
      {
        path: 'reset-password/:userId',
        component: ResetPasswordComponent
      },
      {
        path: 'update-email',
        component: UserEditEmailLandingPageComponent
      },
      {
        path: 'phishing-mails',
        component: PhishingMailsComponent,
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
          },
          {
            path: 'users',
            component: PhishingMailsUserStatisticComponent,
            canActivate: [LoggedInGuard]
          },
          {
            path: 'users/:userId',
            component: PhishingMailsUserStatisticComponent,
            canActivate: [LoggedInGuard]
          },
          {
            path: 'companies',
            component: PhishingMailsCompanyStatisticComponent,
            canActivate: [AccountantGuard]
          },
          {
            path: 'companies/:companyId',
            component: PhishingMailsCompanyStatisticComponent,
            canActivate: [AccountantGuard]
          }
        ]
      },
      {
        path: 'phishing-mail-clicked',
        component: PhishingMailsLandingPageComponent
      },
      {
        path: 'self-delete-invitation',
        component: UserInvitationSelfDeleteLandingPageComponent
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivate: [AdministratorGuard],
      },
      {
        path: 'administration/wv',
        component: AdministrationWvComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/coit',
        component: AdministrationCoitComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/partner',
        component: AdministrationPartnerComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/assekuradeure',
        component: AdministrationAssekuradeureComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'administration/agenturen',
        component: AdministrationAgenturenComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'feedbacks/:feedbackId',
        component: FeedbackDetailsComponent,
        canActivate: [AdministratorGuard]
      },
      {
        path: 'toolkit',
        component: ToolkitComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'subscription',
        component: SubscriptionOverviewComponent,
        canActivate: [LoggedInGuard, AccountantGuard]
      },
      {
        path: 'promotion',
        component: PromotionComponent,
        canActivate: [LoggedInGuard]
      },
    ]
  },
  {
    path: 'cyber-portal',
    component: PlainLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'hilfe-videos/:video',
        component: InfoVideosComponent
      },
      {
        path: 'intro-videos/:course',
        component: IntroVideosComponent
      }
    ]
  },
  {
    path: 'cyber-portal',
    children: [
      {
        path: 'courses/:courseId/player/:contentId',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'courses/:courseId/player',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId/player/:contentId',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'companies/:companyId/courses/:courseId/player',
        component: CoursePlayerComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: 'logout',
        component: LogoutComponent
      }
    ]
  },
  {
    path: 'ransom-clause',
    component: PlainLayoutComponent,
    children: [
      {
        path: '',
        component: RansomClauseComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'cyber-portal'
  }
];
