import { ChangeDetectionStrategy, Component, inject, model } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env';
import { ColibriModule } from '@ac/colibri';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { TranslocoPipe } from '@ngneat/transloco';
import { PhishingMailsService } from '../../../phishing-mails/phishing-mails.service';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-mail-configuration-dialog',
  standalone: true,
  imports: [CommonModule, ColibriModule, MatLegacyCheckboxModule, TranslocoPipe, FormsModule, MatLegacyDialogModule],
  templateUrl: './confirm-mail-configuration-dialog.component.html',
  styleUrl: './confirm-mail-configuration-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMailConfigurationDialogComponent {
  configurationConfirmed = model(false);
  protected readonly environment = environment;
  #dialog = inject(MatLegacyDialogRef<ConfirmMailConfigurationDialogComponent>);
  #phishingMailService = inject(PhishingMailsService);

  confirm() {
    this.#dialog.close(true);
  }

  cancel() {
    this.#dialog.close(false);
  }

  sendTestPhishingMail() {
    this.#phishingMailService.sendConfigurationTestMail().mutate({});
  }
}
