import { CustomValidator, PasswordValidationErrors, PasswordValidators, SelectOptionsService } from '@ac/colibri';
import { PasswordPolicyDto, RegisterUserDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IdentityService } from '../identity.service';
import { PasswordPolicyDialogComponent } from '../password-policy-dialog/password-policy-dialog.component';

@Component({
  selector: 'app-user-registration-form',
  templateUrl: './user-registration-form.component.html',
  styleUrls: ['./user-registration-form.component.scss']
})
export class UserRegistrationFormComponent implements OnInit {
  @ViewChild(MatExpansionPanel) passwordCheckPanel: MatExpansionPanel;

  @Input() token: string;
  @Input() userId: string;
  @Input() passwordPolicy: PasswordPolicyDto;

  @Output() submitForm = new EventEmitter<RegisterUserDto>();

  isPasswordSpecificationNotSatisfied = true;
  passwordValidationErrors$: Observable<PasswordValidationErrors>;
  userRegistrationForm: UntypedFormGroup;
  passwordHide = true;
  environment = environment;
  options = this.selectOptionsService.departments();
  filteredOptions: Observable<string[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private identityService: IdentityService,
    private selectOptionsService: SelectOptionsService
  ) {}

  ngOnInit(): void {
    this.userRegistrationForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        password: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]],
        password2: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]],
        gender: ['', [Validators.required]],
        department: ['', [Validators.required, CustomValidator.requireMatch(this.options)]],
        privacy: ['', [Validators.required]],
        termsOfUse: ['', [Validators.required]]
      },
      { validators: PasswordValidators.equalPasswords }
    );

    this.filteredOptions = this.userRegistrationForm.get('department').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.passwordValidationErrors$ = this.userRegistrationForm.get('password').valueChanges.pipe(
      map(() => Object.keys(this.userRegistrationForm.get('password').errors || []) as PasswordValidationErrors),
      tap(errors => {
        /**
         *
         * Somehow the control error of password is not populated to the form.
         * The form indicates itself as valid, although password contains errors.
         *
         * Until we found the root cause for this behaviour we have to use additional
         * state to disable the registration button.
         */
        if (errors.length) {
          this.isPasswordSpecificationNotSatisfied = true;
        } else {
          this.isPasswordSpecificationNotSatisfied = false;
        }
      })
    );
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: RegisterUserDto = {
      firstName: userData.get('firstName').value,
      lastName: userData.get('lastName').value,
      password: userData.get('password').value,
      isFemale: userData.get('gender').value !== 'male',
      department: userData.get('department').value,
      token: this.token,
      userId: this.userId
    };

    this.submitForm.emit(dto);
  }

  openPasswordInfoDialog(): void {
    this.dialog.open(PasswordPolicyDialogComponent, {
      data: this.passwordPolicy
    });
  }

  openPasswordCheckPanel(): void {
    this.passwordCheckPanel?.open();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
