import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ColibriModule} from "@ac/colibri";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-partner-profile',
  standalone: true,
  imports: [CommonModule, ColibriModule, MatFormField, MatInput, MatLabel, CdkTextareaAutosize, MatIcon],
  templateUrl: './partner-profile.component.html',
  styleUrl: './partner-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerProfileComponent {}
