<app-back-layout
  [backUrl]="urlBuilder.goToHome()"
  [title]="'subscription.title' | transloco"
>
  <div class="grid gap-16 2xl:grid-cols-[max-content,1fr]">
    @if (subscription().isLoading) {
      <div class="min-w-[44rem] flex items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    }

    @if (subscription().data; as subscription) {
      <div class="grid grid-cols-[21rem,21rem] justify-items-center gap-y-2 gap-8 max-w-[60vw]">
        <wv-card class="w-full col-start-1" [title]="'subscription.runningContract' | transloco">
          <div class="grid grid-cols-[36px_1fr] items-center gap-4">
            <mat-icon>redeem</mat-icon>
            <span>
                {{ subscription.runningContract.packageName }}
              </span>

            <mat-icon>credit_card</mat-icon>
            <span>
                @if (subscription.runningContract.licenseCosts === 0) {
                  {{ 'subscription.free' | transloco }}
                } @else {
                  {{ subscription.runningContract.licenseCosts | currency: 'EUR' }}
                  {{ 'subscription.perMonth' | transloco }}
                }
              </span>

            <mat-icon>group</mat-icon>
            <span>
                @if (subscription.runningContract.licenseCosts === 0) {
                  {{ 'subscription.unlimited' | transloco }}
                } @else {
                  {{ 'subscription.minimumQuantity' | transloco }}:
                  {{ subscription.runningContract.minimumNumberOfUsers }}
                }
              </span>

            <mat-icon>event_upcoming</mat-icon>
            <span>{{ 'subscription.endDate' | transloco }}: {{ subscription.runningContract.periodEnd | date }}</span>

            <small class="col-span-2">
              <app-package-info-hint></app-package-info-hint>
            </small>
          </div>
        </wv-card>

        <wv-card [title]="'subscription.phishing.title' | transloco" class="w-full">
          <div class="grid grid-cols-[max-content_1fr] items-center gap-4">
            <mat-icon>mail</mat-icon>
            <span>
                {{ 'subscription.phishing.mailsPerYear' | transloco }}: {{ subscription.phishing.simulationCount }}
              </span>

            <mat-icon>casino</mat-icon>
            <span>
                {{ 'subscription.phishing.randomization' | transloco }}:
              {{ subscription.phishing.randomizationLabel }}
              </span>

            <mat-icon>language</mat-icon>
            <div class="flex items-center h-[1em]">
                <span>
                  {{ 'subscription.phishing.awareness-page' | transloco }}:
                  @if (!subscription.phishing.redirectUrl) {
                    <a
                      [href]="defaultAwarenessPageRedirectUrl"
                      target="_blank"
                      class="text-[--primary]"
                    >
                      {{ 'subscription.awareness-page-redirect-dialog.default-redirect-text' | transloco }}
                    </a>
                  } @else {
                    <a
                      [href]="subscription.phishing.redirectUrl"
                      target="_blank"
                      class="text-[--primary]"
                    >
                      {{ 'subscription.phishing.awareness-page-custom' | transloco }}
                    </a>
                  }
                </span>
              <button
                (click)="openChangeAwarenessPageDialog(subscription)"
                mat-icon-button
                color="primary"
                class="inline"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <mat-icon class="self-start">play_pause</mat-icon>
            <div class="grid gap-1">
              @if (subscription.phishing.isPaused) {
                <div>{{ 'subscription.phishing.paused' | transloco }}</div>
                <button wvButton appearance="small-primary"
                        (click)="continuePhishing()">{{ 'subscription.phishing.continue' | transloco }}
                </button>
              } @else {
                <div>{{ 'subscription.phishing.activated' | transloco }}</div>
                <button wvButton appearance="small-primary"
                        (click)="pausePhishing()">{{ 'subscription.phishing.pause' | transloco }}
                </button>
              }
            </div>
          </div>
        </wv-card>

        <mat-icon class="opacity-80 !font-bold !text-[72px] !w-[72px] !h-[72px] col-start-1">
          keyboard_double_arrow_down
        </mat-icon>

        <wv-card [title]="'subscription.followUpContract' | transloco" class="w-full col-start-1">
          <div class="grid grid-cols-[max-content_1fr] items-center gap-4">
            <mat-icon>redeem</mat-icon>
            <span>
              {{ subscription.followUpContract.packageName }}
            </span>

            <mat-icon>credit_card</mat-icon>
            <span>
              @if (subscription.followUpContract.licenseCosts === 0) {
                {{ 'subscription.free' | transloco }}
              } @else {
                {{ subscription.followUpContract.licenseCosts | currency: 'EUR' }}
                {{ 'subscription.perMonth' | transloco }}
              }
            </span>

            <mat-icon>group</mat-icon>
            <span>
              @if (subscription.followUpContract.licenseCosts === 0) {
                {{ 'subscription.unlimited' | transloco }}
              } @else {
                {{ 'subscription.minimumQuantity' | transloco }}:
                {{ subscription.followUpContract.minimumNumberOfUsers }}
              }
            </span>

            <mat-icon>event_upcoming</mat-icon>
            <span>
              {{ 'subscription.startDate' | transloco }}: {{ subscription.followUpContract.periodStart | date }}
            </span>

            <mat-icon>hourglass</mat-icon>
            <span>
              {{ 'subscription.duration' | transloco }} :
              {{ subscription.followUpContract.periodStart | dateDistance: subscription.followUpContract.periodEnd }}
            </span>
          </div>
        </wv-card>
      </div>
    }
    <div class="flex flex-col gap-4 items-center">
      <app-videoplayer
        [autoplay]="false"
        [resetOnEnd]="true"
        [title]="'help.video.pricedPackages.title' | transloco"
        [video]="video"
        class="shadow-xl"
      ></app-videoplayer>
      <p class="text-sm font-bold">{{ 'help.video.pricedPackages.title' | transloco }}</p>
    </div>
  </div>
</app-back-layout>
