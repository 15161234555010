<app-back-layout [backUrl]="urlBuilder.goToHome()" [title]="'navigation.menuItem.courseOverview' | transloco">
  <section class="courses">
    <app-course-overview-course
      *ngFor="let course of courses$ | async"
      [course]="course"
      [link]="urlBuilder.goToCourse(course.id)"
      class="course"
      data-test="course"
      data-testid="course"
    ></app-course-overview-course>

    <app-course-overview-course
      *ngFor="let companyCourse of companyCourses$ | async"
      [course]="companyCourse"
      [link]="urlBuilder.goToCompanyCourse(identity.getCompanyId(),companyCourse.id)"
      class="course"
      data-test="course"
      data-testid="course"
    ></app-course-overview-course>
  </section>
</app-back-layout>
