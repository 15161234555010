import { CoursePlayerModuleDto } from '@ac/models';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import {hexToRGB} from "../../utils/hex-to-rgb";

@Component({
  selector: 'app-course-player-playlist-module',
  templateUrl: './course-player-playlist-module.component.html',
  styleUrls: ['./course-player-playlist-module.component.scss']
})
export class CoursePlayerPlaylistModuleComponent implements OnInit {
  @Input() module: CoursePlayerModuleDto;
  @Input() position: number;
  @Input() progress: number;
  active: boolean;
  opened: boolean;
  innerStrokeColor = hexToRGB(getComputedStyle(document.documentElement).getPropertyValue('--on-surface'), 0.2);

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => {
          this.active = this.checkIfActive(params.contentId);
        })
      )
      .subscribe();
  }

  checkIfActive(contentId: string): boolean {
    return this.module.lessons.filter(x => x.id === contentId).length !== 0;
  }
}
