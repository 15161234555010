import { CourseDetailsLessonDto } from '@ac/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-details-playlist-lesson',
  templateUrl: './course-details-playlist-lesson.component.html',
  styleUrls: ['./course-details-playlist-lesson.component.scss']
})
export class CourseDetailsPlaylistLessonComponent {
  @Input() lesson: CourseDetailsLessonDto;
}
