import { createTaskOption, ExaminationConfiguration } from '@ac/exam';
import { environment } from '../../../../environments/environment';

export const course4Exam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 2 },
  tasks: [
    {
      type: 'single-choice',
      task: {
        heading: 'Gefahren von gekürzten Links minimieren',
        description:
          'Über das Businessnetzwerk Xing haben Ihnen drei unbekannte Kontakte die folgenden Nachrichten gesendet.<br><br>Wählen Sie die Nachricht aus, die Sie für seriös halten.<br><br>Nutzen Sie dazu <a target="_blank" href="https://checkshorturl.com">dieses Werkzeug</a>. Achten Sie auf die Links, die in Ihrem Browser unten links angezeigt werden.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link1.png',
            'https://adresse.unsicher.de/schadsoftware'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link2.png',
            'https://bit.ly/3sEbj3s'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link3.png',
            'https://amzn.to/2NpuNZy'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link3.png',
          'https://amzn.to/2NpuNZy'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Nachricht 1: Unseriös, da der Link zu einer anderen Zieladresse führt, als er vorgibt.</li>
            <li>Nachricht 2: Unseriös, da der verwendete gekürzte Link auf eine gefährliche Zieladresse verweist.</li>
            <li>Nachricht 3: Seriös, da der Link auf ein glaubwürdiges Ziel verweist.</li>
            </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus.<br><br>Nutzen Sie zur Recherche die Rückwärtssuche von <a target="_blank" href="https://www.dasoertliche.de/rueckwaertssuche/">Das Örtliche</a>.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-1.png',
            'Impressum-1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-2.png',
            'Impressum-2'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-2.png',
          'Impressum-2'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Unseriös, da die angegebene Telefonnummer ungültig ist.</li>
          <li>Impressum 2: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus.<br><br>Nutzen Sie zur Recherche das <a target="_blank" href="https://www.handelsregister.de/">Handelsregister</a>.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-1.png',
            'Impressum-1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-2.png',
            'Impressum-2'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-1.png',
          'Impressum-1'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          <li>Impressum 2: Unseriös, da die Handelsregisternummer ungültig ist.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus. Achten Sie auf die Vollständigkeit des Impressums.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-1.png',
            'Impressum-1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-2.png',
            'Impressum-2'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-2.png',
          'Impressum-2'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Unseriös, da das Impressum keinen Firmensitz aufweist.</li>
          <li>Impressum 2: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'temporary-email',
      task: {
        heading: 'Eigene E-Mail-Adresse sparsam einsetzen',
        description:
          'Sie wollen sich die neuesten Umsatzzahlen für Ihre Branche herunterladen. Die Webseite fordert Sie zur Eingabe Ihrer E-Mail-Adresse auf, damit Sie die Daten herunterladen können.<br>Nutzen Sie dazu <a target="_blank" href="https://temp-mail.org">dieses Werkzeug</a>, um eine temporäre E-Mail-Adresse zu erstellen.<br><br>Die Webseite meldet: "Bitte geben Sie Ihre E-Mail-Adresse an. Wir schicken Ihnen den Code zu, womit Sie die Umsatzzahlen herunterladen können."',
        questionOne: {
          description:
            'Bitte geben Sie Ihre E-Mail-Adresse an. Wir schicken Ihnen den Code zu, womit Sie die Umsatzzahlen herunterladen können.',
          inputField: {
            type: 'input',
            validationEndpoint: environment.apiUrl + environment.apiRoutes.tempMails,
            validationErrorMessage: 'Es handelt sich bei Ihrer Eingabe nicht um eine E-Mail-Adresse von temp-mail.org'
          }
        },
        questionTwo: {
          description:
            'Eine E-Mail mit Ihrem Code wurde an Ihre E-Mail-Adresse geschickt. Bitte geben Sie diesen hier ein:',
          inputField: { type: 'input' }
        },
        solution: 'xxxxxxx',
        summary: {
          wrong: 'Der eingegebene Code stimmt nicht überein. Der erwartete Code lautet: "865321".'
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind in Ihrem Unternehmen für den Einkauf von Edelstahl zuständig. Für einen Kundenauftrag müssen Sie neue Edelstahl-Rohre einkaufen. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text1.png',
            'Text1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text2.png',
            'Text2'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text1.png',
          'Text1'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Seriös, da hochwertiger redaktioneller Inhalt.</li>
          <li>Text 2: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
</ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind der Büro-Manager Ihres Unternehmens und suchen aktuell nach neuer Büroausstattung. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text1.png',
            'Text1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text2.png',
            'Text2'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text3.png',
            'Text3'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text4.png',
            'Text4'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text1.png',
          'Text1'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Seriös, da hochwertiger redaktioneller Inhalt.</li>
          <li>Text 2: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
          <li>Text 3: Unseriös, da Sie in diesem Angebot unter Druck gesetzt werden.</li>
          <li>Text 4: Unseriös, da das Angebot zu gut ist um wahr zu sein.</li>
</ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind der Fuhrpark-Manager Ihres Unternehmens und suchen aktuell nach einem neuen Flottenwagen. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text1.png',
            'Text1'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text2.png',
            'Text2'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text3.png',
            'Text3'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text4.png',
            'Text4'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text4.png',
          'Text4'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Unseriös, da das Angebot zu gut ist um wahr zu sein.</li>
          <li>Text 2: Unseriös, da Sie in diesem Angebot unter Druck gesetzt werden.</li>
          <li>Text 3: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
          <li>Text 4: Seriös, da hochwertiger redaktioneller Inhalt.</li>
</ul>
          `
        }
      }
    }
  ]
};
