<mat-card
  [class.disabled]="course.isDisabled"
  tabindex="-1"
  class="mat-elevation-z2"
>
  <!--  todo: Aufräumen, keine doppelten DOM Elemente-->
  <a
    *ngIf="!course.isDisabled"
    [routerLink]="link"
    class="alink"
    tabindex="0"
  >
    <ac-image-faded
      [src]="urlBuilder.getImageVariation(course.imageUrl, 'card')"
      [alt]="'Titelbild fuer Kurs ' + course.title"
      mat-card-image
    ></ac-image-faded>
  </a>
  <ac-image-faded
    (click)="showDeactivationReason()"
    *ngIf="course.isDisabled"
    [src]="urlBuilder.getImageVariation(course.imageUrl, 'card')"
    [alt]="'Titelbild fuer Kurs ' + course.title"
    class="pointer"
    mat-card-image
    tabindex="-1"
  ></ac-image-faded>
  <a
    *ngIf="!course.isDisabled"
    class="blink"
    [routerLink]="link"
    tabindex="0"
  >
    <mat-card-content>
      <mat-card-title>
        <h3
          data-testid="heading-course-title"
          class="course-title"
        >
          {{ course.title }}
        </h3>
      </mat-card-title>
      <div class="course-duration">
        <div class="course-duration-icon">
          <mat-icon inline="true">schedule</mat-icon>
        </div>
        <div
          class="course-duration-text"
          data-testid="course-duration"
        >
          {{ course.duration | duration }}
        </div>
      </div>
      <div class="course-progress">
        <div class="course-progress-icon">
          <mat-icon inline="true">trending_up</mat-icon>
        </div>
        <div class="course-progress-label">
          <span>{{ 'course.overview.progress' | transloco }}:</span>
        </div>
        <div class="course-progress-bar">
          <mat-progress-bar
            [value]="course.progress"
            class="progress-bar"
            color="accent"
            mode="determinate"
            aria-label="kurs-fortschritte"
          ></mat-progress-bar>
        </div>
      </div>
    </mat-card-content>
  </a>
  <mat-card-content
    (click)="showDeactivationReason()"
    *ngIf="course.isDisabled"
    class="pointer"
    tabindex="-1"
  >
    <mat-card-title>
      <h3 class="course-title">{{ course.title }}</h3>
    </mat-card-title>
    <div class="course-duration">
      <div class="course-duration-icon">
        <mat-icon>schedule</mat-icon>
      </div>
      <div class="course-duration-text">{{ course.duration | duration }}</div>
    </div>
    <div class="course-progress">
      <div class="course-progress-icon">
        <mat-icon>trending_up</mat-icon>
      </div>
      <div class="course-progress-label">
        <span>{{ 'course.overview.progress' | transloco }}:</span>
      </div>
      <div class="course-progress-bar">
        <mat-progress-bar
          [value]="course.progress"
          aria-label="kurs-fortschritte"
          class="progress-bar"
          color="primary"
          mode="determinate"
        ></mat-progress-bar>
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions tabindex="-1">
    <div class="action-partner">
      <button
        (click)="requestCertificate(course.id)"
        *ngIf="course.hasExam && course.examPassed"
        [matTooltip]="'course.requestCertificate' | transloco"
        [attr.aria-label]="'course.requestCertificate' | transloco"
        mat-icon-button
        matTooltipPosition="right"
        data-testid="button-exam-passed"
      >
        <mat-icon class="icon-filled">verified</mat-icon>
      </button>

      <button
        *ngIf="course.hasExam && !course.examPassed"
        [disableRipple]="true"
        [matTooltip]="'course.overview.cantRequestCertificate' | transloco"
        [attr.aria-label]="'course.overview.cantRequestCertificate' | transloco"
        class="default-cursor"
        mat-icon-button
        matTooltipPosition="right"
      >
        <mat-icon>verified</mat-icon>
      </button>
      <button
        *ngIf="!course.hasExam"
        [disableRipple]="true"
        class="default-cursor"
        mat-icon-button
        style="visibility: hidden"
      >
        <mat-icon></mat-icon>
      </button>

      @if(course.title === 'Effektives Basiswissen') {
        <ac-partner-button
          [confirmMessage]="'Sie verlassen unser Portal und werden auf die externe Website unseres Partners Vogel & Partner weitergeleitet. Bitte beachten Sie, dass die dort angebotenen Leistungen unabhängig von unserem Portal sind und nicht zu unserem Angebot gehören.'"
          [href]="'https://www.vogel-partner.eu/'"
          [name]="'Vogel & Partner'"
          [target]="'_blank'"
          [tooltip]="'Dieser Kurs wurde in Zusammenarbeit mit unserem Partner Vogel & Partner erstellt.'"
        >
        </ac-partner-button>
      }
    </div>
  </mat-card-actions>
</mat-card>
