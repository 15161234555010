<div
  class="dialog-title"
  mat-dialog-title
>
  <span>{{ 'whitelistedAccesses.addAccessTitle' | transloco }}</span>
  <button
    (click)="dialogRef.close()"
    [tabIndex]="-1"
    mat-icon-button
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p>{{ 'whitelistedAccesses.addAccessDescription' | transloco | renderHTML }}</p>
  <form
    (ngSubmit)="onSubmit(inviteAgencyForm, $event)"
    [formGroup]="inviteAgencyForm"
  >
    <mat-form-field
      appearance="outline"
      class="full-width"
    >
      <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
      <input
        data-testid="input-email"
        formControlName="email"
        matInput
        type="email"
      />
      <mat-error *ngIf="inviteAgencyForm.get('email').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="inviteAgencyForm.get('email').errors?.email">
        {{ 'form.error.invalidEmail' | transloco }}
      </mat-error>
      <mat-error *ngIf="inviteAgencyForm.get('email').errors?.username">
        {{ 'form.error.invalidUserName' | transloco }}
      </mat-error>
    </mat-form-field>
    <button
      data-testid="button-submit-invitation"
      [disabled]="inviteAgencyForm.invalid"
      appearance="secondary"
      wvButton
    >
      {{ 'whitelistedAccesses.addAccessSubmitButton' | transloco }}
    </button>
  </form>
</div>
