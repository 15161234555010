<table
    *ngIf="dataSource"
    [dataSource]="dataSource"
    mat-table
    matSort
    matSortActive="dateTime"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="subject">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
      >
        Betreff
      </th>
      <td
        *matCellDef="let message"
        mat-cell
      >
        {{ message.subject }}
      </td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
      >
        Unternehmensname
      </th>
      <td
        *matCellDef="let message"
        mat-cell
      >
        {{ message.company }}
      </td>
    </ng-container>
    <ng-container matColumnDef="branch">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
      >
        Branche
      </th>
      <td
        *matCellDef="let message"
        mat-cell
      >
        {{ message.branch }}
      </td>
    </ng-container>
    <ng-container matColumnDef="companySize">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
      >
        Unternehmensgröße
      </th>
      <td
        *matCellDef="let message"
        mat-cell
      >
        {{ message.companySize }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dateTime">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
      >
        Zeitpunkt
      </th>
      <td
        *matCellDef="let message"
        mat-cell
      >
        {{ message.dateTime | date: 'dd.MM.yyyy, HH:mm' }}
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="displayedColumns"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="let message; columns: displayedColumns"
      mat-row
    ></tr>
  </table>


