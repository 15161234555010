<ng-container *transloco="let t">
  <div [innerHTML]="text"></div>
  <form>
    <mat-checkbox
      [(ngModel)]="textRead"
      [ngModelOptions]="{standalone: true}"
      class="full-width"
      color="primary"
      required
    >
      {{ t('ransom-clause.text-confirmation.checkbox-label')}}
    </mat-checkbox>
    <button (click)="confirmText()" [disabled]="!textRead" appearance="secondary" wvButton>
      {{t('ransom-clause.text-confirmation.confirm-label')}}
    </button>
  </form>
</ng-container>
