<form
  (ngSubmit)="emitRegistrationRequestData(companyRegistrationRequestForm, $event)"
  [formGroup]="companyRegistrationRequestForm"
  class="relative"
>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.insuranceNumber' | transloco }}</mat-label>
      <input data-test="input-registration-request-insurance-number" formControlName="insuranceNumber" matInput />
      <mat-error *ngIf="companyRegistrationRequestForm.get('insuranceNumber').errors?.required">
        {{ 'form.error.required' | transloco | renderHTML }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationRequestForm.get('insuranceNumber').errors?.pattern">
        {{ 'form.error.invalidInsuranceNumber' | transloco | renderHTML }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.insuranceNumber.description' | transloco"
      [gdpr]="'info-box.common.anonymizationGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.insuranceDate' | transloco }}</mat-label>
      <input
        [matDatepicker]="picker"
        [max]="maxDate"
        [min]="minDate"
        formControlName="insuranceDate"
        matInput
        readonly
      />
      <mat-datepicker-toggle [for]="picker" [tabIndex]="-1" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker data-test="datepicker-registration-request-date"></mat-datepicker>
      <mat-error *ngIf="companyRegistrationRequestForm.get('insuranceDate').errors?.required">
        {{ 'form.error.required' | transloco | renderHTML }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.insuranceDate.description' | transloco"
      [gdpr]="'info-box.common.anonymizationGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.email' | transloco | renderHTML }}</mat-label>
      <input data-test="input-registration-request-email" formControlName="email" matInput type="email" />
      <mat-error *ngIf="companyRegistrationRequestForm.get('email').errors?.required">
        {{ 'form.error.required' | transloco | renderHTML }}
      </mat-error>
      <mat-error *ngIf="companyRegistrationRequestForm.get('email').errors?.email">
        {{ 'form.error.invalidEmail' | transloco | renderHTML }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.emailRegistrationRequest.description' | transloco"
      [gdpr]="'info-box.emailRegistrationRequest.gdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="check-boxes">
    <div class="checkbox-info-wrapper">
      <mat-checkbox
        class="full-width"
        color="primary"
        data-test="checkbox-registration-request-privacy"
        formControlName="privacy"
        required
      >
        {{ 'form.label.privacy' | transloco: {link: environment.urls.privacy} | renderHTML }}
      </mat-checkbox>
      <app-info-box [gdpr]="'info-box.common.consentGdpr' | transloco"></app-info-box>
    </div>
    <div class="checkbox-info-wrapper">
      <mat-checkbox
        class="full-width"
        color="primary"
        data-test="checkbox-registration-request-agb"
        formControlName="agb"
        required
      >
        {{ 'form.label.termsOfUse' | transloco: {link: environment.urls.termsOfUse} | renderHTML }}
      </mat-checkbox>
      <app-info-box [gdpr]="'info-box.common.consentGdpr' | transloco"></app-info-box>
    </div>
  </div>
  <div class="recaptcha">
    @if (acceptedRecaptcha()) {
      <re-captcha
        class="recaptcha"
        data-test="recaptcha-registration-request-recaptcha"
        formControlName="recaptcha"
      ></re-captcha>
    }
    @if (!acceptedRecaptcha()) {
      <ac-recaptcha-consent (accepted)="acceptedRecaptcha.set(true)"></ac-recaptcha-consent>
    }
  </div>
  <div class="actions">
    <button
      [disabled]="companyRegistrationRequestForm.invalid"
      appearance="primary"
      class="submit-form-button"
      data-test="button-registration-request-request-access"
      wvButton
    >
      {{ 'login.registrationRequestForm.submitButton' | transloco | renderHTML }}
    </button>
  </div>
</form>
