<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Vogel & Partner'"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/partner-profile.webp'"
      imgAlt="TODO"
      [link]="'./partner-profile'"
      [title]="'Meine Daten'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/partner-news.webp'"
      imgAlt="TODO"
      [link]="'./partner-news'"
      [title]="'Nachrichten'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/integrations.webp'"
      imgAlt="TODO"
      [link]="'./integrations'"
      [title]="'Integrationen'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/statistics.webp'"
      imgAlt="TODO"
      [link]="'./statistics'"
      [title]="'Statistiken'"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
