<wv-card [title]="'Rollen'">
  <form [formGroup]="assignRolesForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
      <input formControlName="email" matInput type="email" />
      <mat-error *ngIf="assignRolesForm.get('email').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="assignRolesForm.get('email').errors?.email">
        {{ 'form.error.invalidEmail' | transloco }}
      </mat-error>
      <mat-error *ngIf="assignRolesForm.get('email').errors?.username">
        {{ 'form.error.invalidUserName' | transloco }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'form.label.role' | transloco }}</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of managedRoles" [value]="role">{{ role }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="actions">
    <button (click)="addRole()" [disabled]="assignRolesForm.invalid" appearance="secondary" wvButton>
      {{ 'administration.roleManagement.addRoleButton' | transloco }}
    </button>
    <button (click)="removeRole()" [disabled]="assignRolesForm.invalid" appearance="secondary" wvButton>
      {{ 'administration.roleManagement.removeRoleButton' | transloco }}
    </button>
  </div>
</wv-card>
