<ng-container *ngIf="course$ | async as course">
  <app-course-header
    [continueLink]="continueLink$ | async"
    [description]="course.abstract"
    [imageUrl]="course.imageUrl"
    [isInProgress]="course.isInProgress"
    [title]="course.title"
    class="course-header"
  ></app-course-header>

  <div class="course-tabs" data-test="tab-course" data-testid="tab-course">
    <mat-tab-group [color]="'accent'" [disableRipple]="true" [dynamicHeight]="true" mat-align-tabs="start">
      <mat-tab [label]="'course.details.tabLabel.lessons' | transloco" data-test="tab-course-lessons">
        <app-course-details-playlist [course]="course"></app-course-details-playlist>
      </mat-tab>
      <mat-tab
        [label]="'course.details.tabLabel.description' | transloco"
        data-test="tab-course-description"
        data-testid="tab-course-description"
      >
        <app-course-details-description [description]="course.description"></app-course-details-description>
      </mat-tab>
      <mat-tab *ngIf="course.hasExam" [label]="'course.details.tabLabel.exam' | transloco" data-test="tab-course-exam">
        <div *ngIf="course.isExamDisabled && !course.examPassed" class="exam-deactivated-container">
          {{ course.reasonForDisabledExam }}
        </div>
        <app-course-exam
          *ngIf="!course.isExamDisabled && !course.examPassed"
          [numberOfTasks]="course.numberOfTasks"
          [requiredNumberOfCorrectAnswers]="course.requiredNumberOfCorrectAnswers"
        ></app-course-exam>
        <div *ngIf="course.examPassed" class="exam-passed-container">
          <h3>{{ 'course.details.examPassed.title' | transloco }}</h3>
          <p>{{ 'course.details.examPassed.description' | transloco }}</p>
          <button (click)="requestCertificate(course.id)" [appearance]="'secondary'" wvButton>
            {{ 'course.requestCertificate' | transloco }}
          </button>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="courseResources?.length > 0"
        [label]="'course.details.tabLabel.resources' | transloco"
        data-test="tab-course-resources"
        data-testid="tab-course-resources"
      >
        <app-course-details-files [files]="courseResources"></app-course-details-files>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
