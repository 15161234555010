import {Component, input} from '@angular/core';

@Component({
  selector: 'app-phishing-mails-statistic-company-ranking',
  templateUrl: './phishing-mails-statistic-company-ranking.component.html',
  styleUrls: ['./phishing-mails-statistic-company-ranking.component.scss']
})
export class PhishingMailsStatisticCompanyRankingComponent {
  companyRanking = input.required<number>();
}
