import { Component } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {
  constructor(public urlBuilder: UrlBuilder) {}
}
