<form [formGroup]="form" class="grid grid-cols-2 gap-2">
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.companyName' | transloco }}</mat-label>
      <input formControlName="name" matInput />
      <mat-error *ngIf="form.get('name').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="form.get('name').errors?.pattern">
        {{ 'form.error.invalidCompanyName' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.companyDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.businessSector' | transloco }}</mat-label>
      <input
        [matAutocomplete]="auto"
        formControlName="businessSector"
        matInput
        type="text"
      />
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('businessSector').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="form.get('businessSector').errors?.requireMatch">
        {{ 'form.error.requireMatch' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.companyDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.employeeCount' | transloco }}</mat-label>
      <input
        formControlName="employeeCount"
        matInput
        type="number"
      />
      <mat-error *ngIf="form.get('employeeCount').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="form.get('employeeCount').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.employeeCountDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <div class="input-info-wrapper">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{ 'form.label.officeEmployeeCount' | transloco }}</mat-label>
      <input
        formControlName="officeEmployeeCount"
        matInput
        type="number"
      />
      <mat-error *ngIf="form.get('officeEmployeeCount').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="form.get('officeEmployeeCount').errors?.min">
        {{ 'form.error.min' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="'info-box.common.employeeCountDescription' | transloco"
      [gdpr]="'info-box.common.companyGdpr' | transloco"
    ></app-info-box>
  </div>
  <button
    [disabled]="form.invalid"
    appearance="primary"
    wvButton
    (click)="save()"
  >
    {{ 'control.saveButton' | transloco }}
  </button>
</form>
