<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Württembergische Versicherung - Assekuradeure'"
>
<div class="grid gap-4 grid-cols-4">
  <wv-card [title]="'Assekuradeure freischalten'" class="m-2">
    <mat-form-field [appearance]="'outline'" class="w-full">
      <mat-label>E-Mail</mat-label>
      <input matInput class="w-full">
    </mat-form-field>
    <button wvButton>Freischalten</button>
  </wv-card>
  <wv-card [title]="'Assekuradeure entfernen'" class="m-2">
    <mat-form-field [appearance]="'outline'" class="w-full">
      <mat-label>E-Mail</mat-label>
      <input matInput class="w-full">
    </mat-form-field>
    <button wvButton>Entfernen</button>
  </wv-card>
  <wv-card [title]="'Assekuradeure'" class="m-2 row-start-2 col-span-2">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          Name
        </th>
        <td mat-cell *matCellDef="let assekuradeur"> {{assekuradeur.name}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          E-Mail
        </th>
        <td mat-cell *matCellDef="let assekuradeur"> {{assekuradeur.email}} </td>
      </ng-container>
      <ng-container matColumnDef="referrals">
        <th mat-header-cell *matHeaderCellDef>
          Registrierungen
        </th>
        <td mat-cell *matCellDef="let assekuradeur"><div class="text-end">{{assekuradeur.referrals}}</div></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </wv-card>
</div>
</app-back-layout>
