<app-back-layout
  [backUrl]="urlBuilder.goToAdministration()"
  [title]="'Administration - Agenturen'"
>
  <section class="cards">
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/consultation.webp'"
      imgAlt="TODO"
      [link]="'./consultation'"
      [title]="'Beratungsgespräch'"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="'./assets/images/admin/promotional-materials.webp'"
      imgAlt="TODO"
      [link]="'./promotional-materials'"
      [title]="'Werbematerialien'"
    ></app-cyber-portal-overview-item>
  </section>
</app-back-layout>
