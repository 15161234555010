import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';
import { CompanyService, UpdateCompanyDto } from './company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrl: './company-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyEditComponent {
  protected urlBuilder = inject(UrlBuilder);
  #companyService = inject(CompanyService);
  #identityService = inject(IdentityService);
  #router = inject(Router);
  #companyId = this.#identityService.getCompanyId();
  protected company = this.#companyService.findCompany(this.#companyId).result;

  updateInformation(dto: UpdateCompanyDto) {
    this.#companyService.updateInformation().mutate({ id: this.#companyId, dto: dto });
  }

  async deleteCompany() {
    await this.#companyService
      .delete()
      .mutateAsync({ id: this.#companyId, dto: { deletePhishingMailInformation: false } });

    await this.#router.navigateByUrl(this.urlBuilder.goToLogout());
  }
}
