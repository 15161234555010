import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-user-invitation-dialog',
  templateUrl: './user-invitation-dialog.component.html',
  styleUrls: ['./user-invitation-dialog.component.scss']
})
export class UserInvitationDialogComponent {
  constructor(public dialogRef: MatDialogRef<UserInvitationDialogComponent>) {}
}
