import { Component } from '@angular/core';
import { environment } from '@env';
import { IdentityService } from '../../identity/identity.service';

@Component({
  selector: 'app-help-documents',
  templateUrl: './help-documents.component.html',
  styleUrls: ['./help-documents.component.scss']
})
export class HelpDocumentsComponent {
  environment = environment;

  constructor(public identityService: IdentityService) {}
}
