import { Component, computed, effect, inject, signal } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';
import { DateRange, Preset } from '@ac/colibri';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { Router } from '@angular/router';
import { isDate } from '../../utils/is-date';
import { injectParams } from 'ngxtension/inject-params';
import { Location } from '@angular/common';
import { UrlBuilder } from '../../url-builder.service';
import { PhishingMailsUserStatisticService } from '../phishing-mails-user-statistic.service';

@Component({
  selector: 'app-phishing-mails-user-statistic',
  templateUrl: './phishing-mails-user-statistic.component.html',
  styleUrls: ['./phishing-mails-user-statistic.component.scss']
})
export class PhishingMailsUserStatisticComponent {
  protected range = signal<DateRange | null>(null);
  #params = injectParams();
  #urlBuilder = inject(UrlBuilder);
  #location = inject(Location);
  #queryRange = injectQueryParams(params => ({ from: params.from, to: params.to }));
  #identityService = inject(IdentityService);
  #userId = computed(() => this.#params().userId || this.#identityService.getUserId());
  #phishingMailsStatisticService = inject(PhishingMailsUserStatisticService);
  #phishingMailsUserStatisticQuery = this.#phishingMailsStatisticService.getStatistics(this.#userId());
  protected phishingMailsUserStatistic = this.#phishingMailsUserStatisticQuery.result;
  #phishingMailOptions = this.#phishingMailsStatisticService.getStatisticOptions(this.#userId()).result;
  protected min = computed(() =>
    this.#phishingMailOptions().data ? new Date(this.#phishingMailOptions().data.min) : null
  );
  protected max = computed(() =>
    this.#phishingMailOptions().data ? new Date(this.#phishingMailOptions().data.max) : null
  );
  protected presets = computed<Preset[]>(() =>
    this.#phishingMailOptions().data
      ? this.#phishingMailOptions().data.presets.map(p => ({
          label: p.label,
          range: { from: new Date(p.range.from), to: new Date(p.range.to) }
        }))
      : []
  );
  protected dateRangeDisabled = computed(
    () => this.#phishingMailOptions().data && !this.#phishingMailOptions().data?.allowConfiguration
  );

  constructor() {
    effect(() => this.#handleMissingUserIdUrlParam(), { allowSignalWrites: true });
    effect(() => this.#initializeStatisticDateRange(), { allowSignalWrites: true });
    effect(() => this.#updateStatisticDateRangeOnInputChange(), { allowSignalWrites: true });
    effect(() => this.#updateStatisticDateRangeFromQueryParams(), { allowSignalWrites: true });
  }

  #handleMissingUserIdUrlParam() {
    this.#location.replaceState(this.#urlBuilder.goToPhishingMailsUser(this.#userId()));
  }

  #updateStatisticDateRangeFromQueryParams() {
    const from = isDate(this.#queryRange().from) ? new Date(this.#queryRange().from) : null;
    const to = isDate(this.#queryRange().from) ? new Date(this.#queryRange().to) : null;

    if (from && to && from >= this.min() && to <= this.max()) {
      this.range.set({ from, to });
    }
  }

  #updateStatisticDateRangeOnInputChange() {
    if (this.range()) {
      const queryOptions = this.#phishingMailsStatisticService.getStatisticsQueryOptions(this.#userId(), this.range());
      this.#phishingMailsUserStatisticQuery.updateOptions(queryOptions);
      this.#location.replaceState(
        this.#urlBuilder.goToPhishingMailsUser(this.#userId()),
        `from=${this.range().from.toISOString()}&to=${this.range().to.toISOString()}`
      );
    }
  }

  #initializeStatisticDateRange() {
    if (!!this.range()) return;

    if (!this.#phishingMailOptions().data) return;

    this.range.set(this.#phishingMailOptions().data.defaultRange);
  }
}
