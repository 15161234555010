import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { LoadingDialogService } from '../layouts/loading-dialog/loading-dialog.service';
import { LoadingDialogComponent } from '../layouts/loading-dialog/loading-dialog.component';
import { catchError, tap } from 'rxjs/operators';
import { LoadingDialogState } from '../layouts/loading-dialog/loading-dialog-state.enum';
import { RansomClauseDto } from './ransom-clause.dto';

@Injectable({ providedIn: 'root' })
export class RansomClausesService {
  private endpoint = environment.apiUrl + environment.apiRoutes.ransomClauses;

  constructor(private http: HttpClient, private loadingDialog: LoadingDialogService) {}

  confirmRansomClause(data: RansomClauseDto): Observable<void> {
    this.loadingDialog.open(LoadingDialogComponent);
    return this.http.post<void>(this.endpoint, data).pipe(
      tap(() => this.loadingDialog.close()),
      catchError(message => {
        this.loadingDialog.setData({
          data: LoadingDialogState.Error,
          content: message.error,
          afterClose: () => {
            location.reload();
            return new Promise(() => true);
          }
        });
        throw message;
      })
    );
  }
}
