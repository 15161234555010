<app-back-layout [backUrl]="urlBuilder.goToLogin()" title="Passwort vergessen">
  <div class="container">
    <p>{{ 'passwordReset.description' | transloco | renderHTML }}</p>
    <div class="form-container">
      <wv-card
        [title]="'passwordReset.requestSubtitle' | transloco"
        [overline]="'passwordReset.requestTitle' | transloco"
      >
        <form (ngSubmit)="onSubmit(passwordResetRequestForm, $event)" [formGroup]="passwordResetRequestForm" class="relative">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'form.label.email' | transloco }}</mat-label>
            <input formControlName="email" matInput type="email" />
            <mat-error *ngIf="passwordResetRequestForm.get('email').errors?.required">
              {{ 'form.error.required' | transloco }}
            </mat-error>
            <mat-error *ngIf="passwordResetRequestForm.get('email').errors?.email">
              {{ 'form.error.invalidEmail' | transloco }}
            </mat-error>
            <mat-error *ngIf="passwordResetRequestForm.get('email').errors?.username">
              {{ 'form.error.invalidUserName' | transloco }}
            </mat-error>
          </mat-form-field>
          <div class="recaptcha">
            @if (acceptedRecaptcha()) {
              <re-captcha formControlName="recaptcha"></re-captcha>
            }@else {
              <ac-recaptcha-consent (accepted)="acceptedRecaptcha.set(true)"></ac-recaptcha-consent>
            }
          </div>
          <button
            [disabled]="passwordResetRequestForm.invalid"
            appearance="primary"
            class="submit-form-button"
            wvButton
          >
            {{ 'passwordReset.submitButton' | transloco }}
          </button>
        </form>
      </wv-card>
    </div>
  </div>
</app-back-layout>
