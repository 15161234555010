import { UpdateEmailDto } from '@ac/models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-user-edit-email-landing-page',
  templateUrl: './user-edit-email-landing-page.component.html',
  styleUrls: ['./user-edit-email-landing-page.component.scss']
})
export class UserEditEmailLandingPageComponent implements OnInit {
  token: string;
  email: string;
  updateEmailForm: UntypedFormGroup;
  passwordHide = true;
  private userId: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    public urlBuilder: UrlBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.token = decodeURIComponent(queryParams.token);
      if (this.token === 'undefined') {
        this.router.navigateByUrl(this.urlBuilder.goToUserEdit());
      }
    });
    this.route.queryParams.subscribe(queryParams => {
      this.userId = decodeURIComponent(queryParams.userId);
      if (this.userId === 'undefined') {
        this.router.navigateByUrl(this.urlBuilder.goToUserEdit());
      }
    });
    this.route.queryParams.subscribe(queryParams => {
      this.email = decodeURIComponent(queryParams.email);
      if (this.email === 'undefined') {
        this.router.navigateByUrl(this.urlBuilder.goToUserEdit());
      }
    });

    this.updateEmailForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
  }

  onSubmit(updateEmailForm: UntypedFormGroup, $event: Event) {
    $event.preventDefault();

    if (updateEmailForm.invalid) {
      return;
    }

    const dto: UpdateEmailDto = {
      email: this.email,
      userId: this.userId,
      token: this.token,
      password: this.updateEmailForm.get('password').value
    };

    this.identityService.updateEmail(dto).subscribe();
  }
}
