import { ExaminationConfiguration } from '@ac/exam';
import { Injectable } from '@angular/core';
import { course1Exam, course3Exam, course4Exam, course6Exam } from './exams';

@Injectable({ providedIn: 'root' })
export class CourseExamRepository {
  exams: { [key: string]: ExaminationConfiguration } = {
    'bbc368ea-0de3-4476-b87b-1a0ee45713e1': course1Exam,
    '03bf441e-8a9e-49a3-8217-ec51bc4f829f': course3Exam,
    'e592c346-f359-46ad-aa57-dcec81bdf638': course4Exam,
    '7fa01a9b-f6f9-45e0-9c3c-782ef497913b': course6Exam
  }; //todo: add courseId of course6
  getExamByCourseId(courseId: string): ExaminationConfiguration {
    return this.exams[courseId];
  }
}
