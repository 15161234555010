<wv-card [title]="'toolkit.passwordCheck.title' | transloco">
  <form [formGroup]="passwordCheckForm">
    <div class="input-info-wrapper">
      <mat-form-field
        appearance="outline"
        class="full-width"
      >
        <mat-label>{{ 'form.label.password' | transloco }}</mat-label>
        <input
          data-testid="password-input"
          [type]="passwordHide ? 'password' : 'text'"
          formControlName="password"
          matInput
        />
        <button
          (click)="passwordHide = !passwordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide"
          [tabIndex]="-1"
          mat-icon-button
          matSuffix
          type="button"
        >
          <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="passwordCheckForm.get('password').errors?.required">
          {{ 'form.error.required' | transloco }}
        </mat-error>
      </mat-form-field>
      <app-info-box [gdpr]="'info-box.passwordCheck.gdpr' | transloco"></app-info-box>
    </div>
    <button
      (click)="checkPassword()"
      [disabled]="passwordCheckForm.invalid"
      appearance="secondary"
      wvButton
      data-testid="check-password-button"
    >
      {{ 'toolkit.passwordCheck.submitButton' | transloco }}
    </button>
  </form>
</wv-card>
