import { ChangeDetectionStrategy, Component, computed, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ColibriModule } from '@ac/colibri';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-partner-kurse-bewertung',
  standalone: true,
  imports: [CommonModule, MatTableModule, ColibriModule, MatIcon],
  templateUrl: './partner-statistics-kurse-bewertung.component.html',
  styleUrl: './partner-statistics-kurse-bewertung.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerStatisticsKurseBewertungComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  data = signal([
    {
      kurse: 'Effektives Basiswissen',
      bewertung: [1, 1, 1, 0, 0]
    },
    {
      kurse: 'Sicher per E-Mail kommunizieren',
      bewertung: [1, 1, 0, 0, 0]
    },
    {
      kurse: 'Endlich sichere Passwörter',
      bewertung: [1, 1, 1, 1, 0]
    },
    {
      kurse: 'Jederzeit sicher Surfen',
      bewertung: [1, 1, 1, 1, 0]
    },
    {
      kurse: 'Augen auf beim Online Banking',
      bewertung: [1, 1, 1, 1, 1]
    },
    {
      kurse: 'Schnelleinstieg Datenschutz',
      bewertung: [1, 0, 0, 0, 0]
    }
  ]);

  tableSettings = signal([
    {
      columnName: 'kurse',
      columnTitle: 'Kurse'
    },
    {
      columnName: 'bewertung',
      columnTitle: 'Bewertung'
    }
  ]);

  displayedColumns = computed(() => {
    return this.tableSettings().map(({ columnName }) => columnName);
  });

  ngOnInit(): void {
    this.dataSource.data = this.data();
  }
}
