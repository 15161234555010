<wv-card
  [title]="'company.edit.delete' | transloco"
>
  <p>{{ 'company.edit.deleteInfo' | transloco }}</p>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ 'form.label.companyId' | transloco }}</mat-label>
      <input formControlName="id" matInput type="text">
      <mat-error *ngIf="form.get('id').errors?.required">{{ 'form.error.required' | transloco }}</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="deletePhishingMailInformation">Phishing-Mail Informationen löschen</mat-checkbox>
  </form>
  <button (click)="deleteCompany()" [disabled]="form.invalid" appearance="secondary" wvButton class="mt-4">
    {{ 'company.edit.deleteButton' | transloco }}
  </button>
</wv-card>
