<ac-task-header [configuration]="configuration"></ac-task-header>

<form (ngSubmit)="extractPasswordFromSentence()" [formGroup]="passwordMemorizationSentence">
  <mat-form-field appearance="outline" class="exam__form-field full-width">
    <textarea [attr.disabled]="sentenceSubmitted" formControlName="sentence" label="Antwort" placeholder="Merksatz" matInput></textarea>
    <mat-error *ngIf="hasSentenceError('containsDigits')">
      {{ 'exam.passwordMemorization.error.requireDigit' | transloco }}
    </mat-error>
    <mat-error *ngIf="hasSentenceError('containsLowercase')">
      {{ 'exam.passwordMemorization.error.requireLowercase' | transloco }}
    </mat-error>
    <mat-error *ngIf="hasSentenceError('containsUppercase')">
      {{ 'exam.passwordMemorization.error.requireUppercase' | transloco }}
    </mat-error>
    <mat-error *ngIf="hasSentenceError('containsSpecialCharacter')">
      {{ 'exam.passwordMemorization.error.requireNonAlphanumeric' | transloco }}
    </mat-error>
    <mat-error *ngIf="hasSentenceError('wordsNeeded')">
      {{ 'exam.passwordMemorization.error.requireWordCount' | transloco }}
    </mat-error>
  </mat-form-field>
  <div class="commands">
    <button [disabled]="passwordMemorizationSentence.invalid || sentenceSubmitted" appearance="secondary" wvButton>
      {{ 'exam.checkButton' | transloco }}
    </button>
  </div>
</form>

<form
  (ngSubmit)="submitAnswer()"
  *ngIf="sentenceSubmitted"
  [formGroup]="passwordMemorizationExercise"
  class="second-question"
  #answerTwoForm
>
  <p class="task pt-16">{{ 'exam.passwordMemorization.description' | transloco | renderHTML }}</p>
  <mat-form-field appearance="outline" class="exam__form-field">
    <mat-label>{{ 'exam.answer' | transloco }}</mat-label>
    <input [attr.disabled]="formsSubmitted.exercise" formControlName="password" placeholder="Abgeleitetes Passwort" matInput type="text" />
  </mat-form-field>
  <div class="commands">
    <button
      [disabled]="formsSubmitted.exercise || passwordMemorizationExercise.invalid"
      appearance="secondary"
      wvButton
    >
      {{ 'exam.submitAnswerButton' | transloco }}
    </button>
  </div>
</form>
