import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColibriModule } from '@ac/colibri';
import { environment } from '@env';
import {LayoutsModule} from "../../../layouts/layouts.module";

@Component({
  selector: 'app-promotional-materials-agenturen',
  standalone: true,
  imports: [CommonModule, ColibriModule, LayoutsModule],
  templateUrl: './promotional-materials-agenturen.component.html',
  styleUrl: './promotional-materials-agenturen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionalMaterialsAgenturenComponent {
  protected readonly environment = environment;
}
