<app-back-layout
  [backUrl]="'../'"
  [title]="'Administration - Vogel & Partner - Statistiken'"
>
  <mat-tab-group
    [disableRipple]="true"
    [dynamicHeight]="true"
    class="w-full"
    color="accent"
  >
    <mat-tab
      [label]="'Kurse'"
    >
      <ng-template matTabContent>
        <section class="grid gap-4 p-2">
          <h3 class="p-0">Teilnahme an Ihren Kursen</h3>
          <div class="grid grid-cols-2 gap-8">
            <app-partner-courses-business-sector-usage-statistic class="col-span-2"></app-partner-courses-business-sector-usage-statistic>
            <app-partner-courses-user-usage-statistic></app-partner-courses-user-usage-statistic>
          </div>
        </section>
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Materialien'"
    >
      <ng-template matTabContent>
        <section class="grid gap-4 p-2">
          <h3 class="p-0">Nutzung Ihrer Materialien</h3>
          <div class="grid gap-8">
            <app-partner-resource-clicks-statistic />
            <app-partner-latest-resource-clicks-statistic />
          </div>
        </section>
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Umfragen'"
    >
      <ng-template matTabContent>
        <section class="grid gap-4 p-2">
          <h3 class="p-0">Antworten zu Ihren Umfragen</h3>
          <div class="grid grid-cols-2 gap-8">
            <app-partner-naechsten-themen />
            <app-partner-fehlenden-ressourcen />
            <app-partner-kurse-bewertung />
          </div>
        </section>
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Werkzeuge'"
    >
      <ng-template matTabContent>
        <section class="grid gap-4 p-2">
          <h3 class="p-0">Einsatz Ihrer Werkzeuge</h3>
          <div class="grid grid-cols-2 gap-8">
            <app-partner-toolkit-business-sector-usage-statistic class="col-span-2" />
            <app-partner-toolkit-user-usage-statistic />
          </div>
        </section>
      </ng-template>
    </mat-tab>
    <mat-tab
      [label]="'Webinare'"
    >
      <ng-template matTabContent>
        <section class="grid gap-4 p-2">
          <h3 class="p-0">Analyse Ihrer Webinare</h3>
          <div class="grid gap-8 grid-cols-2">
            <app-partner-webinar-statistic-circle-progress />
            <app-partner-planned-webinar-statistic></app-partner-planned-webinar-statistic>
            <app-partner-webinar-statistic class="col-span-2"/>
          </div>
        </section>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
